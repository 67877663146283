import * as React from 'react';
import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import {makeStyles, useMediaQuery} from '@material-ui/core'
import classes from './PersonalAreaTabBill.module.css'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import {arWidgetDownloadFileTariffs, arWidgetGetTariffsOrganization} from '../../Api/Utils/Utils';
import {useHistory} from 'react-router-dom';
import {stringify} from 'query-string';
import { ArDownloadIcon } from '../../../Assets/Assets';

const tablePaginationClasses = {
    actions: 'actions',
    selectRoot: 'selectRoot',
    caption: 'caption'
  }

const useStyles = makeStyles({
    '@media (max-width: 768px)': {
        pagination: {
          marginBottom: '15px',
         '& .MuiToolbar-root': {
            flexWrap: 'wrap !important'
         },
         '& .MuiTypography-body2': {
           fontSize: '12px !important'
         },
         '& .actions': {
          marginLeft: '5px !important'
         },
         '& .actions .MuiIconButton-root': {
          padding: '2px'
         },
         '& .selectRoot': {
           marginLeft: '5px',
           marginRight: '5px'
         }
        }
      },
})

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {

    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'date',
        numeric: false,
        disablePadding: true,
        contentKey: 'startDate',
        label: 'Дата',
    },
    {
        id: 'name',
        numeric: true,
        disablePadding: false,
        contentKey: 'tariff',
        label: 'Тариф',
    },
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const customIcon = () => {
        return (
            <span className={order === 'desc' ? classes.btnTriangleUp : classes.btnTriangleDown}></span>
        )
    }

    return (
        <TableHead className={classes.billTableHead}>
            <TableRow className={`${classes.billTableRow} ${classes.billTableHeadRow}`}>

                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={`${classes.billTableCell} ${classes.billTableHeadCell}`}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            IconComponent={customIcon}
                            className={classes.billTableSortLabel}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell className={classes.billTableSortLabel} align={'left'}>Документы</TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


export default function EnhancedTable({organizationId}) {
    const styles = useStyles()
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('date');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [rows, setRows] = useState([])
    const [items, setItems] = useState([])
    const [info, setInfo] = useState([])
    const [searchFlag, setSearchFlag] = useState(false);
    const isSmall = useMediaQuery('(max-width:768px)')
    const currentPageSize = sessionStorage.getItem('pageSize') || 5
    let initialQueryParams = {
        page: 1,
        pageSize: currentPageSize,
        organizationId: organizationId
      };
    const [queryParams, setQueryParams] = useState(initialQueryParams);

    const history = useHistory()

    const reduceSearchStr = () => {
        let tempQueryParams = { ...queryParams};
        delete tempQueryParams.pageSize;
        delete tempQueryParams.organizationId;
    
        if (tempQueryParams.page === 1) {
          delete tempQueryParams.page;
        }
    
        for (let key in tempQueryParams) {
          if (tempQueryParams[key] === '') {
            delete tempQueryParams[key];
          }
        }
    
        return stringify(tempQueryParams);
      };
    
      const updateHistory = () => {
      
          history.push({
            pathname: '/personal',
            search: `?${reduceSearchStr()}`,
          });
        
      };

    const downloadIcon = (id, name) => {
        const handleDownloadFile = async () => {
            await arWidgetDownloadFileTariffs(id)
                .then(res => {
                    downloadFiles(res, `Файл ${name + '' + id}`)
                })
        }

        return (
            <div className={classes.downloadIcon} onClick={handleDownloadFile}>
                <ArDownloadIcon />
            </div>
        )
    }

    function downloadFiles(response, name) {
        const url = window.URL
            .createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    // Create rows of table
    function createData(date, name, downloadFile, id) {
        return {
            date,
            name,
            downloadFile,
            id
        };
    }

    const changeDate = (date) => {
        let timeStamp = new Date(date);
        const addZero = (datePart) => datePart.toString().padStart(2, '0');
        let dateValue = `${addZero(timeStamp.getDate())}.${addZero(timeStamp.getMonth() + 1)}.${timeStamp.getFullYear()}`;
        let timeValue = `${addZero(timeStamp.getHours())}:${addZero(timeStamp.getMinutes())}`;

        return dateValue + ' ' + timeValue;
    }

    const getTariffsOrganization = async () => {
        await arWidgetGetTariffsOrganization({ params: queryParams })
            .then(res => {
                setItems(res.data.items)
                setInfo(res.data.info)
                let itemsRow = []
                res.data.items.map(item => {
                    itemsRow.push(createData(changeDate(item.startDate), item.tariff, downloadIcon(item.id, item.tariff), item.id))
                })
                setRows(itemsRow)
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setQueryParams({ ...queryParams, page: ++newPage });
        setSearchFlag(!searchFlag);
    
      };
    
      const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(+event.target.value);
        setQueryParams({ ...queryParams, pageSize: +event.target.value, page: 1 });
        sessionStorage.setItem('pageSize', (+event.target.value));
        setSearchFlag(!searchFlag);
    
      };

    useEffect(() => {
        getTariffsOrganization()
        updateHistory()
    }, [searchFlag])

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;


    // если ширина экрана меньше чем 768, тогда вернем не таблицу, а list
    const setParentId = (parentId) => localStorage.setItem('personal', parentId);
    const ListData = () => {
        return (
            <ul className={classes.listTabBill}>
                {items.map(item => {
                    return (
                        <li key={item.id.toString() + item.startDate.toString() + Math.random() * 100} className={classes.listItemTabBill}>
                            <div className={classes.listRowTabBill}>
                                <div className={classes.nameKeyTabBill}>Дата: </div>
                                <div className={classes.nameValueTabBill}>{changeDate(item.startDate)}</div>
                            </div>
                            <div className={classes.listRowTabBill}>
                                <div className={classes.nameKeyTabBill}>Тариф: </div>
                                <div className={classes.nameValueTabBill}>{item.tariff}</div>
                            </div>
                            <div className={classes.listRowTabBill}>
                                <div className={classes.nameKeyTabBill}>Документы: </div>
                                <div className={classes.nameValueTabBill}>{downloadIcon(item.id, item.tariff)}</div>
                            </div>
                        </li>
                    )
                })}
            </ul>
        )
    }

    return (
        <>
            <Box sx={{ width: '100%' }} >
                {!isSmall ?
                    <Paper sx={{ width: '100%', mb: 2 }} className={classes.billPaper}>
                        <TableContainer>
                            <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                                className={classes.billTable}
                            >
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                />
                                <TableBody className={classes.billTableBody}>
                                    {stableSort(rows, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    tabIndex={-1}
                                                    key={row.id}
                                                    className={classes.billTableRow}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                        align={'left'}
                                                        className={classes.billTableCell}
                                                    >
                                                        {row.date}
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.billTableCell}>{row.name}</TableCell>
                                                    <TableCell align="left" className={classes.billTableCell}>{row.downloadFile}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            className={classes.billTableRow}
                                        >
                                            <TableCell colSpan={6} className={classes.billTableCell} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>

                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 100]}
                                component="div"
                                count={info.rowCount || 1}
                                rowsPerPage={+currentPageSize}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelRowsPerPage={'Строк на странице'}
                                className={`${classes.pagination}`}
                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
                            />
                        </TableContainer>
                    </Paper>
                    : <ListData />}
                {info === null || info.totalPages < 1 ? null : 
                    !isSmall ? <></> :
                    <TablePagination
                        className={`${styles.pagination} ${classes.pagination}`}
                        classes={tablePaginationClasses}
                        rowsPerPageOptions={[ 5, 10, 25, 100]}
                        component='div'
                        count={info.rowCount}
                        rowsPerPage={+currentPageSize}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={'Строк на странице'}
                        style={{ color: '#000 !important' }}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
                    />
                }
            </Box>
        </>
    );
}