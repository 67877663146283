import React, {useRef, useState} from 'react';
import {arWidgetPostImage} from '../../Api/Utils/Utils';
import {Input} from "@material-ui/core";
import {FileUploadStyles} from "./FileUploadStyles";
import {NoImage} from "../../../Assets/Assets";
import Toast from '../../Toast/Toast';

const FileUpload = ({ hidden, name, productId, accept, getUpdate, htmlFor, product }) => {
  const classes = FileUploadStyles();
  const fileInputRef = useRef(null);
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('error')

  const clearInput = (event) => {
    event.target.value = null;
  };


  const handleChange = async (event) => {
    const file = fileInputRef.current.files[0]
    const formData = new FormData()
    formData.append('image', file)
    await arWidgetPostImage(productId, formData)
      .then(() => {
        getUpdate();
      })
      .catch((error) => {
        console.error('Error:', error);
        setErrorMessage(error)
        setError(true)
      });
  };

  if (hidden) {
    return null;
  }
  return (
    <>
    {!error ? <></> : <Toast error={error} message={errorMessage}/>}
    <div>
      <label htmlFor={htmlFor}>
      {product.images.length === 0 ? (
        <NoImage className={classes.image} />
      ) : (
        <img
          className={classes.image}
          src={product.images[product.images.length - 1].url}
          alt={`${product.name} изображение`}
        />
      )}

        <Input
          id={htmlFor}
          inputProps={{ accept: accept }}
          type="file"
          className={classes.input}
          onChange={handleChange}
          onClick={clearInput}
          inputRef={fileInputRef}
          // disabled={!!product.images[0]}
        />

      </label>
    </div>
    </>
  );
};

export default FileUpload;
