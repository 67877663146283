import React, {Fragment, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    background: '#626F78',
    '&:after' : {
      bottom: '1px',
    }
  },
  menuItemOpen: {
    background: '#29ABE2 !important',
  },
  icon: { minWidth: '40px !important',
          color: 'white',},
  sidebarIsOpen: {
    '& a': {
      paddingLeft: theme.spacing(4),
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
  },
  sidebarIsClosed: {
    '& a': {
      paddingLeft: theme.spacing(2),
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
  },
  subMenuLibrary: {
    position: 'relative',
    '&:before' : {
      content: '""',
      width: 'calc(100% - 20px)',
      height: '1px',
      background: '#29ABE2',
      position: 'absolute',
      bottom: 0,
      left: '10px',
      display: 'block !important',
      zIndex: 5
    },
    "& .MuiButtonBase-root:hover:active::after": {
      display: 'none !important',
    },
  }
}));

const SubMenu = ({ handleToggle, sidebarIsOpen, isOpen, name, icon, children, dense, attribute }) => {
  const classes = useStyles();
  const header = (
    <MenuItem className={isOpen ? classes.menuItemOpen : classes.menuItem} dense={dense} button onClick={handleToggle}>
      <ListItemIcon className={classes.icon}>{isOpen ? <ExpandMore /> : icon}</ListItemIcon>
      <Typography variant="inherit" color="white">
        {name}
      </Typography>
    </MenuItem>
  );

  useEffect(() => {
    if(window.location.href === localStorage.getItem('activeMenu')){}
  }, [])

  return (
    <div data-name='subMenuLibrary'  data-attr={attribute} className={classes.subMenuLibrary}>
    <Fragment>
      {sidebarIsOpen || isOpen ? (
        header
      ) : (
        <Tooltip title={name} placement="right">
          {header}
        </Tooltip>
      )}

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          dense={dense}
          component="div"
          disablePadding
          data-id='subMenuListId'
          className={sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed}
        >
          {children}
        </List>
      </Collapse>
    </Fragment>
    </div>
  );
};

export default SubMenu;
