import React, {useContext, useEffect, useState} from 'react';
import {Layout, Sidebar} from 'react-admin';
import Menu from '../Menu';
import classes from './DefaultLayout.module.css';
import DefaultBar from '../DefaultBar/DefaultBar';
import {makeStyles} from '@material-ui/core/styles';
import MyError from '../../Error/Error';
import UserProfile from '../../UserProfile/UserProfile';
import TechnicalSupport from '../../Tables/TechnicalSupport/TechnicalSupport';
import Loading from '../../Loading/Loading'
import Toast from '../../Toast/Toast';
import {NotificationContext} from '../../../App';
import {useMediaQuery} from '@material-ui/core';
import {useHistory} from 'react-router-dom'

const CustomAppBar = (props) => <DefaultBar {...props} />;
const CustomMenu = (props) => <UserProfile {...props} />

const useAppBarStyles = makeStyles({
    appBar: {
        width: '100%',
        background: '#343A40',
        color: '#fff',
        border: 'none',
    },
    toolbar: {
        label: {
            color: '#fff',
        },
    },
    colorDefault: {
        color: '#626F78'
    },
    icon: {
        color: '#fff'
    }
});


const useSidebarStyles = makeStyles({
    fixed: {
        position: 'relative',
        '&::before': {}
    },
    drawerPaper: {
        position: 'relative',
        '&::before': {
            content: '""',
            position: 'fixed',
            left: 0,
            top: 0,
            width: 'inherit',
            height: '100%',
            background: '#626F78',
        }
    },
});


const DefaultLayout = (props) => {
    // const theme = useSelector((state) => {
    //     state.theme === 'dark' ? darkTheme : lightTheme
    // })
    const [state, dispatch] = useContext(NotificationContext);

    const isSmall = useMediaQuery('(max-width:650px)');

    const history = useHistory()

    const styles = useSidebarStyles()

    const CustomSidebar = (props) => <Sidebar classes={styles} {...props} size={200}/>;

    let user = localStorage.getItem('currentUser')
    const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem('currentUser')))

    const startTimeOut = () => {
        setTimeout(() => {
            if (user) {
                setCurrentUser(user)
            } else {
                setCurrentUser(true)
            }
        }, 3000);
    }


    useEffect(() => {

        if(!localStorage.getItem('token')){
            history.push('/login')
            return
        }

        startTimeOut()
    }, [])

    useEffect(() => {
        dispatch({type: 'ADD_CURRENT_USER', payload: {...state, currentUser: JSON.parse(user)}});
    }, [currentUser])

    if (!currentUser) {
        return <Loading/>
    }

    return (

        <Layout {...props}
                className={classes.LayoutApp}
                sidebar={CustomSidebar}
                appBar={CustomAppBar}
                menu={Menu}
                technicalSupport={TechnicalSupport}
                error={MyError}
                notification={Toast}
        />
    )
};

export default DefaultLayout;
