import { useEffect, useState } from 'react'
import {useHistory} from 'react-router'
import { langManager } from '../../../utils/languageManager/manager'

function ViewModels(props) {

  const [searchParam, setSearchParam] = useState('')
  const history = useHistory()

  //Переключение языков **************
  const [$, setViewModels] = useState(langManager.$.pages.viewModels)
  langManager.subscribe(($) => {
    setViewModels($.pages.viewModels)
    document.title = $.pages.viewModels.title
  })
  //********************************/

  useEffect(() => {
    document.title = $.title
    let searchParams = new URLSearchParams(history.location.search)
    let qr = searchParams.get("qr")
    if(qr){
      setSearchParam(qr)
    }else if (props.history.location.state && props.history.location.state.qr){
      setSearchParam(props.history.location.state.qr)
    }

    localStorage.clear()

  }, [])

  useEffect(() => {

    if(!searchParam){
      return false
    }

    const script = document.createElement('script');
    console.log(script)
    script.src = `${process.env.REACT_APP_WIDGET_URL}?qr=${searchParam}`;
    script.async = true;
    script.id = 'AR-widget-alheemea'
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  }, [searchParam]);

  return (
    <></>
  );
}

export default ViewModels;