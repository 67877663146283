import {TextField} from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import {useEffect, useState} from "react"
import {arWidgetGetOrganizations} from "../../Api/Utils/Utils"
import classes from './AutoComplete.module.css'
import { langManager } from '../../../utils/languageManager/manager';

const AutoComplete = ({ name, parentValue, setParentValue, isClear, setIsClear, width=300 }) => {

    
    // Переключение языка
    const [$, setAutoComplete] = useState(langManager.$.components.autoComplete)
    langManager.subscribe(($) => {
        setAutoComplete($.components.autoComplete)
    })
    //**************

   // parentValue нужен только тогда когда queryParams нужно добавить в существующие кверй параметры, а не польностью изменить
   // isClear и setIsClear нужен если надо очистить автокомплейт из родительского компонента, если нет необходимости очистить авктокомплейт из родительского компонента передавать isClear={false}
    const [choices, setChoices] = useState([])
    const [valueAutoComplete, setValueAutoComplete] = useState('')
    const [value, setValue] = useState('')

    const getOptionSelected = (option, value) => {
        return option.id === value.id
    }

    const handleOptionAutoComplete = (choice) => {
        if (choice.name) {
            return choice.name
        } else {
            return ''
        }
    }

    const handleChangeAutoComplete = (event, value, name) => {
        setValueAutoComplete(value)
        
        if (!value && !parentValue) {
            setParentValue({})
        } else if (!value && parentValue) {
            setParentValue({ ...parentValue, [name]: '' })
        } else if (value && parentValue) {
            setParentValue({ ...parentValue, [name]: value.id })
        } else {
            setParentValue(value)
        }
    }

    const getOrganizations = async () => {
        const params = {
            name: value
        }
        await arWidgetGetOrganizations({ params: params })
            .then(res => {
                let options = []
                res.data.items.map(item => {
                    const option = {
                        id: item.id,
                        name: item.name
                    }
                    options.push(option)
                })
                setChoices(options)
            }).catch(err => {
                console.log(err)
            })
    }
    useEffect(() => {
        if (value.length > 2 || value === '' || value.length === 0) {
            getOrganizations(value)
        }
    }, [value])

    useEffect(() => {
        if(isClear){
            setValueAutoComplete(null)
            setValue('')
            setIsClear(false)
        }
    }, [isClear])

    return (
        <>
            <Autocomplete
                id={`${name}Filter`}
                name={`${name}Name`}
                options={choices}
                value={valueAutoComplete}
                className={classes.arAutoComplete}
                onChange={(event, value) => handleChangeAutoComplete(event, value, name)}
                getOptionSelected={(option, value) => getOptionSelected(option, value)}
                getOptionLabel={(choice) => handleOptionAutoComplete(choice)}
                style={{ width: width, margin: '-15px 10px 0px 0px' }}
                inputValue={value}
                onInputChange={(event, newInputValue) => {
                    setValue(newInputValue)
                }}
                renderInput={(params) => <TextField {...params} label={$.organization} />}
            />
        </>
    )
}

export default AutoComplete