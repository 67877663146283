import {makeStyles} from '@material-ui/core/styles';
import {arColors} from '../../../../Assets/Assets';

export const ProductEditStyles = makeStyles({
  main: {},
  codeBlock: {
    '@media(max-width:1280px)': {
      margin: '0 0 40px 0'
    }
  },
  productInfo: {
    '@media(max-width:1280px)': {
      padding: '0 20px 0 20px'
    },
    '@media(max-width:680px)': {
      padding: '0 10px 0 10px'
    },
    '@media(max-width:380px)': {
      padding: '0 5px 0 5px'
    },
  },
  pageLink: {
    alignSelf: 'start',
    marginTop: '2rem',
    '& *': {
      color: arColors.blue,
      fontWeight: '800',
    },
    '@media(max-width:1280px)': {
      marginBottom: '20px'
    }
  },
  breadcrumbs: {
    marginLeft: '0.7rem',
    marginTop: '0.5rem',
    alignSelf: 'start',
  },

});
