import React, { useEffect, useState } from 'react'
import { NoImage } from "../../../../Assets/Assets";
import ActiveImageModel from "./ActiveImageModel";
import * as BABYLON from "babylonjs";
import 'babylonjs-loaders';
import { Box, CircularProgress } from '@material-ui/core';
import QRCode from 'qrcode.react';
import { langManager } from '../../../../utils/languageManager/manager';

const validator = require('gltf-validator');

let productModelName = ''
let model = ''


const ProductPanelActiveImage = ({ activeBtn, product, classes, showPanel }) => {
  const [showModel, setShowModel] = useState('')
  const [loading, setLoading] = useState(false)
  const [base64Text, setBase64Text] = useState('')
  const [loaded, setLoaded] = useState(false)
  const [responses, setResponses] = useState({})
  const [error, setError] = useState('')
  const rootUrl = process.env.REACT_APP_API_URL.substring(0, process.env.REACT_APP_API_URL.lastIndexOf('/api'))+'/upload/model/'

  /**
   * для вавилона, не использую внутри компонента с вавилоном, так как выкидывает ошибки, как только убираю из onSceneReady props
   * @param scene
   */
  const onSceneReady = (scene) => {
    // This creates and positions a free camera (non-mesh)
    var camera = new BABYLON.FreeCamera("camera1", new BABYLON.Vector3(0, 5, -10), scene);

    // This targets the camera to scene origin
    camera.setTarget(BABYLON.Vector3.Zero());

    const canvas = scene.getEngine().getRenderingCanvas();

    // This attaches the camera to the canvas
    camera.attachControl(canvas, true);

    if (base64Text !== '') {
      var base64_model_content = `data:base64,${base64Text}`;
      var raw_content = BABYLON.Tools.DecodeBase64(base64_model_content);
      var blob = new Blob([raw_content]);
      var url = URL.createObjectURL(blob);
      BABYLON.SceneLoader.Append("", url, scene, function () {
        scene.createDefaultCameraOrLight(true, true, true);
        scene.createDefaultEnvironment();
      }, undefined, undefined, ".glb");
    }
  };

  async function Base64Encode(str) {
    setBase64Text(btoa(
      new Uint8Array(str)
        .reduce((data, byte) => data + String.fromCharCode(byte), '')
    ))
    model = btoa(
      new Uint8Array(str)
        .reduce((data, byte) => data + String.fromCharCode(byte), '')
    )
  }


  const gblCheck = () => {
    if (product && product.models && product.models.length > 0 && productModelName !== product.models[0].modelGlb) {
      productModelName = product.models[0].modelGlb
      gblHandle()
    } else {
      // gblResponseHandle()
    }
  }

  // Переключение языка
  const [ $, setProductPanelActiveImage] = useState(langManager.$.components.productPanelActiveImage)
  langManager.subscribe(($) => {
    setProductPanelActiveImage($.components.productPanelActiveImage)
    document.title = $.components.productPanelActiveImage.title
  })
  //**********


  useEffect(() => {

    if (!product || !product.models || (product.models.length !== 0 && productModelName !== product.models[0].modelGlb)) {
      setShowModel('')
    }

    if (product && product.models && product.models.length !== 0) {
      if (productModelName === product.models[0].modelGlb && model) {
        setBase64Text(model)
        setShowModel('show')
      }
    }

  }, [(product && product.models && product.models.length !== 0) ? product.models[0].modelGlb : null])


  const gblHandle = async () => {
    fetch(rootUrl + product.models[0].modelGlb)
      .then((response) => {
        return response.arrayBuffer()
      })
      .then((asset) => {
        Base64Encode(asset)
        return validator.validateBytes(new Uint8Array(asset))
      })
      .then((report) => {
        setShowModel('show')
      })
      .catch((error) => {
        setShowModel('error-show')
      })
  }

  switch (activeBtn) {
    case 'img-product':
      if (!product || !product.images || product.images.length === 0) {
        return <NoImage className={classes.image} />
      } else {
        return (
          <img
            className={classes.image}
            src={product.images[product.images.length - 1].url}
            alt={`${product.name} изображение`}
          />)
      }
    case 'img-model':
      if (!product || !product.models || product.models.length === 0) {
        return <NoImage className={classes.image} />
      } else {
        return (
          <img
            className={classes.image}
            src={product.models[0].imageModel}
            alt={`${product.name} изображение`}
          />)
      }
    case 'qr-code':
      return (
        <div className={classes.qrCodeWrapper}>
          {
            (!product || !product.models || product.models.length < 1 || !product.base64String)
              ?
              <div>{$.noModels}</div>
              :
              <QRCode value={`${window.location.origin}/viewModel?qr=${product.base64String}`} />
          }
        </div>
      )
    case 'glb-model':
      if (!product || !product.models || product.models.length === 0) {
        return <NoImage className={classes.image} />
      } else {
        if (showPanel) {
          gblCheck()
        }

        return (
          <div className={classes.modelsWrapper}>
            {
              showModel === 'show' ?
                <ActiveImageModel antialias model={product.models[0].modelGlb} onSceneReady={onSceneReady} />
                :
                showModel === 'error-show' ?
                  <div className={classes.error}> {$.validationError} {error} </div>
                  :
                  <div className={classes.loadingWrapper}>
                    <p className={classes.loadingText}> {$.loading}</p>
                    <Box sx={{ display: 'flex' }} className={classes.loadingBox}>
                      <CircularProgress style={{ color: '#29ABE2' }} />
                    </Box>
                  </div>
            }
          </div>

        )

      }
    default:
      return null
  }


}

export default ProductPanelActiveImage
