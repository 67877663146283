import React, { useContext, useEffect, useState } from 'react';
import { Link } from '@material-ui/core';
import classes from './LoginPage.module.css';
import darkWindow from './Assets/darkWindow.jpg';
import AuthProvider from '../../Api/AuthProvider/AuthProvider';
import { useHistory } from 'react-router-dom';
import Toast from '../../Toast/Toast';
import { NotificationContext } from '../../../App';
import RemoveRedEyeOutlinedIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

import { langManager } from '../../../utils/languageManager/manager';

const LoginPage = () => {
  const [userData, setUserData] = useState({ username: '', password: '' });
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const history = useHistory();
  const link = useHistory().location.state
  const [state, dispatch] = useContext(NotificationContext);


  //Переключение языков **************
  const [loginPage, setLoginPage] = useState(langManager.$.pages.login)
  langManager.subscribe(($) => {
    setLoginPage($.pages.login)
    document.title = $.pages.login.title
  })
  //********************************/

  const handleInput = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
    setError(false)
  };

  const handleAuth = async (event) => {
    event.preventDefault();
    AuthProvider.login(userData).then(() => {

      if (link && link.nextPathname !== "/login") {
        history.push(link.nextPathname)
      } else {
        history.push('/')
      }
    }).catch(e => {
      setError(true)
      setErrorMessage(loginPage.userNotfound)
    })
  };



  const handleRestorePass = (event) => {
    event.preventDefault();
    history.push('/restorepass');
  };



  const handleRegistration = (e) => {
    e.preventDefault();
    history.push('/registration/organization');
  }



  useEffect(() => {
    document.title = loginPage.title
    dispatch({ type: 'CLEAR_STATE', payload: { ...state, error: false, errorMessage: '', currentUser: {} } });
    localStorage.clear()
  }, [])



  return (
    <>
      {!error ? <></> : <Toast error={error} errorMessage={errorMessage} />}
      <div className={classes.LoginPage}>
        <div className={classes.imgWrapper}>
          <img src={darkWindow} alt="userName image" />
        </div>
        <div className={classes.textWrapper}>
          <div className={classes.textContainer}>
            <h2>ALHEEMEA</h2>
            <h2>3d.viewer | vault</h2>
            <h4>{loginPage.subtitle} alheemea 3d.viewer</h4>
            <div className={classes.txtWrapper}>
              {loginPage.signInOr}{' '}
              <Link href='' onClick={handleRegistration} className={classes.linkRegister}>
                {loginPage.register}
              </Link>
            </div>
            <div className={classes.inputWrapper}>
              <label htmlFor="loginInput" className={classes.label}>{loginPage.login}:</label>
              <input
                id="loginInput"
                name="username"
                className={classes.input}
                // autoComplete="current-username"
                value={userData.username}
                onChange={(e) => handleInput(e)}
              />
              <div className={classes.inputBlock}>
                <label htmlFor="passwordInput" className={classes.label}>{loginPage.password}:</label>
                <input
                  id="passwordInput"
                  className={classes.input}
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  value={userData.password}
                  // autoComplete="current-password"
                  onChange={(e) => handleInput(e)}
                />
                {
                  showPassword
                    ?
                    <VisibilityOffOutlinedIcon
                      className={classes.iconEye}
                      onClick={() => setShowPassword(false)}
                    />
                    :
                    <RemoveRedEyeOutlinedIcon
                      className={classes.iconEye}
                      onClick={() => setShowPassword(true)}
                    />
                }
              </div>
            </div>
            <div className={classes.actionsWrapper}>
              <div className={classes.checkWrapper}>
                <input type="checkbox" name="" className={classes.checkbox} id="remember" />
                <label htmlFor="remember" className={classes.checkLabel} >{loginPage.rememberMe}</label>
              </div>
              <Link href="" className={classes.linkRestore} onClick={handleRestorePass}>
                {loginPage.forgotPassword}
              </Link>
            </div>
            <button className={classes.button} onClick={handleAuth}>
              {loginPage.enter}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
