import React, {useEffect, useState} from 'react';
import GuestBar from '../../layout/GuestBar/GuestBar';
import {Button, Link, TextField} from '@material-ui/core';
import {PassRestorePageStyles} from './PassRestorePageStyles';
import {arWidgetForgotPass} from '../../Api/Utils/Utils';
import {useHistory} from 'react-router';
import {langManager} from '../../../utils/languageManager/manager';

const PassRestorePage = () => {
  const classes = PassRestorePageStyles();
  const config = {
    title: 'Alh 3d',
  };

  const [verifyEmail, SetVerifyEmail] = useState(0)
  const [checkEmail, SetCheckEmail] = useState('PassRestorePage')

  const [email, setEmail] = useState('');

  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const handleInput = (e) => {
    setEmail(e.target.value);
    if (re.test(e.target.value)) {
      SetVerifyEmail('valid')
    } else {
      SetVerifyEmail(0)
    }
  };

  const history = useHistory()

  const handleClick = () => {


    const params = {
      email: email,
    };

    if (verifyEmail === 'valid') {
      arWidgetForgotPass(params)
        .then((response) => {
          if (response.status === 200) {
            SetCheckEmail('PassRestorePageSuccess')
          }
        })
        .catch(e => SetCheckEmail('PassRestorePageFail'))
    } else {
      SetVerifyEmail('unvalid')
    }
  };

  const goBack = (e) => {
    e.preventDefault()
    SetCheckEmail('PassRestorePage')
  }

  // Переключение языка
  const [ $, setRestorePage] = useState(langManager.$.pages.restorePage)
  langManager.subscribe(($) => {
    setRestorePage($.pages.restorePage)
    document.title = $.pages.restorePage.title
  })

  

  useEffect(() => {
    document.title = "Востановление пароля"
    localStorage.clear()
  }, [])

  if (checkEmail === 'PassRestorePage') {
    return (
      <>
        <GuestBar {...config} />

        <div className={classes.passRestorePage}>
        <div className={classes.subHeading}>{$.passwordRecovery}</div>
        <div className={classes.main}>
          <div className={classes.invite}>
            {$.pleaseEnterYourEmail}
          </div>
          <div className={classes.form}>
            <div className={classes.label}>e-mail</div>
            <input
              type="email"
              name="email"
              onChange={handleInput}
              value={email}
              className={`${verifyEmail === 'unvalid' ? classes.unvalidRestoreInput : classes.restoreInput} ${classes.input}`}
            />
            {verifyEmail === 'unvalid' ?  <div className={classes.failText}>{$.correctEmail}</div> : <></>}
          <Button variant="contained"  className={classes.restoreButton} onClick={handleClick}>
            {$.sendRequest}
          </Button>
         </div>
        </div>
</div>
      </>
    );
  }
  if (checkEmail === 'PassRestorePageSuccess') {
    return (
      <>
        <div className={classes.passRestorePage}>
          <div className={classes.subHeading}>{$.passwordRecovery}</div>
          <div className={classes.main}>
            <GuestBar {...config} />
            <div className={classes.successInfo}>{$.successEmail}</div>
          </div>
        </div>
        {/* <PassResetPage/> */}
      </>
    )
  }
  if (checkEmail === 'PassRestorePageFail') {
    return (
      <>
        <GuestBar {...config} />
        <div className={classes.passRestorePage}>
          <div className={classes.subHeading}>{$.passwordRecovery}</div>
          <div className={classes.main}>
            <div className={classes.successInfo}>
              <div className={classes.failText}>{$.failedEmail}</div>
              <Link href="#" onClick={goBack} variant="body2" color="primary" className={classes.failLink}>
                {$.showEmail}
              </Link>
            </div>
          </div>
        </div>
      </>
    )
  }



};
export default PassRestorePage;
