import React, { useEffect, useRef, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, TextField, useMediaQuery } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { ModelPackageStyles } from './ModelPackageStyles';
import FileButtonUpload from '../../../Molecules/FileButtonUpload/FileButtonUpload';
import { arWidgetGetModelArchive, arWidgetPostPackage } from '../../../Api/Utils/Utils';
import { currentUser } from "../../../../Helpers/helpers";
import Toast from '../../../Toast/Toast';
import LinearProgress from "@material-ui/core/LinearProgress";
import classNames from 'classnames';
import { ButtonCancel } from '../../../Buttons/ButtonCancel';
import { ButtonSave } from '../../../Buttons/ButtonSave';
import { ButtonEdit } from '../../../Buttons/ButtonEdit';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import { langManager } from '../../../../utils/languageManager/manager';
import { toast } from 'react-toastify';


const ModelPackage = ({ id, models }) => {
  const classes = ModelPackageStyles();
  const formRef = useRef(null);
  const [formState, setFormState] = useState(null);
  const [loadingFile, setLoadingFile] = useState(false)
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('error')

  //Переключение языка
  const [$, setModelPackage] = useState(langManager.$.components.modelPackage)
  langManager.subscribe(($) => {
    setModelPackage($.components.modelPackage)
  })
  //******************
  const isSmall = useMediaQuery('(max-width:1280px)');

  const [expanded, setExpanded] = useState(true);
  const [names, setNames] = useState({
    glb: null,
    usdz: null,
    image: null,
    imagePath: null,
    author: null,
    pack: null,
  });
  const [params, setParams] = useState({
    packName: '',
    authorModel: '',
  });
  const [disabled, setDisabled] = useState(false)

  const getImageName = (imagePath) => {
    if (!imagePath) {
      return;
    }
    let apiFolder = '/image/';
    return imagePath.substring(imagePath.indexOf(apiFolder) + apiFolder.length);
  };

  const getExistingModel = async () => {
    if (models.length > 0) {
      let currentModel = models[0];
      let imagePath = currentModel.imageModel;

      setNames((names) => ({
        ...names,
        glb: currentModel.modelGlb,
        usdz: currentModel.modelUsdz,
        imagePath: imagePath,
        image: getImageName(imagePath),
        author: currentModel.authorModel,
        pack: currentModel.packName,
        size: currentModel.size,
      }));

      //add query params when fields are not edited
      for (let param in params) {
        if (!params[param]) {
          setParams((params) => ({ ...params, [param]: currentModel[param] }));
        }
      }
      // setDisabled(!!currentModel.packName)
    }

  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleInput = (event) => {
    setError(false)
    const nam = event.target.name;
    let val = event.target.value;
    setParams({ ...params, [nam]: val });
  };

  const handleEdit = () => {
    // for (let key in names) {
    //   setNames((prevNames) => ({ ...prevNames, [key]: null }));
    // }
    setDisabled(true)
    setNames((prevNames) => ({
      ...prevNames,
      glb: null,
      usdz: null,
      image: null,
      author: null,
      pack: null,
    }));
  };

  const handleCancel = () => {

    getExistingModel()
    setDisabled(false)
  }



  const handleSave = () => {

    if (params.packName === null || params.packName === '') {
      setError(true)
      setErrorMessage($.enterNamePackage)

    } else {
      formRef.current.reportValidity();
      let formData = new FormData();

      if(!formState || !formState.fileGlb || !formState.imageModel || !params.packName){
        toast.error($.emptyFieldError, {
          toastId: 'CustomToastIdEmptyFields'
        })
        return false
      }

      timerLoader(0)

      for (let file in formState) {
        formData.set(file, formState[file]);
      }

      formData.set('packName', params.packName)
      formData.set('authorModel', params.authorModel)
      arWidgetPostPackage(id, formData)
        .then((response) => {
          timerLoader(0)
          let uploadedModel = response.data.items;
          let imagePath = uploadedModel.imageModel;

          setNames({
            glb: uploadedModel.modelGlb,
            usdz: uploadedModel.modelUsdz,
            imagePath: imagePath,
            image: getImageName(imagePath),
            author: uploadedModel.authorModel,
            pack: uploadedModel.packName,
          });
        })
        .then(() => {
          setFormState(null);
        })
        .then(() => {
          setDisabled(false)
        })
        .then(() => {
          const params = {
            page: 1,
            pageSize: 3,
            organization: currentUser.organizationId,
            id: id,
          };
          arWidgetGetModelArchive({ params })
          timerLoader(100)
          setProgress(100)
        })
        .finally(() => {

          // formRef.current.reset();
          for (let key of formData.keys()) {
            formData.delete(key);
          }
          // setParams(null);
        })
        .catch((error) => {
          timerLoader(100)
          setDisabled(false)
        });
    }

  };

  function timerLoader(x) {
    let initialNum = 0
    let timer;
    if (x !== 100) {
      setLoadingFile(true)
      timer = setInterval(() => {
        setProgress(oldProgress => {
          if (oldProgress >= 100) {
            stopInterval(timer)
          }
          initialNum += 10
          return Math.min(initialNum, 100);
        });
      }, 1000);

    } else {
      setProgress(100)
      setTimeout(() => {
        setLoadingFile(false)
        clearInterval(timer)
        initialNum = 0
      }, 500);
      setTimeout(() => {
        setProgress(0)
      }, 1000);
    }
  }

  function stopInterval(name) {
    clearInterval(name)
    setProgress(0)
  }

  useEffect(() => getExistingModel(), []);

  return (
    <>
      {!error ? <></> : <Toast error={error} errorMessage={errorMessage} />}
      <Accordion className={classes.accordion} expanded={expanded} onChange={handleChange('panel1')} square>
        <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
          <Typography>{$.packetDownload}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classNames(disabled ? classes.accordionDetails : classes.accordionDetailsDisabled, loadingFile ? classes.loading : classes.loaded)}>
          <form ref={formRef}>
            <Grid
              container
              lg={12}
              spacing={2}
              direction={'row'}
              justify={'space-between'}
              className={classes.packageTextWrapper}
            >
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <TextField
                  onChange={(e) => handleInput(e)}
                  className={classes.textFullWidth}
                  label={`${$.name}*`}
                  size="small"
                  id="packName"
                  name="packName"
                  value={names.pack ?? null}
                  disabled={!disabled}
                />
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <TextField
                  onChange={(e) => handleInput(e)}
                  className={classes.textFullWidth}
                  label={$.author}
                  size="small"
                  id="authorModel"
                  name="authorModel"
                  value={names.author ? names.author : null}
                  defaultValue={names.author ? names.author : null}
                  disabled={!disabled}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.subTitle}>
                {$.model}
              </Grid>
              {!loadingFile ? <></> :
                <Grid item lg={12} md={12} sm={12} xs={12} className={classes.progressBar} >
                  <LinearProgress variant="determinate" color="secondary" value={progress} />
                </Grid>
              }
              <Grid item lg={12} md={12} sm={12} xs={12} justify={'space-around'} className={classes.fileInputWrapper}>
                <FileButtonUpload
                  setFormState={setFormState}
                  formState={formState}
                  accept={'image/jpeg, image/png'}
                  htmlFor="imageModel"
                  filetypeText={'JPEG/PNG'}
                  labelText={$.viewModel}
                  existingName={names.image}
                  existingImage={names.imagePath}
                  disabled={disabled}
                  error={e => setError(e)}
                />
                <FileButtonUpload
                  setFormState={setFormState}
                  formState={formState}
                  accept={'.glb'}
                  htmlFor="fileGlb"
                  filetypeText={'GLB'}
                  labelText={'3D Android/Windows'}
                  existingName={names.glb}
                  existingImage={names.imagePath}
                  disabled={disabled}
                  error={e => setError(e)}
                />
                <FileButtonUpload
                  setFormState={setFormState}
                  formState={formState}
                  accept={'.usdz'}
                  htmlFor="fileUsdz"
                  filetypeText={'USDZ'}
                  labelText={'3D iOs/MacOS'}
                  existingName={names.usdz}
                  existingImage={names.imagePath}
                  disabled={disabled}
                  error={e => setError(e)}
                />
              </Grid>
              {!disabled ? (
                <>
                  {isSmall ? <></> :
                    <Grid item lg={9} md={0} />
                  }
                  <Grid item lg={3} md={3} className={classes.btnWrapper}>
                    <ButtonEdit
                      className={`${classes.button} ${classes.editButton}`}
                      variant={'contained'}
                      size={'small'}
                      onClick={handleEdit}
                      style={{ position: 'relative', zIndex: 19 }}
                      startIcon={<EditIcon />}
                    >
                      {$.edit}
                    </ButtonEdit>
                  </Grid>
                </>
              ) : (
                <>
                  {isSmall ? <></> :
                    <Grid item lg={8} />
                  }
                  <Grid item lg={2} md={2} sm={3} xs={3} className={classes.btnWrapper}>
                    <ButtonCancel
                      className={`${classes.button} ${classes.defaultButton}`}
                      variant={'contained'}
                      size={'small'}
                      onClick={handleCancel}
                      style={{ position: 'relative', zIndex: 19 }}
                      startIcon={<CancelIcon />}
                    >
                      {$.cancel}
                    </ButtonCancel>
                  </Grid>
                  <Grid item lg={2} md={2} sm={3} xs={3} className={classes.btnWrapper}>
                    <ButtonSave
                      className={`${classes.button} ${classes.successButton}`}
                      variant={'contained'}
                      size={'small'}
                      onClick={handleSave}
                      style={{ position: 'relative', zIndex: 19 }}
                      startIcon={<SaveIcon />}
                    >
                      {$.save}
                    </ButtonSave>
                  </Grid>
                  {!isSmall ? <></> :
                    <Grid item lg={6} md={6} sm={6} xs={6} />
                  }
                </>
              )}

            </Grid>
          </form>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ModelPackage;
