// in src/customRoutes.js
import * as React from 'react';
import {Route} from 'react-router-dom';
import PassRestorePage from '../Pages/PassRestorePage/PassRestorePage';
import RegistrationPage from '../Pages/RegistrationPage/RegistrationPage';
import LoginPage from '../Pages/LoginPage/LoginPage';
import PersonalArea from '../PersonalArea/PersonalArea/PersonalArea';
import PassResetPage from '../Pages/PassResetPage/PassResetPage';
import ViewModels from '../Pages/ViewModels/ViewModels';

export default [
<Route exact path="/restorepass" component={PassRestorePage} noLayout />,
<Route exact path="/resetpass" component={PassResetPage}/>,
<Route exact path="/registration/organization" component={RegistrationPage} noLayout />,
<Route exact path="/login" component={LoginPage} />,
<Route exact path="/personal" component={PersonalArea} />,
<Route  path="/viewModel" component={ViewModels} noLayout/>
];