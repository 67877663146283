import {makeStyles} from '@material-ui/core/styles';
import {arColors} from '../../../../Assets/Assets';

export const ProductInfoStyles = makeStyles({
  productInfo: {
    marginTop: '0.6rem',
    // paddingLeft: "5rem",
    right: '1rem',
  },
  accordion: {
    width: '100%',
  },
  accordionDetails: {
    flexWrap: 'wrap',
    paddingBottom: '30px',
    
    // "& > input": {
    //   fontSize: "0.1rem",
    // },
  },
  accordionSummary: {
    backgroundColor: arColors.grayLight,
    textTransform: 'uppercase',
    // padding: "0.2rem",

    '& p': {
      fontWeight: '700',
      fontSize: '14px',
    },
  },

  textFullWidth: {
    width: '100%',
    '& > label': {
      // fontSize: "0.9rem",
    },
  },
  button: {
    width: '100%',
    color: 'white',
    textTransform: 'uppercase',
    fontSize: '9px',
    maxWidth: '130px'
  },
  successButton: {
    backgroundColor: arColors.blue,
    "&:hover , &:active": {
      backgroundColor: arColors.blue,
    },
  },
  defaultButton: {
    backgroundColor: arColors.grayDark,
    "&:hover , &:active": {
      backgroundColor: '#f7593e',
    },
  },

  editButton: {
    backgroundColor: arColors.greenDark,
    "&:hover , &:active": {
      backgroundColor: arColors.greenDark,
    },
  },
  btnWrapper: {
    textAlign: 'end'
  }
});
