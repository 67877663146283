import React, {useContext, useState} from 'react';
import classes from './SelectSearch.module.css'
import SearchIcon from '@material-ui/icons/Search'
import Toast from '../../Toast/Toast';
import {useHistory} from 'react-router';
import {SearchContext} from '../../../App';

const SelectSearch = () => {


  const [open, setOpen] = useState(false)
  const [value, setValue] = useState('')
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const history = useHistory()

  const [stateGlobalSearch, dispatchGlobalSearch] = useContext(SearchContext);

  const handleClick = (e) => {
    e.preventDefault()
    if (open) {
      if (value === '') {
        setOpen(false)
      }
      else {
        setOpen(true)
        dispatchGlobalSearch({ type: 'ADD_SEARCH_CONTENT', payload: { ...stateGlobalSearch, searchContent: value } });
        history.push({
          pathname: '/search',
          state: {searchContent: value}
        })
      }
    } else {
      setOpen(true)
    }
    setOpen(!open)
  }


  const handleKeyUp = (e) => {
    if(e.key === "Enter"){
      handleClick(e)
    }
  }
  


  const handleInput = (e) => {
    setValue(e.target.value)
  }

  return (
    <>
      {!error ? <></> : <Toast error={error} message={errorMessage} />}
      <div className={classes.search} >
        <form className={open ? classes.searchLabel : classes.searchLabelClose} onSubmit={handleClick} onKeyUp={handleKeyUp}>
          <button  className={classes.btnSubmit} type="submit" ><SearchIcon className={open ? classes.searchIcon : classes.searchIconClose}/></button>
          <input type="text" className={open ? classes.searchInput : classes.searchInputClose} value={value} onChange={handleInput} id="selectSearchId"/>
        </form>
      </div>
    </>
  )
};

export default SelectSearch;
