import React, {useEffect, useState} from 'react';
import GuestBar from '../../layout/GuestBar/GuestBar';
import styles from './RegistrationPage.module.css'
import {Checkbox, TextField} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {arWidgetPostRegistration, arWidgetUploadOrganizationImage} from "../../Api/Utils/Utils";
// import ReCAPTCHA from "react-google-recaptcha";
import {langManager} from '../../../utils/languageManager/manager';
import Toast from '../../Toast/Toast';
import { toast } from 'react-toastify';
import { CleanButton } from '../../Buttons/CleanButton';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

        "& .RegistrationPage_inputFile__1hhRn": {
            "& .MuiInputBase-root": {
                "&  .MuiInputBase-input": {
                    height: '200px',
                    position: 'absolute',
                    top: '25px',
                    left: 0,
                    pointerEvents: 'all'
                }
            }
        }
    },
}));

const error = {
    error: {
        color: 'red',
    },
    success: {
        color: '#808080'
    }
}

function RegistrationPage() {
    const [success, setSuccess] = useState(false)
    const [imgPreview, setImgPreview] = useState(null)
    const [imgPreviewUrl, setImgPreviewUrl] = useState('')
    const [file, setFile] = useState([])
    const [checkbox, setCheckbox] = useState(false)
    const [emailValid, setEmailValid] = useState(false)
    const [phoneValid, setPhoneValid] = useState(false)
    const [phoneValidOffice, setPhoneValidOffice] = useState(false)
    const [innValid, setInnValid] = useState(false)
    const [kppValid, setKppValid] = useState(false)
    const [webSiteValid, setWebSiteValid] = useState(false)
    const [textValid, setTextValid] = useState(false)
    const [postCodeValid, setPostCodeValid] = useState(false)
    const [bicValid, setBicValid] = useState(false)
    const [checkingAccountValid, setCheckingAccountValid] = useState(false)
    const [addressValid, setAddressValid] = useState(false)
    const [namesValid, setNamesValid] = useState(false)
    const [errorMessageToast, setErrorMessageToast] = useState(false)
    const [errorToast, setErrorToast] = useState('')

    const classes = useStyles();

    const [values, setValues] = useState({
        surname: '',
        name: '',
        patronymic: '',
        position: '',
        email: '',
        phoneNumber: '',
        organizationName: '',
        inn: '',
        kpp: '',
        generalDirectorFIO: '',
        phoneNumberOffice: '',
        webSite: '',
        countryAddress: '',
        cityAddress: '',
        streetAddress: '',
        postcode: '',
        checkingAccount: '',
        bic: '',
        correspondentAccount: '',
        bankName: '',
        bankAddress: '',
        categoryProduct: ''
    });

      //Переключение языка**************************

    // registrationPage

    const [registrationPage, setRegistrationPage] = useState(langManager.$.pages.registration)

    langManager.subscribe(($) => {
        setRegistrationPage($.pages.registration)
        document.title = $.pages.registration.title
    })
    //*********************************/

    const postRegistration = async () => {
        let formData = new FormData()
        for(let i in values){
            formData.append(i, values[i])
        }
        formData.append('image', file)
        await arWidgetPostRegistration(formData)
        .then((response) => {
            setSuccess(true)
        }).catch(e => {
            console.log(e);
            setErrorToast(true)
            setErrorMessageToast(e.messsage)
        })
    };


    const config = {
        title: 'Alh 3d',
    };


    // хэндл загруженной картинки
    const handleChange = async (e) => {
        setErrorToast(false)

        let fileName = e.target.value;
        let idxDot = fileName.lastIndexOf(".") + 1;
        let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if (extFile == "jpg" || extFile == "jpeg" || extFile == "png" || extFile == "svg") {
            let length = e.target.files.length

            for (let i = 0; i < length; i++) {
                let file = e.target.files[i]
                setImgPreview(e.target.files[i])
                if (file === undefined) {
                    setImgPreview(undefined)
                }
                if (imgPreview !== undefined || imgPreview !== null) {
                    let reader = new FileReader();
                    reader.onloadend = () => {
                        setImgPreviewUrl(reader.result)
                    }
                    reader.readAsDataURL(file)
                    setFile(e.target.files[0])
                } else {
                    setImgPreviewUrl(null)
                }
            }
        } else {
            alert("Only jpg/jpeg ,svg and png files are allowed!");
        }




    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (checkbox && phoneValid && phoneValidOffice && emailValid && innValid && webSiteValid && kppValid && postCodeValid
            && bicValid && textValid && checkingAccountValid && addressValid && namesValid) {
            postRegistration()
        } else {
           console.log('error');
           toast.error(registrationPage.errorValidate, {
               toastId: 'CustomToastIdForRegistration'
           })
        }
    }

    const checkValidation = (element) => {
        switch (element.name) {
            case 'phoneNumber':
                let regexNumber = new RegExp('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$');
                if (regexNumber.test(element.value) && (element.value.length > 5 && element.value.length < 25)) {
                    setPhoneValid(true)
                    element.style.color = error.success.color
                } else {
                    element.style.color = error.error.color
                    setPhoneValid(false)
                }
                break;
            case 'phoneNumberOffice':
                let regexNumberOffice = new RegExp('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$');
                if (regexNumberOffice.test(element.value) && (element.value.length > 5 && element.value.length < 25)) {
                    setPhoneValidOffice(true)

                    element.style.color = error.success.color

                } else {
                    setPhoneValidOffice(false)

                    element.style.color = error.error.color
                }
                break;
            case 'email':
                let regexEmail = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
                if (regexEmail.test(element.value)) {
                    setEmailValid(true)

                    element.style.color = error.success.color
                } else {
                    setEmailValid(false)

                    element.style.color = error.error.color
                }
                break;
            case 'inn':
                let regexInn = new RegExp(/^(?:\d{12}|\d{4}-\d{4}-\d{4}|\d{6}-\d{6})$/)
                if (regexInn.test(element.value)) {
                    setInnValid(true)

                    element.style.color = error.success.color
                } else {
                    setInnValid(false)

                    element.style.color = error.error.color
                }
                break;
            case 'webSite':
                let regexWebSite = new RegExp('^((https?|ftp)\:\/\/)?([a-z0-9]{1})((\.[a-z0-9-])|([a-z0-9-]))*\.([a-z]{2,6})(\/?)$')
                if (regexWebSite.test(element.value)) {
                    setWebSiteValid(true)

                    element.style.color = error.success.color
                } else {
                    setWebSiteValid(false)

                    element.style.color = error.error.color
                }
                break;
            case 'kpp':
                let regexKpp = new RegExp('^([0-9]{9})?$')
                if (regexKpp.test(element.value)) {
                    setKppValid(true)

                    element.style.color = error.success.color
                } else {
                    setKppValid(false)

                    element.style.color = error.error.color
                }
                break;
            case 'postcode':
                let regexPostCode = new RegExp("\[0-9]{6}")
                if (regexPostCode.test(element.value)) {
                    setPostCodeValid(true)

                    element.style.color = error.success.color
                } else {
                    setPostCodeValid(false)

                    element.style.color = error.error.color
                }
                break;
            case 'bic':
                let regexBic = new RegExp("\[0-9]{9}")
                if (regexBic.test(element.value)) {
                    setBicValid(true)

                    element.style.color = error.success.color
                } else {
                    setBicValid(false)

                    element.style.color = error.error.color
                }
                break;
            default:
                break;
        }

        switch (element.type) {
            case 'text':
                if (element.name === "surname" || element.name === "name" || element.name === "patronymic" || element.name === "generalDirectorFIO" ||
                    element.name === "countryAddress" || element.name === "cityAddress" || element.name === "bankName") {
                    let regexText = new RegExp(/^([а-яё\s]+|[a-z\s]+)$/iu)
                    if (regexText.test(element.value)) {
                        setNamesValid(true)

                        element.style.color = error.success.color
                    } else {
                        setNamesValid(false)

                        element.style.color = error.error.color
                    }
                }
                if ( element.name === "countryAddress" || element.name === "cityAddress" || element.name === "bankName") {
                    let regexText = new RegExp(/^([A-Za-z][A-Za-z\-\']{1,50})|([А-ЯЁIЇҐЄа-яёіїґє][А-ЯЁIЇҐЄа-яёіїґє\-\']{1,50})$/)
                    if (regexText.test(element.value)) {
                        setTextValid(true)

                        element.style.color = error.success.color
                    } else {
                        setTextValid(false)

                        element.style.color = error.error.color
                    }
                }
                if (element.name === 'correspondentAccount' || element.name === 'checkingAccount') {
                    let regexCheckingAcc = new RegExp('\[0-9]{20}')
                    if (regexCheckingAcc.test(element.value)) {
                        setCheckingAccountValid(true)

                        element.style.color = error.success.color
                    } else {
                        setCheckingAccountValid(false)

                        element.style.color = error.error.color
                    }
                }
                if (element.name === 'streetAddress' || element.name === 'bankAddress') {

                    if (element.value.split(" ").length > 2) {
                        setAddressValid(true)

                        element.style.color = error.success.color
                    } else {
                        setAddressValid(false)

                        element.style.color = error.error.color
                    }
                }
                break;

            default:
                break;
        }
    }



    const handleChangeCheckbox = async (e) => {
        let checked = e.target.checked
        setCheckbox(checked)
        setErrorToast(false)
    }

    const handleInput = (e) => {
        const nam = e.target.name;
        let val = e.target.value;
        
        setValues({ ...values, [nam]: val });
        checkValidation(e.target)
        setErrorToast(false)
    }

    useEffect(() => {
        document.title = 'Регистрация';
        localStorage.clear()
      }, [])

    if (success) {
        return (
            <>
                <GuestBar {...config} />
                <div className={styles.registrationPage}>
                    <div className={styles.subHeading}>{registrationPage.newUserRegistration}</div>
                    <div className={styles.main}>
                        <div className={styles.successText}>
                            {registrationPage.dataSentCheckYourEmail}
                        </div>
                    </div>
                </div>
            </>
        )
    } 
       
    
    return (
            <>
            {!errorToast ? <></> : <Toast error={errorToast} errorMessage={errorMessageToast} />}
            <GuestBar {...config} />
            <div className={styles.registrationPage}>
                <div className={styles.subHeading}>{registrationPage.newUserRegistration}</div>

                    <div className={styles.main}>
                        <form className={styles.form} onSubmit={handleSubmit}>
                            <div className={styles.title}>
                                {registrationPage.personalInformation}
                            </div>
                            <div className={styles.subTitle}>
                                {registrationPage.userInformation}
                            </div>
                            <div className={classes.root}>
                                <Grid container spacing={6}>
                                    <Grid item xs={12} sm={6} md={4} className={styles.gridItem}>
                                        <div className={styles.label}>{registrationPage.userForm.surname}</div>
                                        <input id="surname" name="surname" value={values.surname} type="text" className={styles.input} required onChange={(e) => handleInput(e)} />
                                        <CleanButton clear={() => setValues({...values, surname: ''})} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} className={styles.gridItem}>
                                        <div className={styles.label}>{registrationPage.userForm.name}</div>
                                        <input id="name" name="name" value={values.name}  type="text" className={styles.input} required onChange={(e) => handleInput(e)} />
                                        <CleanButton clear={() => setValues({...values, name: ''})} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} className={styles.gridItem}>
                                        <div className={styles.label}>{registrationPage.userForm.patronymic}</div>
                                        <input id="patronymic" name="patronymic" value={values.patronymic} type="text" className={styles.input} required onChange={(e) => handleInput(e)} />
                                        <CleanButton clear={() => setValues({...values, patronymic: ''})} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} className={styles.gridItem}>
                                        <div className={styles.label}>{registrationPage.userForm.position}</div>
                                        <input id="position" name="position" value={values.position} type="text" className={styles.input} required onChange={(e) => handleInput(e)} />
                                        <CleanButton clear={() => setValues({...values, position: ''})} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} className={styles.gridItem}>
                                        <div className={styles.label}>{registrationPage.userForm.email}</div>
                                        <input id="email" name="email" value={values.email} type="email" className={styles.input} required onChange={(e) => handleInput(e)} />
                                        <CleanButton clear={() => setValues({...values, email: ''})} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} className={styles.gridItem}>
                                        <div className={styles.label}>{registrationPage.userForm.phoneNumber}</div>
                                        <input id="phoneNumber" name="phoneNumber" value={values.phoneNumber} type="tel" className={styles.input} required onChange={(e) => handleInput(e)} />
                                        <CleanButton clear={() => setValues({...values, phoneNumber: ''})} />
                                    </Grid>
                                </Grid>
                            </div>
                           

                            <div className={styles.title}>
                                {registrationPage.companyInformation}
                            </div>
                            <div className={styles.subTitle}>
                                {registrationPage.registrationIformatoin}
                            </div>

                            <Grid container spacing={6} className={styles.logoInputContainer}>

                                <Grid item xs={12} sm={12} md={12} lg={4}>
                                    <div className={styles.imgWrapper}>

                                        <div className={styles.imgTitle}>
                                            {registrationPage.addCompanyLogotype}
                                        </div>
                                        <div className={styles.ImgBox}>
                                            {imgPreview === null || imgPreview === undefined
                                                ? <div className={styles.imgInfo}>
                                                    {registrationPage.durgAndDrop} <br /> JPEG / PNG  <br />SVG  <br />{registrationPage.orUpload} <br /> {registrationPage.fromComputer}
                                                </div>
                                                : <img src={imgPreviewUrl} className={styles.imgPreview} />
                                            }

                                        </div>
                                        <input id="file" name="file" type="file" className={styles.inputFile} accept=".jpg,.jpeg,.png,.svg" onChange={handleChange} />
                                        <button className={styles.imgButton}>{registrationPage.upload}</button>
                                    </div>

                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={8}>
                                    <Grid container spacing={6}>
                                        <Grid item md={6} xs={12} sm={6}  className={styles.gridItem}>
                                            <div className={styles.label}>{registrationPage.regInfo.organizationName}</div>
                                            <input id="organizationName" name="organizationName" value={values.organizationName} type="text" className={styles.input} required onChange={(e) => handleInput(e)} />
                                            <CleanButton clear={() => setValues({...values, organizationName: ''})} />
                                        </Grid>
                                        <Grid item md={6} xs={12} sm={6}  className={styles.gridItem}>
                                            <div className={styles.label}>{registrationPage.regInfo.TIN}</div>
                                            <input id="inn" name="inn" type="text" value={values.inn} className={styles.input} required onChange={(e) => handleInput(e)} />
                                            <CleanButton clear={() => setValues({...values, inn: ''})} />
                                        </Grid>
                                        <Grid item md={6} xs={12} sm={6}  className={styles.gridItem}>
                                            <div className={styles.label}>{registrationPage.regInfo.productCategory}</div>
                                            <input id="categoryProduct" name="categoryProduct" value={values.categoryProduct} type="text" className={styles.input}  onChange={(e) => handleInput(e)}/>
                                            <CleanButton clear={() => setValues({...values, categoryProduct: ''})} />
                                        </Grid>
                                        <Grid item md={6} xs={12} sm={6}  className={styles.gridItem}>
                                            <div className={styles.label}>{registrationPage.regInfo.RRC}</div>
                                            <input id="kpp" name="kpp" type="text" value={values.kpp} className={styles.input} required onChange={(e) => handleInput(e)} />
                                            <CleanButton clear={() => setValues({...values, kpp: ''})} />
                                        </Grid>
                                        <Grid item md={6} xs={12} sm={6}  className={styles.gridItem}>
                                            <div className={styles.label}>{registrationPage.regInfo.nameOfGeneralDirector}</div>
                                            <input id="generalDirectorFIO" name="generalDirectorFIO" value={values.generalDirectorFIO} type="text" className={styles.input} required onChange={(e) => handleInput(e)} />
                                            <CleanButton clear={() => setValues({...values, generalDirectorFIO: ''})} />
                                        </Grid>
                                        <Grid item md={6} xs={12} sm={6}  className={styles.gridItem}>
                                            <div className={styles.label}>{registrationPage.regInfo.officePhone}</div>
                                            <input id="phoneNumberOffice" name="phoneNumberOffice" value={values.phoneNumberOffice} type="tel" className={styles.input} required onChange={(e) => handleInput(e)} />
                                            <CleanButton clear={() => setValues({...values, phoneNumberOffice: ''})} />
                                        </Grid>
                                        <Grid item md={6} xs={12} sm={6} className={styles.gridItem}>
                                            <div className={styles.label}>{registrationPage.regInfo.website}</div>
                                            <input id="webSite" name="webSite" value={values.webSite}  type="url" className={styles.input} required onChange={(e) => handleInput(e)} />
                                            <CleanButton clear={() => setValues({...values, webSite: ''})} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>


                            <div className={styles.subTitle}>
                                {registrationPage.address}
                            </div>
                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={6} md={4}  className={styles.gridItem}>
                                    <div className={styles.label}>{registrationPage.region}</div>
                                    <input id="countryAddress" name="countryAddress" value={values.countryAddress} type="text" className={styles.input} required onChange={(e) => handleInput(e)} />
                                    <CleanButton clear={() => setValues({...values, countryAddress: ''})} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} className={styles.gridItem}>
                                    <div className={styles.label}>{registrationPage.city}</div>
                                    <input id="cityAddress" name="cityAddress" value={values.cityAddress} type="text" className={styles.input} required onChange={(e) => handleInput(e)} />
                                    <CleanButton clear={() => setValues({...values, cityAddress: ''})} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} className={styles.gridItem}>
                                    <div className={styles.label}>{registrationPage.actualAddress}</div>
                                    <input id="streetAddress" name="streetAddress" value={values.streetAddress} type="text" className={styles.input} required onChange={(e) => handleInput(e)} />
                                    <CleanButton clear={() => setValues({...values, streetAddress: ''})} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} className={styles.gridItem}>
                                    <div className={styles.label}>{registrationPage.postcode}</div>
                                    <input id="postcode" name="postcode" value={values.postcode} type="text" className={styles.input} required onChange={(e) => handleInput(e)} />
                                    <CleanButton clear={() => setValues({...values, postcode: ''})} />
                                </Grid>
                            </Grid>
                            <div className={styles.requisites}>
                                <div className={styles.subTitle}>
                                    {registrationPage.invoiceDetails}
                                </div>
                                <Grid container spacing={6}>
                                    <Grid item xs={12} sm={6} md={4} className={styles.gridItem}>
                                        <div className={styles.label}>{registrationPage.checkingAccount}</div>
                                        <input id="checkingAccount" name="checkingAccount" value={values.checkingAccount} type="text" className={styles.input} required pattern="^[0-9]{2}[0-9]{2}[0-9]{2}[0-9]{2}$" onChange={(e) => handleInput(e)} />
                                        <CleanButton clear={() => setValues({...values, checkingAccount: ''})} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} className={styles.gridItem}>
                                        <div className={styles.label}>{registrationPage.BIC}</div>
                                        <input id="bic" name="bic" value={values.bic} type="text" className={styles.input} required onChange={(e) => handleInput(e)} />
                                        <CleanButton clear={() => setValues({...values, bic: ''})} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} className={styles.gridItem}>
                                        <div className={styles.label}>{registrationPage.correspondentAccount}</div>
                                        <input id="correspondentAccount" name="correspondentAccount" value={values.correspondentAccount} type="text" className={styles.input} required pattern="^[0-9]{2}[0-9]{2}[0-9]{2}[0-9]{2}$" onChange={(e) => handleInput(e)} />
                                        <CleanButton clear={() => setValues({...values, correspondentAccount: ''})} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} className={styles.gridItem}>
                                        <div className={styles.label}>{registrationPage.bank}</div>
                                        <input id="bankName" name="bankName" value={values.bankName} type="text" className={styles.input} required onChange={(e) => handleInput(e)} />
                                        <CleanButton clear={() => setValues({...values, bankName: ''})} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} className={styles.gridItem}>
                                        <div className={styles.label}>{registrationPage.bankAddress}</div>
                                        <input id="bankAddress" name="bankAddress" value={values.bankAddress} type="text" className={styles.input} required onChange={(e) => handleInput(e)} />
                                        <CleanButton clear={() => setValues({...values, bankAddress: ''})} />
                                    </Grid>
                                </Grid>
                            </div>

                            <div className={styles.title}>
                                {registrationPage.licenseAgreement}
                            </div>
                            <div className={styles.aggreements}>
                                <div className={styles.aggreementsText}>
                                    {registrationPage.licenseAgreement}
                                </div>
                            </div>
                            <div className={styles.check}>
                                <Checkbox
                                    color="primary"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    onChange={handleChangeCheckbox}
                                    checked={checkbox}
                                />
                                <div className={styles.checkText}>
                                    {registrationPage.acceptLicenseAgreement}
                                </div>
                            </div>

                            {/* <ReCAPTCHA
                                sitekey="Your client site key"
                                // onChange={onChange}
                            /> */}

                            <button className={styles.submitBtn} type="submit">{registrationPage.sendRequest}</button>

                        </form>
                    </div>
                </div>

            </>
        );
}

export default RegistrationPage;