import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, FormControl, MenuItem, Select } from '@material-ui/core';
import { arWidgetGetRoles, arWidgetGetUser, arWidgetPostUser, arWidgetPutUser, arWidgetPutUserById } from '../../../Api/Utils/Utils';
import AuthProvider from '../../../Api/AuthProvider/AuthProvider';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import { rusRoles } from '../../../Localization/Roles/Roles';
import { makeStyles } from '@material-ui/core/styles';
import { ArBreadcrumbs } from '../../../Organizms/Breadcrumbs/ArBreadcrumbs';
import { ButtonSave } from '../../../Buttons/ButtonSave';
import { ButtonCancel } from '../../../Buttons/ButtonCancel';
import Toast from '../../../Toast/Toast';
import { langManager } from '../../../../utils/languageManager/manager';
import BackStepButton from '../../../Organizms/BackStepButton/BackStepButton';
import { editPagesBreadCrumbs, initialBreadCrumbs, usersBreadCrumbs, isEmailValid, isNameValid } from '../../../../Helpers/helpers';
import { NotificationContext } from '../../../../App';
import AutoComplete from '../../../Molecules/AutoComplete/AutoComplete';
import { toast } from 'react-toastify';
import { CleanButton } from '../../../Buttons/CleanButton';
import RemoveRedEyeOutlinedIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
    maxWidth: '800px',
    justifyContent: 'space-between',
    marginTop: '30px'
  },
  wrapper: {
    margin: '0 0 10px 0',
    position: 'relative'
  },
  orgWrapper: {
    width: '47%',
    marginTop: '29px'
  },
  textField: {
    width: '380px',
    border: '1px solid #C4C4C4',
    padding: '5px 25px 6px 9px',
    outline: 'none',
    fontSize: '14px',
    fontStyle: 'italic',
    color: '#808080',
    letterSpacing: '0.04em'
  },
  btn: {
    flexBasis: '100%',
    marginTop: '15px',
    marginLeft: '10px',
  },
  selectSearch: {
    '& input': {
      '&:hover': {
        borderColor: 'blue !important',
      },
    },
  },
  formControl: {
    width: '380px',
    '& .MuiInputBase-root': {
      background: '#fff',
      padding: '2.5px 10px',
      fontSize: '14px',
      fontStyle: 'italic',
      color: '#808080',
      letterSpacing: '0.04em',
      width: '380px'
    }
  },
  m8: {
    width: '100%',
  },
  buttonsArea: {
    marginTop: '35px',
    alignSelf: 'flex-end',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    '& > button': {
      marginLeft: '28px',
      minWidth: '120px',
    },
    '& > button:first-child': {
      marginLeft: '0px',
    },
  },
  tariff: {
    border: '1px solid #C4C4C4',
    width: '230px',
    '&:after, &:before': {
      display: 'none',
    },
    '&:focus, &>*:focus': {
      background: 'transparent'
    }
  },
  labelText: {
    fontSize: '11px',
    color: '#808080',
    marginBottom: '5px',
  },
  passwordRec: {
    margin: '15px 0px 25px'
  },
  '@media(max-width: 420px)' : {
    textField: {
      width: '300px'
    },
    formControl: {
      '& .MuiInputBase-root': {
        width: '300px'
      }
    }
  },
  cleanAllButton: {
    right: '10px !important',
    top: '54% !important'
  },
  cleanAllButtonSelect: {
    right: '20px !important',
    top: '54% !important'
  },
  iconEye: {
    width: '15px !important',
    color: '#C4C4C4',
    cursor: 'pointer',
    position: 'absolute',
    right: '30px',
    bottom: '6px'
  },
  passwordInput: {
    paddingRight: '50px'
  },
  noValid: {
    color: '#f81c1c'
  }
}));


const UserEdit = (props) => {

  const classes = useStyles();

  const [stateUser,] = useContext(NotificationContext);

  const [organization, setOrganization] = useState('')

  const [showPassword, setShowPassword] = useState(false)

  //Переключение языка
  const [$, setUserEdit] = useState(langManager.$.components.userEdit)
  langManager.subscribe(($) => {
    setUserEdit($.components.userEdit)
  })
  //******************

  const history = useHistory();

  let id = props.id;

  if (!props.id) {
    id = undefined;
  }

  const [user, setUser] = useState({
    id: id,
    email: '',
    password: '',
    userName: '',
    name: '',
    surname: '',
    patronymic: '',
    roleId: '',
    organizationId: '',
  });

  const [loading, setLoading] = useState(true);
  const [backStep, setBackStep] = useState(-1);

  const [role, setRole] = useState('1');
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')


  const getOrganizationId = () => {
    let tempId = document.querySelector('#organizations');
    if (tempId) {
      tempId = parseInt(tempId.parentElement.parentElement.dataset.postid);
      return tempId;
    }
  };

  const getCategoryId = () => {
    let tempId = document.querySelector('#categories');
    if (tempId) {
      tempId = parseInt(tempId.parentElement.parentElement.dataset.postid);
      return tempId;
    }
  };

  const handleInput = (event) => {
    const nam = event.target.name;
    let val = event.target.value;

    setUser({ ...user, [nam]: val });
    setError(false)
  };

  const handleBack = () => {
    history.go(backStep);
  };


  const getRoles = () => {
    setLoading(true)
    arWidgetGetRoles().then((response) => {
      setRoles(response.data.items);
      setLoading(false)
    }).catch(e => {
      setLoading(false)
    })
  };

  const getUser = () => {
    if (!props.id) {
      setLoading(false);
      return;
    }
    setLoading(true)
    arWidgetGetUser(props.id)
      .then((response) => {
        return response.data.items;
      })
      .then((tempUser) => {
        setUser(tempUser);
      })
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false)
        return AuthProvider.checkError(e);
      });
  };

  const putUser = async () => {

    if (stateUser && stateUser.currentUser && stateUser.currentUser.roleName && stateUser.currentUser.roleName === 'admin' && !organization.id) {
      const customToastId = 'customToastIdUserEdit'
      toast.error($.organizationError, {
        toastId: customToastId
      })
      return false
    }

    if(!isEmailValid(user.email) || !isNameValid(user.name) || !isNameValid(user.surname) || !isNameValid(user.patronymic)){
      const customToastId = 'customToastIdUserEdit'
      toast.error($.validationError, {
        toastId: customToastId
      })
      return false
    }

    const params = {
      name: user.name,
      surname: user.surname,
      patronymic: user.patronymic,
      email: user.email,
      roleId: user.roleId,
      organizationId: stateUser && stateUser.currentUser && stateUser.currentUser.roleName && stateUser.currentUser.roleName === 'admin' ? organization.id : user.organizationId,
    };
    await arWidgetPutUserById(id, params)
      .then(() => history.goBack())
      .catch(e => {
        setError(true)
        setErrorMessage(e.data.message)
      })
  };


  const postUser = async () => {

    if (stateUser && stateUser.currentUser && stateUser.currentUser.roleName && stateUser.currentUser.roleName === 'admin' && !organization.id) {
      const customToastId = 'customToastIdUserEdit'
      toast.error($.organizationError, {
        toastId: customToastId
      })
      return false
    }

    if(!isEmailValid(user.email) || !isNameValid(user.name) || !isNameValid(user.surname) || !isNameValid(user.patronymic)){
      const customToastId = 'customToastIdUserEdit'
      toast.error($.validationError, {
        toastId: customToastId
      })
      return false
    }

    const params = {
      name: user.name,
      userName: user.userName,
      surname: user.surname,
      organizationId: stateUser && stateUser.currentUser && stateUser.currentUser.roleName && stateUser.currentUser.roleName === 'admin' ? organization.id : user.organizationId,
      password: user.password,
      patronymic: user.patronymic,
      roleId: user.roleId,
      email: user.email,
    };
    await arWidgetPostUser(params)
      .then((response) => {
        setBackStep(-2);
        history.goBack()
      }).catch(e => {
      })
  };

  const handleSave = async () => {
    if (user.id !== undefined) {
      await putUser();
      // await history.goBack()

    } else {
      await postUser();
      // await history.go(backStep)
    }
  };

  const handleNext = async () => {
    if (user.id !== undefined) {
      await putUser();
      // await history.go(-2)
    } else {
      await postUser();
      // await history.go(-2)
    }
  };

  const handleSelect = (event) => {
    const nam = event.target.name;
    let val = event.target.value;
    setRole(val);
    setUser({ ...user, [nam]: val });
  };

  useEffect(() => {
    getUser();
    getRoles();
    document.title = $.title
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <ArBreadcrumbs breadCrumbs={[initialBreadCrumbs, usersBreadCrumbs, editPagesBreadCrumbs('editing', window.location.pathname, 2)]} />
      {!error ? <></> : <Toast error={error} errorMessage={errorMessage} />}
      <Container>
        <div className={classes.root}>
          <div className={classes.wrapper}>
            <div className={classes.labelText}>{$.userName}</div>
            <input
              className={classes.textField}
              id="userName"
              name="userName"
              value={user.userName}
              onChange={(e) => handleInput(e)}
            />
            <CleanButton  clear={() => setUser({...user, userName: ''})} styles={classes.cleanAllButton}/> 
          </div>

          <div className={classes.wrapper}>
            <div className={classes.labelText}>{$.email}</div>
            <input
              className={`${classes.textField} ${isEmailValid(user.email) ? '' : classes.noValid}`}
              id="email"
              name="email"
              value={user.email}
              onChange={(e) => handleInput(e)}
            />
            <CleanButton  clear={() => setUser({...user, email: ''})} styles={classes.cleanAllButton}/> 
          </div>
          <div className={classes.wrapper}>
            <div className={classes.labelText}>{$.name}</div>
            <input
              className={`${classes.textField} ${isNameValid(user.name) ? '' : classes.noValid}`}
              id="name"
              name="name"
              value={user.name}
              onChange={(e) => handleInput(e)}
            />
            <CleanButton  clear={() => setUser({...user, name: ''})} styles={classes.cleanAllButton}/> 
          </div>
          <div className={classes.wrapper}>
            <div className={classes.labelText}>{$.password}</div>
            <input
              className={`${classes.textField} ${classes.passwordInput}`}
              id="password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={user.password}
              onChange={(e) => handleInput(e)}
            />
             {
                  showPassword
                    ?
                    <VisibilityOffOutlinedIcon
                      className={classes.iconEye}
                      onClick={() => setShowPassword(false)}
                    />
                    :
                    <RemoveRedEyeOutlinedIcon
                      className={classes.iconEye}
                      onClick={() => setShowPassword(true)}
                    />
                }
              <CleanButton  clear={() => setUser({...user, password: ''})} styles={classes.cleanAllButton}/> 
          </div>
        </div>
        <div className={classes.wrapper}>
          <div className={`${classes.passwordRec} ${classes.labelText}`}>{$.passwordRec}</div>
        </div>
        <div className={classes.root}>
          <div className={classes.wrapper}>
            <div className={classes.labelText}>{$.surname} </div>
            <input
               className={`${classes.textField} ${isNameValid(user.surname) ? '' : classes.noValid}`}
              id="surname"
              name="surname"
              value={user.surname}
              onChange={(e) => handleInput(e)}
            />
            <CleanButton  clear={() => setUser({...user, surname: ''})} styles={classes.cleanAllButton}/> 
          </div>
          <div className={classes.wrapper}>
            <div className={classes.labelText}>{$.patronymic}</div>
            <input
               className={`${classes.textField} ${isNameValid(user.patronymic) ? '' : classes.noValid}`}
              id="patronymic"
              name="patronymic"
              value={user.patronymic}
              onChange={(e) => handleInput(e)}
            />
            <CleanButton  clear={() => setUser({...user, patronymic: ''})} styles={classes.cleanAllButton}/> 
          </div>


          <div className={classes.wrapper}>
            <div className={classes.labelText}>{$.role}</div>
            <FormControl className={classes.formControl}>
              <Select
                labelId="role"
                id="role"
                name="roleId"
                value={user.roleId}
                onChange={handleSelect}
                className={`${classes.tariff} ${classes.formSelect}`}
                defaultValue="Пользователь"
              >
                {roles.map((role) => {
                  return (
                    <MenuItem key={role.id + Math.random().toFixed(5)} value={role.id}>
                      {rusRoles[role.id]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <CleanButton  clear={() => setUser({...user, roleId: ''})} styles={classes.cleanAllButtonSelect} /> 
          </div>
          {
            (stateUser && stateUser.currentUser && stateUser.currentUser.roleName && stateUser.currentUser.roleName === 'admin') &&
            <div className={classes.orgWrapper}>
              <AutoComplete
                name={'name'}
                isClear={false}
                setParentValue={e => setOrganization(e)}
                width='100%'
              />
            </div>
          }
          <div className={classes.buttonsArea} align="end">
            <ButtonCancel
              variant="contained"
              color="secondary"
              size="large"
              startIcon={<CancelIcon />}
              onClick={handleBack}
            >
              {$.cancel}
            </ButtonCancel>
            {id === undefined ? (
              <ButtonSave
                variant="contained"
                color="primary"
                size="large"

                startIcon={<SaveIcon />}
                onClick={handleNext}
              >
                {$.save}
              </ButtonSave>
            ) : (
              <ButtonSave
                variant="contained"
                color="primary"
                size="large"

                startIcon={<SaveIcon />}
                onClick={handleSave}
              >
                {$.save}
              </ButtonSave>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default UserEdit;
