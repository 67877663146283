import React, {useEffect, useRef} from "react";
import 'babylonjs-loaders';
import {Engine, Scene} from "@babylonjs/core";

const ActiveImageModel = (props) => {
  const reactCanvas = useRef(null);
  const {antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onSceneReady, model, ...rest} = props;


  useEffect(() => {
    if (reactCanvas.current) {
      const engine = new Engine(reactCanvas.current, antialias, engineOptions, adaptToDeviceRatio);
      const scene = new Scene(engine, sceneOptions);
      if (scene.isReady()) {
        props.onSceneReady(scene);
      } else {
        scene.onReadyObservable.addOnce((scene) => props.onSceneReady(scene));
      }

      engine.runRenderLoop(() => {
        scene.render();
      });

      const resize = () => {
        scene.getEngine().resize();
      };

      if (window) {
        window.addEventListener("resize", resize);
      }

      return () => {
        scene.getEngine().dispose();

        if (window) {
          window.removeEventListener("resize", resize);
        }
      };
    }
  }, [reactCanvas]);


  return <canvas
    ref={reactCanvas}
    {...rest}
    style={{
      width: '100%',
      height: "280px"
    }}/>;
};

export default ActiveImageModel
