import * as React from 'react';
import { useEffect, useState } from 'react';

import { arWidgetDeleteProduct, arWidgetDownloadModelArchive, arWidgetGetProduct, } from '../../../Api/Utils/Utils';
import { ProductPanelStyles } from './ProductPanelStyles';

import ProductPanelInfo from './ProductPanelInfo';
import ProductPanelArchive from './ProductPanelArchive';
import ProductPanelActiveImage from './ProductPanelActiveImage';

import { Button, Grid, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import Toast from '../../../Toast/Toast';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { useHistory } from 'react-router';
import { langManager } from '../../../../utils/languageManager/manager';
import CancelPresentationOutlinedIcon from '@material-ui/icons/CancelPresentationOutlined';
import { ArDeleteIcon, ArDownloadIcon, ArEditIcon, ArImageIcon, ArModelIcon, ArPCIcon, ArPhoneIcon } from '../../../../Assets/Assets';
import { toast } from 'react-toastify';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export const ProductPanel = ({ row, open, btnActive, setOpen, filters }) => {
  const classes = ProductPanelStyles();
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const [activeBtn, setActiveBtn] = useState(btnActive);
  const [isPublish, setIsPublish] = useState(false);
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('error')
  const [idModel, setIdModel] = useState('')

  //Переключение языка
  const [$, setProductPanel] = useState(langManager.$.components.productPanel)
  langManager.subscribe(($) => {
    setProductPanel($.components.productPanel)
  })
  //******************

  const handleIconChange = (e) => {
    setActiveBtn(e.target.id);
  };
  const history = useHistory()
  const handleEditProduct = () => {
    history.push(`/product/${row.id}`)
  }

  const deleteProduct = () => {
    arWidgetDeleteProduct(product.id)
      .then(() => {
        setError(false)
      })
      .catch(err => {
        setError(true)
        setErrorMessage(err.data.message)
      })
  }

  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };


  const handleCloseDialogDisagree = () => {
    setOpenDialog(false);

  };
  const handleCloseDialogAgree = () => {
    setOpenDialog(false);
    deleteProduct()
  };



  const getProduct = () => {
    arWidgetGetProduct(row.id)
      .then((response) => {
        if(!response.data || !response.data.items){
          return false
        }
        return response.data.items;
      })
      .then((response) => {

        if(!response){
          return false
        }

        if (response.models.length > 0) {
          setIdModel(response.models[0].id)
        }

        //
        setProduct(response);

        if (response.archive) {
          setIsPublish(true);
        }
      })
      .finally(() => {
        setLoading(false);
      })
  };


  const handleDownloadModel = () => {
    const params = {
      type: 100
    }

    if(!idModel){
      const customId = 'customToastId'
      toast.error($.toastMessage, {
        toastId: customId
      })
      return false
    }

    arWidgetDownloadModelArchive(idModel, params)
      .then(response => {
        return response.data.items;
      })
      .then((response) => {

        let link = document.createElement('a');
        // link.download = 'download'
        // Construct the URI
        link.href = response;
        document.body.appendChild(link);
        link.click();
        // Cleanup the DOM
        document.body.removeChild(link);

      })
      .catch(err => {
        console.log(err);
      })
  }

  useEffect(() => {
    setActiveBtn(btnActive)
    if (row.id) {
      getProduct();
    }
  }, [row.id]);

  if (loading) {
    return (
      <div className={`${classes.productPanel} ${open ? classes.productPanelOpen : classes.productPanelClosed}`}>
        <h1>{loading}</h1>
      </div>
    );
  }


  return (
    <>
      {!error ? <></> : <Toast error={error} message={errorMessage} />}
      <Grid container
        className={`${classes.productPanel} ${open ? classes.productPanelOpen : classes.productPanelClosed}`}
        style={{ flexWrap: 'nowrap' }}
        id='productPanelContainer'
      >
        <Grid container>
          <Grid item xs={10} lg={10} className={classes.title}>
            {product.name}
          </Grid>
          <Grid item xs={2} lg={2} className={classes.closeButtonWrapper}>
            <button
              className={classes.closeButton}
              onClick={() => setOpen(false)}
            >
              <CancelPresentationOutlinedIcon className={classes.closeIcon} />
            </button>
          </Grid>
        </Grid>
        {/*
Icons Bar
*/}
        <Grid item lg={12}>
          <div className={classes.iconsBar}>
            <IconButton
              id={'img-product'}
              className={activeBtn === 'img-product' ? classes.activeIcon : classes.icon}
              onClick={handleIconChange}
            >
              <ArImageIcon color={activeBtn === 'img-product' ? '#343A40' : '#808080'} />
            </IconButton>
            <IconButton
              className={activeBtn === 'img-model' ? classes.activeIcon : classes.icon}
              id={'img-model'}
              onClick={handleIconChange}
            >
              <ArPCIcon color={activeBtn === 'img-model' ? '#343A40' : '#808080'} />
            </IconButton>
            <IconButton
              className={activeBtn === 'glb-model' ? classes.activeIcon : classes.icon}
              id={'glb-model'}
              onClick={handleIconChange}
            >
              <ArModelIcon color={activeBtn === 'glb-model' ? '#343A40' : '#808080'} />
            </IconButton>
            <IconButton
              className={activeBtn === 'qr-code' ? classes.activeIcon : classes.icon}
              id={'qr-code'}
              onClick={handleIconChange}
            >
              <ArPhoneIcon color={activeBtn === 'qr-code' ? '#343A40' : '#808080'} />
            </IconButton>
          </div>
        </Grid>
        <Grid item lg={12}>
          <ProductPanelActiveImage
            activeBtn={activeBtn}
            product={product}
            classes={classes}
            showPanel={open}
          />
        </Grid>

        <Grid item lg={12} className={classes.editBar}>
          {/*<IconButton>*/}
          {/*    <RadioButtonCheckedOutlinedIcon />*/}
          {/*</IconButton>*/}

          <div className={isPublish ? classes.isArchivePublish : classes.archivePublish} />

          <div className={classes.iconsBar}>
            <IconButton onClick={handleEditProduct}>
              <ArEditIcon />
            </IconButton>
            <IconButton onClick={handleDownloadModel}>
              <ArDownloadIcon />
            </IconButton>
            <IconButton id="deleteIcon" onClick={handleClickOpenDialog}>
              <ArDeleteIcon />
            </IconButton>
          </div>
        </Grid>
        {/*
Tags area
*/}
        <Grid item lg={12}>
          <div className={classes.subTitleText}>{$.tags}</div>
          <div className={classes.tagsWrapper}>
            {(!product || !product.tags || product.tags.length === 0) ? <span className={classes.tag}>-</span> :
              product.tags.map((el, idx) => {
                if (el.name) {
                  return <span key={`tag-${el.id}`} className={classes.tag}>#{el.name}</span>;
                } else {
                  return <span key={`tag-${el.id}`} className={classes.tag}>-</span>;
                }

              })}
          </div>
        </Grid>
        <Grid item lg={12}>
          <div className={classes.subTitleText}>{$.codes}</div>
          <div className={classes.tagsWrapper}>
            {(!product || !product.codes || product.codes.length === 0) ? <span className={classes.tag}>-</span> :
              product.codes.map((el) => <span key={`code-${el.id}`} className={classes.tag}> {el.name} </span>)}
          </div>
        </Grid>
        {/*
Product Panels
*/}
        <Grid item lg={12}>
          <ProductPanelInfo filters={filters} classes={classes} product={product} />
        </Grid>
        <Grid item lg={12}>
          <ProductPanelArchive classes={classes} product={product} />
        </Grid>
      </Grid>

      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialogAgree}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{$.delete}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {$.dialogConfirmText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogDisagree} color="primary">
            {$.cancel}
          </Button>
          <Button onClick={handleCloseDialogAgree} color="primary">
            {$.confirm}
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};
