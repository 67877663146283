import React, { useEffect, useState } from 'react'
import { PassRestorePageStyles } from '../PassRestorePage/PassRestorePageStyles'
import GuestBar from '../../layout/GuestBar/GuestBar'
import { Button, TextField } from '@material-ui/core'
import { arWidgetPutUserPassword } from '../../Api/Utils/Utils'
import Toast from '../../Toast/Toast'
import { useHistory } from 'react-router'
import { langManager } from '../../../utils/languageManager/manager'


function PassResetPage(props) {
  const classes = PassRestorePageStyles()
  const config = {
    title: 'Alh 3d',
  }

  const history = useHistory()

  const [value1, setValue1] = useState('')
  const [value2, setValue2] = useState('')
  const [oldPass, setOldPass] = useState('')
  const [failPassword, setFailPassword] = useState('')
  const [successPassword, setSuccessPassword] = useState(false)
  const [validPass, setValidPass] = useState()
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('error');

  const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})")

  const handleInput1 = (e) => {
    setValue1(e.target.value)
    setError(false)
  }

  const handleInput2 = (e) => {
    setValue2(e.target.value)
    setError(false)
  }

  const handleClick = () => {
    if (value1 === value2) {
      setFailPassword('no-fail')
      setValidPass(mediumRegex.test(value1) && mediumRegex.test(value2))

      if (validPass) {
        setSuccessPassword(true)
      }
    } else {
      setFailPassword('fail')
      setValidPass(mediumRegex.test(value1) && mediumRegex.test(value2))
    }
  }

  const handleChangePassword = async () => {

    if (props.location && props.location.state.change === "change") {
      if (value1 === value2) {
        setFailPassword('no-fail')
        setValidPass(mediumRegex.test(value1) && mediumRegex.test(value2))

        if (mediumRegex.test(value1) && mediumRegex.test(value2)) {
          const params = {
            email: props.location.state.email,
            newPassword: value2,
            oldPassword: oldPass
          }
          await arWidgetPutUserPassword(params)
            .then(res => {
              setError(false)
            })
            .catch(err => {
              setError(true)
              setErrorMessage(err.data.message)
            })
        }
      } else {
        setFailPassword('fail')
        setValidPass(mediumRegex.test(value1) && mediumRegex.test(value2))
      }
    }
  }

  const handleBack = () => {
    if (props.location && props.location.state.change === "change") {
      history.goBack()
    }
  }



  // Переключение языка******************
  const [$, setResetPage] = useState(langManager.$.pages.resetPage)
  langManager.subscribe(($) => {
    setResetPage($.pages.resetPage)
    document.title = $.pages.resetPage.title
  })
  //**************** */

  useEffect(() => {
    localStorage.clear()
  }, [])



  if (props.location.state && props.location.state.change === "change") {
    return (
      <>
        {!error ? <></> : <Toast error={error} message={errorMessage} />}
        <GuestBar {...config} />
        <div className={classes.passRestorePage} style={{ paddingTop: "0px" }}>
          <div className={classes.subHeading}>{$.subtitle}</div>
          <div className={classes.main}>
            <div className={classes.invite}>
              {$.enterOldPassword}
            </div>
            <div>
              <div className={classes.label}>{$.oldPassword}</div>
              <input
                className={`${classes.restoreInput} ${classes.input}`}
                type="password"
                name="password"
                autoComplete="password"
                onChange={e => {
                  setOldPass(e.target.value)
                  setError(false)
                }}
                value={oldPass}
              />
            </div>
            <div className={classes.invite} style={{ marginTop: '45px' }}>
              {$.newPassword}
            </div>
            <div>
              <div className={classes.label}>{$.newPassword}</div>
              <input
                className={`${classes.restoreInput} ${classes.input}`}
                type="password"
                name="password"
                autoComplete="password"
                onChange={handleInput1}
                value={value1}
              />
            </div>
            <div>
            <div className={classes.label}>{$.repeatNewPassword}</div>
              <input
                className={`${classes.restoreInput} ${classes.input}`}
                type="password"
                name="password"
                autoComplete="password"
                onChange={handleInput2}
                value={value2}
              />
            </div>

            {failPassword === 'fail' ?
              <div className={classes.failText} style={{ paddingTop: '15px' }}>
                {$.passwordsDidNotMatch}
              </div>
              : <></>}
            {failPassword === 'no-fail' ? validPass ? <> </> : validPass === undefined ? <></> :
              <div className={classes.failText} style={{ paddingTop: '15px' }}>
                {$.enterStrongerPassword}
              </div>
              : <></>}
            <div className={classes.btnRow}>
              <Button variant="contained" color="primary" className={classes.restoreButton} style={{ width: 'auto', padding: "5px 10px", marginRight: "5px" }} onClick={handleBack}>
                {$.back}
              </Button>
              <Button variant="contained" color="primary" className={classes.restoreButton} style={{ width: 'auto', padding: "5px 10px", marginLeft: "5px" }} onClick={handleChangePassword}>
                {$.changePassword}
              </Button>
            </div>
          </div>
        </div>
      </>
    )
  }

  if (!successPassword) {
    return (
      <>
        <GuestBar {...config} />
        <div className={classes.passRestorePage}>
          <div className={classes.subHeading}>{$.passwordRecovery}</div>
          <div className={classes.main}>
            <div className={classes.invite}>
              {$.enterNewPassword}
            </div>
            <div>
            <div className={classes.label}>{$.password}</div>
              <input
                className={`${classes.restoreInput} ${classes.input}`}
                type="password"
                name="password"
                autoComplete="password"
                onChange={handleInput1}
                value={value1}
              />
            </div>
            <div>
            <div className={classes.label}>{$.repeatPassword}</div>
              <input
                className={`${classes.restoreInput} ${classes.input}`}
                type="password"
                name="password"
                autoComplete="password"
                onChange={handleInput2}
                value={value2}
              />
            </div>

            {failPassword === 'fail' ?
              <div className={classes.failText} style={{ paddingTop: '15px' }}>
                {$.passwordsDidNotMatch}
              </div>
              : <></>}
            {failPassword === 'no-fail' ? validPass ? <> </> : validPass === undefined ? <></> :
              <div className={classes.failText} style={{ paddingTop: '15px' }}>
                {$.enterStrongerPassword}
              </div>
              : <></>}

            <Button variant="contained" color="primary" className={classes.restoreButton} onClick={handleClick}>
              {$.changePassword}
            </Button>
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <GuestBar {...config} />
        <div className={classes.passRestorePage}>
          <div className={classes.subHeading}>{$.passwordRecovery}</div>
          <div className={classes.main}>
            <div className={classes.successInfo}>{$.passwordChangeSuccessfuly}</div>
          </div>
        </div>
      </>
    )
  }



}

export default PassResetPage