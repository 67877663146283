import axios from 'axios';
import { arWidgetRefreshToken } from '../Utils/Utils';
import decodeJwt from 'jwt-decode';
import { toast } from 'react-toastify';

let errorMessage = 'Что-то пошло не так...'


export const arWidget = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
  mode: 'cors',
  headers: {
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json; charset=utf-8',
    'Language': 'ru',
  },
  transformRequest: [
    (data) => {
      return JSON.stringify(data);
    },
  ],
});




export const arWidgetAuth = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
  mode: 'cors',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    'Language': 'ru',
  },
  transformRequest: [
    (data) => {
      return JSON.stringify(data);
    },
  ],
});



export const arWidgetUpload = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
  method: 'post',
  mode: 'cors',
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*',
    'Language': 'ru',
  },
});

export const arWidgetPut = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
  method: 'put',
  mode: 'cors',
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*',
    'Language': 'ru',
  },
});

export const arWidgetUploadFiles = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
  mode: 'cors',
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json; charset=utf-8',
    'Language': 'ru',
  },
  responseType: 'blob'
});


arWidgetAuth.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // localStorage.removeItem('token');
    const customId = 'interceptorsResponseError'
    if (!error.response.data.message) {
      toast.error(errorMessage, {
        toastId: customId
      })
    } else {
      toast.error(error.response.data.message, {
        toastId: customId
      })
    }
    return Promise.reject(error);
  },
);

// Request interceptor for API UPLOAD calls
arWidgetUpload.interceptors.request.use(
  async (config) => {
    const token = await localStorage.getItem('token');
    config.headers['Authorization'] = 'Bearer ' + token;
    return config;
  },
  (error) => {
    const customId = 'responseError'
    if (!error.response.data.message) {
      toast.error(errorMessage, {
        toastId: customId
      })
    } else {
      toast.error(error.response.data.message, {
        toastId: customId
      })
    }
    return Promise.reject(error);
  },
);

// Response interceptor for API UPLOAD calls
arWidgetUpload.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // localStorage.removeItem('token');
    const customId = 'interceptorsResponseError'
    if (!error.response.data.message) {
      toast.error(errorMessage, {
        toastId: customId
      })
    } else {
      toast.error(error.response.data.message, {
        toastId: customId
      })
    }
    return Promise.reject(error);
  },
);

// Request interceptor for API UploadFiles calls
arWidgetUploadFiles.interceptors.request.use(
  async (config) => {
    const token = await localStorage.getItem('token');
    config.headers['Authorization'] = 'Bearer ' + token;
    return config;
  },
  (error) => {
    const customId = 'responseError'
    if (!error.response.data.message) {
      toast.error(errorMessage, {
        toastId: customId
      })
    } else {
      toast.error(error.response.data.message, {
        toastId: customId
      })
    }
    return Promise.reject(error);
  },
);

// Response interceptor for API UploadFiles calls
arWidgetUploadFiles.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // localStorage.removeItem('token');
    const customId = 'interceptorsResponseError'
    if (!error.response.data.message) {
      toast.error(errorMessage, {
        toastId: customId
      })
    } else {
      toast.error(error.response.data.message, {
        toastId: customId
      })
    }
    return Promise.reject(error);
  },
);

// Request interceptor for API Put calls
arWidgetPut.interceptors.request.use(
  async (config) => {
    const token = await localStorage.getItem('token');
    config.headers['Authorization'] = 'Bearer ' + token;
    return config;
  },
  (error) => {
    const customId = 'responseError'
    if (!error.response.data.message) {
      toast.error(errorMessage, {
        toastId: customId
      })
    } else {
      toast.error(error.response.data.message, {
        toastId: customId
      })
    }
    return Promise.reject(error);
  },
);

// Response interceptor for API Put calls
arWidgetPut.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // localStorage.removeItem('token');
    const customId = 'interceptorsResponseError'
    if (!error.response.data.message) {
      toast.error(errorMessage, {
        toastId: customId
      })
    } else {
      toast.error(error.response.data.message, {
        toastId: customId
      })
    }
    return Promise.reject(error);
  },
);

// Request interceptor for API calls
arWidget.interceptors.request.use(
  async (config) => {
    const token = await localStorage.getItem('token');
    config.headers['Authorization'] = 'Bearer ' + token;
    return config;
  },
  (error) => {
    const customId = 'interceptorsRequestError'
    if (!error.response.data.message) {
      toast.error(errorMessage, {
        toastId: customId
      })
    } else {
      toast.error(error.response.data.message, {
        toastId: customId
      })
    }
    return Promise.reject(error);
  },
);

// Response interceptor for API calls

arWidget.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if ((error.response.status === 401 || error.response.status === 403) && (typeof originalRequest._retry === 'undefined' || !originalRequest._retry)) {
      originalRequest._retry = true;
      if (!localStorage.getItem('token')) {
        return Promise.reject(error.response);
      }

      const access_token = await arWidgetRefreshToken({
        accessToken: localStorage.getItem('token'),
        refreshToken: localStorage.getItem('refresh'),
      })
        .then((response) => {
          return response.data.items;
        })
        .then((responseData) => {
          const decodedToken = decodeJwt(responseData.authToken.accessToken);
          // конвертация из секунд в милисекунды
          const expTimeStampNew = decodedToken.exp * 1000;

          localStorage.setItem('token', responseData.authToken.accessToken);
          localStorage.setItem('expires', expTimeStampNew.toString());
          localStorage.setItem('refresh', responseData.authToken.refreshToken);

          return responseData.authToken.accessToken;
        })
        .catch(() => {
          localStorage.clear()
          window.location.replace('/login')
        });
      if (access_token === null) {
        return Promise.reject(error.response);
      }
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
      return arWidget(originalRequest);
    }
    else {
      const customId = 'responseError'
      if (!error.response.data.message) {
        toast.error(errorMessage, {
          toastId: customId
        })
      } else {
        toast.error(error.response.data.message, {
          toastId: customId
        })
      }
      return error
    }
  },
)









