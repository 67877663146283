import React from 'react';
import { makeStyles } from '@material-ui/core';
import { arColors } from '../../../../Assets/Assets';

export const ProductPanelStyles = makeStyles({
  main: {},

  productPanel: {
    fontFamily: 'Helios',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    position: 'fixed',
    background: 'white',
    top: '0',
    width: 'calc(43% + 24px) !important',
    minHeight: '100vh',
    maxHeight: '100vh',
    overflowY: 'scroll',
    zIndex: 10,
    transition: 'right .3s ease-in, visibility .3s',
    color: arColors.blackLight,
    paddingTop: 'calc(1rem + 64px)',
    paddingRight: '1rem',
    paddingBottom: '1rem',
    paddingLeft: '1rem',
    scrollbarWidth: 'none',
    '& > div': {
      flexBasis: 'auto',
    },
    '@media(max-width: 992px)': {
      width: '60% !important'
    },
    '@media(max-width: 576px)': {
      width: '80% !important'
    }
  },

  title: {
    color: arColors.blue,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '2rem',
    letterSpacing: '0.04em',
    '@media(max-width: 576px)': {
      fontSize: '1.5rem'
    }
  },

  productPanelClosed: {
    right: 'calc(-50% - 24px - 3rem)',
    visibility: 'hidden',
  },
  productPanelOpen: { right: '0' },

  image: {
    width: '100%',
    height: '280px',
    objectFit: 'contain',
    backgroundColor: '#F2F2F2',
    marginTop: '2rem',
  },

  imageDark: {
    width: '100%',
    height: '280px',
    objectFit: 'contain',
    backgroundColor: '#a7a7a7',
    marginTop: '2rem',
  },

  error: {
    color: 'red',
    width: '100%',
    height: '280px',
    objectFit: 'contain',
    backgroundColor: '#F2F2F2',
    marginTop: '2rem',
    paddingLeft: '2rem',
    paddingTop: '2rem',
  },

  tagsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '1rem',
    // maxWidth: '320px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: '70px',
  },

  tag: {
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    // background: "rgba(120, 120, 120, 0.2)",
    border: 'none',
    margin: '0.5rem',
    // width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#4D4D4D',
    '': {
      display: 'none',
    },
  },

  subTitleText: {
    marginTop: '0.5rem',
    color: arColors.grayDarkest,
    fontSize: '14px',

  },

  productInfo: {
    marginTop: '0.6rem',
    // paddingLeft: "5rem",
    right: '1rem',
  },
  accordion: {
    width: '100%',
  },
  accordionDetails: {
    flexWrap: 'wrap',
    marginTop: '10px'
    // "& > input": {
    //   fontSize: "0.1rem",
    // },
  },

  accordionSummary: {
    backgroundColor: arColors.grayLight,
    textTransform: 'uppercase',
    '& p': {
      fontSize: '10px',
      lineHeight: '12px',
    },
  },

  textFullWidth: {
    width: '100%',
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: '400',
    '& > label': {
      fontSize: '13px',
      lineHeight: '12px',
      fontWeight: '400',
      letterSpacing: '0.04em',
      color: '#808080 !important'

    },
    '&:disabled > label': {
      color: '#808080'
    },
    '&  input': {
      fontSize: '12px',
      lineHeight: '14px',
      fontWeight: '400',
    }
  },

  textLink: {
    width: '100%',
    color: arColors.blue,
    cursor: 'pointer',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: '400',
    '&:hover': {
      color: arColors.blueLight,
      // textShadow: `1px 1px 2px ${arColors.blue}`,
    },
    '@media(max-width: 576px)': {
      position: 'absolute'
    }
  },

  button: {
    width: '100%',
    color: 'white',
    textTransform: 'uppercase',
    fontSize: '0.7rem',
  },

  iconsBar: {
    paddingTop: '0.5rem',
    display: 'flex',
    justifyContent: 'center',
    '& :hover': {
      color: arColors.blue,
      cursor: 'pointer',
    },
    '&  button': {
      marginRight: '5px'
    },
    '&  button:hover': {
      backgroundColor: 'transparent'
    },
  },

  editBar: {
    display: 'flex',
    justifyContent: 'space-between',
    textOverflow: '...',
  },

  archivePublish: {
    width: '17px',
    height: '17px',
    borderRadius: '50%',
    backgroundColor: 'red',
  },

  isArchivePublish: {
    width: '17px',
    height: '17px',
    borderRadius: '50%',
    backgroundColor: 'green',
  },

  activeIcon: {
    color: arColors.greenLight,
    zIndex: 9,
    '& .MuiIconButton-label, & .MuiIconButton-label .MuiSvgIcon-root, & .MuiIconButton-label .MuiSvgIcon-root path': {
      pointerEvents: 'none'
    }
  },

  listItemNoPadding: {
    paddingLeft: '0',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '@media(max-width: 576px)': {
      position: 'relative',
      height: '50px'
    }
  },

  pWithTopMargin: {
    marginTop: '0',
    marginBottom: '5px',
  },

  filesList: {
    paddingTop: '12px',
  },

  accordionSubHeading: {
    fontSize: '10px',
    lineHeight: '12px',
    letterSpacing: '0.04em',
    color: '#808080'
  },

  accordionParagraph: {
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.04em',
    color: '#4D4D4D'
  },
  icon: {
    zIndex: 9,
    '& .MuiIconButton-label, & .MuiIconButton-label .MuiSvgIcon-root, & .MuiIconButton-label .MuiSvgIcon-root path': {
      pointerEvents: 'none'
    }
  },
  loadingWrapper: {
    width: '100%',
    height: '280px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loadingText: {
    color: '#4d4d4d'
  },
  modelsWrapper: {
    marginTop: '15px'
  },
  closeButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  closeButton: {
    background: 'transparent',
    cursor: 'pointer',
    outline: 'none',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

  },
  closeIcon: {
    fill: '#808080',
  },
  qrCodeWrapper: {
    minHeight: '280px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },
  panelTitle: {
    fontSize: '10px',
    lineHeight: '12px',
    letterSpacing: '0.04em',
    textTransform: 'uppercase',
  }
});
