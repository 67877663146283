import React, { useState, useEffect, useRef, useContext } from 'react';
import {ArBreadcrumbs} from '../Organizms/Breadcrumbs/ArBreadcrumbs';
import classes from './Information.module.css'
import {
  arWidgetGetOrganizationInfo,
  arWidgetPostOrganizationInfo,
  arWidgetPutOrganizationInfo,
} from '../Api/Utils/Utils';
import Toast from '../Toast/Toast';
import SecureLS from 'secure-ls';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import '../../../node_modules/antd/dist/antd.css'
import {langManager} from '../../utils/languageManager/manager';
import AutoComplete from '../Molecules/AutoComplete/AutoComplete';
import { NotificationContext} from '../../App';
import {FormControl, InputLabel, MenuItem, Select} from '@material-ui/core';
import { informationBreadCrumbs, initialBreadCrumbs } from '../../Helpers/helpers';
import { toast } from 'react-toastify';

function Information(props) {

  // Переключение языка
  const [$, setInformation] = useState(langManager.$.information)
  langManager.subscribe(($) => {
    setInformation($.information)
    document.title = $.information.title
  })
  ///////////////////
  const ls = new SecureLS({ encodingType: 'aes' });
  const [value, setValue] = useState({
    information: '',
    language: 'ru'
  })
  const [language, setLanguage] = useState('ru')
  const [typeRequest, setTypeRequest] = useState('post')
  const [organization, setOrganization] = useState({})
  const [disabled, setDisabled] = useState(true)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [state, dispatch] = useContext(NotificationContext);

  let id = null;
  if (props.options.idInfo) {
    id = props.options.idInfo
  } else {
    id = null
  }

  const editor = useRef();
  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };

  const getInfo = (paramId, language) => {
    setError(false)
    setDisabled(false)
    // если сейчас спрашивает инфо суперадмин то мы передаем id
    const params = paramId ? {language: language, id: paramId} : {language: language}
        arWidgetGetOrganizationInfo({params})
        .then(res => {
          setError(false)
          // если инфа организации нет то вернет items:null
          if(res.data.items === null){
            setTypeRequest('post')
          }else {
            setValue({
              information: res.data.items,
            })
            setTypeRequest('put')
          }
        })
        .catch(err => {
          setError(true)
        })
  }

  useEffect(() => {
    document.title = 'Информация'
    if(state.currentUser.roleName !== 'admin'){
      getInfo(null, language)
    }
  }, [])

  const handleModelChange = (e) => {
    setError(false)
    setValue({
      information: e
    })
  }

  const putOrganizationInfo = async (params) => {
    // это для того чтобы тоаст не показывался несколько раз
    const customToastId = 'customToastId'
    const data = organization.id ? {...params, id: organization.id} : params
    await  arWidgetPutOrganizationInfo(data)
    .then(() => {
      toast.success($.toastPutMessage, {
        toastId: customToastId
      })
    })
  }

  const handleClick =  async () => {

    const params = {
      information: value.information,
      language: language
    }

    // если у организации уже существует инфо то мы сделаем put запрос
    if(typeRequest === 'put'){
      putOrganizationInfo(params)
      return
    }

    // это для того чтобы тоаст не показывался несколько раз
    const customToastId = 'customToastId'
    const isAdmin = (state && state.currentUser && state.currentUser.roleName === 'admin')
    // если суперадмин спрашивает инфо то мы передаем id организации
    const data = isAdmin ? {...params, id: organization.id} : params
    await  arWidgetPostOrganizationInfo(data)
      .then(() => {
        toast.success($.toastPostMessage, {
          toastId: customToastId
        })

      })
  }

  const handleChangeLanguage = (e) => {
    setLanguage(e.target.value)
    // если данный юзер не супер админ то спрашиваем информацию без id
    if(state && state.currentUser && state.currentUser.roleName !== 'admin'){
      getInfo(null, e.target.value)
    // если супер админ то посмотрим выбрана ли организация, если выбрана то отправляем запрос
    }else if (organization && organization.id){
      getInfo(organization.id, e.target.value)
    }
  }

// если супер админ выбирает организацию в автокомплете
  useEffect(() => {
    if (organization && organization.id) {
      getInfo(organization.id, language)
    } else {
      setValue({
        information: ''
      })
      if (state && state.currentUser && state.currentUser.roleName === 'admin') {
        setDisabled(true)
      }
    }
  }, [organization])

  // если супер админ входит в данную страницу через страницу Organization
  useEffect(() => {
    setOrganization({
      id: id
    })
  }, [props])


  // нужные плагины для редактора
  const plugins = [['undo', 'redo', 'font', 'align', 'fontSize', 'blockquote', 'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'formatBlock', 'hiliteColor', 'horizontalRule', 'lineHeight', 'list', 'paragraphStyle', 'table', 'textStyle', 'image', 'imageGallery', 'video', 'audio']]
  // языки для выпадающего списка
  const languages = ['ru', 'en']

  return (
    <>
    <ArBreadcrumbs breadCrumbs={[initialBreadCrumbs, informationBreadCrumbs]} />
    <div className={classes.main}>
      {!error ? <></> : <Toast error={error} errorMessage={errorMessage} />}
      <div className={classes.autoCompleteContainer}>
        {(state && state.currentUser && state.currentUser.roleName === 'admin') ?
          <AutoComplete name={`infoOrganization`} setParentValue={e => setOrganization(e)} isClear={false} />
          : <></>
        }
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="languagesInfo"  className={classes.labelSelect}>{$.languagesInfo}</InputLabel>
          <Select
            labelId="languagesInfo"
            id="languagesInfo"
            value={language}
            onChange={handleChangeLanguage}
            label={$.languagesInfo}
            className = {`${classes.inputFilter} ${classes.inputFilterSelect}`}
          >
            {languages.map(item => {
              return (
                <MenuItem value={item} key={item + Math.random()*100}>
                  {item}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>
      <div className={classes.editor}>
        <SunEditor lang={$.textEditorLang}
                   style={{wordBreak: 'break-word'}}
                   className={classes.editorM}
                   getSunEditorInstance={getSunEditorInstance}
                   setContents={value.information}
                   onChange={handleModelChange}
                   setDefaultStyle = "word-break: break-word; overflow: scroll; resize: vertical;  height: auto;" setOptions={{
          wordBreak: 'break-word',
          buttonList: plugins}}
         />
        <button className={`${classes.button} ${disabled && classes.buttonDisabled}`} onClick={handleClick}>{$.save}</button>
      </div>
    </div>
    </>
  );
}


export default Information