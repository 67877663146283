import React, { useContext, useEffect, useState } from 'react';
import { ArBreadcrumbs } from '../../Organizms/Breadcrumbs/ArBreadcrumbs';
import { FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Slider, Switch, TextField } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import styles from './WidgetEdit.module.css'
import {
    arWidgetGetWidget,
    arWidgetGetWidgetButtons,
    arWidgetPostWidget,
    arWidgetPutWidget,
} from '../../Api/Utils/Utils';
import Toast from '../../Toast/Toast';
import { ButtonSave } from '../../Buttons/ButtonSave';
import { ButtonCancel } from '../../Buttons/ButtonCancel';
import CancelIcon from '@material-ui/icons/Cancel';
import { langManager } from '../../../utils/languageManager/manager';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useHistory } from 'react-router'
import DOMPurify from 'dompurify';
import { NotificationContext } from '../../../App';
import { editPagesBreadCrumbs, initialBreadCrumbs, widgetBreadCrumbs } from '../../../Helpers/helpers';
import { toast } from 'react-toastify';
import { CleanButton } from '../../Buttons/CleanButton';
import {JoinNode} from "three/examples/jsm/nodes/utils/JoinNode";


function WidgetEdit(props) {

    const [state, ] = useContext(NotificationContext)
    const currentUser = state.currentUser

    //Переключение языка
    const [$, setWidgetEdit] = useState(langManager.$.components.widgetEdit)
    langManager.subscribe(($) => {
        setWidgetEdit($.components.widgetEdit)
        document.title = $.components.widgetEdit.title
    })
    //******************

    const [loading, setLoading] = useState(false);
    const [select, setSelect] = useState({
        buttonClass: '',
        editToken: false,
        buttonId: ''
    });
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [validText, setValidText] = useState(false);
    const [validUrl, setValidUrl] = useState(false);
    const [buttonClasses, setButtonClasses] = useState([])
    const [btnPreview, setBtnPreview] = useState({
        __html: '<div></div>'
    })
    const [value, setValue] = useState({
        name: '',
        uri: '',
        buttonClass: '',
        buttonId: '',
        isActive: true,
        token: ''
    })
    const [text, setText] = useState('')

    let id = props.id
    if (!props.id) {
        id = undefined
    }


  function showToaster(message) {

    const customId = 'putWidgetIdToast'

    toast.success(message, {
      toastId: customId
    })
  }

    const handleChange = (event) => {
        let nam = event.target.name
        let val = event.target.value
        setSelect(prev => {
            return { ...prev, [nam]: val }
        });
        setError(false)
    };

    const handleChangeBtnClass = (e, child) => {
        setSelect(prev => {
            return { ...prev, buttonId: child.props.id, buttonClass: child.props.value }
        });
        if (!child.props.params) {
            setBtnPreview({
                __html: '<div></div>'
            })
        } else {
            const previewHtml = JSON.parse(child.props.params);
            setBtnPreview({
                __html: DOMPurify.sanitize(previewHtml.html)
            })
        }
    }


    const handleInput = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value })
        setError(false)
        setValidUrl(false)
    }

    const regexURL = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/

    const handleClick = () => {
        if (regexURL.test(value.uri)) {
            if (props.id) {
                putRequests()
            } else {
                postRequests()
            }
        }
        else {
            setValidUrl(true)
        }
        setError(false)
    }

    const putRequests = () => {
        putWidget()
    }

    const postRequests = () => {
        postWidget()
    }

    const handleCheckbox = (e) => {
        let nam = e.target.name;
        let val = e.target.checked;
        setValue({ ...value, [nam]: val });
        setError(false)
    }

    const getWidget = () => {
        if (!props.id) {
            setLoading(false)
            return
        }
        setLoading(true)
        arWidgetGetWidget(props.id)
            .then(res => {
                setLoading(false)
                setValue({
                    name: '',
                    uri: res.data.items.uri,
                    buttonClass: res.data.items.buttonClass,
                    buttonId: res.data.items.buttonId,
                    isActive: res.data.items.isActive,
                    token: res.data.items.token
                })
                setSelect({...select, buttonClass: res.data.items.buttonClass, buttonId: res.data.items.buttonId})
            }).catch(err => {
                setLoading(false)
                setError(true)
                setErrorMessage(err.data.message)
            })

    }


    const putWidget = async () => {
        const params = {
            uri: value.uri,
            buttonClass: select.buttonClass,
            editToken: select.editToken,
            isActive: value.isActive,
            buttonId: select.buttonId,
            organizationId: currentUser.organization.id
        }
        setLoading(true)
        await arWidgetPutWidget(props.id, params)
            .then(res => {
                setLoading(false)
                if(res && res.response && res.response.status && res.response.status === 500){
                    return false
                }else{
                    showToaster($.putToastMessage)
                }
            })
            .catch(err => {
                setLoading(false)
                setError(true)
                setErrorMessage(err.data.message)
            })
    }

    const postWidget = async () => {
        const params = {
            uri: value.uri,
            buttonClass: select.buttonClass,
            buttonId: select.buttonId,
            organizationId: currentUser.organization.id
        }
        setLoading(true)
        await arWidgetPostWidget(params)
            .then(res => {
                setLoading(false)
                if(res && res.response && res.response.status && res.response.status === 500){
                    return false
                }else{
                    showToaster($.postToastMessage)
                }
            })
            .catch(err => {
                setLoading(false)
                setError(true)
                setErrorMessage(err.data.message)
            })
    }

    const getWidgetButtons = () => {

        setLoading(true)
        arWidgetGetWidgetButtons()
            .then(res => {
                setLoading(false)
                setButtonClasses(res.data.items)

            }).catch(err => {
                setError(true)
                setLoading(false)
                setErrorMessage(err.data.message)
            })

    }

    const history = useHistory()

    const handleAddBtnConstructor = () => {
        history.push({
            pathname: '/widgetConstructor',
            state: { id: props.id, location: window.location.pathname }
        })
    }

    const handleBack = () => {
        history.goBack()
    }

    useEffect(() => {
        getWidget()
        getWidgetButtons()
        document.title = $.title
    }, []);

    if (loading) {
        return <div>Loading...</div>
    }

    return (
        <>
            {!error ? <></> : <Toast error={error} errorMessage={errorMessage} />}
            <ArBreadcrumbs breadCrumbs={[initialBreadCrumbs, widgetBreadCrumbs, editPagesBreadCrumbs('editing', window.location.pathname, 2)]} />
            <div className={styles.main}>
                <Grid container spacing={10}>
                    <Grid item xs={12} sm={12} md={7} lg={7}>
                        <Grid container spacing={3} style={{ alignItems: 'flex-end' }}>
                            <Grid item xs={6} className={styles.gridItem}>
                            <div className={styles.labelText}>{$.url}</div>
                                <TextField
                                    id="uri"
                                    type="url"
                                    name="uri"
                                    label={''}
                                    value={value.uri}
                                    required
                                    defaultValue={value.uri}
                                    className={`${validUrl ? styles.label : styles.input} ${styles.textField}`}
                                    onChange={handleInput} />
                                    <CleanButton clear={() => setValue({...value, uri: ''})} styles={styles.clearButtonInput}/>
                            </Grid>
                            <Grid item xs={props.id ? 5 : 6} className={styles.gridItem}>
                            <div className={styles.labelText}>{$.buttonType}</div>
                                <FormControl className={styles.formControl}>
                                    <InputLabel id="demo-simple-select-label"></InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="buttonClass"
                                        value={select.buttonClass}
                                        onChange={(e, ch) => { handleChange(e); handleChangeBtnClass(e, ch) }}
                                        className={styles.select}
                                    >
                                        <MenuItem value=''>{$.chooseYourDestiny}</MenuItem>
                                        {
                                            buttonClasses.length < 1 ? null :
                                                buttonClasses.map(item => (
                                                    <MenuItem value={item.name} id={item.id} params={item.parameters} key={item.id}>{item.name}</MenuItem>
                                                ))
                                        }
                                    </Select>
                                </FormControl>
                                <CleanButton
                                    clear={() => {setSelect({...select, buttonClass: ''}); setBtnPreview({ __html: '<div></div>'})}}
                                    styles={styles.clearButtonSelect}
                                />
                            </Grid>
                            {
                                props.id &&
                                <Grid item xs={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <button className={styles.addStyleButton} onClick={handleAddBtnConstructor}>
                                        <AddCircleOutlineIcon style={{ color: '#fff', pointerEvents: 'none' }} />
                                    </button>
                                </Grid>
                            }
                            {!props.id ? <></> :
                                <Grid item xs={6} className={styles.gridItem}>
                                    <div className={styles.labelText}>{$.editToken}</div>
                                    <FormControl className={styles.formControl}>
                                        <InputLabel id="demo-simple-select-label"></InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            disabled={(currentUser && (currentUser.roleName === 'admin' || currentUser.roleName === 'organization administrator')) ? false : true}
                                            name="editToken"
                                            defaultValue={select.editToken}
                                            value={select.editToken}
                                            onChange={handleChange}
                                            className={styles.select}
                                        >
                                            <MenuItem value={false}>{$.yes}</MenuItem>
                                            <MenuItem value={true}>{$.no}</MenuItem>

                                        </Select>
                                    </FormControl>
                                    <CleanButton
                                        clear={() => setSelect({...select, editToken: ''})}
                                        styles={styles.clearButtonSelect}
                                    />
                                </Grid>
                            }

                            {!props.id ?
                                <></>
                                :
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        className={styles.switchLabel}
                                        name="isActive"
                                        control={
                                            <Switch
                                                checked={value.isActive}
                                                onClick={(e) => handleCheckbox(e)}
                                                name="isActive"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            />
                                        }
                                        label={value.isActive ? 'Активна' : 'Неактивна'}
                                        labelPlacement="start"
                                    />
                                </Grid>
                            }
                        </Grid>
                        <div className={styles.btnBox}>
                            <ButtonCancel
                                startIcon={<CancelIcon />}
                                onClick={handleBack}
                                className={styles.btn}
                            >
                                Отмена
                            </ButtonCancel>
                            <ButtonSave
                                startIcon={<SaveIcon />}
                                onClick={handleClick}
                            >
                                {$.save}
                            </ButtonSave>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5}>
                        <div dangerouslySetInnerHTML={btnPreview} id="customBtnPreviewParent"/>
                     </Grid>
                </Grid>
            </div>
        </>
    );
}

export default WidgetEdit;
