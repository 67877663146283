import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export const ButtonEdit = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '9px',
    lineHeight: '10px',
    padding: '6px 12px',
    border: '1px solid',
    backgroundColor: '#8CC63F',
    borderColor: '#8CC63F',
    color: '#fff',
    borderRadius: '0',
    textTransform: 'uppercase',
    letterSpacing: '0.12em',
    '&:hover': {
      backgroundColor: '#8CC63F',
      borderColor: '#8CC63F',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#8CC63F',
      borderColor: '#8CC63F',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
})(Button);