import classes from './LoadingTemplate.module.css'

function LoadingTemplate({width, height}) {

    return (
        <div style={{width: width, height: height}} className={classes.loadingTemplate}>
            <div className={classes.animation}></div>
        </div>
    );
}

export default LoadingTemplate;