import React, {useState} from "react";
import {connect} from "react-redux";
import {userLogout} from "react-admin";
import MenuItem from "@material-ui/core/MenuItem";
import {useHistory} from "react-router";
import {makeStyles} from "@material-ui/core";
import {langManager} from "../../utils/languageManager/manager";

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        color: '#C4C4C4',
        '&:hover': {
            backgroundColor: 'transparent'
        }
      }
    },
  }));

const LogoutButton = ({ userLogout, ...rest }) => {

    //Переключение языка
    const [ $, setUserProfileMenu] = useState(langManager.$.components.userProfileMenu)
    langManager.subscribe(($) => {
        setUserProfileMenu($.components.userProfileMenu)
    })
    //////////////

    const classes = useStyles();

    const history = useHistory()

    const handleClick = () => {
        userLogout()
        history.push('/login')
    }

    return (
        <div className={classes.root}>
            <MenuItem
                onClick={handleClick}
                // {...sanitizeRestProps(rest)}
                {...rest}
            >
              {$.logout}
            </MenuItem>
        </div>
    );
}
export default connect(
    undefined,
    { userLogout }
)(LogoutButton);
