import React from 'react';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useHistory} from 'react-router';
import classes from './Toast.module.css'


function Toast({ errorMessage, error, ...props }) {
    const history = useHistory()
    // const toastId = React.useRef(null);
    const customId = "custom-id-yes";
    
    const notify = () => {
        if (errorMessage !== '') {
            toast.error(errorMessage, {
                toastId: customId
            });
        }
    }

    if (error ) {
        notify()
    }


    const goBack = () => {
        history.push('/login')
    }

    if(props?.refreshError){

        setTimeout(goBack, 3500)
        return (
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            className={classes.toast}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            limit={1}
            style={{fontSize: '14px', color: '#fff'}}
            onClick={e => {goBack()}}
          />
        )
    }
    return (

        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            limit={1}
            style={{fontSize: '14px'}}
        />

    )
}

export default Toast;