import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Card, CardContent, CardHeader, useMediaQuery } from '@material-ui/core';
import { Chart } from './Chart/Chart';
import Input from '@material-ui/core/Input';
import {
  arWidgetDownloadStatisticsDevices,
  arWidgetDownloadStatisticsProductViewsPerDay,
  arWidgetDownloadStatisticsSortedByDevice,
  arWidgetDownloadStatisticsTopTen,
  arWidgetDownloadStatisticsUniqueIp,
  arWidgetDownloadStatisticsViewsByCategory,
  arWidgetStatisticsDevices,
  arWidgetStatisticsProductViewsPerDay,
  arWidgetStatisticsSortedByDevice,
  arWidgetStatisticsTopTen,
  arWidgetStatisticsUniqueIp,
  arWidgetStatisticsViewsByCategory,
} from '../Api/Utils/Utils';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import classes from './Dashboard.module.css';
import { Link } from 'react-router-dom';
import date from 'date-and-time';
import { langManager } from '../../utils/languageManager/manager';
import { useSelector } from 'react-redux';
import ThatPie from './Pie/Pie';
import LineChart from './LineChart/LineChart';
import LegendCharts from './LegendCharts/LegendCharts';
import LoadingTemplate from '../Molecules/LoadingTemplate/LoadingTemplate';

// import Home from './HandCraftedPie';

export default () => {
  const [showTemplate, setShowTemplate] = useState(true)
  const [devices, setDevices] = useState([]);
  const [uniqIps, setUniqIps] = useState([]);
  const [sortedByDevice] = useState([{ name: 'glbObj' }, { name: 'usdzObj' }]);
  const [sortedByDevice1, setSortedByDevice1] = useState([]);
  const [sortedByDevice2, setSortedByDevice2] = useState([]);
  const [productViewsPerDay, setProductViewsPerDay] = useState([]);
  const [topTen, setTopTen] = useState([]);
  const [viewsByCategory, setViewsByCategory] = useState([]);
  const [value, setValue] = React.useState({ dateFrom: '2020-08-26', dateTo: '2021-08-28' });
  const [activeElDevices, setActiveElDevices] = useState(null)
  const [activeUniqueIps, setActiveUniqueIps] = useState(null)
  const [activeViewsByCategory, setActiveViewsByCategory] = useState(null)
  const isSmall = useMediaQuery('(max-width: 500px)')
  const isSmallLg = useMediaQuery('(max-width: 1900px)')

  // тут цветы для легенд
  const colors = {
    devices: ['rgba(46, 204, 113, 0.8)', 'rgba(244, 208, 63, 0.8)', 'rgba(52, 152, 219, 0.8)', 'rgba(155, 89, 182, 0.8)', 'rgba(235, 152, 78, 0.8)'],
    uniqIps: ['rgba(52, 152, 219, 0.8)', 'rgba(155, 89, 182, 0.8)'],
    productViewsPerDay: ['#008561'],
    sortedByDevice: ['#008561', 'rgba(93, 173, 226, 0.8)'],
    topTen: ['rgba(5, 136, 66, 0.74)', 'rgba(46, 204, 113, 0.8)', 'rgba(244, 208, 63, 0.8)', 'rgba(52, 152, 219, 0.8)', 'rgba(155, 89, 182, 0.8)', 'rgba(235, 152, 78, 0.8)', 'rgba(46, 204, 113, 0.8)', 'rgba(244, 208, 63, 0.8)', 'rgba(52, 152, 219, 0.8)', 'rgba(155, 89, 182, 0.8)', 'rgba(235, 152, 78, 0.8)'],
    viewsByCategory: ['rgba(46, 204, 113, 0.8)', 'rgba(244, 208, 63, 0.8)', 'rgba(52, 152, 219, 0.8)', 'rgba(155, 89, 182, 0.8)', 'rgba(235, 152, 78, 0.8)', '#B77373', '#143519']
  }

  const [loading, setLoading] = useState({
    devices: false,
    uniqIps: false,
    productViewsPerDay: false,
    sortedByDevice: false,
    topTen: false,
    viewsByCategory: false,
  })


  const handleChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };


  const handleSearch = async (value) => {

    let datas = {
      dateFrom: value.dateFrom,
      dateTo: value.dateTo
    }

    if (!value.dateFrom || !value.dateTo) {
      const params = returnDate()
      datas = {
        dateFrom: params.dateTo,
        dateTo: params.dateTo
      }
    }

    await arWidgetStatisticsDevices(datas)
      .then((response) => {
        let res = response.data.items
        let obj = {
          name: '',
          count: '',
          color: ''
        }
        let arr = []
        res.map((item, index) => {
          obj = {
            name: item.name,
            count: item.count,
            color: colors.devices[index]
          }
          arr.push(obj)
        })
        setDevices(arr)
        setLoading(prev => {
          return { ...prev, devices: true }
        })
      })
      .catch(err => {
        setLoading(prev => {
          return { ...prev, devices: true }
        })
      })
    await arWidgetStatisticsUniqueIp(datas)
      .then((response) => {
        let res = JSON.parse(response.data.items)
        let val = []
        let obj = {
          name: '',
          count: '',
        }
        for (let i in res) {
          obj = {
            name: i,
            count: res[i]
          }
          val.push(obj)
        }
        let newVal = []
        let newObj = {
          name: '',
          count: '',
          color: ''
        }
        val.map((item, index) => {
          newObj = {
            name: item.name,
            count: item.count,
            color: colors.uniqIps[index]
          }
          newVal.push(newObj)

        })
        setUniqIps(newVal)
        setLoading(prev => {
          return { ...prev, uniqIps: true }
        })
      })
      .catch(err => {
        setLoading(prev => {
          return { ...prev, uniqIps: true }
        })
      })
    await arWidgetStatisticsProductViewsPerDay(datas)
      .then((response) => {
        let val1 = []
        let obj = { x: '', y: '' }
        let res = JSON.parse(response.data.items)
        if (res.length > 0) {
          res.map(item => {
            obj = {
              x: item.Date.split('.').join('-'),
              y: item.Count,
              color: colors.productViewsPerDay
            }
            val1.push(obj)
            setProductViewsPerDay(val1)
            return false
          })
        }
        setLoading(prev => {
          return { ...prev, productViewsPerDay: true }
        })
      })
      .catch(err => {
        setLoading(prev => {
          return { ...prev, productViewsPerDay: true }
        })
      })
    await arWidgetStatisticsSortedByDevice(datas)
      .then((response) => {
        let val1 = []
        let val2 = []
        let glbObj = { x: '', y: '' }
        let usdzObj = { x: '', y: '' }
        let res = JSON.parse(response.data.items)
        res.map(item => {
          glbObj = {
            x: item.Date.split('.').join('-'),
            y: item.Glb,
            color: colors.sortedByDevice[0]
          }
          usdzObj = {
            x: item.Date.split('.').join('-'),
            y: item.Usdz,
            color: colors.sortedByDevice[1]
          }
          val1.push(glbObj)
          val2.push(usdzObj)
          setSortedByDevice1(val1)
          setSortedByDevice2(val2)
        })

        setLoading(prev => {
          return { ...prev, sortedByDevice: true }
        })
      })
      .catch(err => {
        setLoading(prev => {
          return { ...prev, sortedByDevice: true }
        })
      })
    await arWidgetStatisticsTopTen(datas)
      .then((response) => {
        let res = JSON.parse(response.data.items)
        let val = []
        let obj = {
          name: '',
          count: '',
          id: ''
        }
        res.map(item => {
          obj = {
            name: item.Name,
            count: item.Count,
            color: colors.topTen[0],
            id: item.Id
          }
          val.push(obj)
        })
        setTopTen(val)
        setLoading(prev => {
          return { ...prev, topTen: true }
        })
      })
      .catch(err => {
        setLoading(prev => {
          return { ...prev, topTen: true }
        })
      })
    await arWidgetStatisticsViewsByCategory(datas)
      .then((response) => {
        let res = JSON.parse(response.data.items)
        let val = []
        let obj = {
          name: '',
          count: '',
          color: ''
        }
        if (res.length > 0) {
          res.map((item, index) => {
            obj = {
              name: item.Name,
              count: item.Count,
              color: colors.viewsByCategory[index]
            }
            val.push(obj)
          })
          setViewsByCategory(val)
        }
        setLoading(prev => {
          return { ...prev, viewsByCategory: true }
        })
      })
      .catch(err => {
        setLoading(prev => {
          return { ...prev, viewsByCategory: true }
        })
      })
  };

  //Переключение языка
  const [$, setDashboard] = useState(langManager.$.components.dashboard)
  langManager.subscribe(($) => {
    setDashboard($.components.dashboard)
    document.title = $.components.dashboard.title
  })

  function returnDate() {
    let now = new Date();
    let day = now.getDate()
    if (+now.getDate() < 10) {
      day = `0${now.getDate()}`
    }
    let month = now.getMonth()
    if (+now.getMonth() < 10) {
      month = `0${now.getMonth()}`
    }
    let year = now.getFullYear()
    const params = {
      dateFrom: month !== '00' ? `${year}-${month}-${day}` : `${year - 1}-${12}-${day}`,
      dateTo: `${year}-${now.getMonth() < 9 ? `0${now.getMonth() + 1}` : `${now.getMonth() + 1}`}-${day}`
    }

    return params
  }


  useEffect(() => {
    const params = returnDate()

    setValue(params)

    handleSearch(params)

    document.title = $.title

  }, [])


  const handleDownload = async (name) => {

    switch (name) {
      case 'devices':
        await arWidgetDownloadStatisticsDevices(value)
          .then((res) => {
            downloadStatistics(res, 'Статистика_по_просмотрам_с_устройства_')
          })
          .catch(err => console.log(err))
        break;
      case 'uniqueIp':
        await arWidgetDownloadStatisticsUniqueIp(value)
          .then(res => downloadStatistics(res, 'Кол-во_уникальных_ip_адресов_'))
          .catch(err => console.log(err))
        break;
      case 'productPerDay':
        await arWidgetDownloadStatisticsProductViewsPerDay(value)
          .then(res => downloadStatistics(res, 'Просмотры_продуктов_по_дням_'))
          .catch(err => console.log(err))
        break;
      case 'memoType':
        await arWidgetDownloadStatisticsSortedByDevice(value)
          .then(res => downloadStatistics(res, 'Разделение_по_моделям_'))
          .catch(err => console.log(err))
        break;
      case 'topTen':
        await arWidgetDownloadStatisticsTopTen(value)
          .then(res => downloadStatistics(res, 'Топ_10_моделей_'))
          .catch(err => console.log(err))
        break;
      case 'category':
        await arWidgetDownloadStatisticsViewsByCategory(value)
          .then(res => downloadStatistics(res, 'Топ_10_категорий_'))
          .catch(err => console.log(err))
        break;

      default:
        break;
    }
  }

  function downloadStatistics(response, name) {
    const url = window.URL
      .createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${name}${value.dateFrom}__${value.dateTo}.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  useEffect(() => {

    if (loading.devices &&
      loading.productViewsPerDay &&
      loading.sortedByDevice &&
      loading.topTen &&
      loading.uniqIps &&
      loading.viewsByCategory) {
      setShowTemplate(false)
    }

  }, [loading.devices, loading.productViewsPerDay, loading.sortedByDevice, loading.topTen, loading.uniqIps, loading.viewsByCategory])

  const handleClearDate = () => {
    let now = new Date();
    let day = now.getDate()
    if (+now.getDate() < 10) {
      day = `0${now.getDate()}`
    }
    let month = now.getMonth()
    if (+now.getMonth() < 10) {
      month = `0${now.getMonth()}`
    }
    let year = now.getFullYear()
    setValue({
      dateFrom: month !== '00' ? `${year}-${month}-${day}` : `${year - 1}-${12}-${day}`,
      dateTo: `${year}-${now.getMonth() < 9 ? `0${now.getMonth() + 1}` : `${now.getMonth() + 1}`}-${day}`
    })
  }

  return (

    <Card className={classes.templateCard}>
      <CardHeader title="" />
      <CardContent className={classes.cardContainer}>
        <div className={classes.inputsWrapper}>
          <Input type="date"
            id="dateFrom"
            name="dateFrom"
            className={classes.inputDate}
            value={value.dateFrom}
            onChange={handleChange}
          />
          <Input type="date"
            id="dateTo"
            value={value.dateTo}
            className={classes.inputDate}
            onChange={handleChange}
            name="dateTo"
          />
          <div className={classes.buttonWrapper}>
            <Button color="secondary"
              onClick={e => handleSearch(value)}
              className={classes.btnSearch}
              disabled={showTemplate}
            >
              <SearchIcon />
            </Button>
            <Button color="primary"
              className={classes.btnClear}
              disabled={showTemplate}
              onClick={handleClearDate}
            >
              <ClearIcon />
            </Button>
          </div>
        </div>
        <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }} >

          {/* ================================ Просмотр по устройтсвом и ОС ======================================= */}

          <div className={`${classes.box} ${classes.pie} ${showTemplate ? classes.noPaddingChart : null}`}>
            {
              showTemplate ? <LoadingTemplate width={'100%'} height={450} /> :
                <>
                  <div className={classes.titleBox}>{$.devices}</div>
                  <div className={classes.row}>
                    <div className={classes.col}>
                      <ThatPie width={300} height={350} devices={devices} activeEl={activeElDevices} setActiveEl={setActiveElDevices} />

                      <Button
                        // startIcon={<AddIcon/>}
                        component={Link}
                        variant="contained"
                        color="secondary"
                        className={classes.addButton}
                        onClick={e => handleDownload('devices')}
                      >
                        {$.downloadReport}
                      </Button>
                    </div>
                    <LegendCharts colors={colors} items={devices} contentKey="devices" setActiveEl={setActiveElDevices} />
                  </div>
                </>
            }

          </div>

          {/* ================================ Обращение с уникальных  IP ======================================= */}

          <div className={`${classes.box} ${classes.pie} ${showTemplate ? classes.noPaddingChart : null}`}>
            {
              showTemplate ? <LoadingTemplate width={'100%'} height={450} /> :
                <>
                  <div className={classes.titleBox}>{$.uniqIps}</div>
                  <div className={classes.row}>
                    <div className={classes.col}>
                      <ThatPie width={300} height={350} devices={uniqIps} activeEl={activeUniqueIps} setActiveEl={setActiveUniqueIps} />

                      <Button
                        // startIcon={<AddIcon/>}
                        component={Link}
                        variant="contained"
                        color="secondary"
                        className={classes.addButton}
                        onClick={e => handleDownload('uniqueIp')}
                      >
                        {$.downloadReport}
                      </Button>
                    </div>
                    <LegendCharts colors={colors} items={uniqIps} contentKey="uniqIps" setActiveEl={setActiveUniqueIps} />
                  </div>
                </>
            }
          </div>

          {/* ================================ Просмотр по категориям ======================================= */}

          <div
            className={`${classes.box} ${classes.pie} ${showTemplate ? classes.noPaddingChart : null}`}
          >
            {
              showTemplate ? <LoadingTemplate width={'100%'} height={450} /> :
                <>
                  <div className={classes.titleBox}>{$.viewsByCategory}</div>
                  <div className={classes.row}>
                    <div className={classes.col}>
                      <ThatPie
                        width={300}
                        height={350}
                        devices={viewsByCategory}
                        activeEl={activeViewsByCategory}
                        setActiveEl={setActiveViewsByCategory}
                      />
                      <Button
                        // startIcon={<AddIcon/>}
                        component={Link}
                        variant="contained"
                        color="secondary"
                        className={classes.addButton}
                        onClick={e => handleDownload('category')}
                      >
                        {$.downloadReport}
                      </Button>
                    </div>
                    <LegendCharts colors={colors} items={viewsByCategory} contentKey="viewsByCategory" setActiveEl={setActiveViewsByCategory} />
                  </div>
                </>
            }
          </div>


          {

            !isSmallLg ?
              /* ================================ Количество просмотров в день "ВСЕ УСТРОЙСТВА" ======================================= */
              <div className={`${classes.box} ${classes.lineChart} ${showTemplate ? classes.noPaddingChart : null}`}>
                {
                  showTemplate ? <LoadingTemplate width={'100%'} height={450} /> :
                    <>
                      <div className={classes.titleBox}>{$.productViewsPerDay}</div>
                      <div style={{ maxWidth: '100%' }}>
                        <LineChart
                          width={300}
                          height={350}
                          data1={productViewsPerDay}
                        // data2={stock2}
                        />
                        <Button
                          // startIcon={<AddIcon/>}
                          component={Link}
                          variant="contained"
                          color="secondary"
                          className={classes.addButton}
                          onClick={e => handleDownload('productPerDay')}
                        >
                          {$.downloadReport}
                        </Button>
                      </div>
                    </>}
              </div>
              :

              /* ================================ Топ 10 моделей ======================================= */
              <div className={`${classes.box} ${classes.chart} ${showTemplate ? classes.noPaddingChart : null}`}>
                {
                  showTemplate ? <LoadingTemplate width={'100%'} height={450} /> :
                    <>
                      <div className={classes.titleBox}>{$.topTen}</div>

                      <Chart width={300} height={350} data={topTen} />

                      <Button
                        // startIcon={<AddIcon/>}
                        component={Link}
                        variant="contained"
                        color="secondary"
                        className={classes.addButton}
                        onClick={e => handleDownload('topTen')}
                      >
                        {$.downloadReport}
                      </Button>
                    </>
                }
              </div>

          }

          {
            !isSmallLg ?
              /* ================================ Топ 10 моделей ======================================= */
              <div className={`${classes.box} ${classes.chart} ${showTemplate ? classes.noPaddingChart : null}`}>
                {
                  showTemplate ? <LoadingTemplate width={'100%'} height={450} /> :
                    <>
                      <div className={classes.titleBox}>{$.topTen}</div>

                      <Chart width={300} height={350} data={topTen} />

                      <Button
                        // startIcon={<AddIcon/>}
                        component={Link}
                        variant="contained"
                        color="secondary"
                        className={classes.addButton}
                        onClick={e => handleDownload('topTen')}
                      >
                        {$.downloadReport}
                      </Button>
                    </>
                }
              </div>
              :
              /* ================================ Количество просмотров в день "ВСЕ УСТРОЙСТВА" ======================================= */
              <div className={`${classes.box} ${classes.lineChart} ${showTemplate ? classes.noPaddingChart : null}`}>
                {
                  showTemplate ? <LoadingTemplate width={'100%'} height={450} /> :
                    <>
                      <div className={classes.titleBox}>{$.productViewsPerDay}</div>
                      <div style={{ maxWidth: '100%' }}>
                        <LineChart
                          width={300}
                          height={350}
                          data1={productViewsPerDay}
                        // data2={stock2}
                        />
                        <Button
                          // startIcon={<AddIcon/>}
                          component={Link}
                          variant="contained"
                          color="secondary"
                          className={classes.addButton}
                          onClick={e => handleDownload('productPerDay')}
                        >
                          {$.downloadReport}
                        </Button>
                      </div>
                    </>
                }
              </div>
          }

          {/* ================================ Количество просмотров в день "IOS and OTHERS" ======================================= */}


          <div className={`${classes.box} ${classes.lineChartFull} ${showTemplate ? classes.noPaddingChart : null}`}>
            {
              showTemplate ? <LoadingTemplate width={'100%'} height={450} /> :
                <>
                  <div className={classes.titleBox}>{$.sortedByDevice}</div>
                  <div className={classes.row}>
                    <div className={classes.col}>
                      <LineChart
                        width={300}
                        height={350}
                        data1={sortedByDevice1}
                        data2={sortedByDevice2}
                      />
                      <Button
                        // startIcon={<AddIcon/>}
                        component={Link}
                        variant="contained"
                        color="secondary"
                        className={classes.addButton}
                        onClick={e => handleDownload('memoType')}
                      >
                        {$.downloadReport}
                      </Button>

                    </div>
                    <LegendCharts colors={colors} items={sortedByDevice} contentKey="sortedByDevice" />
                  </div>
                </>
            }
          </div>
        </div>
      </CardContent>
    </Card>

  );
}

