import React, { useEffect, useState} from 'react';
import { initialBreadCrumbs, organizationBreadCrumbs } from '../../../../Helpers/helpers';
import {langManager} from '../../../../utils/languageManager/manager';
import {arWidgetDeleteOrganization, arWidgetGetOrganizations} from '../../../Api/Utils/Utils';
import ArTable from '../../../Organizms/ArTable/ArTable';
import {ArBreadcrumbs} from '../../../Organizms/Breadcrumbs/ArBreadcrumbs';
// import { currentUser } from '../../../../Helpers/helpers';
const  Organizations = (props) => {
  const setParentId = (parentId) => localStorage.setItem('organization', parentId)
  const currentPageSize = sessionStorage.getItem('pageSize') || 5
  
  const currentUser = JSON.parse(localStorage.getItem('currentUser'))
  useEffect(() => {
    document.title = "Организации"
  }, [])

  // Переключение языка
  const [ $, setOrganizations] = useState(langManager.$.components.organizations)
  langManager.subscribe(($) => {
    setOrganizations($.components.organizations)
    document.title = $.components.organizations.title
  })
  //**********

  return (
    <>
    <ArBreadcrumbs breadCrumbs={[initialBreadCrumbs, organizationBreadCrumbs]} />
      {!currentUser ? <></> :
      currentUser.roleName === 'admin' ?
    <ArTable
      basePath="/organization"
      addBtnText={$.newOrganization}
      targetList={{
        get: arWidgetGetOrganizations,
        delete: arWidgetDeleteOrganization,
      }}
      getId = {e => props.options.setIdInfo(e)}
      setParentId={setParentId}
      pageSize={currentPageSize}
      tableHeadings={[
        { name: $.organizationName, align: 'left', width: '290px' },
        { name: $.TIN, align: 'left', width: '250px' },
        { name: $.tariff, align: 'left', width: '90px' },
        { name: $.active, align: 'center', width: '50px' },
        { name: $.actions, align: 'center', width: '250px' },
      ]}
      rowCells={[
        { type: 'text', align: 'left', contentKey: 'name' },
        { type: 'text', align: 'left', contentKey: 'inn' },
        { type: 'text', align: 'left', contentKey: 'tariffName' },
        {
          type: 'badge',
          align: 'center',
          contentKey: 'isActive', 
          truthy: { text: $.active , cls: 'active' },
          falsy: { text: $.inactive , cls: 'inactive' },
        },
        { type: 'actionsOrg', align: 'center', cellPath: '/info' },
      ]}
      filters={[
        {
          name: 'name',
          type: 'input',
          initialValue: '',
          placeholder: $.organizationName,
        },
        { name: 'inn', type: 'input', initialValue: '', placeholder: $.TIN },
        {
          name: 'isActive',
          type: 'select',
          initialValue: 'default',
          options: [
            { value: 'default', text: $.all },
            { value: true, text: $.active },
            { value: false, text: $.inactive },
          ],
        },
      ]}
    />
        :
        <ArTable
        basePath="/organization"
        addBtnText="Новая организация"
        targetList={{
        get: arWidgetGetOrganizations,
        delete: arWidgetDeleteOrganization,
      }}
        setParentId={setParentId}
        pageSize={currentPageSize}
        tableHeadings={[
          { name: $.organizationName, align: 'left', width: '290px' },
          { name: $.TIC, align: 'left', width: '250px' },
          { name: $.tariff, align: 'left', width: '90px' },
          { name: $.active, align: 'center', width: '50px' },
          { name: $.actions, align: 'center', width: '250px' },
        ]}
        rowCells={[
      { type: 'text', align: 'left', contentKey: 'name' },
      { type: 'text', align: 'left', contentKey: 'inn' },
      { type: 'text', align: 'left', contentKey: 'tariffName' },
      {
        type: 'badge',
        align: 'center',
        contentKey: 'isActive',
        truthy: { text: 'Активна', cls: 'active' },
        falsy: { text: 'Неактивна', cls: 'inactive' },
      },
      { type: 'actions', align: 'center', cellPath: '/info' },
        ]}
        filters={[
      {
        name: 'name',
        type: 'input',
        initialValue: '',
        placeholder: $.organizationName,
      },
      { name: 'inn', type: 'input', initialValue: '', placeholder: $.TIN },
      {
        name: 'isActive',
        type: 'select',
        initialValue: '',
        options: [
      { value: 'default', text: $.all },
      { value: true, text: $.active },
      { value: false, text: $.inactive },
        ],
      },
        ]}
        />
      }
    </>
  );
}

export default  Organizations
