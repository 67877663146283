import {useState} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import classes from './Loading.module.css'
import {langManager} from '../../utils/languageManager/manager';

function Loading() {

    const [$, setLoading] = useState(langManager.$)
    langManager.subscribe(($) => {
        setLoading($)
    })


    return (
        <div className={classes.loading}>
            <div className={classes.title}>{$.loading}</div>
            <Box sx={{ display: 'flex' }}>
                <CircularProgress className={classes.circular}/>
            </Box>
        </div>
    );
}

export default Loading;