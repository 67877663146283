import { useEffect, useState } from 'react';
import { langManager } from '../../../../utils/languageManager/manager';
import { arWidgetDownloadSupportRequests, arWidgetGetSupportRequestsById, arWidgetPutSupportRequests } from '../../../Api/Utils/Utils';
import { ArBreadcrumbs } from '../../../Organizms/Breadcrumbs/ArBreadcrumbs';
import classes from './ApplicationsEdit.module.css'
import date from 'date-and-time'
import {useHistory} from 'react-router'
import { applicationsBreadCrumbs, editPagesBreadCrumbs, initialBreadCrumbs } from '../../../../Helpers/helpers';

function ApplicationsEdit({ id }) {
    //Переключение языка
    const [$, setApplicationsEdit] = useState(langManager.$.components.applicationsEdit)
    langManager.subscribe(($) => {
        setApplicationsEdit($.components.applicationsEdit)
        document.title = $.components.applicationsEdit.title
    })
    //******************

    const history = useHistory()

    const [items, setItems] = useState([])
    const [answer, setAnswer] = useState('')

    const getSupportRequest = async () => {
        await arWidgetGetSupportRequestsById(id)
            .then(res => {
                setItems(res.data.items)
                setAnswer(res.data.items.answer)
            })
            .catch(err => {
                console.log(err);
            })

    }

    const handleChangeTextArea = (e) => {
        setAnswer(e.target.value)
    }

    const handleClick = async () => {
        const params = {
            answer: answer
        }
        await arWidgetPutSupportRequests(id, params)
            .then(res => {
                history.goBack()
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleDownloadFiles = async () => {
        await arWidgetDownloadSupportRequests(id)
            .then(res => {
                downloadFiles(res, 'File')
            })
            .catch(err => {
                console.log(err);
            })
    }

    function downloadFiles(response, name) {
        let now = new Date()
        const url = window.URL
          .createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name}__${date.format(now, 'YYYY-MM-DD[__]HH:mm:ss')}.zip`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

    useEffect(() => {
        document.title = $.title
        getSupportRequest()
    }, [])


    return (
        <>
            <ArBreadcrumbs breadCrumbs={[initialBreadCrumbs, applicationsBreadCrumbs, editPagesBreadCrumbs('editing', window.location.pathname, 2)]} />
            <div className={classes.applicationsEditContainer}>
                <div className={classes.applicationsEditTextAreaWrapper}>
                    <textarea
                        name="applicationText"
                        id="applicationText"
                        value={items.textRequest ? items.textRequest : ''}
                        disabled={true}
                        className={`${classes.applicationsEditTextArea}`}
                    />
                </div>
                {
                    (items && items.hasFiles) && (
                        <>
                            <div className={classes.applicationsEditDownloadBtnWrapper}>
                                <button
                                    className={classes.applicationsEditBtn}
                                    onClick={handleDownloadFiles}
                                >
                                    {$.downloadFile}
                                </button>
                            </div>
                        </>
                    )
                }
                <div className={classes.applicationsEditTextAreaWrapper}>
                    <textarea
                        name="applicationAnswer"
                        id="applicationAnswer"
                        value={answer}
                        onChange={handleChangeTextArea}
                        className={`${classes.applicationsEditTextArea} ${classes.applicationsEditTextAreaVal}`}
                    />
                </div>
                <div className={classes.applicationsEditBtnWrapper}>
                    <button
                        className={classes.applicationsEditBtn}
                        onClick={handleClick}
                    >
                        {$.send}
                    </button>
                </div>
            </div>
        </>
    );
}

export default ApplicationsEdit;