import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, Slide, TextField } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '@material-ui/core/Typography';
import { ProductInfoStyles } from './ProductInfoStyles';
import { arWidgetGetCategory, arWidgetGetCategoryFilters, arWidgetGetCategoryFilterValues, arWidgetGetProduct, arWidgetPostProduct, arWidgetPutProduct } from '../../../Api/Utils/Utils';
import { useHistory } from 'react-router-dom';
import { langManager } from '../../../../utils/languageManager/manager';
import { ButtonCancel } from '../../../Buttons/ButtonCancel';
import { ButtonSave } from '../../../Buttons/ButtonSave';
import { ButtonEdit } from '../../../Buttons/ButtonEdit';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import { toast } from 'react-toastify';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ProductInfo = ({
  id,
  product,
  setProduct,
  getDataAttr,
  getOrganizationId,
  backStep,
  setBackStep,
  createProductCategoryId
}) => {

  //Переключение языка
  const [$, setProductsInfo] = useState(langManager.$.components.productsInfo)
  langManager.subscribe(($) => {
    setProductsInfo($.components.productsInfo)
  })
  //******************

  const history = useHistory();
  const classes = ProductInfoStyles();
  const [selectValue, setSelectValue] = useState(id ? product.trackingType : 0);
  const [expanded, setExpanded] = useState(true);
  const [filters, setFilters] = useState([]);
  const [filtersProduct, setFiltersProduct] = useState({
    filter1: (product && product.filter1) ? product.filter1.id : '',
    filter2: (product && product.filter2) ? product.filter2.id : '',
    filter3: (product && product.filter3) ? product.filter3.id : '',
    filter4: (product && product.filter4) ? product.filter4.id : '',
  });
  const [disabled, setDisabled] = useState(true)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleInput = (event) => {
    const nam = event.target.name;
    let val = event.target.value;
    setProduct({ ...product, [nam]: val });
  };

  const handleBack = () => {
    history.go(backStep);
  };

  const handleCancel = () => {
    if (id) {
      setDisabled(true)
    }
  }

  const handleEdit = () => {
    setDisabled(false)

  }

  const handleSave = async () => {
    await putProduct();
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };


  const handleCloseDialogDisagree = () => {
    setOpenDialog(false);
  };
  const handleCloseDialogAgree = () => {
    setOpenDialog(false);
    history.go(-1)
  };

  function showToaster(message) {

    const customId = 'putProductIdToast'

    toast.success(message, {
      toastId: customId
    })
  }


  const putProduct = async () => {
    const params = {
      id: +id,
      name: product.name,
      price: product.price,
      organizationId: getOrganizationId(),
      categoryId: product.category.id,
      description: product.description,
      trackingType: selectValue,
      images: product.images,
      filter1Id: filtersProduct.filter1 ? filtersProduct.filter1 : null,
      filter2Id: filtersProduct.filter2 ? filtersProduct.filter2 : null,
      filter3Id: filtersProduct.filter3 ? filtersProduct.filter3 : null,
      filter4Id: filtersProduct.filter4 ? filtersProduct.filter4 : null,
    };
    await arWidgetPutProduct(id, params)
      .then(() => {
        showToaster($.putToastMessage)
        setDisabled(true)
      });
  };

  const postProduct = async () => {
    const params = {
      name: product.name,
      price: product.price,
      organizationId: getOrganizationId(),
      categoryId: +localStorage.getItem('category'),
      description: product.description,
      trackingType: selectValue,
      filter1: filtersProduct.filter1 ? filtersProduct.filter1 : null,
      filter2: filtersProduct.filter2 ? filtersProduct.filter2 : null,
      filter3: filtersProduct.filter3 ? filtersProduct.filter3 : null,
      filter4: filtersProduct.filter4 ? filtersProduct.filter4 : null,
    };
    arWidgetPostProduct(params)
      .then((response) => {
        let newProductId = response.data.items.id
        showToaster($.postToastMessage)
        history.push(`/product/${newProductId}`)
        return newProductId
      }).then((newProductId) => {
        setDisabled(true)
        arWidgetGetProduct(newProductId)
      });
  };

  const handleNext = async () => {
    await postProduct()
  };


  const handleChangeSelect = (event) => {
    setSelectValue(event.target.value);
  };

  const handleChangeSelectFilters = (e) => {
    setFiltersProduct({ ...filtersProduct, [e.target.name]: e.target.value })
  }

  let filtersArr = []

  const getFiltersValue = async (id, index) => {
    arWidgetGetCategoryFilterValues(id)
      .then(res => {
        setFiltersProduct(prev => {
          if (product[`filter${index + 1}`] && product[`filter${index + 1}`].id) {
            return { ...prev, [`filter${index + 1}`]: product[`filter${index + 1}`].id }
          } else {
            return prev
          }
        })
        let valId = ''
        if (res.data.items.valuesForFilters && res.data.items.valuesForFilters.length > 0) {
          res.data.items.valuesForFilters.map(item => {
            for (let i = 1; i <= 4; i++) {

              if (product[`filter${i}`]) {
                if (item.id === product[`filter${i}`].id) {
                  valId = product[`filter${i}`].id
                }
              }

            }

            return false
          })
        }
        filtersArr = [...filtersArr, { ...res.data.items, valId: valId }]
        setFilters(filtersArr)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getCategoryFilters = async () => {
    arWidgetGetCategoryFilters(product.category.id ? product.category.id : localStorage.getItem('category'))
      .then(res => {
        if (res.data && res.data.items && res.data.items.length > 0) {
          res.data.items.map(((item, index) => {
            getFiltersValue(item.id, index)
            return false
          }))
        }
      })
  }

  useEffect(() => {
    getCategoryFilters()
  }, [])


  return (
    <>
      <Accordion className={classes.accordion} expanded={expanded} onChange={handleChange('panel1')} square>
        <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
          <Typography>{$.positionInfo}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            spacing={4}
            direction={'row'}
            justify={'space-between'}
            alignItems={"flex-end"}
          // alignContent={"flex-start"}
          >
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                onChange={(e) => handleInput(e)}
                className={classes.textFullWidth}
                label={$.name}
                size="small"
                id="name"
                name="name"
                defaultValue={product.name}
                disabled={id ? disabled : false}
              // inputProps={{ style: { fontSize: "0.9rem" } }}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <FormControl variant="standard" className={classes.textFullWidth} disabled={id ? disabled : false}>
                <InputLabel id="typeRenderLabelId">{$.typeFitting}</InputLabel>
                <Select
                  labelId="typeRenderLabelId"
                  id="typeRenderId"
                  value={selectValue}
                  onChange={handleChangeSelect}
                  label="Age"
                >
                  <MenuItem value={0}>{$.notIndicated}</MenuItem>
                  <MenuItem value={1}>{$.interior}</MenuItem>
                  <MenuItem value={2}>{$.onFace}</MenuItem>
                  <MenuItem value={3}>{$.onSelf}</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {filters && filters.length > 0 &&
              filters.map((filter, index) => {
                return (
                  <Grid item lg={6} md={6} sm={6} xs={12} key={filter.id}>
                    <FormControl variant="standard" className={classes.textFullWidth} disabled={id ? disabled : false}>
                      <InputLabel id={`filtersLabelId${index + 1}`}>{filter.name}</InputLabel>
                      <Select
                        labelId={`filtersLabelId${index + 1}`}
                        id={`filter${index + 1}`}
                        name={`filter${index + 1}`}
                        defaultValue={filter.valId}
                        onChange={handleChangeSelectFilters}
                      >
                        {
                          filter.valuesForFilters && filter.valuesForFilters.length > 0 &&
                          filter.valuesForFilters.map(value => (
                            <MenuItem value={value.id} key={value.id}>{value.name}</MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                )
              })
            }

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                onChange={(e) => handleInput(e)}
                multiline
                rows={4}
                size="small"
                label={$.description}
                className={classes.textFullWidth}
                name="description"
                id={'description'}
                defaultValue={product.description}
                disabled={id ? disabled : false}
              />
            </Grid>
            <Grid
              container
              lg={12}
              spacing={2}
              direction={'row'}
            >{
                id ? (
                  disabled
                    ?
                    <>
                      <Grid item lg={9} />
                      <Grid item lg={3} className={classes.btnWrapper}>
                        <ButtonEdit
                          className={`${classes.button} ${classes.editButton}`}
                          variant={'contained'}
                          size={'small'}
                          onClick={handleEdit}
                          startIcon={<EditIcon />}
                        >
                          {$.edit}
                        </ButtonEdit>
                      </Grid>
                    </>
                    :
                    <>
                      <Grid item lg={8} xs={0} />
                      <Grid item lg={2} xs={3} className={classes.btnWrapper}>
                        <ButtonCancel
                          className={`${classes.button} ${classes.defaultButton}`}
                          variant={'contained'}
                          size={'small'}
                          onClick={handleCancel}
                          startIcon={<CancelIcon />}
                        >
                          {$.cancel}
                        </ButtonCancel>
                      </Grid>
                      <Grid item lg={2} xs={3} className={classes.btnWrapper}>
                        <ButtonSave
                          className={`${classes.button} ${classes.successButton}`}
                          variant={'contained'}
                          size={'small'}
                          onClick={handleSave}
                          startIcon={<SaveIcon />}
                        >
                          {$.save}
                        </ButtonSave>
                      </Grid>
                    </>
                )
                  : (
                    <>
                      <Grid item lg={8} />
                      <Grid item lg={2}>
                        <ButtonCancel
                          className={`${classes.button} ${classes.defaultButton}`}
                          variant={'contained'}
                          size={'small'}
                          onClick={handleClickOpenDialog}
                          startIcon={<CancelIcon />}
                        >
                          {$.cancel}
                        </ButtonCancel>
                      </Grid>
                      <Grid item lg={2}>
                        <ButtonSave
                          className={`${classes.button} ${classes.successButton}`}
                          variant={'contained'}
                          size={'small'}
                          onClick={handleNext}
                          endIcon={<NavigateNextIcon />}
                        >
                          {$.further}
                        </ButtonSave>
                      </Grid>
                    </>
                  )
              }

            </Grid>

          </Grid>
        </AccordionDetails>
      </Accordion>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialogAgree}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{$.dialogText}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {$.confirmText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogDisagree} color="primary">
            {$.cancel}
          </Button>
          <Button onClick={handleCloseDialogAgree} color="primary">
            {$.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ProductInfo;
