//РУС
const subMenuRoles = {
    user: 'пользователь',
    manager: 'менеджер',
    admin: 'администратор',
    superAdmin: 'суперадмин',
    all: 'Все'
}
const ru = {
    loading: 'Загрузка...',
    homePageTitle: 'Главная',
    pagination: 'на странице',
    organization: 'Организация',
    downloadReport: 'скачать отчёт',
    authError: 'Необходима авторизация',
    breadCrumbs: {
        homePageTitle: 'Главная',
        categories: 'Категории',
        category: 'Категория',
        widgets: 'Виджеты',
        editing: 'Редактирование',
        information: 'Информация',
        users: 'Пользователи',
        support: 'Техническая поддержка',
        settings: 'Конфигурации',
        organizations: 'Организации',
        products: 'Продукты',
        application: 'Заявки',
        constructor: 'Конструктор',
        personal: 'Личный кабинет',
        configurations: 'Настройки',
        globalSearch: 'Глобальный поиск',
        tariffs: 'Тарифы'
    },
    // Information.jsx
    information: {
        title: 'Информация',
        textEditorLang: 'ru',
        save: 'СОХРАНИТЬ',
        languagesInfo: 'Языки',
        toastPutMessage: 'Успешно обновлена информация организации!',
        toastPostMessage: 'Успешно создана информация организации!'
    },///////////////////
    // Компоненты из папки components/pages
    pages: {
        //Страница логина***************************************************
        login: {
            title: 'Авторизация',
            subtitle: 'система управления платформой',
            signInOr: 'войдите в аккаунт или',
            register: 'зарегестрируйтесь',
            login: 'Логин',
            password: 'Пароль',
            rememberMe: 'Запомнить меня',
            forgotPassword: 'Забыли пароль?',
            enter: 'Войти',
            userNotfound: 'Пользователь не не найден'
        }, //*****************
        //Страница регистрации************************************************
        registration: {
            title: 'Регистрация',
            newUserRegistration: 'Регистрация нового пользователя',
            dataSentCheckYourEmail: 'Все данные отправлены. Ожидайте подтвержение на почту!',
            personalInformation: 'персональная информация',
            userInformation: 'информация о пользователе',
            errorValidate: 'Пожалуйста введите валидные поля!',
            // Персональная информация
            userForm: {
                surname: 'Фамилия:',
                name: 'Имя:',
                patronymic: 'Отчество:',
                position: 'Должность:',
                email: 'e-mail:',
                phoneNumber: 'Контактный телефон:'
            },
            // Информация о компании
            companyInformation: 'информация о компании',
            registrationIformatoin: 'Регистрационная иформация',
            addCompanyLogotype: 'Добавьте логотип компании',
            durgAndDrop: 'Перетащите сюда',
            orUpload: 'или загрузите',
            fromComputer: 'с компьютера',
            upload: 'загрузить',
            regInfo: {
                organizationName: 'Наименование:',
                productCategory: 'Категория товаров/позиций:',
                nameOfGeneralDirector: 'ФИО генерального директора:',
                website: 'Сайт:',
                TIN: 'ИНН:',
                RRC: 'КПП:',
                officePhone: 'Телефон офиса:'
            },
            // Адрес
            address: 'адрес',
            region: 'Регион:',
            city: 'Город:',
            actualAddress: 'Фактический адрес:',
            postcode: 'Индекс:',
            // Реквизиты для выставления счета
            invoiceDetails: 'Реквизиты для выставления счёта',
            checkingAccount: 'Расчетный счет:',
            BIC: 'БИК:',
            correspondentAccount: 'Корреспондентский счет:',
            bank: 'Банк:',
            bankAddress: 'Адрес банка:',
            licenseAgreement: 'Лицензионное соглашение',
            acceptLicenseAgreement: 'Я прочитал и принимаю условия лицензионного соглашения.',
            sendRequest: 'Отправить запрос'
        },//*****************
        // Страница восстановления пароля****************************
        restorePage: {
            title: 'Восстановление пароля',
            passwordRecovery: 'Восстановление пароля',
            pleaseEnterYourEmail: 'Пожалуйста введите свой e-mail, на который мы пришлём ссылку на восстановление пароля.',
            correctEmail: 'Введите корректный e-mail',
            sendRequest: 'Отправить запрос',
            successEmail: 'На указанный вами e-mail выслана ссылка для восстановления пароля.',
            failedEmail: 'К сожалению, такого адреса не существует.',
            showEmail: 'Укажите другой e-mail'

        }, // ******************
        // Страница сброса пороля
        resetPage: {
            title: 'Сброс пароля',
            subtitle: 'Смена пароля',
            enterOldPassword: 'Введите старый пароль:',
            enterNewPassword: 'Введите новый пароль:',
            oldPassword: 'Старый пароль:',
            newPassword: 'Новый пароль:',
            repeatNewPassword: 'Повторите новый пароль:',
            passwordsDidNotMatch: 'Пароли не совпали',
            enterStrongerPassword: 'Введите более надежный пароль',
            back: 'Назад',
            changePassword: 'Сменить пароль',
            passwordRecovery: 'Восстановление пароля',
            password: 'Пароль',
            repeatPassword: 'Повторите пароль',
            passwordChangeSuccessfuly: 'Пароль сменен успешно. Вы можете продолжить работу.'
        },//******************

        // Страница просмотра модели ****************************
        viewModels: {
            title: 'Просмотр модели',
        }, // ******************


    },// Конец раздела pages
    // Компоненты из папки Сomponents
    components: {
        //Error.js
        error: {
            error: 'Произошла ошибка !',
            back: 'назад'
        },//****** */
        // компонент Menu.jsx
        menu: {
            library: 'Библиотека',
            categories: 'Категории',
            widget: 'Виджет',
            widgets: 'Виджеты',
            settings: 'Конфигурации',
            information: 'Информация',
            users: 'Пользователи',
            references: 'Справочники',
            organizations: 'Организации',
            support: 'Тех поддержка',
            applications: 'Заявки',
            tariffs: 'Тарифы'
        },//*************
        // Categories.jsx
        categories: {
            title: 'Категории',
            organizationName: 'Название',
            models: 'Модели',
            published: 'Опубликовано',
            unpublished: 'Не опубликовано',
            creationDate: 'Дата создания',
            dateOfEdit: 'Дата изменения',
            organization: 'Организация',
            actions: 'Действия',
            addCategory: 'добавить категорию',
            breadCrumbs: 'Категории',
        },//************** */
        // CategoryEdit.jsx
        categoryEdit: {
            title: 'Категория',
            categoryName: 'Название:',
            searchAttributes: 'Атрибуты поиска',
            oneFieldIsRequired: 'Минимум одно поле обязательно к заполнению. Максимальное количество атрибутов - 4.',
            addLibrary: 'Добавьте библиотеку выбора для каждого атрибута.',
            addSelection: 'добавить выбор',
            choiseAttribute: 'Выбор атрибут',
            attribute: 'Атрибут',
            addAttribute: 'ДОБАВИТЬ АТРИБУТ',
            cancel: 'ОТМЕНИТЬ',
            save: 'СОХРАНИТЬ',
            error: 'Введите что-то...',
            backStepButton: 'Новая категория',
            loading: 'Загружается...',
            putToastMessage: 'Категория успешно обновлена!',
            postToastMessage: 'Категория успешно создана!'
        },//***********
        // Organizations.jsx
        organizations: {
            title: 'Организации',
            organizationName: 'Название',
            TIN: 'ИНН',
            tariff: 'Тариф',
            active: 'Активна',
            actions: 'Действия',
            newOrganization: 'Новая организация',
            all: 'Все',
            inactive: 'Неактивна'
        },//**********
        // OrganizationEdit.jsx , OrganizationPanel.jsx
        organizationEdit: {
            crumbs: 'Редактировать',
            organizationName: 'Название',
            TIN: 'ИНН',
            active: 'Активна',
            apiKey: 'Ключ API',
            cancel: 'ОТМЕНА',
            save: 'СОХРАНИТЬ',
            tariff: 'Тарифы',
            backStepButton: 'Новая организация'
        },/////////////
        //Products.jsx
        products: {
            title: 'Продукты',
            image: 'Изображение',
            productName: 'Название',
            model: 'Модель',
            organization: 'Организация',
            actions: 'Действия',
            filter: 'Фильтр',
            newProduct: 'новый продукт',
            loading: 'Загрузка...',
            name: 'Название',
            code: 'Код',
            modelAva: 'Наличие модели',
            all: 'Все',
            withModel: 'С моделью',
            noModel: 'Без модели',
            organization: 'Организация',
            tagName: 'Название тега'
        },//////////

        //ProductEditAr.jsx
        productEditAr: {
            title: 'Редактирование продукта',
            loading: 'Загрузка',
            back: 'Назад',
            codes: 'Коды',
            newCode: 'Новый код',
            addCode: 'Добавить код',
            newTag: 'Новый тэг',
            addTag: 'Добавить тэг',
            tags: 'Тэги',
            errorMessageField: 'Поле не может быть пустым!',
        },

        // *******************************************

        //ProductsInfo.jsx
        productsInfo: {
            positionInfo: 'Информация о позиции',
            name: 'Наименование',
            typeFitting: 'Тип примерки',
            notIndicated: 'Не указано',
            interior: 'В интерьере',
            onFace: 'На лице',
            onSelf: 'На себе',
            description: 'Описание',
            cancel: 'Отмена',
            save: 'Сохранить',
            edit: 'Редактировать',
            further: 'Далее',
            confirm: 'Подтвердить',
            dialogText: 'Отменить и возвращаться назад',
            confirmText: 'Дейстивельно хотите возвращаться назад?. Подтвердите действие!',
            putToastMessage: 'Товар успешно обновлен!',
            postToastMessage: 'Товар успешно создан!',
        },
        // *******************************************

        //ProductPanel.jsx
        productPanel: {
            loading: 'Загрузка',
            tags: 'Теги',
            codes: 'Коды',
            dialogConfirmText: 'Операцию удаления невозможно отменить. Подтвердите действие!',
            delete: 'Удаление',
            cancel: 'Отмена',
            confirm: 'Подтвердить',
            toastMessage: 'Не найден'
        },
        //ProductPanelArchive.jsx
        productPanelArchive: {
            packetDownload: 'Пакет загрузки',
            name: 'Название',
            archive: 'Архив',
            author: 'Автор',
            link: 'Линк',
            packetWeight: 'Вес пакета',
            listFiles: 'Список файлов',
            image: 'Изображение',
            toastMessage: 'Не найден'
        },
        //ProductPanelInfo.jsx
        productPanelInfo: {
            infoPosition: 'Информация о позиции',
            name: 'Наименование',
            category: 'Категория товаров/позиций',
            description: 'Описание:',
            attr: 'Атрибут',
            filter: 'Фильтр',
            noFilter: 'Нет фильтра'
        },
          // ProductPanelActiveImage.jsx
          productPanelActiveImage: {
            noModels: 'В данном товаре нет модели',
            validationError: 'Модель не прошла валидацию:',
            loading: 'Загрузка...'
        },
        //ModelPackage.jsx
        modelPackage: {
            enterNamePackage: 'Введите название пакета!',
            packetDownload: 'Пакет загрузки',
            name: 'Название',
            author: 'Автор',
            model: 'Модель',
            viewModel: 'Предпросмотр модели',
            edit: 'Редактировать',
            cancel: 'Отменить',
            save: 'Сохранить',
            emptyFieldError: 'Заполните обязательные поля!'
        },
        //ModelArchive.jsx
        modelArchive: {
            archiveModels: 'Архив моделей',
            image: 'Изображение',
            name: 'Название',
            dateCreation: 'Дата создания',
            dateArchivation: 'Дата архивации',
            actions: 'Действия'
        },
        //FileButtonUpload.jsx
        fileButtonUpload: {
            viewModel: 'Предпросмотр модели',
            downloadFromComp: 'загрузите с компьютера',
            download: 'Загрузить',

        },

        // Widget,jsx
        widget: {
            title: 'Виджеты',
            website: 'Сайт',
            embedCode: 'Код вставки',
            organization: 'Организация',
            status: 'Статус',
            actions: 'Действия',
            createWidget: 'Создать виджет',
        },//********** */
        // WidgetEdit.jsx
        widgetEdit: {
            crumbs: 'Редактирование',
            url: 'Адрес сайта',
            buttonType: 'Класс кнопки',
            chooseYourDestiny: 'Выбирите класс кнопки',
            rectrangular: 'Прямоугольная',
            rounded: 'Круглая',
            save: 'Сохранить',
            cancel: 'Отмена',
            editToken: 'Редактировать токен для виджета?',
            yes: 'да',
            no: 'нет',
            title: 'Редактирование виджета',
            putToastMessage: 'Виджет успешно обновлен!',
            postToastMessage: 'Виджет успешно создан!',
        },////////////

        // WidgetConstructor.jsx
        widgetConstructor: {
            title: 'Конструктор виджета',
            background: 'Фоновый цвет',
            border: 'Цвет границы',
            borderRadius: 'Радиус границы',
            text: 'Текст',
            textColor: 'Цвет текста',
            fontSize: 'Размер шрифта',
            fontWeight: 'Жирность шрифта',
            borderWidth: 'Ширина границы',
            nameBtn: 'Название кнопки',
            widthBtn: 'Длина кнопки',
            heightBtn: 'Высота кнопки',
            img: 'Загрузить картинку',
            imgWidth: 'Ширина картинки',
            imgHeight: 'Высота картинки',
            message: 'Размер изображения не должен превышать 64 KB',
            messageNameBtn: 'Введите название кнопки!',
            messageErrorLang: 'Название кнопки  должно быть в выбранном языке!',
            shadow: 'Тень',
            switchForShadowExt: 'Внешняя',
            switchForShadowInt: 'Внутренняя',
            horizontalShadow: 'Горизонтальное смещение',
            verticalShadow: 'Вертикальное смещение',
            blurShadow: 'Размытие',
            stretchShadow: 'Растяжение',
            colorShadow: 'Цвет тени',
            opacity: 'Прозрачность',
            removeImg: 'Удалить картинку',
            remove: 'Удалить',
            language: 'Язык',
            save: 'Сохранить',
            cancel: 'Отмена',
        },


        // TechnicalSupport.jsx
        support: {
            title: 'Техническая поддержка',
            messageSucces: 'Ваше сообщение успешно отправлено!',
            toHomePage: 'Вернуться на главную страницу',
            supportRequest: 'запрос в техническую поддержку',
            defaultFormFields: 'Поля формы будут заполнены по умолчанию в соответствии с вашим профилем. При необходимости измените данные.',
            answerWillBeSentToEmail: 'Вашему запросу будет присвоен номер. Ответ придет на электронную почту, указанную в профиле клиента или в соответствующем поле формы.',
            fullName: 'ФИО:',
            company: 'Компания:',
            phoneNumber: 'Контактный телефон:',
            problemDetails: 'Сведения о проблеме',
            requestSubject: 'Тема запроса:',
            attachFile: 'Прикрепите файл:',
            selectFile: 'выбрать файл',
            ext: 'Расширение: ',
            extension: '.pdf .txt .doc .docx .xls .xlsx .rtf .jpg .png',
            size: 'Размер: ',
            sizeMb: 'до 20 Мб',
            requestText: 'Текст запроса',
            dataProcessingConcent: 'Я даю согласие на обработку своих персональных данных.',
            sendRequest: 'Отправить запрос',
            invalidFields: 'Недопустимые поля'
        },//************** */
        // Users.jsx
        users: {
            title: 'Пользователи',
            avatar: 'Аватар',
            surname: 'Фамилия',
            name: 'Имя',
            patronymic: 'Отчество',
            email: 'Почта',
            role: 'Роль',
            organization: 'Организация',
            actions: 'Действия',

            subMenuRoles,

            newUser: 'новый пользователь',
        },//********
        // UserEdit.jsx
        userEdit: {
            title: 'Редактирование пользователя',
            editing: 'Редактировать',
            userName: 'Имя пользователя',
            email: 'Электронная почта',
            password: 'Пароль',
            name: 'Имя',
            surname: 'Фамилия',
            patronymic: 'Отчество',
            subMenuRoles,
            cancel: 'ОТМЕНА',
            save: 'СОХРАНИТЬ',
            role: 'Роли',
            backStepButton: 'Новый пользователь',
            organizationError: 'Выберите организацию!',
            passwordRec: 'Пароль должен содержать заглавные и строчные буквы, спецсимволы и цифры, а длина пароля должна быть больше 6 символов!',
            validationError: 'Невалидные данные'
        },
        // Settings.jsx
        settings: {
            title: 'Конфигурации',
            section: 'Секция',
            sectionName: 'Наименование',
            value: 'Значение',
            actions: 'Действия',
        },//**********
        //SettingsEdit.jsx
        settingsEdit: {
            crumbs: 'Редактировать',
            section: 'Секция',
            name: 'Наименование',
            value: 'Значение',
            cancel: 'ОТМЕНА',
            save: 'СОХРАНИТЬ'
        },/////////////////
        // Личный кабинет / PersonalArea.jsx
        personalArea: {
            title: 'Личный кабинет',
            logotype: 'Логотип компании',
            durgAndDrop: 'Перетащите сюда',
            orUpload: 'или загрузите',
            fromComputer: 'с компьютера',
            upload: 'загрузить',
            user: 'ПОЛЬЗОВАТЕЛЬ',
            bankAccounts: 'СЧЕТА',
            company: 'Компания',
            position: 'Должность',
            phoneNumber: 'Контактный телефон:',
            changeData: 'Изменить данные',
            changePassword: 'Изменить пароль',
            cancel: 'Отменить',
            save: 'Сохранить',

            organizationName: 'Название компании',
            productCategory: 'Категория товаров / позиций',
            fullNameOfCEO: 'ФИО генерального директора',
            TIN: 'ИНН',
            officePhoneNumber: 'Телефон офиса',
            RRC: 'КПП',
            website: 'Сайт',

            address: 'адрес',
            region: 'Регион:',
            postcode: 'Индекс:',
            city: 'Город:',
            actualAddress: 'Фактический адрес(дом, улица):',

            invoiceDetails: 'реквизиты для выставления счета',
            checkingAccount: 'Рассчетный счет:',
            bank: 'Банк:',
            BIC: 'БИК:',
            bankAddress: 'Адрес банк:',
            correspondentAccount: 'Корреспондентский счет:',

            extendTariff: 'Продлить тариф',
            changeTariff: 'Изменить тариф',
            changeDetails: 'Изменить реквизиты'
        },//****************
        userProfileMenu: {
            userProfile: 'Личный кабинет',
            logout: 'ВЫХОД',
            configuration: 'Настройка'
        },
        // ************************************
        // Dashboard.jsx
        dashboard: {
            title: 'Главная страница',
            devices: 'Просмотр по устройтсвом и ОС',
            uniqIps: 'Обращение с уникальных  IP',
            viewsByCategory: 'Просмотр по категориям',
            productViewsPerDay: 'Количество просмотров в день "ВСЕ УСТРОЙСТВА"',
            topTen: 'Топ 10 моделей',
            sortedByDevice: 'Количество просмотров в день "IOS and OTHERS"',
            downloadReport: 'Скачать отчет',
        },
        //**************************************

        //Configuration.jsx

        configuration: {
            title: 'Настройка',
            saveInAzure: 'Хранить модели в азур',
            azureConnectionString: 'Строка для подключения в азур',
            send: 'Отправить',
            language: 'Язык',
            message: 'Заполняйте поле для подключения азуре!',
            ru: 'Русский',
            en: 'Английский'
        },
        //********************************** */

        // arTable
        arTable: {
            hide: 'Скрыть',
            show: 'Показать',
            noData: 'Нет данных',
            pagination: 'На странице',
        },

        // Applications.jsx

        applications: {
            title: 'Заявки',
            requestNumber: 'Номер заявки',
            createdAt: 'Дата создания',
            theme: 'Тема',
            status: 'Статус',
            actions: 'Действия',
            organization: 'Организация',
            all: 'Все'
        },
        // *******************************************

        // ApplicationsEdit.jsx

        applicationsEdit: {
            title: 'Редактировать',
            send: 'Отправить',
            downloadFile: 'Скачать файлы'
        },

        //autoComplete.jsx
        autoComplete: {
            organization: 'Организация'
        },

        //Search.jsx
        searchComponent: {
            title: 'Поиск',
            categories: 'Категории',
            organizationName: 'Название',
            models: 'Модели',
            published: 'Опубликовано',
            unpublished: 'Не опубликовано',
            creationDate: 'Дата создания',
            dateOfEdit: 'Дата изменени',
            organization: 'Организация',
            actions: 'Действия',
            addCategory: 'добавить категорию',
            products: 'Продукты',
            breadCrumbs: 'Категории',
            image: 'Изображение',
            productName: 'Название',
            model: 'Модель',
            filter: 'Фильтр',
            yes: 'есть',
            no: 'но',
        },
        // **************************************

        //Tariffs.jsx
        tariffs: {
            title: 'Тарифы',
            addTariffs: 'Добавить тариф',
            picture: 'Изображение',
            name: 'Наименование',
            description: 'Описание',
            price: 'Стоимость',
            actions: 'Действия',
            isActive: 'Активность',
            isPublic: 'Публичность',
            active: 'Активный',
            inactive: 'Не активный',
            public: 'Публичный',
            inpublic: 'Не публичный',
            all: 'Все'
        },
        // ****************************************

        //TariffsEdit.jsx
        tariffsEdit: {
            title: 'Редактирование тарифа',
            name: 'Наименование',
            description: 'Описание',
            price: 'Стоимость',
            active: 'Активный',
            inactive: 'Не активный',
            public: 'Публичный',
            inpublic: 'Не публичный',
            count: 'Количество',
            withoutBorders: 'Без Ограничений',
            byUsers: 'Ограничение по количеству пользователей',
            byModels: 'Ограничение по количеству моделей',
            byWidgets: 'Ограничение по количеству виджетов',
            byApps: 'Ограничение по количеству приложений',
            cancel: 'Отменить',
            save: 'Сохранить',
            validationErrMess: 'Заполните обязательные поля!',
            mustBeNumber: 'Стоимость тарифа должна быть числовой!'
        },

        //Clean Button
        cleanButton: {
            title: 'Очистить все поля'
        }
    },
    // конец раздела components
}

export { ru }