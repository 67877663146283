import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { ArBreadcrumbsStyles } from './ArBreadcrumbsStyles';
import { langManager } from '../../../utils/languageManager/manager';
import { useContext, useState } from 'react';
import { NotificationContext } from '../../../App';


const ArBreadcrumbs = ({ breadCrumbs, background="inherit" }) => {

    const lei = breadCrumbs.length - 1 // индекс последнего элемента, сокращение от last element index
    const classes = ArBreadcrumbsStyles();

    const [errorLoadAvatar, setErrorLoadAvatar] = useState(false)

    // получить данный юзер
    const [state,] = useContext(NotificationContext);

    return (
        <div className={classes.rowBreadCrumbs} style={{backgroundColor: background}}>
            {
               !errorLoadAvatar && state && state.currentUser && state.currentUser.images && state.currentUser.images.length > 0 ?
                    <div className={classes.logoWrapper}>
                        <img 
                            src={state.currentUser.images[0]} 
                            alt="logo" 
                            onError={() => setErrorLoadAvatar(true)}
                        />
                    </div>
                    :
                    <div className={classes.logoWrapper}></div>
            }

            <Breadcrumbs
                aria-label="breadcrumb"
                className={classes.breadcrumbs}
                separator={<NavigateNextIcon className={classes.icon} fontSize="small" />}
            >

                {
                    breadCrumbs.slice(0, lei).map(crumb => {
                        return (<Link color="inherit" to={crumb.href}>
                            {langManager.$.breadCrumbs[crumb.name]}
                        </Link>)
                    })
                }


                <Typography color="textPrimary">{langManager.$.breadCrumbs[breadCrumbs[lei].name]}</Typography>
            </Breadcrumbs>
        </div>
    )
}


export { ArBreadcrumbs }
