import React, { useEffect, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Grid, TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { langManager } from '../../../../utils/languageManager/manager';

const ProductPanelInfo = ({ classes, product, filters }) => {
  const disabled = true
  const [expanded, setExpanded] = useState(true);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //Переключение языка
  const [$, setProductPanelInfo] = useState(langManager.$.components.productPanelInfo)
  langManager.subscribe(($) => {
    setProductPanelInfo($.components.productPanelInfo)
  })
  //******************


  return (
    <Accordion className={classes.accordion} expanded={expanded} onChange={handleChange('panel1')} square>
      <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.panelTitle}>{$.infoPosition}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>

        <Grid container
          spacing={2}
          direction={'row'}
          justify={'space-between'}
        >
          <Grid item
            lg={6}>
            <TextField
              id="name"
              className={classes.textFullWidth}
              label={`${$.name}:`}
              name="name"
              defaultValue={product.name}
              value={product && product.name ? product.name : ''}
              disabled={disabled}
              inputProps={{ style: { color: "#4D4D4D" } }}
              InputProps={{ disableUnderline: true }}
            />
          </Grid>
          <Grid item
            lg={6}>
            <TextField
              id={'categories'}
              className={classes.textFullWidth}
              label={`${$.category}:`}
              name="categoryId"
              defaultValue={(!product || !product.category || !product.category.name) ? '': product.category.name}
              disabled={disabled}
              InputProps={{ disableUnderline: true }}
              inputProps={{ style: { color: "#4D4D4D" } }}
            />
          </Grid>
          {
            filters && filters.length && filters.length > 0
              ? filters.map((item, index) => {
                return (
                  <>
                    <Grid item lg={6}>
                      <TextField
                        className={classes.textFullWidth}
                        name={`categoryFilter${index + 1}`}
                        id={`categoryFilter${index + 1}`}
                        label={`${item.name}:`}
                        value={product[`filter${index + 1}`] ? product[`filter${index + 1}`].value : $.noFilter}
                        disabled={disabled}
                        InputProps={{ disableUnderline: true }}
                        inputProps={{ style: { color: "#4D4D4D" } }}
                      />
                    </Grid>
                  </>
                )
              })

              : null
          }

          {product && product.description ?
            <Grid item lg={6}>
              <TextField
                className={classes.textFullWidth}
                name={`productDescription`}
                id={`productDescription`}
                label={$.description}
                defaultValue={product.description}
                disabled={disabled}
                InputProps={{ disableUnderline: true }}
                inputProps={{ style: { color: "#4D4D4D" } }}
              />
            </Grid>
            :
            null
          }

        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default ProductPanelInfo
