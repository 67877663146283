import {makeStyles} from '@material-ui/core/styles';
import {arColors} from '../../../Assets/Assets';

export const FileButtonUploadStyles = makeStyles({
  root: {
    display: 'flex',
  },
  input: {
    display: 'none',
  },
  button: {
    backgroundColor: arColors.blue,
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    borderRadius: 0,
    marginTop: '1rem',
    marginLeft: '1.3rem',
  },
  buttonDisabled: {
    backgroundColor: '#e0e0e3',
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    borderRadius: 0,
    marginTop: '1rem',
    marginLeft: '1.3rem',
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '10rem',
    height: '10rem',
    border: 'dashed white 1px',
    backgroundColor: arColors.grayDarkest,
  },
  fileImage: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '9rem',
    height: '9rem',
    backgroundColor: arColors.grayMiddle,
    objectFit: 'contain',

    margin: '0.5rem',
  },

  fileBorder: {
    border: `1px solid ${arColors.grayMiddle}`,
    padding: '0.2rem',
    width: '10rem',
    height: '14rem',
    '& p': {
      fontSize: '10px',
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
      color: arColors.grayMiddle,
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
  },

  fileTypeText: {
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    marginBottom: '0',
    flexShrink: '1',
    flexBasis: '50%',
    alignSelf: 'flex-end',
    textAlign: 'center',
  },
  inviteText: {
    color: 'white',
    marginTop: 0,
    flexShrink: '1',
    flexBasis: '50%',
    alignSelf: 'center',
    textAlign: 'center',
    paddingRight: '0.5rem',
    paddingLeft: '0.5rem',
    fontSize: '0.8rem',
  },
  labelText: {
    color: arColors.grayDark,
    fontSize: 11,
  },
  statusTextWrapper: {
    alignSelf: 'center',
    textAlign: 'center',
    '& :first-child': {
      color: arColors.greenLight,
    },
  },

  '@media(max-width: 870px)': {
    box: {
      width: '8rem',
    },
    fileBorder: {
      width: '8rem',
      '& p': {
        overflowWrap: 'normal',
      }
    },
    inviteText: {
      overflowWrap: 'normal',
    },
    button: {
      display: 'block',
      marginLeft: 0,
      marginTop: '0.5rem',
      textAlign: 'center',
    },
    fileImage: {
      width: '100%',
      height: '85%',
      margin: '0.5rem 0'
    }
  },
  '@media(max-width: 768px)': {
    root: {
      width: '5.8rem',
    },
    box: {
      width: '5.8rem',
      height: '5.8rem'
    },
    fileBorder: {
      width: '5.8rem',
      height: '5.8rem'
    },
    button: {
      width: '5.8rem',
      padding: '3px 0px',
      fontSize: '11px'
      
    },
    labelText: {
      height: '34px'
    },
    fileImage: {
      margin: '0'
    }
  },
  '@media(max-width: 599px)': {
    root: {
      width: '8rem',
    },
    box: {
      width: '8rem',
      height: '8rem'
    },
    fileBorder: {
      width: '8rem',
      height: '8rem'
    },
    button: {
      width: '8rem',
      padding: '3px 0px',
      fontSize: '11px'
      
    },
    labelText: {
      height: '34px'
    }
  },
  '@media(max-width: 480px)': {
    root: {
      width: '6.5rem',
    },
    box: {
      width: '6.5rem',
      height: '6.5rem'
    },
    fileBorder: {
      width: '6.5rem',
      height: '6.5rem'
    },
    button: {
      width: '6.5rem',
      padding: '3px 0px',
      fontSize: '11px'
      
    },
    labelText: {
      height: '34px'
    }
  },
  '@media(max-width: 390px)': {
    root: {
      width: '6rem',
    },
    box: {
      width: '6rem',
      height: '6rem'
    },
    fileBorder: {
      width: '6rem',
      height: '6rem'
    },
    button: {
      width: '6rem',
      padding: '3px 0px',
      fontSize: '11px'
      
    },
    labelText: {
      height: '34px'
    }
  },
});
