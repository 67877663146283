import React, { useEffect, useState } from 'react';
import { categoryBreadCrumbs, initialBreadCrumbs, productsBreadCrumbs } from '../../../../Helpers/helpers';
import { langManager } from '../../../../utils/languageManager/manager';
import { arWidgetDeleteProduct, arWidgetGetCategoryFilters, arWidgetGetCategoryFilterValues, arWidgetGetProducts } from '../../../Api/Utils/Utils';
import ArTable from '../../../Organizms/ArTable/ArTable';
import { ArBreadcrumbs } from '../../../Organizms/Breadcrumbs/ArBreadcrumbs';
import Toast from '../../../Toast/Toast';


export default function Products(props) {
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  const currentPageSize = sessionStorage.getItem('pageSize')

  const setParentId = (parentId) => localStorage.setItem('category', parentId);
  const orgId = currentUser && currentUser.organization.id ? currentUser.organization.id : null;
  const parentId = localStorage.getItem('category');
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [filters, setFilters] = useState([])

  //Переключение языка
  const [$, setProducts] = useState(langManager.$.components.products)
  langManager.subscribe(($) => {
    setProducts($.components.products)
    document.title = $.components.products.title
  })
  //******************



  const [categoryFilters, setCategoryFilters] = useState([
    {
      name: 'name',
      type: 'input',
      initialValue: '',
      placeholder: $.name,
    },
    {
      name: 'code',
      type: 'input',
      initialValue: '',
      placeholder: $.code,
    },
    {
      name: 'tagName',
      type: 'input',
      initialValue: '',
      placeholder: $.tagName,
    },
    {
      name: 'withModel',
      type: 'select',
      initialValue: 2,
      placeholder: $.modelAva,
      options: [
        { value: 2, text: $.all },
        { value: 1, text: $.withModel },
        { value: 0, text: $.noModel },
      ],
    },
  ]);

  const [categoryFiltersAd, setCategoryFiltersAd] = useState([
    {
      name: 'name',
      type: 'input',
      initialValue: '',
      placeholder: $.name,
    },
    {
      name: 'code',
      type: 'input',
      initialValue: '',
      placeholder: $.code,
    },
    {
      name: 'tagName',
      type: 'input',
      initialValue: '',
      placeholder: $.tagName,
    },
    {
      name: 'withModel',
      type: 'select',
      initialValue: 2,
      placeholder: $.modelAva,
      options: [
        { value: 2, text: $.all },
        { value: 1, text: $.withModel },
        { value: 0, text: $.noModel },
      ],
    },
    {
      name: 'organizationId',
      type: 'autocomplete',
      initialValue: '',
      placeholder: $.organization,
    },
  ]);

  const getCategoryFilters = async () => {
    setLoading(true)
    await arWidgetGetCategoryFilters(parentId, orgId)
      .then(response => {
        return response.data.items;
      })
      .then((filtersArr) => {
        setLoading(false)
        setFilters(filtersArr)
        let tempCategoryFilters = []
        if (currentUser.roleName === 'admin') {
          tempCategoryFilters = [...categoryFiltersAd];
        }else{
          tempCategoryFilters = [...categoryFilters];
        }

          filtersArr.map((element, index) => {
            setLoading(true)
            let options = []
            // get values of filters
           arWidgetGetCategoryFilterValues(element.id)
              .then(res => {

                if(res.data.items.valuesForFilters && res.data.items.valuesForFilters.length > 0){
                  options = [{value: 'default', text: $.all}]
                  res.data.items.valuesForFilters.map(item => {
                    let option = { value: item.name, text: item.name }
                    options = [...options, option]
                  })
                }

                tempCategoryFilters.push({
                  name: `strValuesFilter${index + 1}`,
                  type: 'select',
                  relatedId: element.id,
                  initialValue: 'default',
                  placeholder: element.name,
                  options: options
                });
                setLoading(false)
              }).catch(err => {
                console.log(err)
                setLoading(false)
              })

          })
          setCategoryFiltersAd(tempCategoryFilters)
          setCategoryFilters(tempCategoryFilters)
        
      })
      .catch(e => {
        setLoading(false)
        setError(true)
        setErrorMessage(e.data.message)
      })
  };

  useEffect(() => {
    document.title = "Продукты"
    getCategoryFilters()

  }, []);

  //TODO Проверить необходимость этой функции
  // if (!categoryFilters[categoryFilters.length - 1].relatedId) {
  //   return <div>Loading...</div>
  //
  // }

  if (loading) {
    return <div>{$.loading}</div>
  }

  return (
    <>
      {!error ? <></> : <Toast error={error} errorMessage={errorMessage} />}
      <ArBreadcrumbs breadCrumbs={[initialBreadCrumbs, categoryBreadCrumbs, productsBreadCrumbs]} />
      {currentUser.roleName === 'admin' ?
        <ArTable
          child={true}
          basePath={`/product`}
          addBtnText="Новый продукт"
          targetList={{ get: arWidgetGetProducts, delete: arWidgetDeleteProduct }}
          pageSize={currentPageSize}
          organizationId={orgId}
          parentParams={[{ paramName: 'categoryId', paramValue: parentId }]}
          setParentId={setParentId}
          categoryFilters={filters}
          tableHeadings={[
            { name: $.image, align: 'left', width: '250px' },
            { name: $.productName, align: 'left', width: '290px' },
            { name: $.model, align: 'center', width: '250px' },
            { name: $.organization, align: 'center', width: '250px' },
            { name: $.actions, align: 'center', width: '250px' },
          ]}
          rowCells={[
            { type: 'image', align: 'left', contentKey: 'images' },
            { type: 'text', align: 'left', contentKey: 'name', onClick: 'panel' },
            {
              type: 'badge',
              align: 'center',
              contentKey: 'hasModel',
              truthy: { text: 'есть', cls: 'active' },
              falsy: { text: 'нет', cls: 'inactive' },
            },
            { type: 'text', align: 'center', contentKey: 'organization' },
            { type: 'actions', align: 'center' },
          ]}
          filters={categoryFiltersAd}
        />
        :
        <ArTable
          child={true}
          basePath={`/product`}
          addBtnText={$.newProduct}
          targetList={{ get: arWidgetGetProducts, delete: arWidgetDeleteProduct }}
          pageSize={currentPageSize}
          organizationId={orgId}
          parentParams={[{ paramName: 'categoryId', paramValue: parentId }]}
          setParentId={setParentId}
          tableHeadings={[
            { name: $.image, align: 'left', width: '250px' },
            { name: $.productName, align: 'left', width: '290px' },
            { name: $.model, align: 'center', width: '250px' },
            { name: $.actions, align: 'center', width: '250px' },
          ]}
          rowCells={[
            { type: 'image', align: 'left', contentKey: 'images' },
            { type: 'text', align: 'left', contentKey: 'name', onClick: 'panel' },
            {
              type: 'badge',
              align: 'center',
              contentKey: 'hasModel',
              truthy: { text: 'есть', cls: 'active' },
              falsy: { text: 'нет', cls: 'inactive' },
            },
            { type: 'actions', align: 'center' },
          ]}
          filters={categoryFilters}
        />
      }
    </>
  );
}
