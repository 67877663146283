import React, { useEffect, useRef, useState } from 'react';
import { Button, Input } from '@material-ui/core';
import { FileButtonUploadStyles } from './FileButtonUploadStyles';
import CheckIcon from '@material-ui/icons/Check';
import { langManager } from '../../../utils/languageManager/manager';

const FileButtonUpload = ({
  filetypeText,
  htmlFor,
  accept,
  labelText,
  existingName,
  existingImage,
  setFormState,
  formState,
  error,
  ...props
}) => {
  const classes = FileButtonUploadStyles();
  const fileInputRef = useRef(null);

  const [previewName, setPreviewName] = useState(existingName);

  //Переключение языка
  const [$, setFileButtonUpload] = useState(langManager.$.components.fileButtonUpload)
  langManager.subscribe(($) => {
    setFileButtonUpload($.components.fileButtonUpload)
  })
  //******************

  const handleFileInputChange = (event) => {
    error(false)
    if (fileInputRef.current.files[0]) {
      setPreviewName(fileInputRef.current.files[0].name);
      setFormState({ ...formState, [htmlFor]: fileInputRef.current.files[0] });
    }
  };

  let disabled;
  if (props.disabled === undefined) {
    disabled = true;
  } else {
    disabled = props?.disabled;
  }


  const clearInput = (event) => {
    error(false)
    event.target.value = null;
  };
  useEffect(() => {
    if (fileInputRef.current.files[0] === undefined) {
      setPreviewName(existingName);
    } else {
      setPreviewName(null);
    }
  }, [fileInputRef, existingName]);

  return (
    <div className={classes.root}>
      <label htmlFor={htmlFor}>
        <p className={classes.labelText}>{labelText}</p>
        {existingName ? (
          <div className={classes.fileBorder}>
            <img className={classes.fileImage} src={existingImage} alt={$.viewModel} />
            <p>{existingName}</p>
          </div>
        ) : (
          <>
            <div className={classes.box}>
              {previewName ? (
                <div className={classes.statusTextWrapper}>
                  <CheckIcon />
                  <p className={classes.inviteText}> {previewName}</p>
                </div>
              ) : (
                <>
                  <p className={classes.fileTypeText}>{filetypeText}</p>
                  <p className={classes.inviteText}>{$.downloadFromComp}</p>
                </>
              )}
            </div>
            {disabled ?
              <Button variant='contained' component='span' className={disabled ? classes.button : classes.buttonDisabled}>
                {$.download}
              </Button>
              : <></>
            }
          </>
        )}
        <Input
          inputProps={{ accept: accept }}
          id={htmlFor}
          type='file'
          className={classes.input}
          onChange={handleFileInputChange}
          onClick={clearInput}
          inputRef={fileInputRef}
          disabled={!!existingName}
        />
      </label>
    </div>
  );
};

export default FileButtonUpload;
