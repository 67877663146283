import React, { useEffect, useState} from 'react';
import ArTable from '../../../Organizms/ArTable/ArTable';
import {arWidgetDeleteUser, arWidgetGetUsers} from '../../../Api/Utils/Utils';
import {ArBreadcrumbs} from '../../../Organizms/Breadcrumbs/ArBreadcrumbs';
import {langManager} from '../../../../utils/languageManager/manager';
import { initialBreadCrumbs, usersBreadCrumbs } from '../../../../Helpers/helpers';
// import { currentUser } from '../../../../Helpers/helpers';

const Users = () => {
  const setParentId = (parentId) => localStorage.setItem('user', parentId)
  const currentPageSize = sessionStorage.getItem('pageSize') || 5
  
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  useEffect(() => {
    document.title = "Пользователи"
  }, [])


  //Переключение языка
  const [ $, setUsers] = useState(langManager.$.components.users)
  langManager.subscribe(($) => {
    setUsers($.components.users)
    document.title = $.components.users.title
  })
  //******************

  return (
    <>
    <ArBreadcrumbs breadCrumbs={[initialBreadCrumbs, usersBreadCrumbs]}/>
    {currentUser.roleName === 'admin' ?
    <ArTable
    basePath="/user"
    addBtnText={$.newUser}
    targetList={{ get: arWidgetGetUsers, delete: arWidgetDeleteUser }}
    pageSize={currentPageSize}
    setParentId={setParentId}
    tableHeadings={[
      { name: $.avatar , align: 'left', width: '250px' },
      { name: $.surname , align: 'left', width: '290px' },
      { name: $.name , align: 'left', width: '250px' },
      { name: $.patronymic , align: 'left', width: '250px' },
      { name: $.email , align: 'left', width: '250px' },
      { name: $.role , align: 'left', width: '250px' },
      { name: $.organization , align: 'left', width: '250px' },
      { name: $.actions , align: 'center', width: '250px' },
    ]}
    rowCells={[
      { type: 'image', align: 'left', contentKey: 'avatar' },
      { type: 'text', align: 'left', contentKey: 'surname', clickable: true },
      { type: 'text', align: 'left', contentKey: 'name' },
      { type: 'text', align: 'left', contentKey: 'patronymic' },
      { type: 'text', align: 'left', contentKey: 'email' },
      { type: 'text', align: 'left', contentKey: 'roleId', locale: 'ru' },
      { type: 'text', align: 'left', contentKey: 'organization' },
      { type: 'actions', align: 'center' },
    ]}
    filters={[
      { name: 'name', 
        type: 'input', 
        initialValue: '', 
        placeholder: $.name },
      {
        name: 'surname',
        type: 'input',
        initialValue: '',
        placeholder: $.surname,
      },
      {
        name: 'email',
        type: 'input',
        initialValue: '',
        placeholder: $.email,
      },
      {
        name: 'organizationId',
        type: 'autocomplete',
        initialValue: '',
        placeholder: $.organization,
      },
      {
        name: 'roleId',
        type: 'select',
        options: [ {value : 'default', text: $.subMenuRoles.all}, {value : 3, text: $.subMenuRoles.user}, {value: 4, text: $.subMenuRoles.manager}, {value: 2, text: $.subMenuRoles.admin}, {value: 1, text: $.subMenuRoles.superAdmin}],
        initialValue: '',
        placeholder: $.role,
      },
    ]}
  />
    :
    <ArTable
      basePath="/user"
      addBtnText={$.newUser}
      targetList={{ get: arWidgetGetUsers, delete: arWidgetDeleteUser }}
      pageSize={currentPageSize}
      setParentId={setParentId}
      tableHeadings={[
        { name: $.avatar , align: 'left', width: '250px' },
        { name: $.surname , align: 'left', width: '290px' },
        { name: $.name , align: 'left', width: '250px' },
        { name: $.patronymic , align: 'left', width: '250px' },
        { name: $.email , align: 'left', width: '250px' },
        { name: $.role , align: 'left', width: '250px' },
        { name: $.actions , align: 'center', width: '250px' },
      ]}
      rowCells={[
        { type: 'image', align: 'left', contentKey: 'avatar' },
        { type: 'text', align: 'left', contentKey: 'surname', clickable: true },
        { type: 'text', align: 'left', contentKey: 'name' },
        { type: 'text', align: 'left', contentKey: 'patronymic' },
        { type: 'text', align: 'left', contentKey: 'email' },
        { type: 'text', align: 'left', contentKey: 'roleId', locale: 'ru' },
        { type: 'actions', align: 'center' },
      ]}
      filters={[
        { name: 'name', 
          type: 'input', 
          initialValue: '', 
          placeholder: $.name },
        {
          name: 'surname',
          type: 'input',
          initialValue: '',
          placeholder: $.surname,
        },
        {
          name: 'email',
          type: 'input',
          initialValue: '',
          placeholder: $.email,
        },
        {
          name: 'roleId',
          type: 'select',
          options: [ {value : 'default', text: $.subMenuRoles.all}, {value : 3, text: $.subMenuRoles.user}, {value: 4, text: $.subMenuRoles.manager}, {value: 2, text: $.subMenuRoles.admin}, {value: 1, text: $.subMenuRoles.superAdmin}],
          initialValue: '',
          placeholder: $.role,
        },
      ]}
    />
  }
    </>
  );
};

export default Users;
