import { useEffect, useState } from "react"
import { Checkbox, Container, FormControlLabel, FormGroup, Switch } from "@material-ui/core"
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Cancel'
import { useHistory } from 'react-router-dom'
import { langManager } from "../../../../utils/languageManager/manager"
import { ArBreadcrumbs } from "../../../Organizms/Breadcrumbs/ArBreadcrumbs"
import classes from './TariffsEdit.module.css'
import { ButtonCancel } from "../../../Buttons/ButtonCancel"
import { ButtonSave } from "../../../Buttons/ButtonSave"
import { arWidgetCopyTariffs, arWidgetGetTariffsById, arWidgetPostTariffs, arWidgetPutTariffs, arWidgetPostTariffsProperty } from '../../../Api/Utils/Utils'
import { toast } from "react-toastify"
import { editPagesBreadCrumbs, initialBreadCrumbs, tariffsBreadCrumbs } from "../../../../Helpers/helpers"
import { CleanButton } from "../../../Buttons/CleanButton"

function TariffsEdit({ id, location }) {
    //Переключение языка
    const [$, setTariffsEdit] = useState(langManager.$.components.tariffsEdit)
    langManager.subscribe(($) => {
        setTariffsEdit($.components.tariffsEdit)
        document.title = $.components.tariffsEdit.title
    })
    //******************

    const history = useHistory()

    const [tariff, setTariff] = useState({
        name: '',
        price: '',
        description: '',
        isActive: false,
        isPublic: false,
    })

    const [tariffBorders, setTariffBorders] = useState({
        byUsers: { id: '', value: '' },
        byModels: { id: '', value: '' },
        byWidgets: { id: '', value: '' },
        byApps: { id: '', value: '' }
    })

    const [borders, setBorders] = useState({
        byUsers: false,
        byModels: false,
        byWidgets: false,
        byApps: false
    })
    const [disabled, setDisabled] = useState({
        byUsers: false,
        byModels: false,
        byWidgets: false,
        byApps: false
    })

    const handleInput = (e) => {
        let nam = e.target.name
        let val = e.target.value
        setTariff({ ...tariff, [nam]: val })
    }

    const handleChange = (e) => {
        let nam = e.target.name
        let val = e.target.checked
        setTariff({ ...tariff, [nam]: val })
    }

    const handleBack = () => {
        history.goBack()
    }

    function getId(i) {
        switch (i) {
            case 'byUsers':

                return 1
            case 'byModels':

                return 2
            case 'byWidgets':

                return 3
            case 'byApps':

                return 4

            default:
                return null;
        }
    }

    // вернуть массив properties
    function propReturn(post = false) {
        let newArr = []

        for (let i in tariffBorders) {
            if (post) {
                if (borders[i] && tariffBorders[i]) {
                    let newObj = {
                        id: getId(i),
                        values: [
                            {
                                name: 'quantity',
                                value: disabled[i] ? null : tariffBorders[i] ? Number(tariffBorders[i].value) : null
                            },
                            {
                                name: 'nolimit',
                                value: disabled[i]
                            },

                        ]
                    }
                    newArr = [...newArr, newObj]
                }
            } else {
                if (borders[i] && tariffBorders[i] && tariffBorders[i].id) {
                    let newObj = {
                        id: tariffBorders[i].id,
                        values: [
                            {
                                name: 'quantity',
                                value: disabled[i] ? null : tariffBorders[i] ? Number(tariffBorders[i].value) : null
                            },
                            {
                                name: 'nolimit',
                                value: disabled[i]
                            },

                        ]
                    }

                    newArr = [...newArr, newObj]
                }
            }
        }

        return newArr

    }


    // создание тарифа
    const handleSave = async () => {

        if (!tariff.name || !tariff.description || tariff.price === '') {
            const customId = 'tariffToastId'
            toast.error($.validationErrMess, {
                toastId: customId
            })
            return false
        }

        if (typeof Number(tariff.price) !== 'number') {
            const customId = 'tariffToastId'
            toast.error($.mustBeNumber, {
                toastId: customId
            })
            return false
        }

        const params = {
            name: tariff.name,
            description: tariff.description,
            isActive: tariff.isActive,
            isPublic: tariff.isPublic,
            price: +tariff.price,
            properties: propReturn(true)
        }

        await arWidgetPostTariffs(params)
            .then(res => {
                history.goBack()
            })
            .catch(err => {
                console.log(err);
            })
    }

    // редактирование тарифа
    const handleEdit = async () => {

        if (!tariff.name || !tariff.description || tariff.price === '') {
            const customId = 'tariffToastId'
            toast.error($.validationErrMess, {
                toastId: customId
            })
            return false
        }

        if (typeof Number(tariff.price) !== 'number') {
            const customId = 'tariffToastId'
            toast.error($.mustBeNumber, {
                toastId: customId
            })
            return false
        }

        const params = {
            name: tariff.name,
            description: tariff.description,
            isActive: tariff.isActive,
            isPublic: tariff.isPublic,
            price: +tariff.price,
            properties: propReturn()
        }

        const copyParams = {
            ...params, properties: propReturn(true)
        }

        // тут копирование тарифа
        if (location.state && location.state.copy) {
            await arWidgetCopyTariffs(id, copyParams)
                .then(res => {
                    history.goBack()
                })
                .catch(err => {
                    console.log(err);
                })

            return false
        }

        // тут редактирование тарифа
        await arWidgetPutTariffs(id, params)
            .then(res => {
                let newArr = propReturn(true)
                postTariffProperty(newArr)
                history.goBack()
            })
            .catch(err => {
                console.log(err);
            })
    }

    // Создание свойста тарифа
    const postTariffProperty = async (params) => {
        await arWidgetPostTariffsProperty(id, params)
            .then(res => {
                console.log(res)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const getProperties = (properties) => {

        if (properties && properties.length > 0) {
            properties.map((prop) => {
                switch (prop.propertyId) {
                    case 1:
                        let newArrByUsers = prop.value ? (JSON.parse(prop.value) && JSON.parse(prop.value).values) ? JSON.parse(prop.value).values : [] : []
                        if (newArrByUsers.length > 0) {
                            setTariffBorders(prev => {
                                return { ...prev, byUsers: { id: prop.id, value: newArrByUsers[0].value } }
                            })
                            setDisabled(prev => {
                                if (newArrByUsers[1]) {
                                    return { ...prev, byUsers: newArrByUsers[1] && newArrByUsers[1].value === 'true' ? true : false }
                                } else {
                                    return { ...prev, byUsers: newArrByUsers[0] && newArrByUsers[0].name === 'nolimit' ? true : false }
                                }
                            })
                            setBorders(prev => {
                                return { ...prev, byUsers: true }
                            })
                        }
                        break;
                    case 2:
                        let newArrByModels = prop.value ? (JSON.parse(prop.value) && JSON.parse(prop.value).values) ? JSON.parse(prop.value).values : [] : []
                        if (newArrByModels.length > 0) {
                            setTariffBorders(prev => {
                                return { ...prev, byModels: { id: prop.id, value: newArrByModels[0].value } }
                            })
                            setDisabled(prev => {
                                if (newArrByModels[1]) {
                                    return { ...prev, byModels: newArrByModels[1] && newArrByModels[1].value === 'true' ? true : false }
                                } else {
                                    return { ...prev, byModels: newArrByModels[0] && newArrByModels[0].name === 'nolimit' ? true : false }
                                }
                            })
                            setBorders(prev => {
                                return { ...prev, byModels: true }
                            })
                        }
                        break;
                    case 3:
                        let newArrByWidgets = prop.value ? (JSON.parse(prop.value) && JSON.parse(prop.value).values) ? JSON.parse(prop.value).values : [] : []
                        if (newArrByWidgets.length > 0) {
                            setTariffBorders(prev => {
                                return { ...prev, byWidgets: { id: prop.id, value: newArrByWidgets[0].value } }
                            })
                            setDisabled(prev => {
                                if (newArrByWidgets[1]) {
                                    return { ...prev, byWidgets: newArrByWidgets[1] && newArrByWidgets[1].value === 'true' ? true : false }
                                } else {
                                    return { ...prev, byWidgets: newArrByWidgets[0] && newArrByWidgets[0].name === 'nolimit' ? true : false }
                                }
                            })
                            setBorders(prev => {
                                return { ...prev, byWidgets: true }
                            })
                        }
                        break;
                    case 4:
                        let newArrByApps = prop.value ? (JSON.parse(prop.value) && JSON.parse(prop.value).values) ? JSON.parse(prop.value).values : [] : []
                        if (newArrByApps.length > 0) {
                            setTariffBorders(prev => {
                                return { ...prev, byApps: { id: prop.id, value: newArrByApps[0].value } }
                            })
                            setDisabled(prev => {
                                if (newArrByApps[1]) {
                                    return { ...prev, byApps: newArrByApps[1] && newArrByApps[1].value === 'true' ? true : false }
                                } else {
                                    return { ...prev, byApps: newArrByApps[0] && newArrByApps[0].name === 'nolimit' ? true : false }
                                }
                            })
                            setBorders(prev => {
                                return { ...prev, byApps: true }
                            })
                        }
                        break;

                    default:
                        break;
                }

                return false
            })
        }

    }

    // получение тарифа по id
    const getTariffById = async () => {
        await arWidgetGetTariffsById(id)
            .then(res => {
                setTariff({
                    name: res.data.items.name,
                    price: res.data.items.price,
                    description: res.data.items.description,
                    isActive: res.data.items.isActive,
                    isPublic: res.data.items.isPublic,
                })
                if (res.data.items.properties) {
                    getProperties(res.data.items.properties)
                }
            })
            .catch(err => {
                console.log(err);
            })
    }


    useEffect(() => {
        document.title = $.title
        if (id) {
            getTariffById()
        }
    }, [])
    return (
        <>
            <ArBreadcrumbs breadCrumbs={[initialBreadCrumbs, tariffsBreadCrumbs, editPagesBreadCrumbs('editing', window.location.pathname, 2)]} />
            <Container>
                <div className={classes.main}>
                    <div style={{ width: '100%' }}>
                        <div className={classes.inputWrapper}>
                            <div className={classes.inputBlock}>
                                <div className={classes.labelText}>{$.name}*</div>
                                <input
                                    id="name"
                                    name="name"
                                    required
                                    value={tariff.name}
                                    className={classes.textField}
                                    onChange={(e) => handleInput(e)}
                                />
                                <CleanButton styles={classes.clearAllBtn} clear={() => setTariff({...tariff, name: ''})}/>
                            </div>
                            <div className={classes.inputBlock}>
                                <div className={classes.labelText}>{$.price}*</div>
                                <input
                                    id="price"
                                    name="price"
                                    required
                                    value={tariff.price}
                                    className={classes.textField}
                                    onChange={(e) => handleInput(e)}
                                />
                                <CleanButton styles={classes.clearAllBtn} clear={() => setTariff({...tariff, price: ''})}/>
                            </div>
                        </div>
                        <div className={classes.textareaWrapper}>
                            <div className={classes.labelText}>{$.description}*</div>
                            <textarea
                                id="description"
                                name="description"
                                required
                                value={tariff.description}
                                className={classes.textarea}
                                onChange={(e) => handleInput(e)}
                            />
                            <CleanButton styles={classes.clearAllBtn} clear={() => setTariff({...tariff, description: ''})}/>
                        </div>
                        <div className={classes.inputWrapper}>
                            <div className={`${classes.inputBlock} ${classes.switchWrapper}`}>
                                <FormControlLabel
                                    className={classes.switchLabel}
                                    name="isActive"
                                    control={
                                        <Switch
                                            checked={tariff.isActive}
                                            onClick={handleChange}
                                            name="isActive"
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        />
                                    }
                                    label={tariff.isActive ? $.active : $.inactive}
                                    labelPlacement="start"
                                />
                            </div>
                            <div className={`${classes.inputBlock} ${classes.switchWrapper}`}>
                                <FormControlLabel
                                    className={classes.switchLabel}
                                    name="isActive"
                                    control={
                                        <Switch
                                            checked={tariff.isPublic}
                                            onClick={handleChange}
                                            name="isPublic"
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        />
                                    }
                                    label={tariff.isPublic ? $.public : $.inpublic}
                                    labelPlacement="start"
                                />
                            </div>
                        </div>
                        <FormGroup className={classes.checkboxWrapper}>
                            <FormControlLabel
                                control={<Checkbox checked={borders.byUsers} onChange={e => setBorders({ ...borders, byUsers: e.target.checked })} />}
                                label={$.byUsers}
                            />
                        </FormGroup>
                        {
                            borders.byUsers &&
                            <div className={classes.inputWrapper}>
                                <div className={classes.inputBlock}>
                                    <div className={classes.labelText}>{$.count}</div>
                                    <input
                                        id="byUsers"
                                        name="byUsers"
                                        type="number"
                                        min={0}
                                        value={tariffBorders.byUsers.value}
                                        className={classes.textField}
                                        onChange={(e) => setTariffBorders({ ...tariffBorders, byUsers: { ...tariffBorders.byUsers, value: e.target.value } })}
                                        disabled={disabled.byUsers}
                                    />
                                    <CleanButton styles={classes.clearAllBtnForm} clear={() => setTariffBorders({...tariffBorders, byUsers: {id: tariffBorders.byUsers.id, value: ''}})}/>
                                </div>
                                <div className={classes.inputBlock}>
                                    <div className={classes.labelText}>{$.withoutBorders}</div>
                                    <Checkbox checked={disabled.byUsers} onChange={e => setDisabled({ ...disabled, byUsers: e.target.checked })} />
                                </div>
                            </div>
                        }
                        <FormGroup className={classes.checkboxWrapper}>
                            <FormControlLabel
                                control={<Checkbox checked={borders.byModels} onChange={e => setBorders({ ...borders, byModels: e.target.checked })} />}
                                label={$.byModels}
                            />
                        </FormGroup>
                        {
                            borders.byModels &&
                            <div className={classes.inputWrapper}>
                                <div className={classes.inputBlock}>
                                    <div className={classes.labelText}>{$.count}</div>
                                    <input
                                        id="byModels"
                                        name="byModels"
                                        type="number"
                                        min={0}
                                        value={tariffBorders.byModels.value}
                                        className={classes.textField}
                                        onChange={(e) => setTariffBorders({ ...tariffBorders, byModels: { ...tariffBorders.byModels, value: e.target.value } })}
                                        disabled={disabled.byModels}
                                    />
                                    <CleanButton styles={classes.clearAllBtnForm} clear={() => setTariffBorders({...tariffBorders, byModels: {id: tariffBorders.byModels.id, value: ''}})}/>
                                </div>
                                <div className={classes.inputBlock}>
                                    <div className={classes.labelText}>{$.withoutBorders}</div>
                                    <Checkbox checked={disabled.byModels} onChange={e => setDisabled({ ...disabled, byModels: e.target.checked })} />
                                </div>
                            </div>
                        }
                        <FormGroup className={classes.checkboxWrapper}>
                            <FormControlLabel
                                control={<Checkbox checked={borders.byWidgets} onChange={e => setBorders({ ...borders, byWidgets: e.target.checked })} />}
                                label={$.byWidgets}
                            />
                        </FormGroup>
                        {
                            borders.byWidgets &&
                            <div className={classes.inputWrapper}>
                                <div className={classes.inputBlock}>
                                    <div className={classes.labelText}>{$.count}</div>
                                    <input
                                        id="byWidgets"
                                        name="byWidgets"
                                        type="number"
                                        min={0}
                                        value={tariffBorders.byWidgets.value}
                                        className={classes.textField}
                                        onChange={(e) => setTariffBorders({ ...tariffBorders, byWidgets: { ...tariffBorders.byWidgets, value: e.target.value } })}
                                        disabled={disabled.byWidgets}
                                    />
                                    <CleanButton styles={classes.clearAllBtnForm} clear={() => setTariffBorders({...tariffBorders, byWidgets: {id: tariffBorders.byWidgets.id, value: ''}})}/>
                                </div>
                                <div className={classes.inputBlock}>
                                    <div className={classes.labelText}>{$.withoutBorders}</div>
                                    <Checkbox checked={disabled.byWidgets} onChange={e => setDisabled({ ...disabled, byWidgets: e.target.checked })} />
                                </div>
                            </div>
                        }
                        <FormGroup className={classes.checkboxWrapper}>
                            <FormControlLabel
                                control={<Checkbox checked={borders.byApps} onChange={e => setBorders({ ...borders, byApps: e.target.checked })} />}
                                label={$.byApps}
                            />
                        </FormGroup>
                        {
                            borders.byApps &&
                            <div className={classes.inputWrapper}>
                                <div className={classes.inputBlock}>
                                    <div className={classes.labelText}>{$.count}</div>
                                    <input
                                        id="byApps"
                                        name="byApps"
                                        type="number"
                                        min={0}
                                        value={tariffBorders.byApps.value}
                                        className={classes.textField}
                                        onChange={(e) => setTariffBorders({ ...tariffBorders, byApps: { ...tariffBorders.byApps, value: e.target.value } })}
                                        disabled={disabled.byApps}
                                    />
                                    <CleanButton styles={classes.clearAllBtnForm} clear={() => setTariffBorders({...tariffBorders, byApps: {id: tariffBorders.byApps.id, value: ''}})}/>
                                </div>
                                <div className={classes.inputBlock}>
                                    <div className={classes.labelText}>{$.withoutBorders}</div>
                                    <Checkbox checked={disabled.byApps} onChange={e => setDisabled({ ...disabled, byApps: e.target.checked })} />
                                </div>
                            </div>
                        }
                        <div className={classes.btnWrapper}>
                            <ButtonCancel
                                variant="contained"
                                color="secondary"
                                size="large"
                                startIcon={<CancelIcon />}
                                onClick={handleBack}
                            >
                                {$.cancel}
                            </ButtonCancel>
                            <ButtonSave
                                variant="contained"
                                color="primary"
                                size="large"
                                startIcon={<SaveIcon />}
                                onClick={id ? handleEdit : handleSave}
                            >
                                {$.save}
                            </ButtonSave>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default TariffsEdit