import { useContext, useEffect, useState } from 'react'
import { NotificationContext } from '../../App'
import classes from './Configuration.module.css'
import { TextField, Checkbox, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { langManager } from '../../utils/languageManager/manager';
import { toast } from 'react-toastify';
import { arWidgetPutOrganization } from '../Api/Utils/Utils';
import { ArBreadcrumbs } from '../Organizms/Breadcrumbs/ArBreadcrumbs';
import { configurationBreadCrumbs, initialBreadCrumbs } from '../../Helpers/helpers';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function Configuration() {
    const [state, dispatch] = useContext(NotificationContext);
    const role = state.currentUser ? state.currentUser.roleName : null
    const [value, setValue] = useState({
        isActive: false,
        azureConnectionString: ''
    })
    const [language, setLanguage] = useState('ru')
    // переключение языка
    const [$, setConfiguration] = useState(langManager.$.components.configuration)
    langManager.subscribe(($) => {
        setConfiguration($.components.configuration)
        document.title = $.components.configuration.title
    })

    // Языки для выдающего списка
    const languages = [
        { key: 'ru', value: $.ru },
        { key: 'en', value: $.en }
    ]

    const handleChengeCheckbox = (e) => {
        setValue({
            ...value,
            isActive: e.target.checked
        })
    }

    const handleInputChange = (e) => {
        setValue({
            ...value,
            azureConnectionString: e.target.value
        })
    }

    const handleSaveToAzure = async (id) => {
        await arWidgetPutOrganization(id, value)
            .then(res => {
                console.log(res);
            })
            .catch(err => {
                console.log(err);
            })
    }



    const handleClick = async () => {
        const id = state.currentUser ? state.currentUser.organization.id : null
        if (id && value.isActive && value.azureConnectionString !== '') {
            handleSaveToAzure(id)
        } else {
            const customId = "config-custom-id";
            toast.error($.message, {
                toastId: customId
            });
        }

    }

    const handleChangeSelect = (e) => {
        setLanguage(e.target.value)
        localStorage.setItem('language', e.target.value)
        // Изменить данный язык
        langManager.switch(e.target.value)
    }

    useEffect(() => {
        document.title = $.title
        if(localStorage.getItem('language')){
            setLanguage(localStorage.getItem('language'))
            langManager.switch(localStorage.getItem('language'))
        }
    }, [])

    return (
        <>
        <ArBreadcrumbs breadCrumbs={[initialBreadCrumbs, configurationBreadCrumbs]}/>
        <div className={classes.configWrapper}>
            {
                (role && (role === 'organization administrator' || role === 'manager')) &&
                (
                    <>
                        <div className={classes.configRow}>
                            <div className={classes.configCheckWrapper}>
                                <Checkbox
                                    {...label}
                                    className={classes.configCheck}
                                    name="azureIsActive"
                                    checked={value.isActive}
                                    onChange={handleChengeCheckbox}
                                />
                            </div>
                            <p className={classes.configText}>{$.saveInAzure}:</p>
                            <div className={classes.configRowForm}>
                                <TextField
                                    id="azureConnectionString"
                                    label={$.azureConnectionString}
                                    className={classes.configTextField}
                                    variant="standard"
                                    name="azureConnectionString"
                                    value={value.azureConnectionString}
                                    onChange={handleInputChange}
                                    disabled={!value.isActive}
                                />
                            </div>
                        </div>
                    </>
                )
            }

            <div className={classes.configRow}>
                <p
                    className={`${classes.configText} ${(role && (role === 'organization administrator' || role === 'manager')) && classes.configTextMl }`}
                >
                    {$.language}:
                </p>
                <FormControl variant="standard" className={classes.configSelect}>
                    <InputLabel id="langSelect"></InputLabel>
                    <Select
                        labelId="langSelect"
                        id="langSelect"
                        value={language}
                        onChange={handleChangeSelect}
                        label="Age"
                    >
                        {languages.map(item => {
                            return (
                                <MenuItem value={item.key} key={item.key}>{item.value}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </div>
            {
                (role && (role === 'organization administrator' || role === 'manager')) &&
                (
                    <button
                        className={classes.configBtn}
                        onClick={handleClick}
                        disabled={!value.isActive}
                    >
                        {$.send}
                    </button>
                )
            }
        </div>
        </>
    );
}

export default Configuration