import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { arWidgetGetOrganization, arWidgetGetTariffs, arWidgetPostOrganization, arWidgetPutOrganization, } from '../../../Api/Utils/Utils';
import { FormControlLabel, MenuItem, Select, Switch, Container } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { useHistory } from 'react-router-dom';
import { ArBreadcrumbs } from '../../../Organizms/Breadcrumbs/ArBreadcrumbs';
import { ButtonSave } from '../../../Buttons/ButtonSave';
import { ButtonCancel } from '../../../Buttons/ButtonCancel';
import Toast from '../../../Toast/Toast';
import { langManager } from '../../../../utils/languageManager/manager';
import BackStepButton from '../../../Organizms/BackStepButton/BackStepButton';
import { editPagesBreadCrumbs, initialBreadCrumbs, organizationBreadCrumbs } from '../../../../Helpers/helpers';
import { CleanButton } from '../../../Buttons/CleanButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '20px',
    maxWidth: '800px',
    width: '100%'
  },
  textField: {
    width: '380px',
    border: '1px solid #C4C4C4',
    padding: '5px 9px 6px',
    outline: 'none',
    fontStyle: 'italic',
    fontSize: '14px',
    color: '#808080',
    letterSpacing: '0.04em'
  },
  button: {
    flexBasis: '100%',
    marginTop: '15px',
    marginLeft: '10px',
  },
  switchLabel: {
    marginLeft: '40px',
    color: 'gray',
  },
  tariff: {
    minWidth: '115px',
    width: '380px',
    border: '1px solid #C4C4C4',
    padding: '2px 40px 3px 9px',
    outline: 'none',
    background: '#fff',
    fontSize: '14px',
    fontStyle: 'italic',
    color: '#808080',
    letterSpacing: '0.04em',
    '&:before, &:after': {
      display: 'none !important'
    },
    '&:focus, &>*:focus': {
      background: 'transparent !important'
    }
  },
  buttonsArea: {
    // paddingRight: '250px',
    marginTop: '35px',
    alignSelf: 'flex-end',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',

    '& > button': {
      marginLeft: '28px',
      minWidth: '120px',
    },
    '& > button:first-child': {
      marginLeft: '0px',
    },
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  inputDisabled: {
    width: '100%',
    padding: '5px 9px 6px',
    marginRight: '8px',
    boxSizing: 'border-box',
    fontSize: '14px',
    fontStyle: 'italic',
    color: '#808080',
    letterSpacing: '0.04em',
    borderRadius: 0,
    border: '1px solid #C4C4C4',
  },
  labelText: {
    fontSize: '11px',
    color: '#808080',
    marginBottom: '5px',
    marginTop: '10px'
  },
  inputBlock: {
    position: 'relative'
  },
  gridItem: {
    position: 'relative',
    minWidth: '115px',
    width: '380px',
    display: 'inline-block'
  },
  allCleanBtn: {
    right: '10px !important',
    top: '60% !important'
  },
  allCleanBtnSelect: {
    right: '20px !important',
    top: '60% !important'
  },
}));

export default function OrganizationEdit(props) {
  const classes = useStyles();
  const history = useHistory();

  //Переключение языка
  const [$, setOrganizationEdit] = useState(langManager.$.components.organizationEdit)
  langManager.subscribe(($) => {
    setOrganizationEdit($.components.organizationEdit)
  })///////////////////////////

  const id = () => {
    if (props.id) {
      return props.id;
    } else {

    }
  };

  const [organization, setOrganization] = useState({
    id: id(),
    isActive: false,
    name: '',
    inn: '',
    apiKey: '',
    tariffId: '',
  });

  const [loading, setLoading] = useState(true);
  const [tariff, setTariff] = useState('1');
  const [tariffs, setTariffs] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');


  const handleSelect = (event) => {
    const nam = event.target.name;
    let val = event.target.value;
    setTariff(val);
    setOrganization({ ...organization, [nam]: val });
    setError(false)
  };

  const handleCheckbox = (event) => {
    let nam = event.target.name;
    let val = event.target.checked;
    setOrganization({ ...organization, [nam]: val });
    setError(false)
  };

  const handleInput = (event) => {
    const nam = event.target.name;
    let val = event.target.value;
    setOrganization({ ...organization, [nam]: val });
    setError(false)
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleSave = async () => {
    if (organization.id !== undefined) {
      await putOrganization();
    } else {
      await postOrganization();
    }
  };

  const getOrganization = async () => {
    if (!props.id) {
      setLoading(false);
      return;
    }
    await arWidgetGetOrganization(props.id).then((response) => {
      setOrganization({...organization, ...response.data.items});
      setTariff(response.data.items.tariffId);
    }).catch(e => {
      setError(true)
      setErrorMessage(e.data.message)
    })
    await setLoading(false);
  };

  const getTariffs = async () => {
    await arWidgetGetTariffs({
      params: {
        page: 1,
      },
    }).then((response) => {
      // console.log(response.data.items)
      setTariffs(response.data.items);
    }).catch(e => {
      setError(true)
      setErrorMessage(e.data.message)
    })
  };

  const putOrganization = async () => {
    const params = {
      id: parseInt(props.id),
      isActive: organization.isActive,
      name: organization.name,
      inn: organization.inn,
      tariffId: tariff,
    };
    await arWidgetPutOrganization(props.id, params)
      .then(() => {
        history.go(-1)
      })
      .catch(e => {
        setError(true)
        setErrorMessage(e.data.message)
        console.log(e.data);
      });
  };

  const postOrganization = async () => {
    const params = {
      isActive: organization.isActive,
      name: organization.name,
      inn: organization.inn,
      tariffId: tariff,
    };
    await arWidgetPostOrganization(params)
      .then(() => history.go(-1)).catch(e => {
        setError(true)
        setErrorMessage(e.message)
      })
  };

  useEffect(() => {
    getOrganization();
    getTariffs();
  }, []);


  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      {!error ? <></> : <Toast error={error} errorMessage={errorMessage} />}
      <ArBreadcrumbs breadCrumbs={[initialBreadCrumbs, organizationBreadCrumbs, editPagesBreadCrumbs('editing', window.location.pathname, 2)]} />
      <Container>
        <div className={classes.root}>
          <div style={{ width: '100%' }}>
            <div className={classes.inputWrapper}>
              <div className={classes.inputBlock}>
                <div className={classes.labelText}>{$.organizationName}</div>
                <input
                  id="name"
                  name="name"
                  value={organization.name}
                  fullWidth
                  className={classes.textField}
                  onChange={(e) => handleInput(e)}
                />
                <CleanButton clear={() => setOrganization({ ...organization, name: '' })} styles={classes.allCleanBtn}/>
              </div>
              <div className={classes.inputBlock}>
                <div className={classes.labelText}>{$.TIN}</div>
                <input
                  id="inn"
                  name="inn"
                  value={organization.inn}
                  className={classes.textField}
                  onChange={(e) => handleInput(e)}
                />
                <CleanButton clear={() => setOrganization({ ...organization, inn: '' })} styles={classes.allCleanBtn}/>
              </div>
            </div>
            <div className={classes.gridItem}>
              <div className={classes.labelText}>{$.tariff}</div>
              <Select
                labelId="Тариф"
                id="tariff"
                name="tariffId"
                value={organization.tariffId}
                onChange={handleSelect}
                className={classes.tariff}
              >
                {tariffs.map((tariff) => {
                  return (
                    <MenuItem key={tariff.id + tariff.name} value={tariff.id}>
                      {tariff.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <CleanButton clear={() => { setTariff(''); setOrganization({ ...organization, tariffId: '' }); }} styles={classes.allCleanBtnSelect}/>
            </div>
            <FormControlLabel
              className={classes.switchLabel}
              name="isActive"
              control={
                <Switch
                  checked={organization.isActive}
                  onClick={(e) => handleCheckbox(e)}
                  name="isActive"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              }
              label={$.active}
              labelPlacement="start"
            />
            <div className={classes.labelText}>{$.apiKey}</div>
            <input
              placeholder={$.apiKey}
              id="apiKey"
              name="apiKey"
              defaultValue={organization.apiKey}
              disabled
              onChange={(e) => handleInput(e)}
              className={classes.inputDisabled}
            />
          </div>
          <div className={classes.buttonsArea} align="end">
            <ButtonCancel
              variant="contained"
              color="secondary"
              size="large"
              startIcon={<CancelIcon />}
              onClick={handleBack}
            >
              {$.cancel}
            </ButtonCancel>
            <ButtonSave
              variant="contained"
              color="primary"
              size="large"
              startIcon={<SaveIcon />}
              onClick={handleSave}
            >
              {$.save}
            </ButtonSave>
          </div>
        </div>
      </Container>
    </>
  );
}
