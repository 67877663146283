import {makeStyles} from '@material-ui/core/styles';
import {arColors} from '../../../Assets/Assets';

export const ArBreadcrumbsStyles = makeStyles({
  breadcrumbs: {
    '& a': {
      color: arColors.blue,
      fontSize: '12px',
      lineHeight: '32px'
    },
    '& li p': {
      color: arColors.blue,
      fontWeight: '800',
      fontSize: '12px',
    },
  },
  icon: {
    color: '#29ABE2'
  },
  rowBreadCrumbs: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '2.5%',
  },
  logoWrapper: {
    maxWidth: '32px',
    width: '100%',
    height: '32px',
    background: '#B3B3B3',
    marginRight: '20px',
    '& img': {
      width: '100%',
      height: '100%',
      fontSize: '9px'
    }
  }
});
