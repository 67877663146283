import React, {useState} from 'react';
import ActiveBadge from '../../Molecules/ActiveBadge/ActiveBadge';
import CloseIcon from '@material-ui/icons/Close';
import {Button, Grid} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import {arWidgetDeleteCode, arWidgetDeleteTag, arWidgetPostCode, arWidgetPostTag} from '../../Api/Utils/Utils';
import {TagBlockStyles} from './TagBlockStyles';
import { toast } from 'react-toastify';

const TagBlock = ({
                    hidden,
                    inputId,
                    name,
                    label,
                    originalArr,
                    productId,
                    getOrganizationId,
                    placeholder,
                    buttonText,
                    errorMessage = ''
                  }) => {
  const classes = TagBlockStyles();
  const [itemText, setItemText] = useState('');
  const [tags, setTags] = useState(originalArr);

  const item = (id, tagText) => {
    let outputId
    switch (name) {
      case 'code':
        outputId = 'code-' + id
        break
      case 'tag':
        outputId = 'tag-' + id
        break
    }

    return (
      <span id={outputId} className={classes.tagBlockGroup}>
        <ActiveBadge cls={'gray'} txt={tagText}/>
        <CloseIcon onClick={handleRemoveItem} className={classes.tagBlockButton}/>
      </span>
    );
  };

  const handleInput = (e) => {
    setItemText(e.target.value);
  };

  const handleRemoveItem = (e) => {
    const fullId = e.target.parentElement.id;
    const id = parseInt(fullId.substring(fullId.indexOf(name) + name.length + 1))

    let deletePromise
    switch (name) {
      case 'code':
        deletePromise = arWidgetDeleteCode(id)
        break
      case 'tag':
        deletePromise = arWidgetDeleteTag(productId, id)
        break
    }
    deletePromise.then(() => {
      let tempArr = [...tags];
      for (let i = 0; i < tempArr.length; i++) {
        if (tempArr[i].id === id) {
          tempArr.splice(i, 1);
          break;
        }
      }
      setTags(tempArr);
    });
  };

  const handleNewItem = () => {
    let params = {}

    if(itemText.trim() === ''){
      toast.error(errorMessage, {
        toastId: 'CustomToastId'
      })

      return false
    }
    switch (name) {
      case 'code':
        params = {
          organizationId: getOrganizationId(),
          productId: parseInt(productId),
          codeName: itemText,
        };
        arWidgetPostCode(params)
          .then((response) => {
            if(response && response.data && response.data.items){
              return response.data.items;
            }
            return false
          })
          .then((response) => {
            
            if(!response){
              return
            }
            let tempArr = [...tags];
            tempArr.push({id: response.id, name: response.code});
            setTags(tempArr);
          })
          .then(() => (document.querySelector(`#${inputId}`).value = ''));
        break
      case 'tag':
        params = {id:parseInt(productId), name: itemText}
        arWidgetPostTag(parseInt(productId), params)
          .then((response) => {
            if(response && response.data && response.data.items){
              return response.data.items;
            }
            return false
          })
          .then((response) => {
            if(!response){
              return
            }
            let tempArr = [...tags];
            tempArr.push({id: response.id, name: response.name});
            setTags(tempArr);
          })
          .then(() => (document.querySelector(`#${inputId}`).value = ''));
        break
    }
  };

  const handleEnter = (e) => {
    if (e.keyCode === 13) {
      handleNewItem();
    }
  };

  if (hidden || !tags) {
    return null;
  }

  return (
    <div className={classes.tagBlock}>
      {tags && tags.map((el) => {
          return item(el.id, el.name);
        })}
      <Grid container lg={12} md={12} sm={12} xs={12} justify={'space-between'} alignItems={'flex-end'} className={classes.inputGroup}>
        <Grid item lg={7} xl={8} md={7} sm={7} xs={7}>
          <TextField
            id={inputId}
            name={name}
            label={label}
            placeholder={placeholder}
            onChange={(e) => handleInput(e)}
            onKeyDown={handleEnter}
            className={classes.textField}
          />
        </Grid>
        <Grid item lg={4} xl={4} md={4} sm={4} xs={4}>
          <Button className={classes.button} variant={'contained'} component="span" onClick={handleNewItem}>
            {buttonText}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default TagBlock;
