import { useState, useMemo, useEffect } from "react";
import { Pie } from "@visx/shape";
import { Group } from "@visx/group";
import { Text } from "@visx/text";
import classes from './Pie.module.css'

function ThatPie({ width, height, devices, activeEl = false, setActiveEl }) {
    const [active, setActive] = useState(null);
    const half = width / 2;

    const total = useMemo(
        () =>
            Math.floor(
                devices.reduce((acc, coin) => acc + coin.count, 0)
            ),
        [devices]
    );

    const handleMouseEnter = (data) => {
        setActive(data)
        setActiveEl(data)
    }

    const handleMouseLeave = () => {
        setActive(null)
        setActiveEl(null)
    }

    useEffect(() => {
        setActive(activeEl)
    }, [activeEl])


    return (
        <>
            <svg width={width} height={height} style={{ paddingTop: '30px', boxSizing: 'border-box' }}>
                <Group top={half} left={half}>
                    <Pie
                        data={devices}
                        pieValue={(data) => data.count}
                        outerRadius={half}
                        innerRadius={({ data }) => {
                            const size = active && data.name === active.name ? 68 : 50;
                            return half - size;
                        }}
                        cornerRadius={3}
                        padAngle={0.005}
                    >
                        {(pie) => {
                            return pie.arcs.map((arc) => {
                                const [centroidX, centroidY] = pie.path.centroid(arc);
                                return (
                                    <g
                                        key={arc.data.name}
                                        onMouseEnter={() => handleMouseEnter(arc.data)}
                                        onMouseLeave={() => handleMouseLeave()}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <path d={pie.path(arc)} fill={arc.data.color}></path>
                                    </g>
                                );
                            });
                        }}
                    </Pie>

                    {active ? (
                        <>
                            <Text textAnchor="middle" fill="#111" fontSize={40} dy={0}>
                                {`${Math.floor(active.count)}`}
                            </Text>

                                <Text
                                    fill="#000"
                                    dy="1.1em"
                                    fontSize={15}
                                    textAnchor="middle"
                                    pointerEvents="none"
                                    className={classes.text}
                                >
                                    {active.name}
                                </Text>

                        </>
                    ) : (
                        <>
                            <Text textAnchor="middle" fill="#111" fontSize={40} dy={0}>
                                {`${total}`}
                            </Text>
                        </>
                    )}
                </Group>
            </svg>
        </>
    );
}

export default ThatPie