import { makeStyles } from '@material-ui/core/styles';

export const PassRestorePageStyles = makeStyles({
  passRestorePage: {
    paddingTop: '90px',
  },
  subHeading: {
    paddingLeft: '45px',
    paddingTop: '35px',
    fontSize: '18px',
    fontWeight: 800,
    color: '#29ABE2',
  },
  main: {
    paddingLeft: '245px',
    paddingTop: '75px',
    letterSpacing: '1px',
    '@media(max-width: 1366px)': {
      maxWidth: '100%',
      padding: '75px 40px 0 40px'
    },
    '@media(max-width: 576px)': {
      padding: '75px 20px 0 20px'
    }

  },
  invite: {
    fontSize: '23px',
    color: '#4D4D4D',
    '@media(max-width: 576px)': {
      fontSize: '16px'
    }
  },
  restoreInput: {
    width: '440px',
    '& > div, & > label': {
      paddingTop: '59px',
      width: '100%',
      paddingLeft: '10px',
      fontSize: '20px',
    },
    '@media(max-width: 1366px)': {
      maxWidth: '100%',
      width: '100%',
    }
  },
  unvalidRestoreInput: {
    borderBottomColor: "#FF0000",
    width: '440px',
    '& > div, & > label': {
      paddingTop: '59px',
      width: '100%',
      paddingLeft: '10px',
      fontSize: '20px',
      color: "#FF0000"
    },
    '&::after, &::before': {
      borderColor: "#FF0000",
    },
    '@media(max-width: 1366px)': {
      maxWidth: '100%',
      width: '100%',
    }
  },

  restoreButton: {
    marginTop: '60px',
    width: '300px',
    paddingTop: '18px',
    paddingBottom: '18px',
    fontSize: '18px',
    backgroundColor: '#29ABE2',
    color: '#ffffff',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: '#29ABE2',
      color: '#ffffff',
    },
    '@media(max-width: 576px)': {
      maxWidth: '100%',
      width: '100%'
    }
  },
  successInfo: {
    fontSize: '23px',
    lineHeight: '19px',
    fontFamily: 'Helios',
    color: '#4D4D4D',
    letterSpacing: '0.04em'
  },
  failText: {
    color: '#FF0000',
    fontFamily: 'Helios',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.04em',
    paddingBottom: '30px'
  },
  failLink: {
    fontSize: '15px',
    lineHeight: '19px',
    letterSpacing: '0.04em',
    color: '#29ABE2',
    borderBottom: '1px solid #29ABE2',
    paddingBottom: '3px',
    '&:hover': {
      textDecoration: 'none',
    }
  },
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  btnRow: {
    display: 'flex',
  },
  label: {
    color: '#4D4D4D',
    fontFamily: 'Helios',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '11px',
    lineHeight: '13px',
    letterSpacing: '0.04em',
    padding: '22px 0px 5px'
  },
  input: {
    outline: 'none',
    border: '1px solid #C4C4C4',
    maxWidth: '300px',
    width: '100%',
    padding: '2px 9px 3px'
  }
});
