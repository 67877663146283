import React from 'react';
import classes from './LegendCharts.module.css'

function LegendCharts({ items, colors, contentKey, setActiveEl = null }) {

    const addActiveElement = (item) => {
        if(!setActiveEl){
            return
        }

        setActiveEl({
            name: item.name,
            count: item.count
        })

    }

    const removeActiveElement = (item) => {
        if(!setActiveEl){
            return
        }

        setActiveEl(null)

    }

    if (items.length < 1) {
        return <></>
    }
    return (
        <div className={classes.legendChartsWrapper}>
            {
                items.map((item, index) => {
                    return (
                        <div 
                            className={classes.row} 
                            key={item.name + (Math.random()*100).toString()}
                            onMouseEnter={() => addActiveElement(item)}
                            onMouseLeave={() => removeActiveElement(item)}
                        >
                            <div className={classes.circle} style={{background: (colors[contentKey])[index]}}></div>
                            <div className={classes.name}>{item.name}</div>
                        </div>
                    )
                })}
        </div>
    );
}

export default LegendCharts;