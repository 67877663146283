import React, { useState } from 'react';
import { Box, useScrollTrigger } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { MenuItemLink } from 'react-admin';
import SubMenu from './SubMenu';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import CategoryIcon from '@material-ui/icons/Category';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { Widgets } from '@material-ui/icons';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SettingsIcon from '@material-ui/icons/Settings';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ListAltIcon from '@material-ui/icons/ListAlt';
import DescriptionIcon from '@material-ui/icons/Description';
import InfoIcon from '@material-ui/icons/Info';
import MoneyIcon from '@material-ui/icons/Money';
import { langManager } from '../../utils/languageManager/manager';

let openMenu = {
  reference: false,
  library: false
}

const menuState = JSON.parse(localStorage.getItem('menuReferences'))

if (menuState) {
  openMenu = menuState
}

const useStyles = makeStyles({
  sidebar: {
    background: '#626F78',
    position: 'fixed',
    overflow: 'auto',
    zIndex: '99',
    '& a[data-class="target"]': {
      background: '#1A698B !important',
      color: '#ffffff !important'
    },
    '& a[aria-current="page"]': {
      background: '#1A698B !important',
      color: '#ffffff !important'
    },
    '& a': {
      paddingLeft: '16px'
    },
  },
  sidebarOpen: {
    width: '240px',
  },
  sidebarClosed: {
    width: '55px'
  },
  sidebarTop: {
    top: 0,
    height: '100%',
  },
  sidebarBottom: {
    top: '60px',
    height: 'calc(100% - 60px)',
    '@media(max-width:600px)': {
      top: 0,
      height: '100%',
    }
  },
  menu: {
    background: '#626F78',
    color: '#C4C4C4',
    "&:hover:active::after": {
      display: 'none !important',
    }
  },
  subMenu: {
    position: 'relative',
    "&:hover:active::after": {
      display: 'none !important',
    },
    '&:before': {
      content: '""',
      width: 'calc(100% - 20px)',
      height: '1px',
      background: '#29ABE2',
      position: 'absolute',
      bottom: 0,
      left: '10px',
    },
  },
  category: {
    background: '#626F78',
    color: '#C4C4C4',
    position: 'relative',
    '&:before': {
      content: '""',
      width: 'calc(100% - 20px)',
      height: '1px',
      background: '#29ABE2',
      position: 'absolute',
      bottom: 0,
      left: '10px',
      display: 'block'
    },
    "&:hover:active::after": {
      display: 'none !important',
    },
  }
})

const Menu = ({ onMenuClick, logout, dense = false }) => {
  const currentUser = JSON.parse(localStorage.getItem('currentUser'))
  const trigger = useScrollTrigger();
  const [state, setState] = useState({
    library: openMenu.library,
    reference: openMenu.reference
  });

  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  useSelector((state) => state.theme);
  const handleToggle = async (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
    openMenu = { ...openMenu, [menu]: !state[menu] }
    localStorage.setItem('menuReferences', JSON.stringify(openMenu))
  };

  const [idInfo, setIdInfo] = useState()

  // Переключение языка
  const [$, setMenu] = useState(langManager.$.components.menu)
  langManager.subscribe(($) => {
    setMenu($.components.menu)
  })
  //*******************

  const classes = useStyles();

  if (!currentUser) {
    return (
      <div className={`${classes.sidebar} ${open ? classes.sidebarOpen : classes.sidebarClosed} ${trigger ? classes.sidebarTop : classes.sidebarBottom}`} >
        <Box mt={2} id="boxMenu">
          <SubMenu
            handleToggle={() => {
              handleToggle('library');
            }}
            // className={openSubHeading ? classes.menuSubHeading : classes.menuSubHeadingClose}
            isOpen={state.library}
            sidebarIsOpen={open}
            name={$.menuReferences}
            icon={<LibraryBooksIcon />}
            className={classes.subMenu}
          >

            <MenuItemLink
              className={classes.category}
              to={`/category`}
              primaryText={$.categories}
              leftIcon={<CategoryIcon />}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/widget`}
              className={classes.menu}
              primaryText={$.widget}
              leftIcon={<Widgets />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          </SubMenu>


          <MenuItemLink
            to={`/support`}
            className={classes.menu}
            primaryText={$.support}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
            leftIcon={<HelpOutlineIcon />}
          />
          <MenuItemLink
            to={`/settings`}
            className={classes.menu}
            primaryText={$.settings}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
            leftIcon={<SettingsIcon />}
          />
        </Box>
      </div>
    );
  }

  return (
    <div className={`${classes.sidebar} ${open ? classes.sidebarOpen : classes.sidebarClosed} ${trigger ? classes.sidebarTop : classes.sidebarBottom}`}>
      <Box id="boxMenu">
        <SubMenu
          handleToggle={() => {
            handleToggle('library');
          }}
          // className={openSubHeading ? classes.menuSubHeading : classes.menuSubHeadingClose}
          isOpen={state.library}
          sidebarIsOpen={open}
          name={$.library}
          icon={<LibraryBooksIcon />}
          className={classes.subMenu}
          attribute='menuReferences'
        >

          <MenuItemLink
            className={classes.category}
            to={`/category`}
            primaryText={$.categories}
            leftIcon={<CategoryIcon />}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/widget`}
            className={classes.menu}
            primaryText={$.widgets}
            leftIcon={<Widgets />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
            className={classes.menu}
          />

          <MenuItemLink
            to={`/info`}
            primaryText={$.information}
            leftIcon={<InfoIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
            className={classes.menu}
          />
        </SubMenu>
        {currentUser ?
          currentUser.roleName === 'user' ? null :
            <MenuItemLink
              className={classes.menu}
              to={`/user`}
              primaryText={$.users}
              sidebarIsOpen={open}
              dense={dense}
              leftIcon={<SupervisorAccountIcon />}
            />
          :
          <></>
        }
        {currentUser ?
          currentUser.roleName === 'admin' ?
            <SubMenu
              handleToggle={() => {
                handleToggle('reference');
              }}
              // className={openSubHeading ? classes.menuSubHeading : classes.menuSubHeadingClose}
              isOpen={state.reference}
              sidebarIsOpen={open}
              name={$.references}
              icon={<ListAltIcon />}
              className={classes.subMenu}
              attribute='references'
            >
              <MenuItemLink
                className={classes.menu}
                to={`/organization`}
                primaryText={$.organizations}
                sidebarIsOpen={open}
                dense={dense}
                leftIcon={<AccountBalanceIcon />}
                getId={(e) => setIdInfo(e)}

              />
              <MenuItemLink
                to={`/tariffs`}
                className={classes.menu}
                primaryText={$.tariffs}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
                leftIcon={<MoneyIcon />}
                className={classes.menu}
              />
            </SubMenu>
            :
            <></>
          :
          <></>
        }

        <MenuItemLink
          to={`/support`}
          className={classes.menu}
          primaryText={$.support}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
          leftIcon={<HelpOutlineIcon />}
          className={classes.menu}
        />
        {currentUser ?
          currentUser.roleName === 'admin' ?
            <MenuItemLink
              to={`/settings`}
              className={classes.menu}
              primaryText={$.settings}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
              leftIcon={<SettingsIcon />}
              className={classes.menu}
            />
            :
            <></>
          :
          <></>
        }
        {currentUser ?
          currentUser.roleName === 'admin' ?
            <MenuItemLink
              to={`/applications`}
              className={classes.menu}
              primaryText={$.applications}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
              leftIcon={<DescriptionIcon />}
              className={classes.menu}
            />
            :
            <></>
          :
          <></>
        }
      </Box>
    </div>
  );

};

export default Menu;
