import React, { useEffect, useState} from 'react';
import { initialBreadCrumbs, settingsBreadCrumbs } from '../../../../Helpers/helpers';
import {langManager} from '../../../../utils/languageManager/manager';
import {arWidgetGetSettings} from '../../../Api/Utils/Utils';
import ArTable from '../../../Organizms/ArTable/ArTable';
import {ArBreadcrumbs} from '../../../Organizms/Breadcrumbs/ArBreadcrumbs';


function Settings() {
    const setParentId = (parentId) => localStorage.setItem('settings', parentId);
    const currentPageSize = sessionStorage.getItem('pageSize');

    useEffect(() => {
      document.title = "Конфигурации"
    }, [])


    //Переключение языка
    const [ $, setSettings] = useState(langManager.$.components.settings)
    langManager.subscribe(($) => {
      setSettings($.components.settings)
      document.title = $.components.settings.title
    })
    //******************

    return (
        <>
        <ArBreadcrumbs breadCrumbs={[initialBreadCrumbs, settingsBreadCrumbs]}/>
        <ArTable
        basePath="/settings"
        addBtnText="No btn"
        // parentId={props.options.parentId}
        setParentId={setParentId}
        targetList={{
          get: arWidgetGetSettings,
        //   delete: arWidgetDeleteCategory,
        }}
        pageSize={currentPageSize}
        tableHeadings={[
          { name: $.section , align: 'left', width: '200px' },
          { name: $.sectionName, align: 'left', width: '200px' },
          { name: $.value, align: 'left', width: '200px' },
          { name: $.actions , align: 'center', width: '200px' },
        ]}
        rowCells={[
          { type: 'text', align: 'left', contentKey: 'section'},
          { type: 'text', align: 'left', contentKey: 'name' },
          { type: 'text', align: 'left', contentKey: 'value' },
          { type: 'editAction', align: 'center' },
        ]}
        />
            
        </>
    );
}

export default Settings;