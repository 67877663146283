import {arWidget, arWidgetAuth, arWidgetPut, arWidgetUpload, arWidgetUploadFiles} from '../Axios/Axios';

export const arWidgetLogin = (params) => arWidgetAuth.post('/auth/login', params);
export const arWidgetRefreshToken = (params) => arWidgetAuth.post('/auth/refresh', params);
export const arWidgetForgotPass = (params) => arWidgetAuth.post('/auth/forgotPassword', params);

export const arWidgetLogout = () => arWidget.get('/auth/logout');

export const arWidgetGetProducts = (params) => arWidget.get('/product', params);
export const arWidgetGetProduct = (id) => arWidget.get(`/product/${id}`);
export const arWidgetDeleteProduct = (id) => arWidget.delete(`/product/${id}`);
export const arWidgetPutProduct = (id, params) => arWidget.put(`/product/${id}`, params);
export const arWidgetPostProduct = (params) => arWidget.post(`/product`, params);

export const arWidgetUploadSupportRequests = (id, data) => arWidgetUpload(`/support/requests/${id}/upload`, {data: data});
export const arWidgetGetSupportRequests = ( params) => arWidget.get(`/support/requests`, params);
export const arWidgetGetSupportRequestsById = (id) => arWidget.get(`/support/requests/${id}`);
export const arWidgetPostSupportRequests = ( params) => arWidget.post(`/support/requests`, params);
export const arWidgetPutSupportRequests = (id, params) => arWidget.put(`/support/requests/${id}`, params);
export const arWidgetDownloadSupportRequests = (id) => arWidgetUploadFiles.get(`/support/requests/${id}/download`);

export const arWidgetGetSupportStatuses = ( params) => arWidget.get(`dictionary/support/status`, params);

export const arWidgetPostRegistration = (data) => arWidgetUpload(`/registration/organization`, {data:data});

export const arWidgetGetOrganizations = (params) => arWidget.get('/dictionary/organization', params);
export const arWidgetGetOrganization = (id) => arWidget.get(`/dictionary/organization/${id}`);
export const arWidgetDeleteOrganization = (id) => arWidget.delete(`/dictionary/organization/${id}`);
export const arWidgetPutOrganization = (id, params) => arWidget.put(`/dictionary/organization/${id}`, params);
export const arWidgetPostOrganization = (params) => arWidget.post(`/dictionary/organization`, params);
export const arWidgetUploadOrganizationImage = (id, data) => arWidgetUpload(`/dictionary/organization/${id}/image`, {data: data});

export const arWidgetGetOrganizationInfo = (params) => arWidget.get(`/dictionary/organization/info`, params);
export const arWidgetPostOrganizationInfo = (params) => arWidget.post(`/dictionary/organization/info`, params);
export const arWidgetPutOrganizationInfo = (params) => arWidget.put(`/dictionary/organization/info`, params);

export const arWidgetGetTariffs = (params) => arWidget.get('/tariffs', params);
export const arWidgetGetTariffsById = (id) => arWidget.get(`/tariffs/${id}`);
export const arWidgetDeleteTariffs = (id) => arWidget.delete(`/tariffs/${id}`);
export const arWidgetPostTariffs = (params) => arWidget.post('/tariffs', params);
export const arWidgetPutTariffs = (id, params) => arWidget.put(`/tariffs/${id}`, params);
export const arWidgetCopyTariffs = (id, params) => arWidget.post(`/tariffs/${id}`, params);

export const arWidgetPostTariffsProperty = (id, params) => arWidget.post(`/tariffs/${id}/properties`, params);

export const arWidgetGetTariffsOrganization = (params) => arWidget.get('/tariffOrganization', params);
export const arWidgetDownloadFileTariffs = (id) => arWidgetUploadFiles.get(`/tariffOrganization/${id}/file`);

export const arWidgetGetCategories = (params) => arWidget.get('/dictionary/category', params);
export const arWidgetGetCategory = (id) => arWidget.get(`/dictionary/category/${id}`);
export const arWidgetDeleteCategory = (id) => arWidget.delete(`/dictionary/category/${id}`);
export const arWidgetPutCategory = (id, params) => arWidget.put(`/dictionary/category/${id}`, params);
export const arWidgetPostCategory = (params) => arWidget.post(`/dictionary/category`, params);



export const arWidgetPostImage = (id, data) => arWidgetUpload(`/product/${id}/image`, { data: data });

export const arWidgetPostPackage = (id, data) => arWidgetUpload(`/product/${id}/model`, {data: data});

export const arWidgetPostCode = (params) => arWidget.post('/product/code', params);
export const arWidgetDeleteCode = (id) => arWidget.delete(`/product/code/${id}`);

export const arWidgetPostTag = (productId, params) => arWidget.post(`/product/${productId}/tag`, params)
export const arWidgetDeleteTag = (productId, tagId) => arWidget.delete(`/product/${productId}/tag/${tagId}`);


export const arWidgetGetUsers = (params) => arWidget.get('/user', params);
export const arWidgetDeleteUser = (id) => arWidget.delete(`/user/${id}`);
export const arWidgetGetUser = (id) => arWidget.get(`/user/${id}`);
export const arWidgetPutUserById = (id, params) => arWidget.put(`/user/${id}`, params);
export const arWidgetPutUser = (data) => arWidgetPut.put(`/user`, data);
export const arWidgetPostUser = (params) => arWidget.post(`/user`, params);
export const arWidgetGetCurrentUser = () => arWidget.get('/user/current');
export const arWidgetPutUserPassword = (params) => arWidget.post(`/user/password`, params);

export const arWidgetPostUserImage = (data) => arWidgetUpload(`/user/image`, {data: data});

export const arWidgetGetRoles = () => arWidget.get('/roles');

export const arWidgetGetCategoryFilters = (catId, orgId = null) => arWidget.get(`/dictionary/category/${catId}/filter${orgId ? `?organizationId=${orgId}` : ''}`);

export const arWidgetPostCategoryFilters = (id, params) => arWidget.post(`/dictionary/category/${id}/filter`, params);
export const arWidgetGetCategoryFilterValues = (id) => arWidget.get(`/dictionary/category/filter/${id}/value`);
export const arWidgetPostCategoryFiltersValues = (id, params) => arWidget.post(`/dictionary/category/filter/${id}/value`, params);
export const arWidgetDeleteCategoryFiltersValues = (id) => arWidget.delete(`/dictionary/category/filter/value/${id}`);

export const arWidgetPutCategoryFiltersValues = (id, params) => arWidget.put(`/dictionary/category/filter/${id}/value`, params);

export const arWidgetGetModelArchive = (params) => arWidget.get(`/product/${params.params.id}/models/images`);
export const arWidgetDeleteModel = (id) => arWidget.delete(`/product/${id}/model`);

export const arWidgetDownloadModelArchive = (id, params) => arWidget.get(`/models/download/model/${id}?type=${params.type}`);
export const arWidgetDownloadModel = (id, params) => arWidget.get(`/models/download/model/${id}?type=${params.type}`);
export const arWidgetGetQRCodeModel = (fileName) => arWidget.get(`/models/download/model/${fileName}/qr`);

export const arWidgetDownloadFile = (params) => arWidget.get('/models/download', params);


export const arWidgetGetRequisities = (id) => arWidget.get(`/dictionary/organization/${id}/requisites`);
export const arWidgetPutRequisities = (id, params) => arWidget.put(`/dictionary/organization/${id}/requisites`, params);

export const arWidgetGetWidgets = (params) => arWidget.get(`/widget`, params);
export const arWidgetDeleteWidget = (id) => arWidget.delete(`/widget/${id}`);
export const arWidgetPutWidget = (id, params) => arWidget.put(`/widget/${id}`, params);
export const arWidgetPostWidget = (params) => arWidget.post(`/widget`, params);
export const arWidgetGetWidget = (id) => arWidget.get(`/widget/${id}`);

export const arWidgetGetWidgetButtons = () => arWidget.get(`/widget/button`);
export const arWidgetPostWidgetButton = (params) => arWidget.post(`/widget/button`, params);
export const arWidgetPutWidgetButton = (id, name, params) => arWidget.put(`/widget/button/${id}?name=${name}&parameters=${params}`);

export const arWidgetGetTheme = () => arWidget.get('/dictionary/support/theme');

export const arWidgetGetSettings = (params) => arWidget.get('/settings', params);
export const arWidgetGetSettingsId = (id) => arWidget.get(`/settings/${id}`);
export const arWidgetPutSettings = (id, params) => arWidget.put(`/settings/${id}`, params);

export const arWidgetStatisticsCurrentUserHistory = () => arWidget.get(`/statistics/login`);
export const arWidgetStatisticsUserHistory = (id) => arWidget.get(`/statistics/${id}/login`);


export const arWidgetStatisticsDevices = (params) => arWidget.get(`/statistics/devices?dateFrom=${params.dateFrom}&dateTo=${params.dateTo}`);
export const arWidgetStatisticsUniqueIp = (params) => arWidget.get(`/statistics/uniqueIp?dateFrom=${params.dateFrom}&dateTo=${params.dateTo}`);
export const arWidgetStatisticsProductViewsPerDay = (params) => arWidget.get(`/statistics/productPerDay?dateFrom=${params.dateFrom}&dateTo=${params.dateTo}`);
export const arWidgetStatisticsSortedByDevice = (params) => arWidget.get(`/statistics/memoType?dateFrom=${params.dateFrom}&dateTo=${params.dateTo}`);
export const arWidgetStatisticsTopTen = (params) => arWidget.get(`/statistics/topTen?dateFrom=${params.dateFrom}&dateTo=${params.dateTo}`);
export const arWidgetStatisticsViewsByCategory = (params) => arWidget.get(`/statistics/category?dateFrom=${params.dateFrom}&dateTo=${params.dateTo}`);

export const arWidgetDownloadStatisticsDevices = (params) => arWidgetUploadFiles.get(`/statistics/download/devices?dateFrom=${params.dateFrom}&dateTo=${params.dateTo}`);
export const arWidgetDownloadStatisticsUniqueIp = (params) => arWidgetUploadFiles.get(`/statistics/download/uniqueIp?dateFrom=${params.dateFrom}&dateTo=${params.dateTo}`);
export const arWidgetDownloadStatisticsProductViewsPerDay = (params) => arWidgetUploadFiles.get(`/statistics/download/productPerDay?dateFrom=${params.dateFrom}&dateTo=${params.dateTo}`);
export const arWidgetDownloadStatisticsSortedByDevice = (params) => arWidgetUploadFiles.get(`/statistics/download/memoType?dateFrom=${params.dateFrom}&dateTo=${params.dateTo}`);
export const arWidgetDownloadStatisticsTopTen = (params) => arWidgetUploadFiles.get(`/statistics/download/topTen?dateFrom=${params.dateFrom}&dateTo=${params.dateTo}`);
export const arWidgetDownloadStatisticsViewsByCategory = (params) => arWidgetUploadFiles.get(`/statistics/download/category?dateFrom=${params.dateFrom}&dateTo=${params.dateTo}`);



export const arWidgetGetGlobalSearch = (params) => arWidget.get('/globalSearch', params);


  // ?dateFrom=5%2F05%2F2020&dateTo=5%2F06%2F2020//*devices?dateFrom${params.dateFrom}*/