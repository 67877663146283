import React, { useEffect, useState } from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select, Slider, Switch } from '@material-ui/core';
import { langManager } from '../../../utils/languageManager/manager';
import classes from './WidgetConstructor.module.css'
import { ArBreadcrumbs } from '../../Organizms/Breadcrumbs/ArBreadcrumbs';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router'
import { arWidgetPostWidgetButton } from '../../Api/Utils/Utils';
import DOMPurify from 'dompurify';
import { editPagesBreadCrumbs, initialBreadCrumbs, widgetBreadCrumbs } from '../../../Helpers/helpers';

function WidgetConstructor(props) {


    //Переключение языка
    const [$, setWidgetConstructor] = useState(langManager.$.components.widgetConstructor)
    langManager.subscribe(($) => {
        setWidgetConstructor($.components.widgetConstructor)
        document.title = $.components.widgetConstructor.title
    })
    //******************

    const history = useHistory()

    const [languageBtn, setLanguageBtn] = useState('ru')
    const [nameBtn, setNameBtn] = useState('')
    const [widthBtn, setWidthBtn] = useState(100)
    const [heightBtn, setHeightBtn] = useState(100)
    const [borderRadiusBtn, setBorderRadiusBtn] = useState(0);
    const [backgroundColorBtn, setBackgroundColorBtn] = useState('#ffffff');
    const [borderColorBtn, setBorderColorBtn] = useState('#000000');
    const [textBtn, setTextBtn] = useState('')
    const [colorTextBtn, setColorTextBtn] = useState('#000000')
    const [fontSizeBtn, setFontSizeBtn] = useState(10)
    const [fontWeightBtn, setFontWeightBtn] = useState(400)
    const [borderWidthBtn, setBorderWidthBtn] = useState(1)
    const [img, setImg] = useState('')
    const [baseImg, setBaseImg] = useState('')
    const [imgWidth, setImgWidth] = useState(100)
    const [imgHeight, setImgHeight] = useState(100)
    const [boxShadow, setBoxShadow] = useState({
        horizontal: 0,
        vertical: 0,
        blur: 0,
        stretching: 0,
        colorShadow: '#000000',
        opacity: 1,
        inset: ''
    })

    const handleUploadImg = async (e) => {
        const file = e.target.files[0];

        if (!file) {
            return
        }
        setImg(e.target.value)
        // Если размер файла больше 64 KB то его мы не принимаем
        if (file.size > 64000) {
            const customId = 'widgetConstructor'
            toast.error($.message, {
                toastId: customId
            })
            setImg('')
            setBaseImg('')
        } else {
            const base64 = await convertBase64(file);
            setBaseImg(base64);
        }
    }

    // конвертация изображения в base64
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    // Проверка русского языка
    const isCyrillic = (text) => {
        return /[а-я]/i.test(text);
    }

    const handleChangeSwitch = (e) => {
        if (e.target.checked) {
            setBoxShadow({ ...boxShadow, inset: 'inset' })
        } else {
            setBoxShadow({ ...boxShadow, inset: '' })
        }
    }

    const handleSave = async () => {
        const customId = 'widgetConstructorBtn'
        // если не введено название кнопки то вернем ошибку
        if (!nameBtn.trim()) {
            toast.error($.messageNameBtn, {
                toastId: customId
            })
            return false
        }

        const html = document.querySelector('#customWidgetButtonParent')
        // если данное поле пустое то вернем false, не отправляем запрос
        if (!html) {
            return false
        }

        const id = props.history.location.state.id

        if (!id) {
            return false
        }

        // если выбранный язык и язык названия кнопки не совпадает то вернем ошибку
        if(languageBtn === 'ru'){
            if(!isCyrillic(nameBtn.trim())){
                toast.error($.messageErrorLang, {
                    toastId: customId
                })
                return false
            }
        }

        const sanitizedHTML = DOMPurify.sanitize(html.innerHTML)

        const params = {
            name: nameBtn,
            parameters: '{\'css\':\'\',\'html\':\'' +sanitizedHTML + '\'}',
            language: languageBtn,
            widgetId: id
        }

        await arWidgetPostWidgetButton(params)
            .then((res) => {
                history.goBack()
            })
            .catch(err => {
                console.log(err);
            })

    }

    // это кастомная кнопка
    const ButtonView = () => {
        // Полученный цвет тени из инпута (#000000) превращать в массив для rgb
        const hexToRgb = (hex) => {
            return hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
                , (m, r, g, b) => '#' + r + r + g + g + b + b)
                .substring(1).match(/.{2}/g)
                .map(x => parseInt(x, 16))
        }
        const arrayRgb = hexToRgb(boxShadow.colorShadow)
        const rgba = `rgba(${arrayRgb[0]}, ${arrayRgb[1]}, ${arrayRgb[2]}, ${boxShadow.opacity})`
        const boxShadowString = `${boxShadow.horizontal}px ${boxShadow.vertical}px ${boxShadow.blur}px ${boxShadow.stretching}px ${rgba} ${boxShadow.inset}`
        // стили для кнопки
        const styles = {
            textAlign: 'center',
            overflow: 'hidden',
            wordWrap: 'break-word',
            borderStyle: 'solid',
            borderRadius: `${borderRadiusBtn}%`,
            backgroundColor: backgroundColorBtn,
            borderColor: borderColorBtn,
            color: colorTextBtn,
            fontSize: `${fontSizeBtn}px`,
            fontWeight: fontWeightBtn,
            borderWidth: `${borderWidthBtn}px`,
            width: `${widthBtn}px`,
            height: `${heightBtn}px`,
            boxShadow: boxShadowString,
        }
        return (
            <div id="customWidgetButtonParent">
                <div
                    data-name="customWidgetButton"
                    style={styles}
                >
                    {
                        baseImg &&
                        <img
                            src={baseImg}
                            style={{
                                width: `${imgWidth}%`,
                                height: `${imgHeight}%`,
                                objectFit: 'contain'
                            }}
                        />
                    }
                    {textBtn}
                </div>
            </div>
        )
    }

    useEffect(() => {
        document.title = $.title
    }, [])

    // языки для выпадающего списка
    const languages = ['ru', 'en']


    if (!props.history.location.state || !props.history.location.state.id) {
       history.push('/widget')
       return <></>
    }

    return (
        <>
            <ArBreadcrumbs breadCrumbs={[initialBreadCrumbs, widgetBreadCrumbs, editPagesBreadCrumbs('editing', props.history.location.state.location, 2), editPagesBreadCrumbs('constructor', window.location.pathname, 3)]} />
            <Grid container spacing={3} className={classes.btnStylesContainer}>
                <Grid item xs={6}>
                    <ButtonView />
                </Grid>
                <Grid item container spacing={6} xs={6}>
                    <Grid item xs={12}>
                        <div className={classes.title}>{$.nameBtn}*</div>
                        <input
                            type="text"
                            value={nameBtn}
                            required
                            onChange={e => setNameBtn(e.target.value)}
                            className={classes.inputText}

                        />
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.title}>{$.widthBtn}</div>
                        <Slider
                            value={widthBtn}
                            min={10}
                            max={500}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            onChange={(e, v) => setWidthBtn(v)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.title}>{$.heightBtn}</div>
                        <Slider
                            value={heightBtn}
                            min={10}
                            max={500}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            onChange={(e, v) => setHeightBtn(v)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.title}>{$.background}</div>
                        <input
                            type="color"
                            value={backgroundColorBtn}
                            onChange={e => setBackgroundColorBtn(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.title}>{$.border}</div>
                        <input
                            type="color"
                            value={borderColorBtn}
                            onChange={e => setBorderColorBtn(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.title}>{$.borderWidth}</div>
                        <Slider
                            value={+borderWidthBtn}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            min={0}
                            max={10}
                            onChange={(e, v) => setBorderWidthBtn(v)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.title}>{$.borderRadius}</div>
                        <Slider
                            value={+borderRadiusBtn}
                            aria-label="Default"
                            min={0}
                            max={50}
                            valueLabelDisplay="auto"
                            onChange={(e, v) => setBorderRadiusBtn(v)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.title}>{$.text}</div>
                        <input
                            type="text"
                            value={textBtn}
                            onChange={e => setTextBtn(e.target.value)}
                            className={classes.inputText}

                        />
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.title}>{$.textColor}</div>
                        <input
                            type="color"
                            value={colorTextBtn}
                            onChange={e => setColorTextBtn(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.title}>{$.fontSize}</div>
                        <Slider
                            value={fontSizeBtn}
                            min={10}
                            max={36}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            onChange={(e, v) => setFontSizeBtn(v)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.title}>{$.fontWeight}</div>
                        <Slider
                            value={fontWeightBtn}
                            step={100}
                            min={100}
                            max={900}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            onChange={(e, v) => setFontWeightBtn(v)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.title}>{$.img}</div>
                        <input
                            value={img}
                            type="file"
                            onChange={e => handleUploadImg(e)}
                            accept=".jpg,.jpeg,.png,.svg"
                            className={classes.inputText}

                        />
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.title}>{$.removeImg}</div>
                        <button
                            onClick={e => { setBaseImg(''); setImg('') }}
                            className={`${classes.btnRemove} ${classes.btn}`}
                        >
                            {$.remove}
                        </button>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.title}>{$.imgWidth}</div>
                        <Slider
                            value={imgWidth}
                            min={10}
                            max={100}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            onChange={(e, v) => setImgWidth(v)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.title}>{$.imgHeight}</div>
                        <Slider
                            value={imgHeight}
                            min={10}
                            max={100}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            onChange={(e, v) => setImgHeight(v)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.title}>{$.shadow}:</div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.title}></div>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={4}>
                                <p className={classes.text}>{$.switchForShadowExt}</p>
                            </Grid>
                            <Grid item xs={4}>
                                <Switch inputProps={{ 'aria-label': 'ant design' }} onChange={handleChangeSwitch} />
                            </Grid>
                            <Grid item xs={4}>
                                <p className={classes.text}>{$.switchForShadowInt}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.title}>{$.colorShadow}</div>
                        <input
                            type="color"
                            value={boxShadow.colorShadow}
                            onChange={e => setBoxShadow({ ...boxShadow, colorShadow: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.title}>{$.horizontalShadow}</div>
                        <Slider
                            value={boxShadow.horizontal}
                            aria-label="Default"
                            min={-50}
                            max={50}
                            valueLabelDisplay="auto"
                            onChange={(e, v) => setBoxShadow({ ...boxShadow, horizontal: v })}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.title}>{$.verticalShadow}</div>
                        <Slider
                            value={boxShadow.vertical}
                            aria-label="Default"
                            min={-50}
                            max={50}
                            valueLabelDisplay="auto"
                            onChange={(e, v) => setBoxShadow({ ...boxShadow, vertical: v })}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.title}>{$.blurShadow}</div>
                        <Slider
                            value={boxShadow.blur}
                            aria-label="Default"
                            min={0}
                            max={100}
                            valueLabelDisplay="auto"
                            onChange={(e, v) => setBoxShadow({ ...boxShadow, blur: v })}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.title}>{$.stretchShadow}</div>
                        <Slider
                            value={boxShadow.stretching}
                            aria-label="Default"
                            min={-50}
                            max={50}
                            valueLabelDisplay="auto"
                            onChange={(e, v) => setBoxShadow({ ...boxShadow, stretching: v })}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.title}>{$.opacity}</div>
                        <Slider
                            value={boxShadow.opacity}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            step={0.1}
                            min={0}
                            max={1}
                            style={{marginTop: '7px'}}
                            onChange={(e, v) => setBoxShadow({ ...boxShadow, opacity: v })}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.title}>{$.language}</div>
                        <FormControl variant="standard" style={{ m: 1, minWidth: 120, width: '100%' }}>
                            <InputLabel id="languagesWidgetBtnLabel" className={classes.labelSelect}></InputLabel>
                            <Select
                                labelId="languagesWidgetBtnLabel"
                                id="languagesWidgetBtn"
                                value={languageBtn}
                                onChange={e => setLanguageBtn(e.target.value)}
                                className={`${classes.inputFilter} ${classes.inputFilterSelect}`}
                            >
                                {languages.map(item => {
                                    return (
                                        <MenuItem value={item}>
                                            {item}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container >
                            <Grid item xs={8} />
                            <Grid item xs={2}>
                                <button
                                    onClick={e => history.goBack()}
                                    className={`${classes.btnBack} ${classes.btn}`}
                                >
                                    {$.cancel}
                                </button>
                            </Grid>
                            <Grid item xs={2}>
                                <button
                                    onClick={handleSave}
                                    className={`${classes.btnSave} ${classes.btn}`}
                                >
                                    {$.save}
                                </button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </>
    );
}

export default WidgetConstructor;
