import {makeStyles} from "@material-ui/core/styles";

export const FileUploadStyles = makeStyles({
  input: {
    display: 'none',
  },
  image: {
    width: '100%',
    height: "280px",
    objectFit: 'contain',
    backgroundColor: '#F2F2F2',
    // left: "160px",
    // top: "192px",
  },
});
