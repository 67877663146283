import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ArBreadcrumbs } from '../../Organizms/Breadcrumbs/ArBreadcrumbs';
import classes from './TechnicalSupport.module.css';
import { Checkbox, FormControl, InputLabel, Link, MenuItem, Select, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { arWidgetGetTheme, arWidgetPostSupportRequests, arWidgetUploadSupportRequests } from '../../Api/Utils/Utils';
import { langManager } from '../../../utils/languageManager/manager';
import { initialBreadCrumbs, technicalSupportBreadCrumbs } from '../../../Helpers/helpers';
import { toast } from 'react-toastify';
import { CleanButton } from '../../Buttons/CleanButton';

const error = {
    error: {
        color: 'red',
    },
    success: {
        color: '#808080',
    },
};

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 180,
        width: '100%',
        height: '26px',
        margin: 0
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const TechnicalSupport = (props) => {


    //Переключение языка
    const [$, setSupport] = useState(langManager.$.components.support)
    langManager.subscribe(($) => {
        setSupport($.components.support)
        document.title = $.components.support.title
    })
    //******************
    const styles = useStyles();
    const [success, setSuccess] = useState(false)
    const [checkbox, setCheckbox] = useState(false);
    const [emailValid, setEmailValid] = useState(false);
    const [phoneValid, setPhoneValid] = useState(false);
    const [nameValid, setNameValid] = useState(false);
    const [themes, setThemes] = useState([]);
    const [themeId, setThemeId] = useState('');
    const [file, setFile] = useState([]);
    const [values, setValues] = useState({
        textRequest: '',
        themeId: '',
    });
    const [user, setUser] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        organization: '',
    });

    useEffect(() => {
        document.title = "Техническая поддержка"
        arWidgetGetTheme()
            .then((res) => {
                setThemes(res.data.items)
            })
            .catch(e => {
                console.log(e);
            })
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (checkbox && emailValid && phoneValid && nameValid) {
            PostSupportRequests();
        } else {
            toast.error($.invalidFields, {
                toastId: 'CustomToastIdForSupport'
            })
        }
    };
    const handleInput = (e) => {
        const nam = e.target.name;
        if (nam === 'name' || nam === 'email' || nam === 'phoneNumber' || nam === 'organization') {
            let val = e.target.value;
            setUser({ ...user, [nam]: val });
        } else {
            let val = e.target.value;
            setValues({ ...values, [nam]: val });
        }
        checkValidation(e.target);
    };

    let arr = []
    const handleChange = (e) => {
        let fileName = e.target.value;
        let idxDot = fileName.lastIndexOf('.') + 1;
        let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if (
            extFile == 'jpg' ||
            extFile == 'jpeg' ||
            extFile == 'png' ||
            extFile == 'pdf' ||
            extFile == 'txt' ||
            extFile == 'doc' ||
            extFile == 'docx' ||
            extFile == 'xls' ||
            extFile == 'xlsx' ||
            extFile == 'rtf'
        ) {
            let length = e.target.files.length;
            for (let i = 0; i < length; i++) {
                arr.push(e.target.files[i])
            }
            setFile(arr)
        } else {
        }
    };

    const checkValidation = (element) => {

        switch (element.name) {
            case 'email':
                let regexEmail = new RegExp(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                );
                if (regexEmail.test(element.value)) {
                    setEmailValid(true);
                } else {
                    setEmailValid(false);             
                }
                break;
            case 'phoneNumber':
                let regexNumber = new RegExp('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$');
                if (regexNumber.test(element.value) && (element.value.length > 5 && element.value.length < 25)) {
                    setPhoneValid(true);
                   
                } else {
                    setPhoneValid(false);
                }
                break;
            case 'name':
                let regexText = new RegExp(/^([а-яё\s]+|[a-z\s]+)$/iu);
                if (regexText.test(element.value) && element.value.split(' ').length >= 3 && element.value.split(' ')[2]) {
                    setNameValid(true);         
                } else {
                    setNameValid(false);       
                }
                break;
            default:
                break;
        }
    };

    const handleChangeCheckbox = (e) => {
        let checked = e.target.checked;
        setCheckbox(checked);
    };

    // загрузка картинок, файлов
    const UploadSupportRequests = async (id) => {
        let formData = new FormData()
        file.map(item => {
            formData.append('files', item)
        })
        await arWidgetUploadSupportRequests(id, formData)
            .then(() => {

            })
            .catch(err => {
                console.log(err);
            })
    }

    const PostSupportRequests = async () => {
        const params = {
            user: user,
            textRequest: values.textRequest,
            themeId: themeId,
            files: []
        };
        await arWidgetPostSupportRequests(params)
            .then((res) => {
                UploadSupportRequests(res.data.items.id)
                // setSuccess(true)
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleSelect = (event) => {
        setThemeId(event.target.value);
    };

    const history = useHistory()

    const handleClick = (e) => {
        e.preventDefault()
        history.push('/')
    }

    if (success) {
        return (
            <>
                <ArBreadcrumbs breadCrumbs={[initialBreadCrumbs, technicalSupportBreadCrumbs]} />
                <div className={classes.wrapper}>
                    <div className={classes.title}>{$.messageSuccess}</div>
                    <Link href="#" onClick={handleClick} className={classes.link}>
                        {$.toHomePage}
                    </Link>
                </div>
            </>
        )
    }

    return (
        <>
            <ArBreadcrumbs breadCrumbs={[initialBreadCrumbs, technicalSupportBreadCrumbs]} />
            <div className={classes.wrapper}>
                <div className={classes.title}>{$.supportRequest}</div>
                <div className={classes.note}>
                    {$.defaultFormFields}
                </div>
                <div className={classes.info}>
                    {$.answerWillBeSentToEmail}
                </div>
                <div className={classes.form}>
                    <Grid container spacing={6}>
                        <Grid item xs={12} md={4} sm={12} className={classes.gridItem}>
                            <div className={classes.label}>{$.fullName}</div>
                            <input
                                id="name"
                                name="name"
                                type="text"
                                className={`${classes.input} ${!nameValid ? classes.errorField : ''}`}
                                value={user.name}
                                
                                onChange={(e) => handleInput(e)}
                            />
                            <CleanButton clear={() => setUser({...user, name: ''})} /> 
                        </Grid>
                        <Grid item xs={12} md={4} sm={12} className={classes.gridItem}>
                            <div className={classes.label}>{$.company}</div>
                            <input
                                id="organization"
                                name="organization"
                                type="text"
                                className={`${classes.input}`}
                                value={user.organization}
                                
                                onChange={(e) => handleInput(e)}
                            />
                            <CleanButton clear={() => setUser({...user, organization: ''})} /> 
                        </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                        <Grid item xs={12} md={4} sm={12} className={classes.gridItem}>
                            <div className={classes.label}>e-mail:</div>
                            <input
                                id="email"
                                name="email"
                                type="text"
                                className={`${classes.input} ${!emailValid ? classes.errorField : ''}`}
                                value={user.email}
                                
                                onChange={(e) => handleInput(e)}
                            />
                            <CleanButton clear={() => setUser({...user, email: ''})} /> 
                        </Grid>
                        <Grid item xs={12} md={4} sm={12} className={classes.gridItem}>
                            <div className={classes.label}>{$.phoneNumber}</div>
                            <input
                                id="phoneNumber"
                                name="phoneNumber"
                                type="text"
                                className={`${classes.input} ${!phoneValid ? classes.errorField : ''}`}
                                value={user.phoneNumber}
                                
                                onChange={(e) => handleInput(e)}
                            />
                           <CleanButton clear={() => setUser({...user, phoneNumber: ''})} /> 
                        </Grid>
                    </Grid>

                    <div className={classes.problem}>{$.problemDetails}</div>
                    <Grid container spacing={6} className={classes.theme}>
                        <Grid item xs={8} className={classes.gridItem}>
                            <div className={classes.label}>{$.requestSubject}</div>
                            <FormControl className={styles.formControl}>
                                <InputLabel id="demo-simple-select-label"></InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={themeId}
                                    className={classes.select}
                                    onChange={handleSelect}
                                >
                                    {themes.map(item => {
                                        return <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            <CleanButton clear={() => setThemeId('')} styles={classes.formControlClearIcon}/>
                        </Grid>
                    </Grid>
                    <div className={classes.fileInvite}>{$.attachFile}</div>
                    <Grid container spacing={6} className={classes.aic}>
                        <Grid item xs={3} className={classes.fileBlock}>
                            <input
                                type="file"
                                id="inputFile"
                                className={classes.inputFile}
                                multiple={true}
                                accept=".jpg,.jpeg,.png,.pdf,.txt,.doc,.docx,.xls,.xlsx,.rtf "
                                onChange={handleChange}
                            />
                            <button className={classes.loadingFile}>{$.selectFile}</button>
                        </Grid>
                        {
                            file.length < 1 ?
                                <Grid item xs={9}>
                                    <div className={classes.fileNote}>
                                        {$.ext} <span className={classes.bold}>{$.extension}</span>
                                    </div>
                                    <div className={classes.fileNote}>
                                        {$.size} <span className={classes.bold}>{$.sizeMb}</span>
                                    </div>
                                </Grid>
                                :
                                <Grid item xs={9}>
                                    {
                                        file.map((item, idx) => (
                                            <span className={classes.fileNote} key={idx + Date.now()}>
                                                {item.name}{idx !== file.length - 1 && ', '}
                                            </span>
                                        ))
                                    }
                                </Grid>
                        }
                    </Grid>

                    <div className={classes.text}>{$.requestText}</div>
                    <div className={classes.textField}>
                        <textarea
                            id="textRequest"
                            name="textRequest"
                            className={classes.textarea}
                            value={values.textRequest}
                            onChange={(e) => handleInput(e)}
                        ></textarea>
                    </div>
                    <div className={classes.check}>
                        <Checkbox
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            checked={checkbox}
                            onChange={handleChangeCheckbox}
                        />
                        <div className={classes.checkText}>{$.dataProcessingConcent}</div>
                    </div>
                    <button className={classes.submitBtn} onClick={handleSubmit}>
                        {$.sendRequest}
                    </button>
                </div>
            </div>
        </>
    );

}

export default TechnicalSupport;
