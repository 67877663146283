import { useState, useEffect } from 'react'
import { langManager } from '../../../../utils/languageManager/manager'
import { arWidgetDeleteTariffs, arWidgetGetTariffs } from '../../../Api/Utils/Utils'
import ArTable from '../../../Organizms/ArTable/ArTable'
import { ArBreadcrumbs } from '../../../Organizms/Breadcrumbs/ArBreadcrumbs'
import {editPagesBreadCrumbs, initialBreadCrumbs, tariffsBreadCrumbs} from "../../../../Helpers/helpers";

function Tariffs() {
    const setParentId = (parentId) => localStorage.setItem('tariffs', parentId)
    const currentPageSize = sessionStorage.getItem('pageSize')
    const currentUser = JSON.parse(localStorage.getItem('currentUser'))

    //Переключение языка
    const [$, setTariffs] = useState(langManager.$.components.tariffs)
    langManager.subscribe(($) => {
        setTariffs($.components.tariffs)
        document.title = $.components.tariffs.title
    })
    //******************

    useEffect(() => {
        document.title = $.title
    }, [])

    return (
        <>
            <ArBreadcrumbs breadCrumbs={[initialBreadCrumbs, tariffsBreadCrumbs]} />
            <ArTable
                basePath="/tariffs"
                addBtnText={$.addTariffs}
                // parentId={props.options.parentId}
                setParentId={setParentId}
                targetList={{
                    get: arWidgetGetTariffs,
                    delete: arWidgetDeleteTariffs,
                }}
                pageSize={currentPageSize}
                tableHeadings={[
                    // { name: $.picture, align: 'left', width: '200px' },
                    { name: $.name, align: 'left', width: '200px' },
                    { name: $.description, align: 'left', width: '200px' },
                    { name: $.isActive, align: 'left', width: '200px' },
                    { name: $.isPublic, align: 'left', width: '200px' },
                    { name: $.price, align: 'left', width: '200px' },
                    { name: $.actions, align: 'center', width: '200px' },
                ]}
                rowCells={[
                    // { type: 'image', align: 'left', contentKey: 'picture'},
                    { type: 'text', align: 'left', contentKey: 'name' },
                    { type: 'text', align: 'left', contentKey: 'description' },
                    { type: 'badge', align: 'left', contentKey: 'isActive', truthy: { text: $.active , cls: 'active' }, falsy: { text: $.inactive , cls: 'inactive' } },
                    { type: 'badge', align: 'left', contentKey: 'isPublic', truthy: { text: $.public , cls: 'active' }, falsy: { text: $.inpublic , cls: 'inactive' } },
                    { type: 'text', align: 'left', contentKey: 'price' },
                    { type: 'actionsWithCopy', align: 'center' },
                ]}
                filters={[
                    {
                        name: 'name',
                        type: 'input',
                        initialValue: '',
                        placeholder: $.name,
                    },
                    {
                        name: 'isActive',
                        type: 'select',
                        initialValue: 'default',
                        options: [
                          { value: 'default', text: $.all },
                          { value: true, text: $.active },
                          { value: false, text: $.inactive },
                        ],
                    },
                    {
                        name: 'isPublic',
                        type: 'select',
                        initialValue: 'default',
                        options: [
                          { value: 'default', text: $.all },
                          { value: true, text: $.public },
                          { value: false, text: $.inpublic },
                        ],
                    },
                ]} 
            />
        </>
    )
}

export default Tariffs