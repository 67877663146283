import React, {useState} from "react";
import {MenuItemLink, UserMenu} from "react-admin";
import SettingsIcon from "@material-ui/icons/Settings";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import {langManager} from "../../utils/languageManager/manager";
import classes from './UserProfile.module.css'


const UserProfile = (props) => {

  //Переключение языка
  const [ $, setUserProfileMenu] = useState(langManager.$.components.userProfileMenu)
  langManager.subscribe(($) => {
      setUserProfileMenu($.components.userProfileMenu)
  })
  //////////////

  return (
    <UserMenu {...props}>
      <MenuItemLink
        to="/personal"
        primaryText={$.userProfile}
        leftIcon={<AccountCircleIcon />}
        className={classes.menuLink}
      />
      <MenuItemLink
        to="/configuration"
        primaryText={$.configuration}
        leftIcon={<SettingsIcon />}
        className={classes.menuLink}
      />
    </UserMenu>
  );
};

export default UserProfile;