import {makeStyles} from '@material-ui/core/styles';

const blue = '#29ABE2';
const grayMenu = '#ECECEC';
const grayButon = '#B3B3B3';
const green = '#8CC63F';
const lightGreen = '#b4f65e';

export const TagBlockStyles = makeStyles({
  tagBlock: {
    width: '100%',
    // marginTop: "2rem",
    paddingTop: '1rem',
    // paddingBottom: "20px",
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'start',
  },
  button: {
    width: '100%',
    backgroundColor: blue,
    color: 'white',
    fontSize: '0.5rem',
    paddingLeft: '0.1rem',
    paddingRight: '0.1rem',
  },
  tagBlockButton: {
    borderRadius: '50%',
    width: '0.8rem!important',
    height: '0.7rem !important',
    padding: '0.1rem',
    cursor: 'pointer',
    position: 'relative',
    top: '-0.9rem',
    left: '-1.2rem',
    backgroundColor: 'whitesmoke',
    boxShadow: '1px 1px 4px 2px rgba(0, 0, 0, 0.1)',
    transition: 'scale 100ms, ease-in 50ms',
    '& :hover': {
      transform: 'scale(0.95)',
    },
    '& :active': {
      transform: 'scale(0.90) rotate(25deg)',
    },
  },
  tagBlockGroup: {
    marginBottom: '25px',
    '& > input': {
      width: '85%',
    },
  },
  textField: {
    width: '100%'
}
});
