import React, { useContext, useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { ArBreadcrumbs } from '../../Organizms/Breadcrumbs/ArBreadcrumbs';
import styles from './PersonalArea.module.css'
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';
import {
    arWidgetGetCurrentUser,
    arWidgetGetRequisities,
    arWidgetPutOrganization,
    arWidgetPutRequisities,
    arWidgetPutUser
} from '../../Api/Utils/Utils';
import Toast from '../../Toast/Toast';
import { useHistory } from 'react-router';
import PersonalAreaTabBill from '../PersonalAreaTabBill/PersonalAreaTabBill';
import { langManager } from '../../../utils/languageManager/manager';
import { initialBreadCrumbs, personalAreaBreadCrumbs } from '../../../Helpers/helpers';
import { NotificationContext } from '../../../App';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'transparent',
        width: '100%',
        marginTop: '45px',
        '& header': {
            backgroundColor: 'transparent',
            boxShadow: 'none'

        },
        '& .MuiTabs-indicator': {
            display: 'none !important',
        },
        '& .MuiTab-textColorPrimary.Mui-selected': {
            color: '#29ABE2',
            position: 'relative',
            '& span::before': {
                content: '',
                width: '10px',
                height: '10px',
                display: 'inline-block',
                backgroundColor: '#000000'
            }
        },
        '& .Mui-selected': {
            position: 'relative',
        },
        '& .Mui-selected:before': {
            content: '""',
            width: '7px',
            height: '7px',
            display: 'inline-block',
            position: 'absolute',
            bottom: '-3px',
            borderLeft: '1px solid #C4C4C4',
            borderTop: '1px solid #C4C4C4',
            backgroundColor: '#ffffff',
            transform: 'rotate(45deg)',
            zIndex: 2
        },
        '& .MuiTabs-flexContainer': {
            paddingBottom: '8px'
        },
        '@media(max-width: 576px)': {
            '& .MuiTab-textColorPrimary.MuiButtonBase-root  .MuiTab-wrapper': {
                fontSize: '11px'
            }
        }
    },
    table: {
        backgroundColor: 'transparent',
        '& .MuiTableRow-root': {
            height: '80px'
        },
        '& .MuiTableRow-head': {
            height: '30px',
            backgroundColor: '#ECECEC'
        }
    },
    tabLabel: {
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '0.04em',
        textTransform: 'uppercase',
        color: '#808080',
    }
}));



const PersonalArea = ({ staticContext, ...props }) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory()
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };
    const dataProvider = useDataProvider();
    const [imgPreview, setImgPreview] = useState(null)
    const [edit, setEdit] = useState({
        user: false,
        company: false,
        bill: false
    })
    const [editPass, setEditPass] = useState(false)
    const [imgPreviewUrl, setImgPreviewUrl] = useState('')
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [allImages, setAllImages] = useState([])

    const [user, setUser] = useState({
        name: '',
        surname: '',
        id: '',
        organizationName: '',
        organizationId: '',
        roleName: '',
        roleId: '',
        email: '',
        phoneNumber: '',
        patronymic: '',
        images: ''
    })

    const [orgId, setOrgId] = useState()

    const [organization, setOrganization] = useState({
        organizationName: '',
        generalDirectorFio: '',
        inn: '',
        officePhone: '',
        kpp: '',
        site: '',
        countryAddress: '',
        postcode: '',
        cityAddress: '',
        streetAddress: '',
        checkingAccount: '',
        bic: '',
        correspondentAccount: '',
        bankName: '',
        bankAddress: '',
        requisiteId: '',
        id: ''
    })

    const [count, setCount] = useState(0)

    const [state, dispatch] = useContext(NotificationContext);

    // Переключение языка
    const [$, setPersonalArea] = useState(langManager.$.components.personalArea)
    langManager.subscribe(($) => {
        setPersonalArea($.components.personalArea)
    })
    //**************

    const handleInputChange = (e) => {
        setError(false)
        let nam = e.target.name
        let val = e.target.value
        setUser({ ...user, [nam]: val })
    }
    const handleChangeOrg = (e) => {
        setError(false)
        let nam = e.target.name
        let val = e.target.value
        setOrganization({ ...organization, [nam]: val })
    }

    const handleEdit = (name) => {
        setEdit({ ...edit, [name]: true })
    }
    const handleCancel = (name) => {
        setEdit({ ...edit, [name]: false })
    }

    const handleChangeImg = async (e) => {
        setError(false)
        setCount(count + 1)
        let fileName = e.target.value;
        let idxDot = fileName.lastIndexOf(".") + 1;
        let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if (extFile == "jpg" || extFile == "jpeg" || extFile == "png" || extFile == "svg") {
            let length = e.target.files.length
            setAllImages([...allImages, e.target.files[0]])
            for (let i = 0; i < length; i++) {
                let file = e.target.files[i]
                setImgPreview(e.target.files[i])
                if (file === undefined) {
                    setImgPreview(undefined)
                }
                if (imgPreview !== undefined || imgPreview !== null) {
                    let reader = new FileReader();
                    reader.onloadend = () => {
                        setImgPreviewUrl(reader.result)
                    }
                    reader.readAsDataURL(file)
                } else {
                    setImgPreviewUrl(null)
                }
            }
        } else {
            if (count === 2) {
                setError(true)
                setErrorMessage('Можно загрузить только jpg/jpeg, svg и png!')
            }
        }
    }

    const getUser = () => {
        arWidgetGetCurrentUser().then(res => {
            setUser({
                name: res.data.items.userName,
                surname: res.data.items.surname,
                patronymic: res.data.items.patronymic,
                id: res.data.items.id,
                organizationName: res.data.items.organization.name,
                organizationId: res.data.items.organization.id,
                roleName: res.data.items.roleName,
                email: res.data.items.email,
                phoneNumber: res.data.items.phoneNumber,
                roleId: res.data.items.roleId,
                images: res.data.items.images
            })
            setOrgId(res.data.items.organization.id)
            setError(false)
        }).catch(e => {
            console.log(e);
            setError(true)
            setErrorMessage(e.data.message)
        })
    }

    const handleClick = () => {
        setError(false)
    }

    const handleOrganization = async (e) => {
        setError(false)
        await arWidgetGetRequisities(orgId)
            .then(res => {
                console.log(res);
                setError(false)
                setOrganization({
                    organizationName: res.data.items.organization.name,
                    generalDirectorFio: res.data.items.organization.generalDirectorFio,
                    inn: res.data.items.organization.inn,
                    officePhone: res.data.items.organization.officePhone,
                    kpp: res.data.items.organization.kpp,
                    site: res.data.items.organization.site,
                    countryAddress: res.data.items.organization.countryAddress,
                    postcode: res.data.items.organization.postcode,
                    cityAddress: res.data.items.organization.cityAddress,
                    streetAddress: res.data.items.organization.streetAddress,
                    checkingAccount: res.data.items.checkingAccount,
                    bic: res.data.items.bic,
                    correspondentAccount: res.data.items.correspondentAccount,
                    bankName: res.data.items.bankName,
                    bankAddress: res.data.items.bankAddress,
                    requisiteId: res.data.items.id,
                    id: res.data.items.organization.id
                })
            })
            .catch(err => {
            })
    }

    const handleEditUser = async () => {
        setError(false)

        let formData = new FormData()
        formData.append('name', user.name)
        formData.append('surname', user.surname)
        formData.append('patronymic', user.patronymic)
        formData.append('organizationId', user.organizationId)
        formData.append('roleId', user.roleId)
        formData.append('email', user.email)
        formData.append('phoneNumber', user.phoneNumber)

        if (allImages.length > 0) {
            formData.append('avatar', allImages[0])
        }

        await arWidgetPutUser(formData)
            .then(res => {
                setError(false)
                localStorage.setItem('currentUser', JSON.stringify(res.data.items))
                dispatch({type: 'ADD_CURRENT_USER', payload: {...state, currentUser: res.data.items}});
            })
            .catch(err => {
                setError(true)
            })
    }
    const handleEditOrganization = async () => {
        setError(false)
        const params = {
            id: orgId,
            name: organization.organizationName,
            inn: organization.inn,
            kpp: organization.kpp,
            generalDirectorFio: organization.generalDirectorFio,
            site: organization.site,
            officePhone: organization.officePhone,
            countryAddress: organization.countryAddress,
            cityAddress: organization.cityAddress,
            streetAddress: organization.streetAddress,
            postcode: organization.postcode,
        }
        const paramsRequisites = {
            checkingAccount: organization.checkingAccount,
            bic: organization.bic,
            correspondentAccount: organization.correspondentAccount,
            bankName: organization.bankName,
            bankAddress: organization.bankAddress
        }
        console.log('par', params)
        await arWidgetPutOrganization(orgId, params)
            .then(res => {
                setError(false)
            })
            .catch(err => {
                setError(true)
                setErrorMessage(err.data.message)
            })
        await arWidgetPutRequisities(orgId, paramsRequisites)
            .then(res => {
                setError(false)
            })
            .catch(err => {
                setError(true)
                setErrorMessage(err.data.message)
            })
    }

    const handlePassChange = () => {

        history.push({
            pathname: '/resetpass',
            state: { email: user.email, change: "change" }
        })
    }

    useEffect(() => {
        document.title = $.title
        getUser()
    }, []);

    return (
        <>
            <ArBreadcrumbs breadCrumbs={[initialBreadCrumbs, personalAreaBreadCrumbs]} background="#fff"/>
            <div className={styles.main}>
                {!error ? <></> : <Toast error={error} errorMessage={errorMessage} />}
                <div className={styles.container}>
                    <div className={styles.logo}>
                        <div className={styles.imgWrapper}>

                            <div className={styles.imgTitle}>
                                {$.logotype}
                            </div>
                            <div className={styles.ImgBox}>
                                {/* {imgPreview === null || imgPreview === undefined */}
                                {user.images === '' || user.images === [] || user.images.length === 0
                                    ? <div className={styles.imgInfo}>
                                        {$.durgAndDrop} <br /> JPEG / PNG  <br />SVG  <br />{$.orUpload} <br /> {$.fromComputer}
                                    </div>
                                    : <img src={imgPreviewUrl === '' ? user.images[0] : imgPreviewUrl} className={styles.imgPreview} />
                                }

                            </div>
                            <input id="file" name="file" type="file" className={styles.inputFile} accept=".jpg,.jpeg,.png,.svg" onChange={handleChangeImg} />
                            <button className={styles.imgButton}>{$.upload}</button>
                        </div>
                        <div className={classes.root}>
                            <AppBar position="static" color="default">
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                    aria-label="full width tabs example"
                                >
                                    <Tab label={$.user} {...a11yProps(0)} onClick={(e) => { handleClick() }} className={classes.tabLabel} />
                                    {user.roleName !== 'admin' ?
                                        <Tab label={$.company} {...a11yProps(1)} onClick={(e) => { handleOrganization(); handleClick() }} className={classes.tabLabel} />
                                        : <></>
                                    }
                                    {user.roleName !== 'admin' ?
                                        <Tab label={$.bankAccounts} {...a11yProps(2)} onClick={(e) => { handleClick() }} className={classes.tabLabel} />
                                        : <></>
                                    }
                                </Tabs>
                            </AppBar>
                            <div className={styles.line}></div>
                            <TabPanel className={styles.tabPanel} value={value} index={0} dir={theme.direction}>
                                <div className={styles.tabWrapper}>
                                    <div className={styles.inputWrapper}>
                                        <input className={styles.title} style={{ margin: '0 10px' }} value={user.name} name="name" onChange={handleInputChange} placeholder="Имя" disabled={!edit.user} />
                                        <input className={styles.title} style={{ margin: '0 10px' }} value={user.surname} name="surname" onChange={handleInputChange} placeholder="Фамилия" disabled={!edit.user} />
                                    </div>
                                    <div className={styles.idUser}>ID {user.id}</div>
                                    <Grid container spacing={3} className={styles.userGridContainer}>
                                        <Grid item xs={6} className={styles.userGridItem}>
                                            <div className={styles.userRow}>
                                                <div className={styles.subtitle}>{$.company}</div>
                                                <input disabled={!edit.user && (user.roleName !== 'admin' || user.roleName !== 'organization administrator')} value={user.organizationName} name="organizationName" id="" onChange={handleInputChange} className={styles.nameOrg} />
                                                <div className={styles.subtitle}>{$.position}</div>
                                                <input disabled={!edit.user && (user.roleName === 'admin' || user.roleName === 'organization administrator')} value={user.roleName} name="roleName" id="" onChange={handleInputChange} className={styles.nameOrg} />
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} className={styles.userGridItem}>
                                            <div className={styles.userRow}>
                                                <div className={styles.subtitle}>e-mail:</div>
                                                <input disabled={!edit.user} value={user.email} name="email" id="email" onChange={handleInputChange} className={styles.nameOrg} />
                                                <div className={styles.subtitle}>{$.phoneNumber}</div>
                                                <input disabled={!edit.user} value={user.phoneNumber} name="phoneNumber" id="phoneNumber" onChange={handleInputChange} className={styles.nameOrg} />
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <div className={styles.btnBox}>
                                        {!edit.user ?
                                            (<>
                                                <button className={styles.button} onClick={e => handleEdit('user')}>{$.changeData}</button>
                                                <button className={styles.button} onClick={handlePassChange}>{$.changePassword}</button>
                                            </>)
                                            :
                                            <>
                                                <button className={styles.button} onClick={handlePassChange}>{$.changePassword}</button>
                                                <button className={styles.button} onClick={e => handleCancel('user')}>{$.cancel}</button>
                                                <button className={styles.button} onClick={handleEditUser}>{$.save}</button>
                                            </>
                                        }

                                    </div>
                                </div>
                            </TabPanel>
                            {user.roleName !== 'admin' ?
                                <TabPanel className={styles.tabPanel} value={value} index={1} dir={theme.direction}>
                                    <div className={styles.tabWrapper}>
                                        <input className={styles.title} disabled={!edit.company} name="organizationName" id="organizationName" value={organization.organizationName} onChange={handleChangeOrg} placeholder={$.organizationName} />
                                        <Grid container spacing={10} className={styles.organizationGridContainer} style={{ marginTop: '0' }}>
                                            <Grid item xs={12} sm={6} md={12} sm={6} md={4} >
                                                <div className={styles.userRow}>
                                                    <div className={styles.subtitle}>{$.productCategory}</div>
                                                    {/* <input type="text" name="" id="" value={"lorem"} disabled={!edit.company} className={styles.nameOrg} /> */}
                                                    <input type="text" name="" id="" value={"lorem"} disabled={true} className={styles.nameOrg} />
                                                    <div className={styles.subtitle}>{$.fullNameOfCEO}</div>
                                                    <input type="text" name="generalDirectorFio" id="generalDirectorFio" onChange={handleChangeOrg} value={organization.generalDirectorFio} disabled={!edit.company} className={styles.nameOrg} />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <div className={styles.userRow}>
                                                    <div className={styles.subtitle}>{$.TIN}</div>
                                                    <input type="text" name="inn" id="" onChange={handleChangeOrg} value={organization.inn} disabled={!edit.company} className={styles.nameOrg} />
                                                    <div className={styles.subtitle}>{$.officePhoneNumber}</div>
                                                    <input type="text" name="officePhone" id="" onChange={handleChangeOrg} value={organization.officePhone} disabled={!edit.company} className={styles.nameOrg} />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <div className={styles.userRow}>
                                                    <div className={styles.subtitle}>{$.RRC}</div>
                                                    <input type="text" name="kpp" id="" onChange={handleChangeOrg} value={organization.kpp} disabled={!edit.company} className={styles.nameOrg} />
                                                    <div className={styles.subtitle}>{$.website}</div>
                                                    <input type="text" name="site" id="" onChange={handleChangeOrg} value={organization.site} disabled={!edit.company} className={styles.nameOrg} />
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <div className={styles.textOrg}>{$.address}</div>
                                        <Grid container spacing={10} className={styles.organizationGridContainer}>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <div className={styles.userRow}>
                                                    <div className={styles.subtitle}>{$.region}</div>
                                                    <input type="text" name="countryAddress" id="" onChange={handleChangeOrg} value={organization.countryAddress} disabled={!edit.company} className={styles.nameOrg} />
                                                    <div className={styles.subtitle}>{$.postcode}</div>
                                                    <input type="text" name="postcode" id="" onChange={handleChangeOrg} value={organization.postcode} disabled={!edit.company} className={styles.nameOrg} />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} >
                                                <div className={styles.userRow}>
                                                    <div className={styles.subtitle}>{$.city}</div>
                                                    <input type="text" name="cityAddress" id="" onChange={handleChangeOrg} value={organization.cityAddress} disabled={!edit.company} className={styles.nameOrg} />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} >
                                                <div className={styles.userRow}>
                                                    <div className={styles.subtitle}>{$.actualAddress}</div>
                                                    <input type="text" name="streetAddress" id="" onChange={handleChangeOrg} value={organization.streetAddress} disabled={!edit.company} className={styles.nameOrg} />
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <div className={styles.textOrg}>{$.invoiceDetails}</div>
                                        <Grid container spacing={10} className={styles.organizationGridContainer}>
                                            <Grid item xs={12} sm={6} md={4} >
                                                <div className={styles.userRow}>
                                                    <div className={styles.subtitle}>{$.checkingAccount}</div>
                                                    <input type="text" name="checkingAccount" id="" onChange={handleChangeOrg} value={organization.checkingAccount} disabled={!edit.company} className={styles.nameOrg} />
                                                    <div className={styles.subtitle}>{$.bank}</div>
                                                    <input type="text" name="bankName" id="" onChange={handleChangeOrg} value={organization.bankName} disabled={!edit.company} className={styles.nameOrg} />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} >
                                                <div className={styles.userRow}>
                                                    <div className={styles.subtitle}>{$.BIC}</div>
                                                    <input type="text" name="bic" id="" onChange={handleChangeOrg} value={organization.bic} disabled={!edit.company} className={styles.nameOrg} />
                                                    <div className={styles.subtitle}>{$.bankAddress}</div>
                                                    <input type="text" name="bankAddress" id="" onChange={handleChangeOrg} value={organization.bankAddress} disabled={!edit.company} className={styles.nameOrg} />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} >
                                                <div className={styles.userRow}>
                                                    <div className={styles.subtitle}>{$.correspondentAccount}</div>
                                                    <input type="text" name="correspondentAccount" id="" onChange={handleChangeOrg} value={organization.correspondentAccount} disabled={!edit.company} className={styles.nameOrg} />
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <div className={styles.btnBox}>
                                            {!edit.company ?
                                                (<button className={styles.button} onClick={e => handleEdit('company')}>{$.changeData}</button>)
                                                :
                                                <>
                                                    <button className={styles.button} onClick={e => handleCancel('company')}>{$.cancel}</button>
                                                    <button className={styles.button} onClick={handleEditOrganization}>{$.save}</button>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </TabPanel>
                                : <></>}
                            {user.roleName !== 'admin' ?

                                <TabPanel className={styles.tabPanel} value={value} index={2} dir={theme.direction}>
                                    <PersonalAreaTabBill organizationId={user.organizationId} />
                                    <div className={styles.btnBoxBill}>
                                        <button className={styles.button}>{$.extendTariff}</button>
                                        <button className={styles.button}>{$.changeTariff}</button>
                                        <button className={styles.button}>{$.changeDetails}</button>
                                    </div>

                                </TabPanel>
                                : <></>
                            }
                        </div>
                    </div>


                </div>

            </div>
        </>
    );
};

export default PersonalArea