const subMenuRoles = {
    user: 'user',
    manager: 'manager',
    admin: 'administrator',
    superAdmin: 'superadmin',
    all: 'All',
}

const en = {

    breadCrumbs: {
        homePageTitle: 'Home page',

        categories: 'Categories',
        category: 'Category',
        widgets: 'Widgets',
        editing: 'Editing',
        information: 'Information',
        users: 'Users',
        support: 'Support',
        settings: 'Settings',
        organizations: 'Organizations',
        products: 'Products',
        personal: 'Personal area',
        application: 'Applications',
        configurations: 'Configurations',
        constructor: 'Constructor',
        globalSearch: 'Global search',
        tariffs: 'Tariffs'
    },

    loading: 'Loading...',

    homePageTitle: 'Home page',

    pagination: 'notes on page',

    organization: 'Organization',

    downloadReport: 'download report',

    authError: 'Authorization required',

    // Information.jsx  
    information: {
        title: 'Information',
        textEditorLang: 'en',
        save: 'SAVE',
        languagesInfo: 'Languages',
        toastPutMessage: 'Organization information has been successfully updated!',
        toastPostMessage: 'Organization information successfully created!'
    },///////////////////

    // Компоненты из папки components/pages
    pages: {

        //Страница логина******************************************
        login: {
            title: 'Authorization',
            subtitle: 'platform managment system',
            signInOr: 'sign in to accaunt or',
            register: 'register',
            login: 'Login',
            password: 'Password',
            rememberMe: 'Remember me',
            forgotPassword: 'Forgot password?',
            enter: 'Log in'
        },
        //***************************** */



        //Страница регистрации*************************************
        registration: {

            title: 'Registration',
            newUserRegistration: 'New user registration',
            dataSentCheckYourEmail: 'Your data has been sent. Check your e-mail!',
            personalInformation: 'personal information',
            userInformation: 'user information',
            errorValidate: 'Please enter valid fields!',

            userForm: {
                surname: 'Surname:',
                name: 'Name:',
                patronymic: 'Patronymic:',
                position: 'Position:',
                email: 'e-mail:',
                phoneNumber: 'Phone number:'
            },

            // Информация о компании
            companyInformation: 'Company Information',
            registrationIformatoin: 'Registration Information',
            addCompanyLogotype: 'Add company logotype',
            durgAndDrop: 'Drag and drop',
            orUpload: 'or upload',
            fromComputer: 'from computer',
            upload: 'upload',


            regInfo: {
                organizationName: 'Organization name:',
                productCategory: 'Product category:',
                nameOfGeneralDirector: 'Name of general director:',
                website: 'Website:',
                TIN: 'TIN:',
                RRC: 'RRC:',
                officePhone: 'Office phone:'
            },


            //Адресс
            address: 'address',
            region: 'Region:',
            city: 'City:',
            actualAddress: 'Actual address:',
            postcode: 'Postcode:',


            // Реквизиты для выставления счета
            invoiceDetails: 'invoice details',
            checkingAccount: 'Checking Account:',
            BIC: 'BIC:',
            correspondentAccount: 'Correspondent Account:',
            bank: 'Bank:',
            bankAddress: 'Bank Address:',


            licenseAgreement: 'License agreement',
            acceptLicenseAgreement: 'I have read and accept the license agreement.',
            sendRequest: 'Send a request'
        },
        //*********************************** */



        // Страница восстановления пароля****************************
        restorePage: {
            title: 'Password recovery',
            passwordRecovery: 'Password recovery',
            pleaseEnterYourEmail: 'Please, enter your email and we will send you a link to reset your password',
            correctEmail: 'Enter a correct e-mail',
            sendRequest: 'Send a request',
            successEmail: 'A link to reset your password has been sent to your e-mail address.',
            failedEmail: 'Unfortunately, no such address exists.',
            showEmail: 'Enter another e-mail'

        }, // ******************



        // Страница сброса пороля
        resetPage: {
            title: 'Password reset',
            subtitle: 'Change password',
            enterOldPassword: 'Enter old password:',
            enterNewPassword: 'Enter new password:',
            oldPassword: 'Old password:',
            newPassword: 'New password:',
            repeatNewPassword: 'Reapeat a new password:',
            passwordsDidNotMatch: 'Passwords did not match',
            enterStrongerPassword: 'Enter a stronger password',
            back: 'Back',
            changePassword: 'Change password',
            passwordRecovery: 'Password recovery',
            password: 'Password',
            repeatPassword: 'Repeat password',
            passwordChangeSuccessfuly: 'Password changed successfully. You can continue working.'

        },//******************

        // Страница просмотра модели ****************************
        viewModels: {
            title: 'View model',
        }, // ******************


    },// Конец раздела pages



    /////////////////////////////////////////////////////////////////////////////////



    // Компоненты из папки Сomponents
    components: {

        //Error.js
        error: {
            error: 'Error !',
            back: 'back'
        },//***** */


        // компонент Menu
        menu: {
            library: 'Library',
            categories: 'Categories',
            widget: 'Widget',
            widgets: 'Widgets',
            support: 'Support',
            settings: 'Settings',
            information: 'Information',
            users: 'Users',
            references: 'References',
            organizations: 'Organizations',
            applications: 'Applications',
            tariffs: 'Tariffs'
        },//***********


        // Categories.jsx
        categories: {
            title: 'Categories  ',
            organizationName: 'Organization name',
            models: 'Моdels',
            published: 'Published',
            unpublished: 'Unpublished',
            creationDate: 'Creation date',
            dateOfEdit: 'Date of edit',
            organization: 'Organization',
            actions: 'Actions',
            addCategory: 'add category',

            breadCrumbs: 'Categories'
        },//************* */


        // CategoryEdit.jsx
        categoryEdit: {
            title: 'Category',
            categoryName: 'Category name:',
            searchAttributes: 'Search attributes',
            oneFieldIsRequired: 'One field is required. The maximum number of attributes is 4.',
            addLibrary: 'Add a selection library for each attribute.',
            addSelection: 'Add selection',
            choiseAttribute: 'Choise attribute',
            attribute: 'Attribute',
            addAttribute: 'ADD ATTRIBUTE',
            cancel: 'CANCEL',
            save: 'SAVE',

            error: 'Enter something...',
            backStepButton: 'New category',
            loading: 'Loading...',
            putToastMessage: 'Category has been  successfully updated!',
            postToastMessage: 'Category has been successfully created!'
        },//***********


        // Organizations.jsx
        organizations: {
            title: 'Organizations',
            organizationName: 'Organization name',
            TIN: 'TIN',
            tariff: 'Tariff',
            active: 'Active',
            actions: 'Actions',

            newOrganization: 'New organization',

            all: 'All',
            inactive: 'Inactive'
        },//**********



        // OrganizationEdit.jsx , OrganizationPanel.jsx
        organizationEdit: {
            crumbs: 'Editing',
            organizationName: 'Organization name',
            TIN: 'TIN',
            active: 'Active',
            apiKey: 'API key',
            cancel: 'CANCEL',
            save: 'SAVE',
            tariff: 'Tariffs',
            backStepButton: 'New organization'
        },/////////////



        // Widget,jsx
        widget: {
            title: 'Widgets',
            website: 'Website',
            embedCode: 'Embed code',
            organization: 'Organization',
            status: 'Status',
            actions: 'Actions',
            createWidget: 'Create widget',

            breadCrumbs: 'Widgets'
        },//********** */



        // WidgetEdit.jsx
        widgetEdit: {
            crumbs: 'Editing',
            url: 'Website address',
            buttonType: 'Button type',
            chooseYourDestiny: 'Choose button type',
            rectrangular: 'Rectrangular',
            rounded: 'Rounded',
            save: 'save',
            editToken: 'Edit token for widget?',
            yes: 'yes',
            no: 'no',
            title: 'Edit widget',
            putToastMessage: 'Widget has been successfully updated!',
            postToastMessage: 'Widget has been successfully created!',
        },////////////

        // WidgetConstructor.jsx
        widgetConstructor: {
            title: 'Widget constructor',
            background: 'Background color',
            border: 'Border color',
            borderRadius: 'Border radius',
            text: 'Text',
            textColor: 'Color of text',
            fontSize: 'Font size',
            fontWeight: 'Font weight',
            borderWidth: 'Border width',
            nameBtn: 'Title of button',
            widthBtn: 'Width of button',
            heightBtn: 'Height of button',
            img: 'Upload image',
            imgWidth: 'Width of image',
            imgHeight: 'Height of image',
            message: 'Image size must not exceed 64 KB',
            messageNameBtn: 'Enter the name of the button!',
            messageErrorLang: 'The button name must be in the selected language!',
            shadow: 'Box shadow',
            switchForShadowExt: 'External',
            switchForShadowInt: 'Internal',
            horizontalShadow: 'Horizontal displacement',
            verticalShadow: 'Vertical displacement',
            blurShadow: 'Blur',
            stretchShadow: 'Stretching',
            colorShadow: 'Shade color',
            opacity: 'Transparency',
            removeImg: 'Remove image',
            remove: 'Remove',
            language: 'Language',
            save: 'Save',
            cancel: 'Cancel'
        },

        // TechnicalSupport.jsx
        support: {
            title: 'Support',
            messageSucces: 'Your message has been successfully sent!',
            toHomePage: 'Back to home page',
            supportRequest: 'support request',
            defaultFormFields: 'The form fields will be filled in by default according to your profile. Change the data if necessary.',
            answerWillBeSentToEmail: "Your request will be assigned a number. The answer will be sent to the e-mail specified in the client's profile or in the corresponding field of the form.",

            fullName: 'Full name:',
            company: 'Organization:',
            phoneNumber: 'Phone number:',
            problemDetails: 'Problem details',
            requestSubject: 'Request subject:',
            attachFile: 'Attach a file:',
            selectFile: 'Select a file',
            ext: 'Extention: ',
            extension: '.pdf .txt .doc .docx .xls .xlsx .rtf .jpg .png',
            size: 'Size up',
            sizeMb: 'to 20 Mb',
            requestText: 'Request text',
            dataProcessingConcent: 'I consent to the processing of my personal data.',
            sendRequest: 'Send request',
            invalidFields: 'Invalid fields'
        },//************** */



        // Users.jsx
        users: {
            title: 'Users',
            avatar: 'Avatar',
            surname: 'Surname',
            name: 'Name',
            patronymic: 'Patronymic',
            email: 'E-mail',
            role: 'Role',
            organization: 'Organization',
            actions: 'Actions',

            subMenuRoles,

            newUser: 'new user',
        },//******** 



        // UserEdit.jsx
        userEdit: {
            title: 'Edit user',
            editing: 'Editing',
            userName: 'User name',
            email: 'E-mail',
            password: 'Password',
            name: 'Name',
            surname: 'Surname',
            patronymic: 'Patronymic',

            subMenuRoles,

            cancel: 'CANCEL',
            save: 'SAVE',
            role: 'Roles',
            backStepButton: 'New user',
            organizationError: 'Choose an organization!',
            passwordRec: 'The password must contain uppercase and lowercase letters, special characters and numbers, and the length of the password must be greater than 6 symbols!',
            validationError: 'Validation error'
        },


        // Settings.jsx
        settings: {
            title: 'Settings',
            section: 'Section',
            sectionName: 'Section name',
            value: 'Value',
            actions: 'Actions',
        },//**********



        //SettingsEdit.jsx
        settingsEdit: {
            crumbs: 'Editing',
            section: 'Section',
            name: 'Name',
            value: 'Value',
            cancel: 'CANCEL',
            save: 'SAVE'
        },/////////////////


        // Личный кабинет / PersonalArea.jsx
        personalArea: {
            title: 'Personal area',

            logotype: 'Logotype',

            durgAndDrop: 'Drag and drop',
            orUpload: 'or upload',
            fromComputer: 'from computer',
            upload: 'upload',
            user: 'USER',
            company: 'Organization',
            bankAccounts: 'ACCOUNTS',
            position: 'Position',
            phoneNumber: 'Phone number:',
            changeData: 'Change data',
            changePassword: 'Change password',
            cancel: 'Cancel',
            save: 'Save',

            organizationName: 'Organization name',
            productCategory: 'Product category',
            fullNameOfCEO: 'Full name of CEO',
            TIN: 'TIN',
            officePhoneNumber: 'Office phone',
            RRC: 'RRC',
            website: 'Website',

            address: 'address',
            region: 'Region:',
            postcode: 'Postcode:',
            city: 'City:',
            actualAddress: 'Actual address:',

            invoiceDetails: 'invoice details',
            checkingAccount: 'Checking account:',
            bank: 'Bank:',
            BIC: 'BIC:',
            bankAddress: 'Bank address:',
            correspondentAccount: 'Correspondent account:',

            extendTariff: 'Extend tariff',
            changeTariff: 'Change tariff',
            changeDetails: 'Change details'

        },//******************************


        products: {
            title: 'Products',
            image: 'Image',
            productName: 'Product name',
            model: 'Model',
            organization: 'Organization',
            actions: 'Actions',
            newProduct: 'new product',
            loading: 'Loading...',
            name: 'Name',
            code: 'Code',
            modelAva: 'Model availability',
            all: 'All',
            withModel: 'With model',
            noModel: 'Without model',
            organization: 'Organization',
            tagName: 'Tag name'
        },
        //ProductEditAr.jsx
        productEditAr: {
            title: 'Editing product',
            loading: 'Loading',
            back: 'Back',
            codes: 'Codes',
            newCode: 'New code',
            addCode: 'Add code',
            newTag: 'New tag',
            addTag: 'Add tag',
            tags: 'Tags',
            errorMessageField: 'The field cannot be empty!'
        },

        // *******************************************

        //ProductsInfo.jsx
        productsInfo: {
            positionInfo: 'Position information',
            name: 'Name',
            typeFitting: 'Fitting type',
            notIndicated: 'Not indicated',
            interior: 'In the interior',
            onFace: 'On the face',
            onSelf: 'On myself',
            description: 'Description',
            cancel: 'Cancel',
            save: 'Save',
            edit: 'Edit',
            further: 'Further',
            confirm: 'Confirm',
            dialogText: 'Undo and go back',
            confirmText: 'Do you really want to go back? Confirm the action!',
            putToastMessage: 'Product has been successfully updated!',
            postToastMessage: 'Product has been successfully created!',
        },

        // *******************************************

        //ProductPanel.jsx
        productPanel: {
            loading: 'Loading',
            tags: 'Tags',
            codes: 'Codes',
            dialogConfirmText: 'The delete operation cannot be undone. Confirm Action!',
            delete: 'Deletion',
            cancel: 'Cancel',
            confirm: 'Confirm',
            toastMessage: 'Not Found'
        },
        //ProductPanelArchive.jsx
        productPanelArchive: {
            packetDownload: 'Download package',
            name: 'Name',
            archive: 'Archive',
            author: 'Author',
            link: 'Link',
            packetWeight: 'Package weight',
            listFiles: 'List of files',
            image: 'Image',
            toastMessage: 'Not Found'
        },
        //ProductPanelInfo.jsx
        productPanelInfo: {
            infoPosition: 'Position information',
            name: 'Name',
            category: 'Category of products/items',
            description: 'Description:',
            attr: 'Attribute',
            filter: 'Filter',
            noFilter: 'No filter'
        },
        // ProductPanelActiveImage.jsx
        productPanelActiveImage: {
            noModels: 'There is no model in this product',
            validationError: 'The model did not pass validation:',
            loading: 'Loading...'
        },
        //ModelPackage.jsx
        modelPackage: {
            enterNamePackage: 'Enter the package name!',
            packetDownload: 'Download package',
            name: 'Name',
            author: 'Author',
            model: 'Model',
            viewModel: 'Model preview',
            edit: 'Edit',
            cancel: 'Cancel',
            save: 'Save',
            emptyFieldError: 'Fill in the required fields!'
        },
        //ModelArchive.jsx
        modelArchive: {
            archiveModels: 'Models archive',
            image: 'Image',
            name: 'Name',
            dateCreation: 'Date of creation',
            dateArchivation: 'Archived date',
            actions: 'Actions'
        },
        //FileButtonUpload.jsx
        fileButtonUpload: {
            viewModel: 'Model preview',
            downloadFromComp: 'download from computer',
            download: 'Download',
        },

        userProfileMenu: {
            userProfile: 'User profile',
            logout: 'LOGOUT',
            configuration: 'Configuration'
        },
        // ********************************

        // Dashboard.jsx

        dashboard: {
            title: 'Home page',
            devices: 'View by device and OS',
            uniqIps: 'Handling from unique IPs',
            viewsByCategory: 'Browse by category',
            productViewsPerDay: 'Number of views per day "ALL DEVICES"',
            topTen: 'Top 10 models',
            sortedByDevice: 'Number of views per day "IOS and OTHERS"',
            downloadReport: 'Download report',
        },
        //**************************************

        //Configuration.jsx

        configuration: {
            title: 'Configuration',
            saveInAzure: 'Store models in azure',
            azureConnectionString: 'A string to connect to azure',
            send: 'Send',
            language: 'Language',
            message: 'Fill the field for connection with azure!',
            ru: 'Russian',
            en: 'English'
        },
        // ************************************************

        // arTable
        arTable: {
            hide: 'Hide',
            show: 'Show',
            noData: 'There is no data'
        },

         // Applications.jsx

        applications: {
            title: 'Applications',
            requestNumber: 'Request number',
            createdAt: 'Date of creation',
            theme: 'Theme',
            status: 'Status',
            actions: 'Actions',
            organization: 'Organization',
            all: 'All'
        },
        // *******************************************

        // ApplicationsEdit.jsx

        applicationsEdit: {
            title: 'Edit application',
            send: 'Send',
            downloadFile: 'Download files'
        },

        //autoComplete.jsx
        autoComplete: {
            organization: 'Organization'
        },

        //Search.jsx
        searchComponent: {
            title: 'Search',
            categories: 'Categories',
            organizationName: 'Organization name',
            models: 'Моdels',
            published: 'Published',
            unpublished: 'Unpublished',
            creationDate: 'Creation date',
            dateOfEdit: 'Date of edit',
            organization: 'Organization',
            actions: 'Actions',
            addCategory: 'add category',
            products: 'Products',
            image: 'Image',
            productName: 'Product name',
            model: 'Model',
            yes: 'yes',
            no: 'no'
        },
        // **************************************

        //Tariffs.jsx
        tariffs: {
            title: 'Tariffs',
            addTariffs: 'Add tariff',
            picture: 'Picture',
            name: 'Name',
            description: 'Description',
            price: 'Price',
            actions: 'Actions',
            isActive: 'Active',
            isPublic: 'Public',
            active: 'Active',
            inactive: 'Inactive',
            public: 'Public',
            inpublic: 'Not public',
            all: 'All'
        },
        // ****************************************

        //TariffsEdit.jsx
        tariffsEdit: {
            title: 'Edit tariff',
            name: 'Name',
            price: 'Price',
            description: 'Description',
            active: 'Active',
            inactive: 'Inactive',
            public: 'Public',
            inpublic: 'Not public',
            count: 'Quantity',
            withoutBorders: 'Without Borders',
            byUsers: 'Limit on the number of users',
            byModels: 'Limit on the number of models',
            byWidgets: 'Limit on the number of widgets',
            byApps: 'Limit on the number of applications',
            cancel: 'Cancel',
            save: 'Save',
            validationErrMess:'Fill in required fields!',
            mustBeNumber: 'Tariff cost must be numerical!'
        },

        //Clean Button
        cleanButton: {
            title: 'Clean all fields'
        }

    },// конец раздела components

}

export { en }