import React from 'react';
import noImage from './images/noimage.jpg';
import logo from './images/alheeemea-logo.png';
import magnigier_icon from './images/SelectSearch__Icon__magnifier.svg';

export const NoImage = ({ className }) => {
  return <img className={className} src={noImage} alt={`изображение недоступно`} />;
};

export const Logo = () => {
  return <img src={logo} alt="логотип" />;
};

export const MagnifierIcon = () => {
  return <img src={magnigier_icon} alt="иконка поиска" />
}

export const arColors = {
  blue: '#29ABE2',
  blueLight: 'rgba(115,206,255,0.71)',
  blueDark: '#1a7293',
  grayLight: '#ECECEC',
  grayMiddle: '#C4C4C4',
  grayDark: '#B3B3B3',
  grayDarkest: '#808080',
  greenDark: '#8CC63F',
  greenLight: '#b4f65e',
  blackLight: '#4D4D4D',
};

export const arBorder = (color) => {
  return { border: `solid ${color} 1px` };
};

export const ArEditIcon = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.5 16.5H15.2" stroke="#808080" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M4.7 12.5L0.5 13.6L1.6 9.4L10.4 0.5L13.6 3.7L4.7 12.5Z" stroke="#808080" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9.1001 1.79999L12.3001 4.99999" stroke="#808080" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M2.2998 9.59998L4.3998 11.8" stroke="#808080" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export const ArDeleteIcon = () => {
  return (
    <svg width="13" height="19" viewBox="0 0 13 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.1998 3H0.799805" stroke="#808080" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M11.7998 5H1.2998V17.8H11.7998V5Z" stroke="#808080" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M4.7998 7.20001V15.4" stroke="#808080" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8.59961 7.20001V15.4" stroke="#808080" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.5 3V1" stroke="#808080" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  )
}

export const ArCopyIcon = () => {
  return (
    <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg" className="copyIconSvg">
      <path d="M13.7998 0.800049H3.7998V13.8H13.7998V0.800049Z" fill="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.7998 4.80005H0.799805V17.8H10.7998V4.80005Z" fill="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export const ArDownloadIcon = () => {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.800049 13V17H17.9V13" stroke="#808080" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9.30005 12.8V0.800049" stroke="#808080" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M14.8001 7.80005L9.30015 13.4L3.90015 7.80005" stroke="#808080" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export const ArImageIcon = ({ color = "#808080" }) => {
  return (
    <svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.2998 0.799988H0.799805V13.6H20.2998V0.799988Z" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16.7997 6.09996C17.6834 6.09996 18.3997 5.38362 18.3997 4.49996C18.3997 3.61631 17.6834 2.89996 16.7997 2.89996C15.9161 2.89996 15.1997 3.61631 15.1997 4.49996C15.1997 5.38362 15.9161 6.09996 16.7997 6.09996Z" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M0.799805 8.89998L4.5998 6.09998L12.1998 13.5" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M17.3996 13.5L11.9996 8.20001L9.59961 10.3" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export const ArPCIcon = ({ color = "#808080" }) => {
  return (
    <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.294 0.778992H0.806641V14.0114H23.294V0.778992Z" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12 14.8865V17.3189" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7.26025 17.3189H16.7392" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export const ArPhoneIcon = ({ color = "#808080" }) => {
  return (
    <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5998 0.799988H0.799805V17.8H10.5998V0.799988Z" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5.6998 16.5C6.19686 16.5 6.5998 16.0971 6.5998 15.6C6.5998 15.103 6.19686 14.7 5.6998 14.7C5.20275 14.7 4.7998 15.103 4.7998 15.6C4.7998 16.0971 5.20275 16.5 5.6998 16.5Z" fill="#808080" />
      <path d="M3.7998 2.59998H7.4998" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export const ArModelIcon = ({ color = "#808080" }) => {
  return (
    <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.7 15L6.5 11.6V4.20001L12.7 7.60001V15Z" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12.7002 15L19.0002 11.6V4.20001L12.7002 7.60001V15Z" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.5 4.19999L12.7 7.59999L19 4.19999L12.7 0.799988L6.5 4.19999Z" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M0.799805 14C0.799805 17.1 6.1998 19.5 12.7998 19.5" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M17.5996 19.1C21.7996 18.2 24.6996 16.3 24.6996 14" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M11.7998 17.9L13.4998 19.6L11.7998 21.3" stroke={color} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export const ArUserIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.9002 6.1C15.9002 8.9 13.6002 11.2 10.8002 11.2C8.0002 11.2 5.7002 8.9 5.7002 6.1C5.7002 3.3 8.0002 1 10.8002 1C13.6002 1 15.9002 3.3 15.9002 6.1Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M1 21C1 15.6 5.4 11.2 10.8 11.2C16.2 11.2 20.6 15.6 20.6 21" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

