import React, { useEffect, useState } from 'react';
import { applicationsBreadCrumbs, initialBreadCrumbs } from '../../../../Helpers/helpers';
import { langManager } from '../../../../utils/languageManager/manager';
import { arWidgetGetSupportRequests, arWidgetGetTheme, arWidgetGetSupportStatuses } from '../../../Api/Utils/Utils';
import ArTable from '../../../Organizms/ArTable/ArTable';
import { ArBreadcrumbs } from '../../../Organizms/Breadcrumbs/ArBreadcrumbs';

function Applications() {

    //Переключение языка
    const [$, setApplications] = useState(langManager.$.components.applications)
    langManager.subscribe(($) => {
        setApplications($.components.applications)
        document.title = $.components.applications.title
    })
    //******************

    const [themes, setThemes] = useState([])
    const [themesOptions, setThemesOptions] = useState([])

    const [statuses, setStatuses] = useState([])
    const [statusesOptions, setStatusesOptions] = useState([])

    const setParentId = (parentId) => localStorage.setItem('applications', parentId);
    const currentPageSize = sessionStorage.getItem('pageSize');

    const getTheme = async () => {
        await arWidgetGetTheme()
            .then((res) => {
                setThemes(res.data.items)
            })
            .catch(e => {
                console.log(e);
            })
    }

    const getStatuses = async () => {
        await arWidgetGetSupportStatuses()
            .then(res => {
                setStatuses(res.data.items)
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        document.title = $.title
        getTheme()
        getStatuses()
    }, [])

    useEffect(() => {
        if(themes.length > 0){
            let arrOptions = [{value: 'default', text: $.all}]
            themes.map(theme => {
                let option = {value: theme.id, text: theme.name}
                arrOptions.push(option)
                setThemesOptions(arrOptions)
                return false
            })
        }

    }, [themes, $])

    useEffect(() => {
        if(statuses.length > 0){
            let arrOptions = [{value: 'default', text: $.all}]
            statuses.map(status => {
                let option = {value: status.id, text: status.name}
                arrOptions.push(option)
                setStatusesOptions(arrOptions)
                return false
            })
        }

    }, [statuses, $])
    return (
        <>
            <ArBreadcrumbs breadCrumbs={[initialBreadCrumbs, applicationsBreadCrumbs]} />
            <ArTable
                basePath="/applications"
                addBtnText={'No btn'}
                setParentId={setParentId}
                targetList={{
                    get: arWidgetGetSupportRequests,
                    delete: '',
                }}
                sortParameters={{'r.id': 'desc'}}
                pageSize={currentPageSize}
                tableHeadings={[
                    { name: $.requestNumber, align: 'left', width: '200px' },
                    { name: $.theme, align: 'left', width: '200px' },
                    { name: $.createdAt, align: 'left', width: '200px' },
                    { name: $.status, align: 'left', width: '200px' },
                    { name: $.actions, align: 'center', width: '200px' }
                ]}
                rowCells={[
                    { type: 'text', align: 'left', contentKey: 'requestNumber' },
                    { type: 'filterOptions', align: 'left', contentKey: 'theme', datas: themesOptions, dataFor: 'themeId' },
                    { type: 'date', align: 'left', contentKey: 'createdAt', color: '#8CC63F' },
                    { type: 'filterOptions', align: 'left', contentKey: 'status', datas: statusesOptions, dataFor: 'statusId'},
                    { type: 'editAction', align: 'center' },
                ]}
                filters={[
                    {
                        name: 'statusId',
                        type: 'select',
                        initialValue: 'default',
                        options: statusesOptions,
                        placeholder: $.status,
                    },
                    {
                        name: 'themeId',
                        type: 'select',
                        initialValue: 'default',
                        options: themesOptions,
                        placeholder: $.theme,
                    },
                    {
                        name: 'createdAt',
                        type: 'date',
                        initialValue: '',
                        placeholder: $.createdAt,
                    },
                    {
                        name: 'organization',
                        type: 'autocomplete',
                        initialValue: '',
                        placeholder: $.organization,
                    },
                ]}
            />
        </>
    );
}

export default Applications;