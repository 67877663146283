import React, { useEffect, useState } from 'react';
import { ArBreadcrumbs } from '../../../Organizms/Breadcrumbs/ArBreadcrumbs';
import { makeStyles } from '@material-ui/core/styles';
import { arWidgetGetSettingsId, arWidgetPutSettings } from '../../../Api/Utils/Utils';
import { ButtonSave } from '../../../Buttons/ButtonSave';
import { ButtonCancel } from '../../../Buttons/ButtonCancel';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { useHistory } from 'react-router';
import Toast from '../../../Toast/Toast';
import { langManager } from '../../../../utils/languageManager/manager';
import { Container } from '@material-ui/core';
import { editPagesBreadCrumbs, initialBreadCrumbs, settingsBreadCrumbs } from '../../../../Helpers/helpers';
import { CleanButton } from '../../../Buttons/CleanButton';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2),
        maxWidth: '800px',
    },
    textField: {
        width: '380px',
        border: '1px solid #C4C4C4',
        outline: 'none',
        padding: '4px 10px 5px',
        flex: '1',
        fontSize: '14px',
        fontStyle: 'italic',
        color: '#808080',
        letterSpacing: '0.04em',
        '&::placeholder': {
            fontStyle: 'italic'
        }
    },
    btn: {
        flexBasis: '100%',
        marginTop: '15px',
        marginLeft: '10px',
    },
    selectSearch: {
        marginBottom: '20px',
        marginTop: '20px',
        '& input': {
            '&:hover': {
                borderColor: 'blue !important',
            },
        },
    },
    formControl: {
        width: '200px',
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(2),
    },
    inputWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '10px 0px'
    },
    btnWrapper: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    btnSave: {
        marginLeft: '10px'
    },
    labelText: {
        fontSize: '11px',
        color: '#808080',
        marginBottom: '5px',
    },
    inputBlock: {
        position: 'relative'
    },
    cleanButton: {
        right: '10px !important',
        top: '55% !important'
    }
}));

function SettingsEdit(props) {
    const classes = useStyles()

    //Переключение языка
    const [$, setSettingsEdit] = useState(langManager.$.components.settingsEdit)
    langManager.subscribe(($) => {
        setSettingsEdit($.components.settingsEdit)
    })
    ///////////////////////

    const history = useHistory()

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [settings, setSettings] = useState({
        id: '',
        name: '',
        section: '',
        value: ''
    })

    const handleInput = (e) => {
        const nam = e.target.name;
        let val = e.target.value
        setSettings({ ...settings, [nam]: val });
        setError(false)
    }


    const handleBack = () => {
        setError(false)
        history.goBack()
    }

    const handleSave = () => {
        putSettings()
    }


    const getSettings = () => {

        setLoading(true)

        arWidgetGetSettingsId(props.id)
            .then(res => {
                setSettings({
                    id: res.data.items.id,
                    name: res.data.items.name,
                    section: res.data.items.section,
                    value: res.data.items.value
                })
                setLoading(false)
                setError(false)
            })
            .catch(err => {
                setError(true)
                setErrorMessage(err.message)
                setLoading(false)
            })
    }

    const putSettings = () => {
        const params = {
            name: settings.name,
            section: settings.section,
            value: settings.value
        }
        setLoading(true)
        arWidgetPutSettings(props.id, params)
            .then(res => {
                setLoading(false)
                setError(false)
                history.goBack()
            })
            .catch(err => {
                setError(true)
                setErrorMessage(err.message)
                setLoading(false)
            })
    }

    useEffect(() => {
        getSettings()

    }, [])


    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <ArBreadcrumbs breadCrumbs={[initialBreadCrumbs, settingsBreadCrumbs, editPagesBreadCrumbs('editing', window.location.pathname, 2)]} />
            {!error ? <></> : <Toast error={error} errorMessage={errorMessage} />}
            <Container>
                <div className={classes.root}>
                    <div className={classes.inputWrapper}>
                        <div className={classes.inputBlock}>
                            <div className={classes.labelText}>{$.section}</div>
                            <input
                                className={classes.textField}
                                id="section"
                                name="section"
                                value={settings.section}
                                onChange={(e) => handleInput(e)}
                            />
                            <CleanButton styles={classes.cleanButton} clear={() => setSettings({...settings, section: ''})}/>
                        </div>
                        <div className={classes.inputBlock}>
                            <div className={classes.labelText}>{$.name}</div>
                            <input
                                className={classes.textField}
                                id="name"
                                name="name"
                                value={settings.name}
                                onChange={(e) => handleInput(e)}
                            />
                             <CleanButton styles={classes.cleanButton} clear={() => setSettings({...settings, name: ''})}/>
                        </div>
                    </div>
                    <div className={classes.inputWrapper}>
                        <div className={classes.inputBlock}>
                            <div className={classes.labelText}>{$.value}</div>
                            <input
                                className={classes.textField}
                                id="value"
                                name="value"
                                value={settings.value}
                                onChange={(e) => handleInput(e)}
                            />
                             <CleanButton styles={classes.cleanButton} clear={() => setSettings({...settings, value: ''})}/>
                        </div>
                    </div>
                    <div className={classes.btnWrapper}>
                        <ButtonCancel
                            startIcon={<CancelIcon />}
                            onClick={handleBack}
                        >
                            {$.cancel}
                        </ButtonCancel>
                        <ButtonSave
                            startIcon={<SaveIcon />}
                            className={classes.btnSave}
                            onClick={handleSave}
                        >
                            {$.save}
                        </ButtonSave>
                    </div>
                </div>
            </Container>


        </>
    );
}

export default SettingsEdit;