// in src/MyAppBar.js
import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {Link} from '@material-ui/core';
import {barStyles} from '../makeStyles/barStyles';
import {useHistory} from 'react-router';
import {Logo} from '../../../Assets/Assets';

const GuestBar = (props) => {
  const history = useHistory();
  const barClasses = barStyles();
  const preventDefault = (e) => {
    e.preventDefault();
    history.replace('/');
  };
  return (
    <AppBar {...props} className={barClasses.arAppBar}>
      <Toolbar>
        <Link href="#" onClick={preventDefault} className={barClasses.logo}>
          {/*{props.title}*/}
          <Logo />
        </Link>
      </Toolbar>
    </AppBar>
  );
};
export default GuestBar;
