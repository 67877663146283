import React from 'react';

const alheemeaTheme = {
  palette: {
    primary: {
      main: '#4f3cc9',
    },
    secondary: {
      main: '#29ABE2',
      textColor: '#fff',

    },

    background: {
      default: '#fcfcfe',
    },
  },
  shape: {
    // borderRadius: 10,
  },


  overrides: {
    RaLogout: {
      background: '#626F78',
      menuList: {
        background: '#626F78 !important',
      },
      menuItem: {
        color: '#fff !important',
      }
    },
    RaAppBar: {
      toolbar: {
        '& button[aria-label="Refresh"]': { 
            display: 'none'
        }
      }
    },

    MuiBreadcrumbs: {
      separator: {
        color: '#000',
      },
    },

    MuiTablePagination: {
      menuItem: {
        color: '#fff !important',
      },
      actions: {
        color: '#000',
      },
    },

    MuiIconButton: {
      // label: {
      //   color: '#fff',
      // },
      '.RaAppBar-menuButtonIconClosed-22': {
        color: '#fff',
        '&:hover': {
          color: '#fff',
        }
      },
      '.RaAppBar-menuButtonIconOpen-23': {
        color: '#fff',
        '&:hover': {
          color: '#fff',
        }
      },
    },


    MuiFormControl: {
      fullWidth: {
        // maxWidth: '300px',
        minWidth: '200px',
        '& > input': {
          borderBottom: 'none',
        },
      },
    },

    MuiPaper: {
      background: '#626F78 !important',
      elevation1: {
        boxShadow: 'none',

      },
      elevation0: {
        // backgroundColor: '#626F78',
        background: '#626F78 !important',
        border: 'none'
      },
      elevation8: {
        background: '#626F78',
        border: 'none',
        // left: 'calc(100% - 200px) !important',
        width: '200px',
        borderRadius: 0
      },
      elevation4: {
        border: 'none !important'
      },
      root: {
        border: '1px solid #e0e0e3',
        backgroundClip: 'padding-box',
        '& .RaSidebar-fixed-42': {
          position: 'relative'
        }
      },
    },

    MuiPopover: {
      root: {
        '&#menu-appbar': {
          '& > .MuiPaper-elevation8': {
            left: 'calc(100% - 200px) !important',
          }
        }
      },
    },

    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },

    MuiToolbar: {
      root: {
        height: '60px',
        border: 'none',
        width: '95%',
        alignSelf: 'center',
        padding: 'auto',
        display: 'flex',
        justifyContent: 'space-between',

      },
    },



    MuiButton: {
      background: '#626F78 !important',
      label: {
        // color: '#fff',
      },
      root: {
        '&.RaUserMenu-userButton-28': {
          color: '#fff !important'
        },
        '&.jss28': {
          color: '#fff !important'
        },
        '&[aria-label="Profile"]': {
          color: '#fff !important'
        },
        '&.skip-nav-button': {
          position: 'fixed !important'
        },
        '&::after': {
          display: 'none !important'
        },
        "&:hover:active::after": {
          right: 'auto',
          left: 0,
          height: '100%',
          display: 'none !important',
        }
      },
      "@media(max-width: 1500px)": {
        root: {
          minWidth: 0
        }
      }
    },

    MuiButtonBase: {
      root: {
        "&:hover:active::after": {
          right: 'auto',
          left: 0,
          height: '100%',
          display: 'none !important'
        },
      },
      "&:hover:active::after": {
        right: 'auto',
        left: 0,
        height: '100%',
        display: 'none !important'

      },
      focusVisible: {
        "&:hover:active::after": {
          right: 'auto',
          left: 0,
          height: '100%'

        },
      }
    },


    MuiMenuItem: {
      root: {
        // color: '#000',
        background: '#626F78',
        margin: 0,
        paddingTop: '16px',
        paddingBottom: '14px',
        '&[aria-current="page"]': {
          background: '#1A698B !important',
          color: '#ffffff !important'
        }
      },
      active: {
        background: '#1A698B !important',
        color: '#ffffff !important'
      },
      gutters: {
        // borderBottom: '1px solid #29ABE2',
        position: 'relative',
        '&:before': {
          content: '""',
          width: 'calc(100% - 20px)',
          height: '1px',
          background: '#29ABE2',
          position: 'absolute',
          bottom: '0',
          left: '10px'
        },
        '&:last-child:before': {
          display: 'none'
        },
      },
    },

    MuiInputBase: {
      input: {
        border: '#fff',
      },
    },


    MuiInput: {
      underline: {
        border: '#fff',
        '&:before': {
          border: '#fff',
        },
      },
    },

    MuiListItemIcon: {
      root: {
        color: '#fff',
        minWidth: '40px !important'
      },
    },

    MuiListItem: {
      borderBottom: '1px solid #29ABE2',
      button: {
        color: '#ffffff',
        '&:lastOfType': {
          borderBottom: 'none',
        },
      },
    },

    MuiButtonBase: {
      root: {
        '&:hover:active::after': {
          // recreate a static ripple color
          // use the currentColor to make it work both for outlined and contained buttons
          // but to dim the background without dimming the text,
          // put another element on top with a limited opacity
          display: 'none'
        },
      },
    },

    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: '#f5f5f5',
      },
      barColorPrimary: {
        backgroundColor: '#d7d7d7',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        '&$disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        border: 'none',
      },
    },
  },
  props: {
    MuiButtonBase: {
      // disable ripple for perf reasons
      disableRipple: true,
    },
  },

  'body .visx-tooltip': {
    zIndex: '1000 !important',
  },
  ".RaSidebar-fixed-43": {
    position: 'relative'
  },


  MuiTableRow: {
    head: {
      height: '30px'
    }
  },

  copyBtn: {
    color: '#808080'
  },

  typography: {
    fontFamily: "'Helios', serif",
    textTransform: "none",
    letterSpacing: '0.04em'
  }

};

export default alheemeaTheme;
