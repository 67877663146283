import {arWidgetGetCurrentUser, arWidgetLogin, arWidgetLogout} from '../Utils/Utils';
import decodeJwt from 'jwt-decode';
// import { currentUser } from '../../../Helpers/helpers';


export default {
  login: async ({ username, password }) => {
    return (
      arWidgetLogin({ username, password })
        .then((response) => {
          return response.data;

        })
        .then((jsonData) => {
          const decodedToken = decodeJwt(jsonData.items.authToken.accessToken);
          // конвертация из секунд в милисекунды
          const expTimeStamp = decodedToken.exp * 1000;
          localStorage.setItem('token', jsonData.items.authToken.accessToken);
          localStorage.setItem('expires', expTimeStamp.toString());
          localStorage.setItem('refresh', jsonData.items.authToken.refreshToken);
          arWidgetGetCurrentUser().then((response) => {
            localStorage.setItem('currentUser', JSON.stringify(response.data.items));
            localStorage.setItem('permissions', response.data.items.roleName)
            // encryptStorage.setItem('currentUser', JSON.stringify(response.data.items));
            // localStorage.setItem('permissions', JSON.stringify(response.data.items.roleName))
          });
        })

        .catch((error) => {
         
          throw new Error(error)
        })
    );
  },

  logout: async () => {
    if (localStorage.getItem("token")) {
      arWidgetLogout().then(() => localStorage.clear());
    }
    return Promise.resolve();
  },

  /**TODO
   * обработчик ошибок
   * @param error
   */

  checkError: async (error) => {
    const status = error.response.status;
    if (status === 401 || status === 403) {
      localStorage.clear();
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
      if (localStorage.getItem("token")) {
        localStorage.clear();
        window.location.reload();

      }
      // return Promise.reject();
    },

  /**TODO
   * проверка авторизации
   * @returns {Promise<void>|Promise<never>}
   */
  checkAuth: async () => {
    return localStorage.getItem('token')  ? Promise.resolve(true) : Promise.reject();
  },

  /**TODO
   * получение разрешений
   * @returns {Promise<string>|Promise<never>}
   */
  getPermissions:   () => {
    const role =  localStorage.getItem('permissions');
    return role ? Promise.resolve(role) : Promise.reject();
  },

  getIdentity: async () => {
    try {
      const currentUser = await JSON.parse(localStorage.getItem('currentUser'))
      const id = currentUser.id;
      const fullName = `${currentUser.name} ${currentUser.surname}`;
      const avatar = currentUser.images[0];

      return Promise.resolve({ id, fullName, avatar });
      // return {id, fullName, avatar}
    } catch (error) {
      return Promise.reject(error);
    }
  },


};
