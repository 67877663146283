import React, {useContext, useEffect, useState} from 'react';
import {ProductEditStyles} from './ProductEditStyles';
import {arWidgetGetProduct} from '../../../Api/Utils/Utils';
import AuthProvider from '../../../Api/AuthProvider/AuthProvider';
import {Button, Grid} from '@material-ui/core';
import TagBlock from '../../../Organizms/TagBlock/TagBlock';
import {ArBreadcrumbs} from '../../../Organizms/Breadcrumbs/ArBreadcrumbs';
import ProductInfo from '../../../Organizms/ArEdit/ProductInfo/ProductInfo';
import ModelPackage from '../../../Organizms/ArEdit/ModelPackage/ModelPackage';
import ModelArchive from '../../../Organizms/ArEdit/ModelArchive/ModelArchive';
import FileUpload from '../../../Molecules/FileUpload/FileUpload';
import {useHistory} from 'react-router';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { langManager } from '../../../../utils/languageManager/manager';
import { NotificationContext } from '../../../../App';
import { categoryBreadCrumbs, editPagesBreadCrumbs, initialBreadCrumbs, productsBreadCrumbs } from '../../../../Helpers/helpers';


const ProductEditAr = ({ ...props }) => {
  const classes = ProductEditStyles();

  //Переключение языка
  const [ $, setProductEditAr] = useState(langManager.$.components.productEditAr)
  langManager.subscribe(($) => {
    setProductEditAr($.components.productEditAr)
    document.title = $.components.productEditAr.title
  })
  //******************

  const [state, dispatch] = useContext(NotificationContext);

  const createProductCategoryId = +localStorage.getItem('category');
  const history = useHistory()
  let id = props.id;

  if (!props.id) {
    id = undefined;
  }

  const [product, setProduct] = useState({
    id: id,
    name: '',
    description: '',
    organization: {},
    category: {},
    images: [],
    models: [],
    tags: [],
    codes: [],
    filter1: null,
    filter2: null,
    filter3: null,
    filter4: null,
  });

  const [loading, setLoading] = useState(true);
  const [backStep, setBackStep] = useState(-1);
  const [back, setBack] = useState(0)

  const getOrganizationId = () => {
    return state.currentUser.organization.id
  };

  const getDataAttr = nodeId => {
    let tempId = document.querySelector('#' + nodeId);
    if (tempId) {
      tempId = parseInt(tempId.parentElement.parentElement.dataset.postid);
      return tempId;
    }
  };


  const getProduct = () => {
    if (!id) {
      setLoading(false);
      return;
    }

    arWidgetGetProduct(props.id)
      .then((response) => {
        return response.data.items;
      })
      .then((tempProduct) => {
        setProduct(tempProduct);
      })
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        return AuthProvider.checkError(e);
      });
  };

  const handleBack = () => {
    history.go(-1)
  }

  useEffect(() => {
    document.title = $.title
    getProduct();
  }, []);

  if (loading) {
    return <div>{$.loading}...</div>;
  }

  return (
    <>
      <ArBreadcrumbs breadCrumbs={[initialBreadCrumbs, categoryBreadCrumbs, productsBreadCrumbs, editPagesBreadCrumbs('editing', window.location.pathname, 3)]}/>
      <Grid container>
      <Grid container className={classes.pageLink}>
      <Button onClick={e => handleBack()} color="primary" size="small" startIcon={<NavigateBeforeIcon/>}>
         {$.back}
      </Button>
      </Grid>
        <Grid lg={12} container direction="column" justify="center" alignItems="center" className={classes.main}>
          <Grid container lg={10} className={classes.productInfo} direction="row" justify="space-between"
                alignItems="flex-start">
            {id && (
              <Grid container lg={4} md={12} sm={12} xs={12} direction={'row'} justify="space-between">
                <Grid item lg={11} md={12} sm={12} xs={12}>
                  <FileUpload
                    product={product}
                    hidden={id === undefined}
                    name={'product image'}
                    productId={id}
                    htmlFor={'productImage'}
                    accept={'image/*'}
                    getUpdate={getProduct}
                  />
                </Grid>
                <Grid item lg={11} md={5} sm={12} xs={11}  className={classes.codeBlock}>
                  <TagBlock
                    hidden={id === undefined}
                    inputId={'codes'}
                    name={'code'}
                    originalArr={product ? product.codes : null}
                    productId={id}
                    getOrganizationId={getOrganizationId}
                    label={$.codes}
                    placeholder={$.newCode}
                    buttonText={$.addCode}
                    errorMessage={$.errorMessageField}
                  />
                </Grid>
                <Grid item lg={11} md={5} sm={12} xs={11} className={classes.codeBlock}>
                  <TagBlock
                    hidden={id === undefined}
                    inputId={'tags'}
                    name={'tag'}
                    originalArr={product.tags ?? null}
                    productId={id}
                    getOrganizationId={getOrganizationId}
                    label={$.tags}
                    placeholder={$.newTag}
                    buttonText={$.addTag}
                    errorMessage={$.errorMessageField}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container lg={id ? 8 : 12}  md={12} sm={12} xs={12}>
              <ProductInfo
                id={id}
                product={product}
                setProduct={setProduct}
                getOrganizationId={getOrganizationId}
                getDataAttr={getDataAttr}
                backStep={backStep}
                setBackStep={setBackStep}
                createProductCategoryId={createProductCategoryId}
              />
              {id && (
                <>
                  <ModelPackage id={id} models={product.models}/>
                  <ModelArchive models={product.models} id={id}/>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default ProductEditAr;
