import { Button } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    button: {
        position: 'absolute',
        right: '30px',
        top: '50%',
        width: '15px',
        height: '15px',
        outline: 'none',
        border: 'none',
        background: 'transparent',  
        cursor: 'pointer',
        padding: 0
    },
    icon: {
        width: '15px',
        height: '15px',
        color: '#C4C4C4' 
    }
}))

export const CleanButton = ({clear, styles=''}) => {

    const classes = useStyles()

    return (
            <button 
                onClick={clear}
                className={`${classes.button} ${styles}`}
            >
                <ClearIcon className={classes.icon}/>
            </button>
    );
}
