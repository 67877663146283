import {makeStyles} from '@material-ui/core/styles';
import {arColors} from '../../../Assets/Assets';

export const BackStepButtonStyle = makeStyles({
    pageLink: {
    alignSelf: 'start',
    marginTop: '2rem',
    '& *': {
      color: arColors.blue,
      fontWeight: '800',
    },
  },
});
