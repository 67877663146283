import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import ErrorIcon from '@material-ui/icons/Report';
import History from '@material-ui/icons/History';
import {Title, useTranslate} from 'react-admin';
import {useHistory} from 'react-router';
import classes from './Error.module.css';
import {langManager} from '../../utils/languageManager/manager';

function MyError(error, errorInfo, ...rest) {

  const [$, setError] = useState(langManager.$.components.error)
  langManager.subscribe(($) => {
    setError($.components.error)
  })
 

  const translate = useTranslate();
  const history = useHistory();
  console.log('errorInfo', errorInfo);
  return (
    <div className={classes.errorContainer}>
      <Title title="Error" />
      <h1>
        <ErrorIcon /> {$.error}{' '}
      </h1>
      <div className={classes.btn}>
        <Button variant="contained" startIcon={<History />} onClick={() => history.go(-1)}>
          {$.back}
        </Button>
      </div>
    </div>
  );
}

export default MyError;
