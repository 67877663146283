// in src/App.js
import * as React from 'react';
import { createContext, useState, useReducer, useEffect} from 'react';
import { Admin, Resource } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import Dashboard from './Components/Dashboard/Dashboard';
import AuthProvider from './Components/Api/AuthProvider/AuthProvider';
import Organizations from './Components/Tables/Organizations/Organizations/Organizations';
import Products from './Components/Tables/Products/Products/Products';
import OrganizationEdit from './Components/Tables/Organizations/OrganizationEdit/OrganizationEdit';
import Categories from './Components/Tables/Categories/Categories/Categories';
import CategoryEdit from './Components/Tables/Categories/CategoryEdit/CategoryEdit';
import Users from './Components/Tables/Users/Users/Users';
import UserEdit from './Components/Tables/Users/UserEdit/UserEdit';
import LoginPage from './Components/Pages/LoginPage/LoginPage';
import customRoutes from './Components/customRoutes/customRoutes';
import DefaultLayout from './Components/layout/DefaultLayout/DefaultLayout';
import ProductEditAr from './Components/Tables/Products/ProductEdit/ProductEditAr';
import theme from './Components/layout/themes/alcheemeaTheme';
import LogoutButton from './Components/Buttons/LogoutButton';
import TechnicalSupport from './Components/Tables/TechnicalSupport/TechnicalSupport';
// import { currentUser } from './Helpers/helpers';
import Settings from './Components/Tables/Settings/Settings/Settings';
import SettingsEdit from './Components/Tables/Settings/SettingsEdit/SettingsEdit';
import Widget from './Components/Widget/Widget/Widget';
import WidgetEdit from './Components/Widget/WidgetEdit/WidgetEdit';
import { usePermissions } from 'react-admin';
import { createBrowserHistory as createHistory } from 'history';
import Information from './Components/Information/Information';
import Search from './Components/Search/Search';
import { langManager } from './utils/languageManager/manager';
import WidgetConstructor from './Components/Widget/WidgetConstructor/WidgetConstructor';
import Applications from './Components/Tables/Applications/Applications/Applications';
import ApplicationsEdit from './Components/Tables/Applications/ApplicationsEdit/ApplicationsEdit';
import Configuration from './Components/Configuration/Configuration';
import Tariffs from './Components/Tables/Tariffs/Tariffs/Tariffs';
import TariffsEdit from './Components/Tables/Tariffs/TariffsEdit/TariffsEdit';
import { ThemeProvider } from '@material-ui/styles';

const history = createHistory();
const dataProvider = jsonServerProvider('http://localhost:3000/');

const Reducer = (state, action) => {
  switch (action.type) {
    case 'ADD_ERROR':
      return {
        ...state,
        error: action.payload.error,
        errorMessage: action.payload.errorMessage
      };
    case 'REMOVE_ERROR': 
      return {
        ...state,
        error: action.payload.error,
        errorMessage: action.payload.errorMessage
      }
    case 'ADD_CURRENT_USER': 
      return {
        ...state,
        currentUser: action.payload.currentUser
      }
    case 'CLEAR_STATE': 
      return {
        ...state,
        error: action.payload.error,
        errorMessage: action.payload.errorMessage,
        currentUser: action.payload.currentUser
      }
    case 'TOGGLE_MENU_STATE':
      return {
        ...state,
        library: action.payload.library,
        reference: action.payload.reference
      }
      default:
        return {...state};
    }
};

const ReducerGlobalSearch = (state, action) => {
  switch (action.type) {
    case 'ADD_SEARCH_CONTENT':
      return {
        ...state,
        searchContent: action.payload.searchContent,
      };
    default:
      return {...state};
  }
};

const initialState = {
  error: false,
  errorMessage: '',
  currentUser: {},
  library: false,
  reference: false
}

let initialStateGlobalSearch = {
  searchContent: ''
};


export const SearchContext = createContext(initialStateGlobalSearch)
export const NotificationContext = createContext(initialState)

const currentUser = JSON.parse(localStorage.getItem('currentUser'));

const App = (props) => {
  

  const [parentId, setParentId] = useState();

  const [idInfo, setIdInfo] = useState();
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [defaultVal, setDefaultVal] = useState({
    error: false,
    errorMessage: ''
  })
  
  // Переключение языка
  const [$hp, set$] = useState(langManager.$)
  langManager.subscribe(($) => {
    set$($)
  })////////////////

  const initialBCState = [{ name: $hp.homePageTitle, href: 'http://localhost:3000/', depth: 0 }];
  

  const [state, dispatch] = useReducer(Reducer, initialState);
  const [stateGlobalSearch, dispatchGlobalSearch] = useReducer(ReducerGlobalSearch, initialStateGlobalSearch);


  useEffect(() => {
    let searchParams = new URLSearchParams(history.location.search)
    let qr = searchParams.get("qr")
    if(qr){
     history.push({
      pathname: `/viewModel`,
      search: `?qr=${qr}`,
      state: {qr: qr}
    })
    }
  }, [window.location.href, history.location.search])

  useEffect(() => {
    if(localStorage.getItem('language')){
      langManager.switch(localStorage.getItem('language'))
    }
  })


  return (
    // <ThemeProvider theme={theme}>
    <NotificationContext.Provider value={[state, dispatch]}>
    <SearchContext.Provider value={[stateGlobalSearch, dispatchGlobalSearch]}>
      <Admin
        dashboard={Dashboard}
        dataProvider={dataProvider}
        authProvider={AuthProvider}
        loginPage={LoginPage}
        layout={DefaultLayout}
        logoutButton={LogoutButton}
        customRoutes={customRoutes}
        title={'Alheemea 3D Viewer'}
        theme={theme}
        history={history}
      >

        <Resource
          name='category'
          list={Categories}
          edit={CategoryEdit}
          create={CategoryEdit}
          options={{ parentId, setParentId }}
        />
        {state.currentUser ?
          state.currentUser.roleName === 'user' ? <></> :
            <Resource
              name='user'
              list={Users}
              edit={UserEdit}
              create={UserEdit}
              options={{ parentId, setParentId }}
            /> : <></>
        }

        {state.currentUser ?
          state.currentUser.roleName === 'admin' ?
            <Resource
              name='organization'
              list={Organizations}
              edit={OrganizationEdit}
              create={OrganizationEdit}
              options={{ parentId, setParentId, setIdInfo }}
              getId={(e) => setIdInfo(e)}
            />
            :
            <></>
          :
          <></>
        }

        <Resource
          name="info"
          list={Information}
          options={{ parentId, setParentId, idInfo }}
        />
        <Resource
          name='widget'
          list={Widget}
          edit={WidgetEdit}
          create={WidgetEdit}
          options={{ parentId, setParentId }}
        />
        <Resource
          name='product'
          // list={() => Products({ parentId: 29 })}
          list={Products}
          edit={ProductEditAr}
          create={ProductEditAr}
          options={{ parentId, setParentId }}
        // edit={ProductEdit}
        // create={ProductEdit}
        />
        <Resource
          name='personal'
        />

        <Resource
          name='support'
          list={TechnicalSupport}
        />
        <Resource
          name='search'
          list={Search}
        />
        {state.currentUser ?
          state.currentUser.roleName === 'admin' ?
        <Resource
          name='settings'
           list={Settings}
           edit={SettingsEdit}
           create={SettingsEdit}
           options={{ parentId, setParentId }}
         />
            :
            <></>
          :
          <></>
        }
        {state.currentUser ?
          state.currentUser.roleName === 'admin' ?
        <Resource
          name='applications'
           list={Applications}
           edit={ApplicationsEdit}
           options={{ parentId, setParentId }}
         />
            :
            <></>
          :
          <></>
        }
        <Resource
          name='configuration'
          list={Configuration}
        />
        <Resource
            name='widgetConstructor'
            list={WidgetConstructor}
        />
        <Resource
            name='tariffs'
            list={Tariffs}
            edit={TariffsEdit}
            create={TariffsEdit}
            options={{ parentId, setParentId }}
        />
      </Admin>
    </SearchContext.Provider>
    </NotificationContext.Provider>
// </ThemeProvider>
  )
}
export default App
