import {makeStyles} from '@material-ui/core/styles';

export const barStyles = makeStyles({
  button: {
    color: 'secondary',
  },
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: 'white',
  },
  spacer: {
    flex: 1,
  },
  arAppBar: {
    '& > div': {
      height: '60px',
      width: '100%',
      backgroundColor: '#343A40',
      display: 'flex',
      // justifyContent: "space-between",
        color: 'white',
      '& > *': {
        '&:hover': {
          // color: '#29ABE2',

        },
      },
      '& > *:nth-child(1)': {
        // order: 3,
        '& svg': {
          width: '2rem',
          height: '2rem',
        },
        marginLeft: '1.7rem',
      },
      '& > *:nth-child(3)': {
        // order: 1,
        flexGrow: 1,
        alignSelf: 'end',
        marginLeft: '209px',
        justifyContent: 'center',
      },
      '@media (max-width: 992px)': {
        '& > *:nth-child(3)': {
          marginLeft: '100px',
        }
      },
      '@media (max-width: 768px)': {
        '& > *:nth-child(2)': {
          marginLeft: '0px',
        },
        '& > *[aria-label="Refresh"]': {
          display: 'none'
        }
      },
      '& > *:nth-child(3)': {
        // order: 2,
        '& svg': {
          width: '2rem',
          height: '2rem',
        },
        // marginRight: "3rem",
        // position: "absolute",

        marginRight: '1.5rem',
      },
      '&  > *:nth-child(4)': {
        // order: 4,
        // alignSelf: "flex-start",
        marginRight: '1.5rem',
        // position: "absolute",
      },
    },
    '@media (max-width: 680px)': {

      '& > div > *:nth-child(1)': {
        marginLeft: '0 !important',
      },
      '& > div > *:nth-child(4)': {
        marginRight: 0,
        position: 'absolute',
        right: '190px',
      },
      '& > div > *:nth-child(3)': {
        display: 'none'
      },
      '& > div > *:nth-child(5)': {
        marginRight: '0 !important'
      },
      '&  > .toolbar': {
        paddingRight: '0'
      },
    },
  },
  profile: {
    color: 'red',
    '& svg': {
      width: '3rem',
      height: '3rem',
      marginRight: '20px',
    },
  },
  logo: {
    marginLeft: '0.5rem',
    width: '270px',
  },
  arMenu: {
    position: 'absolute',
    color: 'red',

    '& div:nth-child(3n)': {
      // marginTop: "60px",
      // minWidth: "300px",
      // right: "23px",
      // top: "70px !important",
    },
  },
  arMenuItem: {
    minWidth: 250,
    color: 'red',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'center',
    paddingRight: '0 !important'
  }
});
