import React from 'react';
import {useHistory} from 'react-router-dom';
import {BackStepButtonStyle} from './BackStepButtonStyle';
import {Button, Grid} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';


function BackStepButton({ buttonText }) {
  let classes = BackStepButtonStyle();
  const history = useHistory();
  const handleBack = () => {
    history.go(-1)
  };

  return (
    <Grid container className={classes.pageLink}>
      <Button onClick={handleBack} color="primary" size="small" startIcon={<NavigateBeforeIcon/>}>
        {buttonText}
      </Button>
    </Grid>
  );
}

export default BackStepButton;
