import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  arWidgetGetCategory,
  arWidgetGetCategoryFilters,
  arWidgetGetCategoryFilterValues,
  arWidgetPostCategory,
  arWidgetPostCategoryFiltersValues,
  arWidgetPutCategory,
  arWidgetPostCategoryFilters,
  arWidgetDeleteCategoryFiltersValues
} from '../../../Api/Utils/Utils';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import { useHistory } from 'react-router-dom';
import BackStepButton from '../../../Organizms/BackStepButton/BackStepButton';
import { ArBreadcrumbs } from '../../../Organizms/Breadcrumbs/ArBreadcrumbs';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { ButtonCancel } from '../../../Buttons/ButtonCancel';
import { ButtonSave } from '../../../Buttons/ButtonSave';
import Toast from '../../../Toast/Toast';
import { toast } from 'react-toastify';
import { langManager } from '../../../../utils/languageManager/manager';
import { categoryBreadCrumbs, editPagesBreadCrumbs, initialBreadCrumbs } from '../../../../Helpers/helpers';
import { CleanButton } from '../../../Buttons/CleanButton';

const useStyles = makeStyles((theme) => ({
  contentArea: {
    display: 'flex',
  },

  newCategoryArea: {
    display: 'flex',
    flexDirection: 'column',
    width: '400px',
    marginTop: '20px'
  },

  buttonsArea: {
    paddingRight: '250px',
    marginTop: '35px',
    display: 'flex',
    justifyContent: 'center',
    '& > button': {
      marginLeft: '28px',
      minWidth: '120px',
    },
    '& > button:first-child': {
      marginLeft: '0px',
    },
    '@media(max-width:1200px)': {
      paddingRight: 0,
      justifyContent: 'flex-start'
    }
  },

  filtersArea: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '69px',
  },

  filtersAreaInputs: {

    '& > div': {

      '&:nth-child(9)': {
        display: 'none',
      },
    },
  },

  inputLabel: {
    color: '#808080',
    display: 'block',
    fontSize: '11px',
    lineHeight: '13px',
    letterSpacing: '0.04em',
    '@media(max-width:1200px)': {
      marginLeft: '0 !important',
      marginBottom: '20px',
    }
  },

  input: {
    display: 'block',
    marginTop: '5px',
    width: '280px',
    border: '1px solid #C4C4C4;',
    height: '30px',
    background: '#fff',
    outline: 'none',
    padding: '0px 25px 0px 9px',
  },

  choiceInput: {
    background: '#E5E5E5',
    border: 'none',
    borderRadius: '14px',
    padding: '7px 12px 7px 16px',
    maxWidth: '100%',
    marginRight: '29px',
    marginBottom: '19px',
  },
  label_customMarginTop: {
    color: '#808080',
    display: 'block',
    fontSize: '11px',
    lineHeight: '13px',
    letterSpacing: '0.04em',
    marginTop: '15px',
  },

  choices: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  choicesWrapper: {
    display: 'flex',
    position: 'relative'
  },
  choiceDelete: {
    position: 'absolute',
    background: '#F2F2F2',
    borderRadius: '50%',
    width: '18px',
    height: '18px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    right: '30px',
    top: '-10px',
    cursor: 'pointer'
  },
  subTitle: {
    fontFamily: 'Helios',
    fontStyle: 'normal',
    fontHeight: 'normal',
    fontSize: '11px',
    lineHeight: '13px',
    letterSpacing: '0.04em',
    color: '#808080',
    marginTop: '28px',
  },

  text: {
    fontFamily: 'Helios',
    fontStyle: 'italic',
    fontWeight: 'normal',
    fontSize: '11px',
    lineHeight: '13px',
    letterSpacing: '0.04em',
    color: '#808080',
    margin: '0',
    marginBottom: '23px',
    width: '100%',
    '@media(max-width:1200px)': {
      wordWrap: 'break-word',
      width: '90%',
    },
    '@media(max-width:380px)': {
      width: '70%',
    }
  },

  buttonRemoveInput: {
    position: 'relative',
    top: '2px',
    right: '35px',
    fontSize: '20px',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',
  },

  sectionHeading: {
    display: 'block',
    height: '19px',
    fontFamily: 'Helios',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.04em',
    textTransform: 'uppercase',
    color: '#4D4D4D',
  },

  filterRow: {
    display: 'flex',
    alignItems: 'flex-end',
    marginTop: '48px',
    '@media(max-width:1200px)': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },

  rowWrapper: {
    display: 'flex',
    alignItems: 'start',
  },

  addBtnChoice: {
    background: '#29ABE2',
    fontFamily: 'Helios',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '9px',
    lineHeight: '10px',
    display: 'flex',
    alignItems: 'flex-end',
    textAlign: 'center',
    letterSpacing: '0.12em',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    padding: '11px 8px 9px',
    border: 'none',
    outline: 'none',
    margin: '5px 0px 0px 10px',
    cursor: 'pointer',
    '@media(max-width:1200px)': {
      margin: 0,

    }
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '400px',
  },

  buttonWrapper: {
    marginTop: '48px',
    // marginBottom: '8px',

    '& button': {
      // display: 'none',
      // marginTop: '27px',
    },
  },

  addBtnAttr: {
    background: '#29ABE2',
    borderRadius: '0',
    fontFamily: 'Helios',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '9px',
    lineHeight: '10px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '0.12em',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    padding: '8px 18px 9px',
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    '&:hover': {
      background: '#29ABE2',
      boxShadow: 'none',
    },
  },

  switchLabel: {
    marginLeft: '25px',
    color: 'gray',
  },

  organization: {
    marginLeft: '16px',
    marginTop: '32px',
    width: '400px',
    listStyleType: 'none',
    margin: '0',
    padding: '0',
  },

  selectSearch: {
    marginBottom: '20px',
    marginTop: '20px',
    '& input': {
      '&:hover': {
        borderColor: 'blue !important',
      },
    },
  },
  areas: {
    // display: 'flex',
  },
  rows: {
    position: 'relative',
    maxWidth: '305px'
  },
}));

export default function CategoryEdit(props) {
  const classes = useStyles();
  const history = useHistory();

  const [filters, setFilters] = useState([])
  const [filtersObj, setFiltersObj] = useState({})
  const [filterValues, setFilterValues] = useState({
    1: { id: [], name: [] },
    2: { id: [], name: [] },
    3: { id: [], name: [] },
    4: { id: [], name: [] },
  })
  const [filterPostValues, setFilterPostValues] = useState({
    1: { id: [], name: [] },
    2: { id: [], name: [] },
    3: { id: [], name: [] },
    4: { id: [], name: [] },
  })
  const [values, setValues] = useState({
    value1: '',
    value2: '',
    value3: '',
    value4: ''
  })

  // Переключени языка
  const [$, setCategoryEdit] = useState(langManager.$.components.categoryEdit)
  langManager.subscribe(($) => {
    setCategoryEdit($.components.categoryEdit)
    document.title = $.components.categoryEdit.title
  })
  //////////////////

  const id = () => {
    if (props.id) {
      return props.id;
    } else {

    }
  };

  const [category, setCategory] = useState({
    name: '',
    organizationId: '',
  });

  const [loading, setLoading] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // хандл редактирование категории
  const handleEditCategory = (e) => {
    setCategory({ ...category, [e.target.name]: e.target.value })
  }

  // добавление поля фильтров
  const handleAddFilter = () => {
    setFilters([...filters, ''])
    let newArr = [...Object.keys(filtersObj)]
    setFiltersObj(prev => {
      return { ...prev, [`filter${newArr.length + 1}`]: { id: '', name: '' } }
    })
  }

  // хандл инпут при изменении поля фильтров
  const handleChangeFilter = (e, i) => {
    setFiltersObj(prev => {
      if (prev[e.target.name]) {
        return { ...prev, [e.target.name]: { ...prev[e.target.name], name: e.target.value } }
      } else {
        return { ...prev, [e.target.name]: { id: '', name: e.target.value } }
      }
    })
  }

  // убрать поля при нажатии на кнопку х
  const removeFormFields = (e, i) => {
    let obj = {}
    let newObj = { ...filtersObj }
    let newCloneObj = { ...filtersObj }
    for (let k in newObj) {

      if (k === `filter${i + 1}`) {
        delete newCloneObj[k]
      }
    }

    for (let j = 0; j < Object.values(newCloneObj).length; j++) {
      obj = { ...obj, [`filter${j + 1}`]: Object.values(newCloneObj)[j] }
    }

    setFilters(prev => {
      prev.pop()
      return prev
    })
    setFiltersObj(obj)
  }

  // // хандл инпут при изменении поля значений для фильтров
  const handleChangeValues = (e) => {
    setValues(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  };


  // добавить (локально) значений для фильтров
  const handleAddValues = (i, e) => {

    if (values[`value${i + 1}`] === '') {
      const customId = 'customToastId'
      toast.error($.error, {
        toastId: customId
      })
      return
    }

    let newArrValues = { ...filterValues }
    if (newArrValues[i + 1]) {
      let newArr = [...newArrValues[i + 1].name]
      newArr.push(values[`value${i + 1}`])
      newArrValues = { ...newArrValues, [i + 1]: { ...newArrValues[i + 1], name: newArr } }
    } else {
      let newArr = []
      newArr.push(values[`value${i + 1}`])
      newArrValues = { ...newArrValues, [i + 1]: { id: '', name: newArr } }
    }
    setFilterValues(newArrValues)

    if (props.id) {
      let newArrPostValues = { ...filterPostValues }
      if (newArrPostValues[i + 1]) {
        let newArr = [...newArrPostValues[i + 1].name]
        newArr.push(values[`value${i + 1}`])
        newArrPostValues = { ...newArrPostValues, [i + 1]: { ...newArrPostValues[i + 1], name: newArr } }
      } else {
        let newArr = []
        newArr.push(values[`value${i + 1}`])
        newArrPostValues = { ...newArrPostValues, [i + 1]: { id: '', name: newArr } }
      }
      setFilterPostValues(newArrPostValues)

    }

    setValues({ ...values, [`value${i + 1}`]: '' })
  };

  // ######### ----- Тут запросы -------------- ####################

  // ************** -- GET запросы --- ******************
  // получение категории
  const getCategory = async () => {
    await arWidgetGetCategory(props.id)
      .then(res => {
        setCategory({
          name: res.data.items.name,
          organizationId: res.data.items.organization.id
        })
        getFilters(props.id, res.data.items.organization.id)
      })
      .catch(err => {
        console.log(err)
      })
  }

  // получение фильтров
  const getFilters = async (catId, orgId, post = false) => {
    await arWidgetGetCategoryFilters(catId, null)
      .then(res => {
        if (res.data.items && res.data.items.length > 0) {
          res.data.items.map((item, index) => {
            setFiltersObj(prev => {
              return { ...prev, [`filter${index + 1}`]: { name: item.name, id: item.id } }
            })
            setFilters(prev => {
              return [...prev, index]
            })
            if (post) {
              postValuesFilter(item.id, index)
            } else {
              getValuesFilter(item.id, index)
            }
            return
          })
        }
      })
      .catch(err => {
        console.log(err)
      })
  }
  // получение значений фильтров
  const getValuesFilter = async (id, i) => {
    await arWidgetGetCategoryFilterValues(id)
      .then(res => {
        if (res.data.items.valuesForFilters && res.data.items.valuesForFilters.length > 0) {
          mapValuesFilter(res.data.items, i)
        }
      }).catch(err => {
        console.log(err)
      })
  }

  let newArrValues = { ...filterValues }

  // записать значение фильтров в переменную
  async function mapValuesFilter(items, i) {
    if (items.valuesForFilters && items.valuesForFilters.length > 0) {
      items.valuesForFilters.map((item, idx) => {
        const isJson = checkIsJson(item.name)
        if (isJson) {
          JSON.parse(item.name).map(val => {
            newArrValues = { ...newArrValues, [i + 1]: { ...newArrValues[i + 1], id: [...newArrValues[i + 1].id, item.id], name: [...newArrValues[i + 1].name, val] } }
            return false
          })
        } else {
          newArrValues = { ...newArrValues, [i + 1]: { ...newArrValues[i + 1], id: [...newArrValues[i + 1].id, item.id], name: [...newArrValues[i + 1].name, item.name] } }
        }
        setFilterValues(newArrValues)
        return false
      })
    }
  }

  // проверить является ли строка json строкой
  function checkIsJson(item) {
    item = typeof item !== "string"
      ? JSON.stringify(item)
      : item;

    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }

    if (typeof item === "object" && item !== null) {
      return true;
    }

    return false;
  }

  // ************************ --- POST запросы --- *****************************

  // создания категории с фильтрами
  const postCategory = async () => {
    if (category.name.trim() === '') {
      const customId = 'customToastId'
      toast.error($.error, {
        toastId: customId
      })
      return
    }

    const params = {
      name: category.name,
      filter1: (filtersObj.filter1 && filtersObj.filter1.name) ? filtersObj.filter1.name : null,
      filter2: (filtersObj.filter2 && filtersObj.filter2.name) ? filtersObj.filter2.name : null,
      filter3: (filtersObj.filter3 && filtersObj.filter3.name) ? filtersObj.filter3.name : null,
      filter4: (filtersObj.filter4 && filtersObj.filter4.name) ? filtersObj.filter4.name : null
    }

    await arWidgetPostCategory(params)
      .then(res => {
        getFilters(res.data.items.id, null, true)
        showToaster($.postToastMessage)
      })
      .catch(err => {
        console.log(err)
      })
  }

  // создание фильтров для категории
  const postFilters = async (name, valuesName = null) => {
    await arWidgetPostCategoryFilters(props.id, name)
      .then(res => {
        if (valuesName) {
          postValuesFilter(res.data.items.id, null, valuesName)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  //создание значении для фильтров 
  const postValuesFilter = async (id, i, values = null) => {

    let params = values

    if (!props.id) {
      params = (filterValues[i + 1] && filterValues[i + 1].name) ? filterValues[i + 1].name : null
    }

    if (!params) {
      return
    }

    params.map(item => {
      arWidgetPostCategoryFiltersValues(id, item)
        .then(res => {
        })
        .catch(err => {
          console.log(err)
        })
    })
    return false
  }

  // ************** -- PUT запросы --- ******************

  const putCategory = async () => {

    let params = {
      name: category.name
    }

    let newObj = {}

    for (let idx = 1; idx < 5; idx++) {

      // если в объекте есть поле name но нет id то его надо создавать
      if (filtersObj[`filter${idx}`] && !filtersObj[`filter${idx}`].id && filtersObj[`filter${idx}`].name) {
        if (filterPostValues[idx] && filterPostValues[idx].name) {
          postFilters(filtersObj[`filter${idx}`].name, filterPostValues[idx].name)
        } else {
          postFilters(filtersObj[`filter${idx}`].name, null)
        }
        continue
      }

      newObj = { ...newObj, [`filter${idx}`]: filtersObj[`filter${idx}`] }

      if (filterPostValues[idx] && filterPostValues[idx].name && filterPostValues[idx].name.length > 0) {
        postValuesFilter(filtersObj[`filter${idx}`].id, idx - 1, filterPostValues[idx].name)
      }
    }

    params = { ...params, ...newObj }

    await arWidgetPutCategory(props.id, params)
      .then(res => {
        showToaster($.putToastMessage)
      })
      .catch(err => {
        console.log(err)
      })
  }

  function showToaster(message) {

    const customId = 'putCategoryIdToast'

    toast.success(message, {
      toastId: customId
    })
  }

  // сохранение категории в базе
  const handleSave = () => {

    if (props.id) {
      putCategory()
      return
    }

    postCategory()
  }

  // ************** -- DELETE запросы --- ******************

  const handleDeleteValue = async (id, index, idx) => {

    let newArr = { ...filterValues }
    let name = newArr[index + 1].name
    let ids = newArr[index + 1].id
    name.splice(idx, 1)
    ids.splice(idx, 1)
    newArr = { ...newArr, [index + 1]: { id: ids, name: name } }
    setFilterValues(newArr)

    // если нет id то удаляем просто визуально
    if (!id) {
      return
    }

    await arWidgetDeleteCategoryFiltersValues(id)
      .then(res => {
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleBack = () => {
    history.goBack()
  }

  useEffect(() => {
    history.location.pathname === '/category/create' ? setIsCreate(true) : setIsCreate(false);
    document.title = $.title
    if (props.id) {
      getCategory()
    }
    // getOrganizations()
  }, []);

  if (loading) {
    return <div>{$.loading}</div>;
  }

  return (
    <>
      <ArBreadcrumbs breadCrumbs={[initialBreadCrumbs, categoryBreadCrumbs, editPagesBreadCrumbs('editing', window.location.pathname, 2)]} />
      {!error ? <></> : <Toast error={error} errorMessage={errorMessage} />}
      <Container>

        <div className={classes.areas}>

          <div className={classes.newCategoryArea}>
            <div>
              <h2 className={classes.sectionHeading}>{$.title}</h2>
              <div className={classes.rows}>
                <label className={classes.label_customMarginTop}>{$.categoryName}
                  <input className={classes.input} id='name' name='name' label='Название' style={{ marginLeft: '' }}
                    value={category.name}
                    onChange={handleEditCategory}
                  />
                </label>
                <CleanButton clear={() => setCategory({ ...category, name: '' })} />
              </div>
            </div>

          </div>

          <div className={classes.filtersArea}>
            <h2 className={classes.sectionHeading}>{$.searchAttributes}</h2>
            <p className={classes.text}>{$.oneFieldIsRequired}</p>
            <p className={classes.text}>{$.addLibrary}</p>
            <div className={classes.filtersAreaInputs}>
              {
                Object.keys(filtersObj).length > 0 &&
                Object.keys(filtersObj).map((item, index) => {
                  return (
                    <div key={index}>
                      <div className={classes.filterRow}>
                        <div className={classes.rowWrapper}>
                          <label className={classes.inputLabel}>
                            {$.attribute} {index + 1}:
                            <input className={classes.input}
                              type="text"
                              id={`filter${index + 1}`}
                              name={`filter${index + 1}`}
                              value={filtersObj[`filter${index + 1}`].name}
                              onChange={e => handleChangeFilter(e, index)}
                            />
                          </label>
                          <button type='button'
                            className={classes.buttonRemoveInput}
                            style={{ marginTop: '9px' }}
                            data-remove={index}
                            onClick={(e) => removeFormFields(e, index)}
                          >
                            x
                          </button>
                        </div>
                        <label className={classes.inputLabel} style={{ marginLeft: '79px' }}>
                          <input
                            className={classes.input}
                            value={values[`value${index + 1}`]}
                            name={`value${index + 1}`}
                            type="text"
                            onChange={(e) => handleChangeValues(e)}
                          />
                        </label>
                        <button type='button' className={classes.addBtnChoice}
                          onClick={e => handleAddValues(index, e)}
                        >
                          {$.addSelection}
                        </button>
                      </div>
                      <div>
                        <p className={classes.subTitle}>
                          {`${$.choiseAttribute} ${index + 1}:`}
                        </p>
                        <div className={classes.choices}>
                          {
                            filterValues[index + 1] && filterValues[index + 1].name && filterValues[index + 1].name.length > 0 &&
                            filterValues[index + 1].name.map((val, idx) => {
                              let id = filterValues[index + 1].id ? filterValues[index + 1].id[idx] : null
                              return (
                                <div className={classes.choicesWrapper}>
                                  <input
                                    type='text'
                                    id={id}
                                    name={id}
                                    key={val + idx}
                                    disabled
                                    value={val}
                                    className={classes.choiceInput}
                                  />
                                  <span
                                    className={classes.choiceDelete}
                                    onClick={e => handleDeleteValue(id, index, idx)}
                                  >
                                    x
                                  </span>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                  )
                })
              }
              {
                filters.length < 4 &&
                <Box className={classes.buttonWrapper}>
                  <Button
                    variant='contained'
                    className={classes.addBtnAttr}
                    size='large'
                    startIcon={<AddIcon style={{ border: '1px solid #fff', borderRadius: '50%' }} />}
                    onClick={handleAddFilter}
                  >
                    {$.addAttribute}
                  </Button>
                </Box>
              }
            </div>
          </div>
        </div>

        <div className={classes.buttonsArea} >
          <ButtonCancel
            variant='contained'
            color='secondary'
            size='large'
            startIcon={<CancelIcon />}
            onClick={handleBack}
          >
            {$.cancel}
          </ButtonCancel>

          <ButtonSave
            variant='contained'
            color='secondary'
            size='large'
            startIcon={<SaveIcon />}
            onClick={handleSave}
          >
            {$.save}
          </ButtonSave>
        </div>

      </Container>
    </>
  );
}
