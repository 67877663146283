// in src/MyAppBar.js
import * as React from 'react';
import {AppBar} from 'react-admin';
import Toolbar from '@material-ui/core/Toolbar';
import {Link} from '@material-ui/core';
import {useHistory} from 'react-router';
import {barStyles} from '../makeStyles/barStyles';
import {Logo} from '../../../Assets/Assets';
import UserProfile from '../../UserProfile/UserProfile';
import SelectSearch from '../../Molecules/SelectSearch/SelectSearch';

const styles = {
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: 'white',
  },
  spacer: {
    flex: 1,
  },
  
};

const DefaultBar = ({ ...props }) => {
  const barClasses = barStyles();
  const history = useHistory();

  const preventDefault = (e) => {
    e.preventDefault();
    history.replace('/');
  };


  const classes = {
    toolbar: 'toolbar'
  }

  return (
    <>
    <AppBar {...props} className={barClasses.arAppBar} userMenu={<UserProfile/>} classes={classes}>
    <SelectSearch />
      <Toolbar className={barClasses.toolbar}>
        <Link href="#" onClick={preventDefault} className={barClasses.logo}>
          <Logo />
        </Link>
        
      </Toolbar>
    </AppBar>

    </>
  );
  // };
};

export default DefaultBar;
