import React, {useContext, useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import classes from './Search.module.css'
import ArTable from '../Organizms/ArTable/ArTable'
import {arWidgetDeleteCategory, arWidgetDeleteProduct, arWidgetGetGlobalSearch} from '../Api/Utils/Utils'
import {SearchContext} from '../../App'
import { langManager } from '../../utils/languageManager/manager'
import { ArBreadcrumbs } from '../Organizms/Breadcrumbs/ArBreadcrumbs'
import { globalSearchAreaBreadCrumbs, initialBreadCrumbs } from '../../Helpers/helpers'

function Search() {

     // Переключение языка
     const [$, setSearchComponent] = useState(langManager.$.components.searchComponent)
     langManager.subscribe(($) => {
         setSearchComponent($.components.searchComponent)
         document.title = $.components.searchComponent.title
     })
     //**************

    const currentUser = JSON.parse(localStorage.getItem('currentUser'))

    const history = useHistory()

    const setParentId = (parentId) => localStorage.setItem('category', parentId);
    const currentPageSize = sessionStorage.getItem('pageSize');
    const orgId = currentUser && currentUser.organization.id ? currentUser.organization.id : null;
    const parentId = localStorage.getItem('category');
    const [stateGlobalSearch, dispatchGlobalSearch] = useContext(SearchContext);


    useEffect(() => {
        document.title = $.title
        if(history.location.state && history.location.state.searchContent && history.location.state.searchContent !== ''){
            dispatchGlobalSearch({ type: 'ADD_SEARCH_CONTENT', payload: { ...stateGlobalSearch, searchContent: history.location.state.searchContent } });
        }
    }, [])

    if(stateGlobalSearch.searchContent === ''){
        return <div className={classes.noItems}>...</div>
    }
    return (
        <>
        <ArBreadcrumbs breadCrumbs={[initialBreadCrumbs, globalSearchAreaBreadCrumbs]}/>
            <div className={classes.categoryContainer}>
                <div className={classes.title}>{$.categories}</div>
                {currentUser.roleName === 'admin'
                    ?
                    <ArTable
                        historyState={'no-update'}
                        basePath='/search'
                        editPath='/category'
                        addBtnText="No btn"
                        // parentId={props.options.parentId}
                        setParentId={setParentId}
                        search='categoryGlobalSearch'
                        searchContent={stateGlobalSearch.searchContent}
                        targetList={{
                            get: arWidgetGetGlobalSearch,
                            delete: arWidgetDeleteCategory,
                        }}
                        pageSize={currentPageSize}
                        tableHeadings={[
                            { name: $.organizationName, align: 'left', width: '200px' },
                            { name: $.models, align: 'left', width: '200px' },
                            { name: $.published, align: 'left', width: '200px' },
                            { name: $.unpublished, align: 'left', width: '200px' },
                            { name: $.creationDate, align: 'left', width: '200px' },
                            { name: $.dateOfEdit, align: 'left', width: '200px' },
                            { name: $.organization, align: 'left', width: '200px' },
                            { name: $.actions, align: 'center', width: '200px' },
                        ]}
                        rowCells={[
                            { type: 'text', align: 'left', contentKey: 'name', onClick: 'link' },
                            { type: 'text', align: 'left', contentKey: 'modelsCount' },
                            { type: 'text', align: 'left', contentKey: 'publishedModelsCount' },
                            { type: 'text', align: 'left', contentKey: 'notPublishedModelsCount' },
                            { type: 'date', align: 'left', contentKey: 'createdAt', color: '#8CC63F' },
                            { type: 'date', align: 'left', contentKey: 'updatedAt', color: '#FF931E' },
                            { type: 'text', align: 'left', contentKey: 'organization' },
                            { type: 'actions', align: 'center' },
                        ]}
                    />
                    :
                    <ArTable
                        historyState={'no-update'}
                        basePath='/search'
                        editPath='/category'
                        search='categoryGlobalSearch'
                        searchContent={stateGlobalSearch.searchContent}
                        addBtnText="No btn"
                        // parentId={props.options.parentId}
                        setParentId={setParentId}
                        targetList={{
                            get: arWidgetGetGlobalSearch,
                            delete: arWidgetDeleteCategory,
                        }}
                        pageSize={currentPageSize}
                        tableHeadings={[
                            { name: $.organizationName, align: 'center', width: '200px', },
                            { name: $.models, align: 'center', width: '200px' },
                            { name: $.published, align: 'center', width: '200px' },
                            { name: $.unpublished, align: 'center', width: '200px' },
                            { name: $.creationDate, align: 'center', width: '200px' },
                            { name: $.dateOfEdit, align: 'center', width: '200px' },
                            { name: $.actions, align: 'center', width: '200px' },
                        ]}
                        rowCells={[
                            { type: 'text', align: 'center', contentKey: 'name', onClick: 'link' },
                            { type: 'text', align: 'center', contentKey: 'modelsCount' },
                            { type: 'text', align: 'center', contentKey: 'publishedModelsCount', color: '#8CC63F' },
                            { type: 'text', align: 'center', contentKey: 'notPublishedModelsCount', color: '#FF931E' },
                            { type: 'date', align: 'center', contentKey: 'createdAt' },
                            { type: 'date', align: 'center', contentKey: 'updatedAt' },
                            { type: 'actions', align: 'center' },
                        ]}
                    />}
            </div>
            <div className={classes.categoryContainer}>
                <div className={classes.title}>{$.products}</div>
                {currentUser.roleName === 'admin' ?
                    <ArTable
                        historyState={'no-update'}
                        basePath={'/search'}
                        editPath='/product'
                        search='productGlobalSearch'
                        searchContent={stateGlobalSearch.searchContent}
                        addBtnText="No btn"
                        targetList={{ get: arWidgetGetGlobalSearch, delete: arWidgetDeleteProduct }}
                        pageSize={currentPageSize}
                        organizationId={orgId}
                        // parentParams={[{ paramName: 'category', paramValue: parentId }]}
                        setParentId={setParentId}
                        tableHeadings={[
                            { name: $.image, align: 'left', width: '250px' },
                            { name: $.productName, align: 'left', width: '290px' },
                            { name: $. model, align: 'center', width: '250px' },
                            { name: $.organization, align: 'center', width: '250px' },
                            { name: $.actions, align: 'center', width: '250px' },
                        ]}
                        rowCells={[
                            { type: 'image', align: 'left', contentKey: 'images' },
                            { type: 'text', align: 'left', contentKey: 'name', onClick: 'panel' },
                            {
                                type: 'badge',
                                align: 'center',
                                contentKey: 'hasModel',
                                truthy: { text: $.yes, cls: 'active' },
                                falsy: { text: $.no, cls: 'inactive' },
                            },
                            { type: 'text', align: 'center', contentKey: 'organization' },
                            { type: 'actions', align: 'center' },
                        ]}
                    // filters={categoryFiltersAd}
                    />
                    :
                    <ArTable
                        historyState={'no-update'}
                        basePath={'/search'}
                        editPath='/product'
                        search='productGlobalSearch'
                        searchContent={stateGlobalSearch.searchContent}
                        addBtnText="No btn"
                        targetList={{ get: arWidgetGetGlobalSearch, delete: arWidgetDeleteProduct }}
                        pageSize={currentPageSize}
                        organizationId={orgId}
                        // parentParams={[{ paramName: 'category', paramValue: parentId }]}
                        setParentId={setParentId}
                        tableHeadings={[
                            { name: $.image, align: 'left', width: '250px' },
                            { name: $.productName, align: 'left', width: '290px' },
                            { name: $. model, align: 'center', width: '250px' },
                            { name: $.actions, align: 'center', width: '250px' },
                        ]}
                        rowCells={[
                            { type: 'image', align: 'left', contentKey: 'images' },
                            { type: 'text', align: 'left', contentKey: 'name', onClick: 'panel' },
                            {
                                type: 'badge',
                                align: 'center',
                                contentKey: 'hasModel',
                                truthy: { text: $.yes, cls: 'active' },
                                falsy: { text: $.no, cls: 'inactive' },
                            },
                            { type: 'actions', align: 'center' },
                        ]}
                    />
                }
            </div>
        </>
    )
}

export default Search
