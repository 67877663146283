import {makeStyles} from '@material-ui/core/styles';
import {arColors} from '../../../../Assets/Assets';

export const ModelPackageStyles = makeStyles({
  accordion: {
    width: '100%',
  },
  accordionDetails: {
    flexWrap: 'wrap',
    '@media(max-width:380px)': {
      padding: '5px 0 10px 0',

    }

    // "& > input": {
    //   fontSize: "0.1rem",
    // },
  },
  accordionDetailsDisabled: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.005)',
      zIndex: 10,
    },
    '@media(max-width:380px)': {
      padding: '5px 0 10px 0',

    }

  },
  accordionSummary: {
    backgroundColor: arColors.grayLight,
    textTransform: 'uppercase',
    // padding: "0.2rem",

    '& p': {
      fontWeight: '700',
      fontSize: '14px',
    },
  },

  fileInputWrapper: {
    display: 'flex',
    '@media(max-width: 1280px)': {
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    },
    // '@media(max-width: 680px)': {
    //   flexDirection: 'column'
    // },
    '@media(max-width: 820px)': {
      flexWrap: 'wrap'
    },
    '@media(max-width: 680px)': {
      flexDirection: 'column'
    },
    // justifyContent: "space-around",
  },

  textFullWidth: {
    width: '100%',
    '& > label': {
      // fontSize: "0.9rem",
    },
  },

  packageTextWrapper: {
    width: '99%',
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  subTitle: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    paddingTop: '2rem',
    // paddingLeft: "0.3rem",
    marginBottom: '0.5rem',
  },
  button: {
    marginTop: '1rem',
    width: '100%',
    color: 'white',
    textTransform: 'uppercase',
    fontSize: '9px',
    maxWidth: '130px',
  },
  successButton: {
    backgroundColor: arColors.blue,
    "&:hover , &:active": {
      backgroundColor: arColors.blue,
    },
  },
  defaultButton: {
    backgroundColor: arColors.grayDark,
    "&:hover , &:active": {
      backgroundColor: '#f7593e',
    },
  },

  editButton: {
    backgroundColor: arColors.greenDark,
    "&:hover , &:active": {
      backgroundColor: arColors.greenDark,
    },
  },
  loading: {
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      background: 'rgba(255, 255, 255, 0.9)',
      zIndex: 19,
    }
  },
  progressBar: {
    width: '50%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 29
  },
  btnWrapper: {
    textAlign: 'end'
  }
});
