export const currentUser = JSON.parse(localStorage.getItem('currentUser'));

//тут будет константы для хлебных крошек

// главные страницы

export const initialBreadCrumbs = { name: 'homePageTitle', href: '/', depth: 0 }
export const categoryBreadCrumbs = { name: 'categories', href: '/category', depth: 1 }
export const widgetBreadCrumbs = { name: 'widgets', href: '/widget', depth: 1}
export const informationBreadCrumbs = { name: 'information', href: '/information', depth: 1}
export const usersBreadCrumbs = { name: 'users', href: '/user', depth: 1}
export const organizationBreadCrumbs = { name: 'organizations', href: '/organization', depth: 1}
export const technicalSupportBreadCrumbs = { name: 'support', href: '/support', depth: 1}
export const settingsBreadCrumbs = { name: 'settings', href: '/settings', depth: 1}
export const applicationsBreadCrumbs = { name: 'application', href: '/applications', depth: 1 }
export const productsBreadCrumbs = { name: 'products', href: '/product', depth: 2 }
export const configurationBreadCrumbs = { name: 'configurations', href: '/configuration', depth: 1}
export const personalAreaBreadCrumbs = { name: 'personal', href: '/personal', depth: 1 }
export const globalSearchAreaBreadCrumbs = { name: 'globalSearch', href: '/search', depth: 1 }
export const tariffsBreadCrumbs = { name: 'tariffs', href: '/tariffs', depth: 1 }

// страницы редактирования и создания

export const editPagesBreadCrumbs = (name, href, depth) => ({name: name, href: href, depth: depth})


// валидация

// email
export const isEmailValid = (email) => {
    const regexEmail = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    return regexEmail.test(email)
}

// name
export const isNameValid = (name) => {
    const regexName = new RegExp(/^([а-яё\s]+|[a-z\s]+)$/iu)
    return regexName.test(name)
}

//phoneNumber
export const isPhoneNumberValid = (phoneNumber) => {
    const regexNumber = new RegExp('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$');
    return regexNumber.test(phoneNumber)
}
