import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  Switch,
  useMediaQuery
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import AddIcon from '@material-ui/icons/Add';
import BackspaceIcon from '@material-ui/icons/Backspace';
import SearchIcon from '@material-ui/icons/Search';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { stringify } from 'query-string';
import { ArCopyIcon, ArDeleteIcon, ArDownloadIcon, ArEditIcon, NoImage } from '../../../Assets/Assets';
import ActiveBadge from '../../Molecules/ActiveBadge/ActiveBadge';
import { rusRoles } from '../../Localization/Roles/Roles';
import classes from './ArTable.module.css';
import { ProductPanel } from '../../Tables/Products/ProductPanel/ProductPanel';
import ClearIcon from '@material-ui/icons/Clear';
import { arWidgetPutWidget } from '../../Api/Utils/Utils';
import Toast from '../../Toast/Toast';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SecureLS from 'secure-ls';
import { usePermissions, useGetPermissions } from 'react-admin';
import { arWidgetGetRoles } from '../../Api/Utils/Utils';
import { langManager } from '../../../utils/languageManager/manager';
import ClipboardJS from 'clipboard'
import AutoComplete from '../../Molecules/AutoComplete/AutoComplete';
import FilterListIcon from '@material-ui/icons/FilterList';

const tablePaginationClasses = {
  actions: 'actions',
  selectRoot: 'selectRoot',
  caption: 'caption',
  toolbar: 'toolbar'
}

const useStyles = makeStyles({
  pagination: {
    marginTop: '0.7rem',
    paddingRight: '6%',
    color: '#000 !important',
    '.MuiIconButton-label': {
      color: '#000 !important'
    },
    '.MuiTablePagination-selectIcon': {
      color: '#000 !important'
    },
  },
  '@media (max-width: 1110px)': {
    pagination: {
      marginTop: 0
    }
  },
  '@media (max-width: 750px)': {
    pagination: {
      '& .toolbar': {
        display: 'flex',
        flexWrap: 'wrap',
        paddingLeft: '5px !important',

      }
    }
  },
  '@media (max-width: 470px)': {
    pagination: {
      marginBottom: '15px',
      '& .MuiToolbar-root': {
        flexWrap: 'wrap !important'
      },
      '& .MuiTypography-body2': {
        fontSize: '12px !important'
      },
      '& .actions': {
        marginLeft: '5px !important'
      },
      '& .actions .MuiIconButton-root': {
        padding: '2px'
      },
      '& .selectRoot': {
        marginLeft: '5px',
        marginRight: '5px'
      },

    }
  },
  icon: {
    color: '#000 !important'
  },
  btnArTable: {
    minWidth: '0 !important'
  },
  switchNoChecked: {
    width: '69px',
    overflow: 'visible',
    '& .MuiSwitch-thumb': {
      backgroundColor: '#fff !important',
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      margin: '5px 5px'
    },
    '& .MuiSwitch-colorPrimary.Mui-checked': {
      color: '#8CC63F'
    },
    '& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#8CC63F',
      opacity: '1'
    },
    '& .MuiSwitch-track': {
      backgroundColor: '#FF931E',
      opacity: '1',
      borderRadius: '17px',
      width: '50px',
      height: '24px',

    }
  },
  switchChecked: {
    width: '69px',
    overflow: 'visible',
    '& .MuiSwitch-thumb': {
      backgroundColor: '#fff !important',
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      margin: '5px 5px'
    },
    '& .MuiSwitch-colorPrimary.Mui-checked': {
      color: '#8CC63F'
    },
    '& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#8CC63F',
      opacity: '1'
    },
    '& .MuiSwitch-track': {
      backgroundColor: '#FF931E',
      opacity: '1',
      borderRadius: '17px',
      width: '50px',
      height: '24px',
    }
  }

})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const ArTable = ({
  child,
  basePath,
  pageSize,
  organizationId,
  addBtnText,
  targetList,
  tableHeadings,
  rowCells,
  filters,
  setParentId,
  parentParams,
  hideQueryParams,
  dashboardNoTable,
  color,
  historyState,
  search,
  searchContent,
  editPath,
  sortParameters = '',
  ...props
}) => {
  const isSmall = useMediaQuery('(min-width:1110px)');
  const history = useHistory();
  const location = useLocation();
  const queryString = require('query-string');
  const ls = new SecureLS({ encodingType: 'aes' });
  const currentUser = JSON.parse(localStorage.getItem('currentUser'))

  /*  const parsed = queryString.parse(location.search);*/

  const [currentLocation, setCurrentLocation] = useState({});
  const [status, setStatus] = useState();
  const [searchFlag, setSearchFlag] = useState(false);
  const [categoryFilters, setCategoryFilters] = useState({
    valuesFilter1: { filterId: null, filterValue: null },
    valuesFilter2: { filterId: null, filterValue: null },
    valuesFilter3: { filterId: null, filterValue: null },
    valuesFilter4: { filterId: null, filterValue: null },
  });

  const styles = useStyles()
  const currentPageSize = sessionStorage.getItem('pageSize') ?? (pageSize ?? 10);

  let initialQueryParams = {
    page: 1,
    pageSize: currentPageSize,
    organizationId: organizationId,
    // category: parentId,
  };

  if (child) {
    parentParams.map((value, index) => {
      initialQueryParams[value.paramName] = value.paramValue;
    });
  } else {
    setParentId(null);
  }
  if (filters) {
    for (const filter of filters) {
      if (filter.initialValue !== '' || filter.initialValue !== undefined) {
        initialQueryParams[filter.name] = filter.initialValue;
      }
    }
  }
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('error');
  const [queryParams, setQueryParams] = useState(initialQueryParams);
  const [items, setItems] = useState([]);
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(currentPageSize);
  const [rowOpen, setRowOpen] = useState(false);
  const [row, setRow] = useState({});
  const [filterQuery, setFilterQuery] = useState([]);
  const [activeBtn, setActiveBtn] = useState("img-product")
  const prevRowRef = useRef();
  const prevLocation = useRef();

  const getDataAttr = nodeId => {
    let tempId = document.querySelector('#' + nodeId);
    if (tempId) {
      tempId = parseInt(tempId.parentElement.parentElement.dataset.postid);
      return tempId;
    }
  };

  //PAGINATION FUNCTIONS

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setQueryParams({ ...queryParams, page: ++newPage });
    setSearchFlag(!searchFlag);

  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(+event.target.value);
    setQueryParams({ ...queryParams, pageSize: +event.target.value, page: 1 });
    sessionStorage.setItem('pageSize', (+event.target.value));
    setSearchFlag(!searchFlag);

  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, items.length - page * rowsPerPage);

  const handleClick = (onClick, row) => {
    switch (onClick) {
      case 'panel':
        if (row.id === prevRowRef.current && rowOpen) {
          setRowOpen(false);
        } else if (row.id !== prevRowRef.current && rowOpen) {
          setRowOpen(true);
          setActiveBtn('img-product')
        } else {
          setRowOpen(!rowOpen);
        }
        setRow(row);
        break;
      case 'link':
        history.push('/product')
        setParentId(row.id);
    }
  };


  window.addEventListener('click', e => {
    if (window.innerWidth < 1110 && rowOpen && !e.target.closest('[data-link="link"]') && !e.target.closest('#productPanelContainer')) {
      setRowOpen(false)
    }
  })

  //PAGINATION FUNCTIONS END

  const reduceSearchStr = () => {
    let tempQueryParams = { ...queryParams, content: searchContent };

    if (hideQueryParams) {
      return '';
    }
    delete tempQueryParams.pageSize;
    delete tempQueryParams.organization;

    if (tempQueryParams.page === 1) {
      delete tempQueryParams.page;
    }

    for (let key in tempQueryParams) {
      if (tempQueryParams[key] === '' || tempQueryParams[key] === 'default') {
        delete tempQueryParams[key];
      }
    }
    return stringify(tempQueryParams);
  };

  const updateHistory = () => {
    const stateHistory = {
      ...history.location.state,
      searchContent: searchContent
    }
    if (child) {


      history.replace({
        pathname: basePath,
        search: `?${reduceSearchStr()}`,
        state: stateHistory
      });
    } else {
      history.push({
        pathname: basePath,
        search: `?${reduceSearchStr()}`,
        state: stateHistory
      });
    }
  };

  const handleInput = (e, name) => {
    setQueryParams({ ...queryParams, [name]: e.target.value });
  };

  const handleChangeAutoComplete = (event, value, name) => {
    setValueAutoComplete(value)
    if (value) {
      setQueryParams({ ...queryParams, [name]: +value.id });
    }
  }

  const handleEnter = (e) => {
    if (e.keyCode === 13) {
      handleSearch();
    }
  };

  const addFilterQuery = (filterName, filterId, filterValue) => {
    setCategoryFilters(categoryFilters => ({ ...categoryFilters, [filterName]: { filterId, filterValue } }));
  };

  const handleInputClear = (e, id, name) => {
    document.querySelector(`#${id}`).value = '';
    setQueryParams({ ...queryParams, [name]: e.target.value });
  };

  const handleSelect = (e, name) => {
    let val = e.target.value;
    if (val === 'default') {
      let tempQueryParams = { ...queryParams };
      delete tempQueryParams[name];
      setQueryParams({ ...tempQueryParams });
    } else {
      setQueryParams({ ...queryParams, [name]: e.target.value });
    }
  };

  const [widgetItem, setWidgetItem] = useState({});

  const getItems = async () => {

    if (!targetList.get) {
      await setLoading(false);
    } else {
      if (search && search === 'categoryGlobalSearch') {
        const params = {
          ...queryParams,
          content: searchContent,
          organizationId: currentUser.organization.id
        }
        setQueryParams({ ...queryParams, content: searchContent, organizationId: currentUser.organization.id })
        await targetList
          .get({ params: params })
          .then((response) => {
            setItems(response.data.items.categories);
            setInfo(response.data.items.categoriesInfo);
          })
          .catch((error) => {
            console.log('error: ', error);
            // AuthProvider.checkError(error);
          })
          .finally(
            setLoading(false),
          );
      } else if (search && search === 'productGlobalSearch') {
        const params = {
          ...queryParams,
          content: searchContent,
          organizationId: currentUser.organization.id
        }
        setQueryParams({ ...queryParams, content: searchContent, organizationId: currentUser.organization.id })
        await targetList
          .get({ params: params })
          .then((response) => {
            setItems(response.data.items.products);
            setInfo(response.data.items.productsInfo);
          })
          .catch((error) => {
            console.log('error: ', error);
            // AuthProvider.checkError(error);
          })
          .finally(
            setLoading(false),
          );
      } else {
        let tempQueryParams = { ...queryParams };
        for (let i in tempQueryParams) {
          if (tempQueryParams[i] === 'default') {
            delete tempQueryParams[i]
          }
        }
        setQueryParams({ ...tempQueryParams });
        await targetList
          .get({ params: tempQueryParams })
          .then((response) => {
            setItems(response.data.items);
            setInfo(response.data.info);
          })
          .catch((error) => {
            console.log('error: ', error);
            // AuthProvider.checkError(error);
          })
          .finally(
            setLoading(false),
          );
      }

    }
  };

  const handleSearch = () => {

    queryParams.page = 1;
    let tempQueryParams = { ...queryParams };
    for (let filter in categoryFilters) {
      if (categoryFilters[filter].filterId) {
        tempQueryParams[filter] = categoryFilters[filter].filterId;
      }
    }
    setQueryParams(tempQueryParams);
    setSearchFlag(!searchFlag);
    // console.log(tempQueryParams);
  };


  const [valueAutoComplete, setValueAutoComplete] = useState('')
  const [isClearAutoComplete, setIsClearAutoComplete] = useState(false)

  const handleClearAll = async () => {
    const tempQueryParams = { ...queryParams };
    tempQueryParams.page = 1;
    // setQueryParams({...queryParams, page: 1});

    for (let filter in filters) {
      const tempName = filters[filter].name;
      const tempId = tempName + 'Filter';
      if (filters[filter].type === 'input') {
        document.querySelector(`#${tempId}`).value = '';
      } else if (filters[filter].type === 'select') {
       document.querySelector(`#${tempId}`).innerText = filters[filter].options[0].text;
      } else if (filters[filter].type === 'selectSearch') {
        setCategoryFilters({
          valuesFilter1: { filterId: null, filterValue: null },
          valuesFilter2: { filterId: null, filterValue: null },
          valuesFilter3: { filterId: null, filterValue: null },
          valuesFilter4: { filterId: null, filterValue: null },
        });
      } else if (filters[filter].type === 'autocomplete') {
        setIsClearAutoComplete(true)
      }
      delete tempQueryParams[tempName];
    }
    setQueryParams(tempQueryParams);
    setSearchFlag(!searchFlag);
  };

  // Action buttons

  const EditButton = ({ basePath, row }) => {

    if (editPath) {
      return (
        <Button component={Link} to={{ pathname: `${editPath}/${row.id}` }}>
          <ArEditIcon />
        </Button>
      )
    }
    else {
      return (
        <Button component={Link} to={{ pathname: `${basePath}/${row.id}` }} className={styles.btnArTable}>
          <ArEditIcon />
        </Button>
      )
    }
  };

  const handleClickInfoBtn = (id, path) => {
    props.getId(id)
    ls.set('orgId', id)
    history.push(`${path}/${id}`)
  }

  const InfoButton = ({ id, path }) => {
    return (
      <Button onClick={(e) => handleClickInfoBtn(id, path)} className={styles.btnArTable}>
        <SendOutlinedIcon className={classes.sendIcon} />
      </Button>
    );
  };

  //component={Link} to={{ pathname: `${path}/${id}` }}


  const DownLoadButton = ({ basePath, row }) => {
    const params = {
      type: 100
    }
    return (
      <Button className={styles.btnArTable}
        onClick={() => {
          setDisabled(true);
          targetList.download(row.id, params)
            .then(response => {
              return response.data.items;
            })
            .then((response) => {

              let link = document.createElement('a');
              // link.download = 'download'
              // Construct the URI
              link.href = response;
              document.body.appendChild(link);
              link.click();
              // Cleanup the DOM
              document.body.removeChild(link);

            }).catch(e => {
              setErrorMessage(e);
              setError(true);
            })
            .finally(() => setDisabled(false));
        }}
      >
        <ArDownloadIcon />
      </Button>
    )
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [itemRow, setItemRow] = useState(null);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const removeRow = (row) => {
    setDisabled(true);
    targetList
      .delete(row.id)
      .then((data) => {
        if (data.status === 200) {
          // saves clicked ID of product
          const itemToDelete = row.id;
          // finds clicked product in the current array
          const arrIdToDelete = items.findIndex((row) => row.id === itemToDelete);
          // copies state array to make it editable
          const itemsUpdated = [...items];
          //removes found element
          itemsUpdated.splice(arrIdToDelete, 1);
          if (items.length > 3) {
            setItems(itemsUpdated);
          } else {
            // getItems();
            handleSearch();
          }
        }
      })
      .catch(e => {
        setErrorMessage(e);
        setError(true);
      })
      .finally(() => {
        setDisabled(false);
      });

  };


  const handleCloseDialogDisagree = () => {
    setOpenDialog(false);

  };
  const handleCloseDialogAgree = () => {
    setOpenDialog(false);
    removeRow(itemRow);
  };

  const DeleteButton = ({ row }) => (
    <Button className={styles.btnArTable}
      disabled={disabled}
      onClick={async (e) => {
        setItemRow(row);
        handleClickOpenDialog();
      }}
    >
      <ArDeleteIcon />
    </Button>
  );

  const CopyButton = ({ basePath, row }) => (
    <Button className={styles.btnArTable}
      onClick={e => history.push({
        pathname: `${basePath}/${row.id}`,
        state: { copy: true }
      })}
    >
      <ArCopyIcon color="#808080" />
    </Button>
  )

  const [check, setCheck] = useState({
    a: true,
    b: false,
  });


  const [checked, setChecked] = useState(false);

  const handleChange = async (event, isActive, id, params) => {
    let nam = event.target.name;
    let val = event.target.checked;
    params.isActive = val;

    setCheck({ ...check, [nam]: val });

    await arWidgetPutWidget(id, params)
      .then(res => {
        setChecked(true);
      })
      .catch(err => {
        setError(true)
        setErrorMessage(err.data.message)
      });

    setSearchFlag(prev => !prev)

  }

  const handleCopy = (e) => {
    document.querySelectorAll('.copyCol').forEach(item => {
      item.classList.remove('copyCol');
      item.style.color = '#808080';
    });
    e.classList.add('copyCol');
    e.style.color = '#29ABE2';
  };

  const handleChangeDate = (e, name) => {
    setQueryParams({ ...queryParams, [name]: e.target.value })
  }



  const drawCell = (cell, row) => {

    switch (cell.type) {
      case 'text':
        let cellText = '';
        if (cell.locale === 'ru') {
          cellText = rusRoles[row[cell.contentKey]];
        } else {
          if (cell.contentKey === 'organization') {
            cellText = row.organization.name
          } else {
            cellText = row[cell.contentKey];
          }

        }
        return cellText
      case 'date':
        let timeStamp = new Date(row[cell.contentKey]);
        const addZero = (datePart) => datePart.toString().padStart(2, '0');
        let dateValue = `${addZero(timeStamp.getDate())}.${addZero(timeStamp.getMonth() + 1)}.${timeStamp.getFullYear()}`;
        let timeValue = `${addZero(timeStamp.getHours())}:${addZero(timeStamp.getMinutes())}`;

        return dateValue + ' ' + timeValue;
      case 'image':
        let image;
        if ((!Array.isArray(row.images) && !row.imageModel) || (Array.isArray(row.images) && !row.images[0])) {
          image = <NoImage className={isSmall ? classes.image : classes.imageSmall} />;
        } else if (Array.isArray(row.images) && row.images[0]) {
          image = (
            <img className={isSmall ? classes.image : classes.imageSmall} src={row.images[row.images.length - 1]} alt={`изображение ${row.name}`} />
          );
        } else if (typeof row.imageModel === 'string') {
          image = <img className={isSmall ? classes.image : classes.imageSmall} src={row.imageModel} alt={`изображение ${row.name}`} />;
        }
        return image;
      case 'badge':
        return row[cell.contentKey] ? (
          <ActiveBadge txt={cell.truthy.text} cls={cell.truthy.cls} />
        ) : (
          <ActiveBadge txt={cell.falsy.text} cls={cell.falsy.cls} />
        );
      case 'actions':
        return (
          <>
            <EditButton basePath={basePath} row={row} />
            <DeleteButton basePath={basePath} row={row} />
          </>
        );
      case 'actionsOrg':
        return (
          <>
            <InfoButton id={row.id} path={cell.cellPath} />
            <EditButton basePath={basePath} row={row} />
            <DeleteButton basePath={basePath} row={row} />
          </>
        );
      case 'archiveActions':
        return (
          <div className={classes.archiveActionsButtons}>
            <DownLoadButton basePath={basePath} row={row} />
            <DeleteButton basePath={basePath} row={row} />
          </div>
        );
      case 'icon':
        let clipboard = new ClipboardJS('[data-clipboard-attr="btnCopy"]');
        clipboard.on('success', function (e) {
          handleCopy(e.trigger)
        })
        return (
          <button className={`${classes.copyCol} copyBtn`}
            data-clipboard-attr='btnCopy'
            data-clipboard-text={`<script id='AR-widget-alheemea' src='${process.env.REACT_APP_WIDGET_URL}?token=${row.token}'></script>`}
          >
            <ArCopyIcon color="#808080" style={{ pointerEvents: 'none' }} />
            <p className={classes.iconText} style={{ pointerEvents: 'none' }}>Копировать</p>
          </button>
        );
      case 'status':
        const params = ({
          uri: row.uri,
          container: row.container,
          buttonClass: row.buttonClass,
          organizationId: row.organizationId,
          versionId: row.versionId,
          buttonId: row.buttonId,
          isActive: row.isActive,
          editToken: true,
        });
        return (
          <div>
            {checked ?
              <Switch
                checked={row.isActive}
                onChange={(e) => {
                  handleChange(e, row.isActive, row.id, params);
                }}
                color='primary'
                name={row.createdAt.toString()}
                inputProps={{ 'aria-label': 'primary checkbox', 'role': 'checkbox' }}
                key={row.createdAt}
                className={row.isActive ? styles.switchChecked : styles.switchNoChecked}
              />
              :
              <>
                <Switch
                  checked={row.isActive}
                  onChange={(e) => {
                    handleChange(e, row.isActive, row.id, params);
                  }}
                  color='primary'
                  name={row.createdAt.toString()}
                  inputProps={{ 'aria-label': 'primary checkbox', 'role': 'checkbox' }}
                  key={row.createdAt}
                  className={row.isActive ? styles.switchChecked : styles.switchNoChecked}
                />
              </>
            }
          </div>
        )
      case 'editAction':
        return (
          <>
            <EditButton basePath={basePath} row={row} />
          </>
        );
      case 'downloadAction':
        return (
          <>
            <DownLoadButton basePath={basePath} row={row} />
          </>
        );
      case 'filterOptions':
        if (cell.datas.length > 0) {
          for (let i = 0; i < cell.datas.length; i++) {
            if (cell.datas[i].value === row[cell.dataFor]) {
              return <p key={(row.id).toString() + (Math.random() * 100).toString()}>{cell.datas[i].text}</p>
            }
          }
        }
      case 'actionsWithCopy':
        return (
          <>
            <EditButton basePath={basePath} row={row} />
            <CopyButton basePath={basePath} row={row} />
            <DeleteButton basePath={basePath} row={row} />
          </>
        )
      default:
        return null;
    }
  };

  const drawFilter = (filter, index) => {
    switch (filter.type) {
      case 'input':
        return (
          <input
            key={`filter-${index}`}
            id={`${filter.name}Filter`}
            className={classes.inputFilter}
            placeholder={`${filter.placeholder}`}
            onChange={(e) => handleInput(e, filter.name)}
            onKeyDown={handleEnter}
            // endAdornment={
            //   <InputAdornment position='end'>
            //     <IconButton onClick={(e) => handleInputClear(e, `${filter.name}Filter`, filter.name)}>
            //       <BackspaceIcon />
            //     </IconButton>
            //   </InputAdornment>
            // }
          />
        );
      case 'select':
        if (filter.options.length < 1) {
          return null
        }
        return (
          <div className={classes.formBox}>
            <FormControl className={classes.formControl}>
              <InputLabel id={`${filter.name}LabelId`} className={classes.labelSelect}
                placeholder={filter.placeholder} />
              <Select
                id={`${filter.name}Filter`}
                name={`${filter.name}Name`}
                defaultValue={filter.initialValue}
                // value={
                //   queryParams[filter.name]
                //   // === "default"
                //   //   ? "default"
                //   //   : queryParams[filter.name]
                // }
                onChange={(e) => handleSelect(e, filter.name)}
                className={`${classes.inputFilter} ${classes.inputFilterSelect}`}
                labelId={`${filter.name}LabelId`}
              >
                {/*<MenuItem value={999999999} >*/}
                {/*  {filter.placeholder}*/}
                {/*</MenuItem>*/}

                {filter.options.map((option) => {
                  return (
                    <MenuItem key={option.value + Math.random().toFixed(6)} value={option.value} className={classes.inputFilterSelectOptions}>
                      {option.text}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        );
      case 'autocomplete':
        return (
          <div className={classes.arAutoComplete}>
            <AutoComplete
              name={filter.name}
              parentValue={queryParams}
              setParentValue={e => setQueryParams(e)}
              isClear={isClearAutoComplete}
              setIsClear={e => setIsClearAutoComplete(e)}
              width={200}
            />
          </div>
        )
      case 'date':
        return (
          <Input
            type="date"
            id="createdAt"
            name="createdAt"
            className={`${classes.inputDate} ${classes.inputFilter}`}
            value={queryParams.createdAt}
            onChange={e => handleChangeDate(e, filter.name)}
          />
        )

      default:
        return null;
    }
  }

  const [hideShowFiltersBtnData, setHideShowFiltersBtnData] = useState('hide')

  // Перключение языка
  const [$, setArTableLangManager] = useState(langManager.$.components.arTable)
  langManager.subscribe(($) => {
    setArTableLangManager($.components.arTable)
  })


  const handleHideShowFilters = (e) => {
    if (e.target.dataset.show === 'hide') {
      setHideShowFiltersBtnData('show')

    } else {
      setHideShowFiltersBtnData('hide')
    }
  }

  useEffect(() => {
    return prevRowRef.current = row.id;
  }, [row]);

  useEffect(() => {
    getItems();
    updateHistory();
  }, [searchFlag, searchContent]);


  if (loading) {
    return <div>Loading...</div>;
  }

  const SmallLayout = () => {

    return (
      <>
        {
          items.map(row => {
            return (
              <ul className={classes.smallLayoutList} key={row.id.toString() + Math.random() * 100}>
                <li className={classes.smallLayoutListItem}>
                  {tableHeadings.map((heading, index) => {
                    return (
                      <div className={classes.rowSmallLayout} key={row.id.toString() + Math.random() * 100 + `${new Date()}`}>
                        <div className={classes.headingNameTable}>
                          {heading.name}:
                        </div>
                        <div
                          className={!rowCells[index].onClick ? classes.tableCell : classes.clickable}
                          data-link="link"
                          onClick={() => handleClick(rowCells[index].onClick, { ...row })}>
                          {drawCell(rowCells[index], row)}
                        </div>
                      </div>
                    )
                  })}

                </li>
              </ul>
            )

          })
        }
      </>
    )

  }

  return (
    <>
      {!error ? <></> : <Toast error={error} message={errorMessage} />}
      <div className={classes.topPanel}>
        {!filters ? null :
          <div className={classes.filterWrapper}>
            {hideShowFiltersBtnData !== 'hide' ? <></> : filters.map((filter, index) => drawFilter(filter, index))}
            <div style={hideShowFiltersBtnData !== 'hide' ? { display: 'none' } : { display: 'block' }} className={classes.searchBtnWrapper}>
              <Button className={classes.filterButtons} color="primary" onClick={handleSearch}>
                <SearchIcon className={classes.filterIcon} />
              </Button>
              <Button className={classes.filterButtons} color="primary" onClick={handleClearAll}>
                <ClearIcon className={classes.filterIcon} />
              </Button>
            </div>
          </div>
        }
        <div className={classes.btnRowArTable}>
          {!filters ? <></> :
            <button
              className={classes.hideShowFiltersBtn}
              data-show={hideShowFiltersBtnData}
              onClick={handleHideShowFilters}
            >
              <FilterListIcon style={{ pointerEvents: 'none' }} />
            </button>
          }
          {!basePath ? null : addBtnText === 'No btn' ? <></> : (

            <Button
              startIcon={<AddIcon style={{ border: '1px solid #fff', borderRadius: '50%' }} />}
              component={Link}
              variant="contained"
              color="secondary"
              className={classes.addButton}
              to={{
                pathname: `${basePath}/create`,
              }}
            >
              {addBtnText}
            </Button>
          )}
        </div></div>

      <ProductPanel row={row} open={rowOpen} btnActive={activeBtn} filters={props.categoryFilters || []} setOpen={e => setRowOpen(e)} />

      {
        items.length < 1 ? <div className={classes.noItems}>{$.noData}</div> :
          info === null || info.rowCount === 0
            ? null
            : (isSmall ?
              <TableContainer component={Paper} className={classes.ArTable}>

                <Table aria-label='simple table'>

                  <TableHead className={classes.TableHead}>
                    <TableRow className={`${classes.TableRow} ${classes.tableRowHead}`}>
                      {tableHeadings.map((heading) => {
                        return (
                          <TableCell
                            //key={heading.name + Math.random().toFixed(9)}
                            align={heading.align}
                            style={{ width: heading.width }}
                            className={`${classes.tableCell} ${classes.tableCellHead}`}
                          >
                            {heading.name}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {items.map((row) => {
                      return (
                        <TableRow key={row.id + Math.random() * 10} className={classes.TableRow}>
                          {rowCells.map((cell, idx) => {
                            return (
                              <TableCell
                                //key={row.id}
                                align={cell.align}
                                onClick={() => handleClick(cell.onClick, { ...row })}
                                className={!cell.onClick ? classes.tableCell : classes.clickable}
                                style={cell.color ? { color: cell.color, fontWeight: 'bold' } : { color: '#4D4D4D' }, {maxWidth: tableHeadings[idx].width}}>
                                {drawCell(cell, row)}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              : <div className={classes.smallLayoutContainer}>
                <SmallLayout />
              </div>
            )
      }
      {items.length < 1 ? <></> :
        info === null || info.totalPages < 1 ? null : (
          <TablePagination
            className={styles.pagination} classes={tablePaginationClasses}
            rowsPerPageOptions={[5, 10, 25, 100]}
            component='div'
            count={info.rowCount}
            rowsPerPage={+currentPageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={$.pagination}
            style={{ color: '#000 !important' }}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
          />
        )}

      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialogAgree}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title'>{'Удаление'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            Операцию удаления невозможно отменить. Подтвердите действие
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogDisagree} color='primary'>
            Отмена
          </Button>
          <Button onClick={handleCloseDialogAgree} color='primary'>
            Подтвердить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ArTable;
