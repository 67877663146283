import React, {useEffect, useMemo, useState} from 'react';
import {AxisBottom, AxisLeft} from '@visx/axis';
import {Bar} from '@visx/shape';
import {Group} from '@visx/group';
import {scaleBand, scaleLinear} from '@visx/scale';
import styled from "styled-components"
import {toast} from 'react-toastify';

const ChartContainer = styled.div`
  width: 100%;
  text {
    font-family: "Untitled Sans", sans-serif;
  }

  .visx-axis-tick {
    max-width: 100px;
    overflow: hidden;
    text {
      max-width: 100px;
      overflow: hidden;
      width: 100%;
      font-size: 10px;
      font-weight: 400;
      fill: #666666;
      position: relative;
      & > * {
        width: 100px;
        text-overflow: ellipsis;
        position: absolute;
        text-length: 100px;
        // transform: rotate(90deg);
        // text-anchor: start;
      }
    }
    svg {
      // transform: rotate(90deg);
      -webkit-writing-mode: vertical-rl; 
      writing-mode:vertical-rl;
    }
  }
`;

const ColoredSquare = styled.div`
width: 100%;
height: 100%;
  display: inline-block;
  width: 11px;
  height: 11px;
  margin-right: 8px;
  background: ${({ color }) => color};
  border-radius: 4px;
`;

const TooltipContainer = styled.div`
width: 100%;
height: 100%;
  padding: 8px 16px;
  font-size: 12px;
  border-radius: 4px;
  color: #222222;

  .date {
    font-size: 12px;
    margin-bottom: 8px;
    color: #222222;
    font-weight: 600;
  }
  .value {
    display: flex;
    align-items: center;
    font-weight: 400;
    color: #000000;
  }
`;

// const data = letterFrequency.slice(5)
const verticalMargin = 120

interface OsFrequency {
  name: string;
  count: number;
  color: string;
  id: number;
}

declare const osFrequency: OsFrequency[];

// accessors
const getOs = (d: OsFrequency) => d.name
const getOsCount = (d: OsFrequency) => d.count
const getOsX = (d: OsFrequency) => {

  if (d.name.length > 7) {
    let x = `${d.name.substring(0, 7)}..${d.id}`
    return x
  } else {
    return d.name
  }

}

type Props = {
  width: number
  height: number
  events?: boolean,
  data: []
}

const margins = {
  left: 30,
}



export function Chart({ width, height, data, events = true }: Props) {

  const [topTen, setTopten] = useState<any>([])

  const customId = "custom-id-yes";

  const notify = (message: OsFrequency) => {
    toast.info(message.name, {
      toastId: customId
    });
  }

  useEffect(() => {
    setTopten(data)
  }, [data])

  // bounds

  const xMax = width - margins.left
  const yMax = height - verticalMargin

  // scales, memoize for performance
  const xScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, xMax],
        round: true,
        domain: topTen.map(getOs),
        padding: 0.4,
      }),
    [xMax, topTen],
  )
  const xYScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, xMax],
        round: true,
        domain: topTen.map(getOsX),
        padding: 0.4,
      }),
    [xMax, topTen],
  )

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain: [0, Math.max(...topTen.map(getOsCount))],
      }),
    [yMax, topTen],
  )

  if (topTen.length < 1) {
    return <></>
  }

  return width < 10 ? null : (
    <ChartContainer>
      <svg width={width} height={height}>
        {/*<GradientTealBlue id="teal" />*/}
        <rect width={width} height={height} fill="rgba(0, 0, 0, 0)" rx={14} />
        <Group top={verticalMargin / 2} left={margins.left}>
          {topTen.length > 0 ?
            topTen.map((d: OsFrequency) => {
              const letter = getOs(d)
              const barWidth = xScale.bandwidth()
              const barHeight = yMax - (yScale(getOsCount(d)) ?? 0)
              const barX = xScale(letter)
              const barY = yMax - barHeight
              return (
                <Bar
                  key={`bar-${letter}`}
                  x={barX}
                  y={barY}
                  width={barWidth}
                  height={barHeight}
                  fill={d.color}
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    if (events) notify(d)
                  }}
                />
              )
            })
            : <></>
          }
          <AxisBottom
            numTicks={topTen.length}
            top={yMax}
            scale={xYScale}
            tickLabelProps={() => ({
              fill: '#000000',
              fontSize: 10,
              textAnchor: 'start',
              width: 100,
              y: 12
            })}
          />
          <AxisLeft
            scale={yScale.nice()}
            numTicks={10}
            top={0}
            tickLabelProps={(e) => ({
              fill: '#000000',
              fontSize: 10,
              textAnchor: 'end',
              x: -12,
              y: (yScale(e) ?? 0) + 3,
            })}
          />
        </Group>
      </svg>
    </ChartContainer>
  )
}