import React, {useEffect, useState} from 'react';
import { initialBreadCrumbs, widgetBreadCrumbs } from '../../../Helpers/helpers';
import {langManager} from '../../../utils/languageManager/manager';
import {arWidgetDeleteWidget, arWidgetGetWidgets} from '../../Api/Utils/Utils';
import ArTable from '../../Organizms/ArTable/ArTable';
import {ArBreadcrumbs} from '../../Organizms/Breadcrumbs/ArBreadcrumbs';

// import { currentUser } from '../../../Helpers/helpers';

function Widget() {

  const currentPageSize = sessionStorage.getItem('pageSize');
  const setParentId = (parentId) => localStorage.setItem('widget', parentId);
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  useEffect(() => {
    document.title = "Виджеты"
  }, [])


  //Переключение языка
  const [ $, setWidget] = useState(langManager.$.components.widget)
  langManager.subscribe(($) => {
    setWidget($.components.widget)
    document.title = $.components.widget.title
  })
  //******************


  return (
    <>
      <ArBreadcrumbs breadCrumbs={[initialBreadCrumbs, widgetBreadCrumbs]} />
      {currentUser.roleName === 'admin' ?

      <ArTable
        basePath="/widget"
        addBtnText={$.createWidget}
        // parentId={props.options.parentId}
        setParentId={setParentId}
        targetList={{
          get: arWidgetGetWidgets,
          delete: arWidgetDeleteWidget,
        }}
        pageSize={currentPageSize}
        tableHeadings={[
          { name: $.website, align: 'left', width: '200px' },
          { name: $.embedCode, align: 'left', width: '200px' },
          { name: $.organization, align: 'left', width: '200px' },
          { name: $.status, align: 'left', width: '200px' },
          { name: $.actions, align: 'center', width: '200px' },
        ]}
        rowCells={[
          { type: 'text', align: 'left', contentKey: 'uri' },
          { type: 'icon', align: 'left', contentKey: '' },
          { type: 'text', align: 'left', contentKey: 'organization' },
          { type: 'status', align: 'left', contentKey: '' },
          { type: 'actions', align: 'center' },
        ]}
        filters={[
          {
            name: 'uri',
            type: 'input',
            initialValue: '',
            placeholder: $.website,
          },
          {
            name: 'organization',
            type: 'autocomplete',
            initialValue: '',
            placeholder: $.organization,
          },
        ]}/>
      :
      <ArTable
        basePath="/widget"
        addBtnText={$.createWidget}
        // parentId={props.options.parentId}
        setParentId={setParentId}
        targetList={{
          get: arWidgetGetWidgets,
          delete: arWidgetDeleteWidget,
        }}
        pageSize={currentPageSize}
        tableHeadings={[
          { name: $.website, align: 'left', width: '200px' },
          { name: $.embedCode, align: 'left', width: '200px' },
          { name: $.status, align: 'left', width: '200px' },
          { name: $.actions, align: 'center', width: '200px' },
        ]}
        rowCells={[
          { type: 'text', align: 'left', contentKey: 'uri' },
          { type: 'icon', align: 'left', contentKey: '' },
          { type: 'status', align: 'left', contentKey: '' },
          { type: 'actions', align: 'center' },
        ]}
        filters={[
          {
            name: 'uri',
            type: 'input',
            initialValue: '',
            placeholder: $.website,
          },
        ]}/>
      }
    </>
  
  );
}

export default Widget;