function languegeManager(initLang) {
    return {
        // Словарь-объект с первым языком
        ...initLang,

        //Текущий язык
        $: Object.values(initLang)[0],

        

        add(newLang) {
        
            const key = Object.keys(newLang)[0]
            if (!(key in this)) {
                this[key] = newLang[key]
            } else {
                console.error('Этот язык уже добавлен')
            }   
        },

        subscribe(callback) {
            if (!this.subscribes) {
                this.subscribes = []
            }
            if (this.subscribes.indexOf(callback) === -1) {
                this.subscribes.push(callback)
            }
            
        },

        switch(lang) {
            if (!(lang in this)) {
                console.error(`Словарь с идентификатором ${lang} не зарегистрирован`)
            }

            this.$ = this[lang]

            if (!this.subscribes) {
                console.error('Не указано ни одной подписки');
                return
            } else {

                this.subscribes.forEach((callback) => {
                    callback(this.$)
                })
            }
        }
    }
}

export default languegeManager