import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { ModelPackageStyles } from '../ModelPackage/ModelPackageStyles';
import ArTable from '../../ArTable/ArTable';
import { arWidgetDeleteModel, arWidgetDownloadModel, arWidgetGetModelArchive, } from '../../../Api/Utils/Utils';
import { langManager } from '../../../../utils/languageManager/manager';

const ModelArchive = ({ models, id }) => {
  const classes = ModelPackageStyles();
  const [expanded, setExpanded] = useState(true);
  const currentUser = JSON.parse(localStorage.getItem('currentUser'))
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //Переключение языка
  const [$, setModelArchive] = useState(langManager.$.components.modelArchive)
  langManager.subscribe(($) => {
    setModelArchive($.components.modelArchive)
  })
  //******************

  return (
    <Accordion className={classes.accordion} expanded={expanded} onChange={handleChange('panel1')} square>
      <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
        <Typography>{$.archiveModels}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Grid
          container
          spacing={2}
          direction={'row'}
          justify={'space-between'}
          className={classes.packageTextWrapper}
        >
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ArTable
              hideQueryParams={true}
              child={true}
              parentParams={[{ paramName: 'id', paramValue: parseInt(id) }]}
              parentId={id}
              organizationId={currentUser.organization.id}
              pageSize={3}
              targetList={{
                get: arWidgetGetModelArchive,
                delete: arWidgetDeleteModel,
                download: arWidgetDownloadModel,
              }}
              tableHeadings={[
                { name: $.image, align: 'left', width: '200px' },
                { name: $.name, align: 'left', width: '200px' },
                { name: $.dateCreation, align: 'left', width: '200px' },
                { name: $.dateArchivation, align: 'left', width: '200px' },
                { name: $.actions, align: 'center', width: '200px' },
              ]}
              rowCells={[
                { type: 'image', align: 'left', contentKey: 'imageModel' },
                {
                  type: 'text',
                  align: 'left',
                  contentKey: 'packName',
                },
                {
                  type: 'date',
                  align: 'left',
                  contentKey: 'createdAt',
                },
                {
                  type: 'date',
                  align: 'left',
                  contentKey: 'archivateAt',
                },
                { type: 'archiveActions', align: 'center' },
              ]}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default ModelArchive;
