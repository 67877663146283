import React, {useState} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Grid, Link, List, ListItem} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import { arWidgetDownloadModelArchive } from "../../../Api/Utils/Utils";
import { langManager } from "../../../../utils/languageManager/manager";
import { toast } from "react-toastify";

const ProductPanelArchive = ({ product, classes }) => {

    //Переключение языка
    const [ $, setProductPanelArchive] = useState(langManager.$.components.productPanelArchive)
    langManager.subscribe(($) => {
      setProductPanelArchive($.components.productPanelArchive)
    })
    //******************

  const [expanded, setExpanded] = useState(true);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleDownload = (fileCode, newTab) => {
    // setDisabled(true);

    if(!product || !product.models || product.models.length < 0){
      const customId = 'customToastId'
      toast.error($.toastMessage, {
        toastId: customId
      })
      return false
    }

    if(!product || !product.models || !product.models[0].id){
      const customId = 'customToastId'
      toast.error($.toastMessage, {
        toastId: customId
      })
      return false
    }

    const params = {
      type: fileCode,
    }
    arWidgetDownloadModelArchive(product.models[0].id, params)
      .then(response => {
        return response.data.items;
      })
      .then((response) => {

        let link = document.createElement("a");
        // Construct the URI
        link.href = response;
        link.rel ='noopener noreferrer'
        if(newTab) {
          link.target = "_blank"
        }
        document.body.appendChild(link);
        link.click();
        // Cleanup the DOM
        document.body.removeChild(link);

      }).catch((error) => {
      // console.log(error);
    })
    // .finally(() => setDisabled(false));
  }

  const DownLoadLink = ( {text, fileCode, newTab} ) => (
    <Typography className={classes.textLink} onClick={() => handleDownload(fileCode, newTab)}>
      {text}
    </Typography>
  );

  return (
    <Accordion className={classes.accordion} expanded={expanded} onChange={handleChange('panel1')} square>
      <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon/>}>
        <Typography>{$.packetDownload}</Typography>
      </AccordionSummary>
      {!product || !product.models || product.models.length <= 0 ? null :
        <AccordionDetails className={classes.accordionDetails}>

          <Grid container lg={12} spacing={2} direction={'row'} justify={'space-between'}>

            <Grid container lg={6} spacing={2} direction={'column'} justify={'space-between'}>
              <Grid item>
                <h3 className={classes.accordionSubHeading}>{$.name}:</h3>
                <p className={classes.accordionParagraph}>{`${$.archive}: ${product.models[product.models.length - 1].packName}`}</p>
              </Grid>
              <Grid item>
                <p className={classes.accordionSubHeading}>{$.author}:</p>
                <Link>
                  {$.link}
                </Link>
              </Grid>
              <Grid item>
                <p className={classes.accordionSubHeading}>{$.packetWeight}:</p>
                <p className={classes.accordionParagraph}>{`${product.models[product.models.length - 1].size}`}</p>
              </Grid>

            </Grid>

            <Grid container  lg={6} spacing={0} direction={'column'} justify={'space-between'}>
              <Grid item>
                <List subheader={`${$.listFiles}:`} className={classes.accordionSubHeading}>
                  <ListItem className={classes.listItemNoPadding}>
                    <DownLoadLink
                      className={classes.textFullWidth}
                      fileCode={301}
                      text={$.image}
                      newTab={true}
                    />
                  </ListItem>
                  <ListItem className={classes.listItemNoPadding}>
                    <DownLoadLink
                      fileCode={201}
                      text={`GlB: ${product.models[product.models.length - 1].modelGlb}`}
                    />
                  </ListItem>
                  <ListItem className={classes.listItemNoPadding}>
                    <DownLoadLink
                      fileCode={202}
                      text={`USDZ: ${product.models[product.models.length - 1].modelUsdz}`}
                    />
                  </ListItem>
                </List>
              </Grid>

            </Grid>

          </Grid>

        </AccordionDetails>
      }
    </Accordion>
  )
}

export default ProductPanelArchive
