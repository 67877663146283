import {AnimatedAxis, AnimatedGrid, AnimatedLineSeries, Tooltip, XYChart} from "@visx/xychart";
// import styled from "../../../../node_modules/@types/styled-components/index";
import styled from "styled-components"
import {format} from "date-fns";
import classes from './LineChart.module.css'


const tickLabelOffset = 10;

const accessors = {
  xAccessor: (d) => {
    const arr = d.x.split('-')
    const date = `${arr[0]}/${arr[1]}/${arr[2]} 9:00:00 PM`
    return  new Date(date)
  },
  yAccessor: (d) => d.y
};

const ChartContainer = styled.div`
  width: 100%;
  position: relative;  
  overflow: visible;
  text {
    font-family: "Untitled Sans", sans-serif;
  }

  cursor: pointer;

  .visx-axis-tick {
    text {
      font-size: 12px;
      font-weight: 400;
      fill: #666666;
    }
  }
`;

const ColoredSquare = styled.div`
width: 100%;
height: 100%;
  display: inline-block;
  width: 11px;
  height: 11px;
  margin-right: 8px;
  background: ${({ color }) => color};
  border-radius: 4px;
  z-index: 1000;
`;

const TooltipContainer = styled.div`
width: 100%;
  padding: 8px 16px;
  font-size: 12px;
  border-radius: 4px;
  color: #222222;
  position: relative;

  .row {
    margin-bottom: 10px;
  }
  .date {
    font-size: 12px;
    margin-bottom: 8px;
    color: #222222;
    font-weight: 600;
  }
  .value {
    display: flex;
    align-items: center;
    font-weight: 400;
    color: #000000;
  }


`;

const LineChart = ({ data1, data2, width, height }) => {

  if (data1.length < 1) {
    return <></>
  }

  // Пока не нашел решение добавить zIndex к данному элементу через CSS
  window.addEventListener('mousemove', e => {
    if(document.querySelector('.visx-tooltip.visx-tooltip-glyph')){
      document.querySelector('.visx-tooltip.visx-tooltip-glyph').style.zIndex = 1000
    }
  })

  return (
    <ChartContainer>
      <XYChart
        height={height}
        // margin={{ left: 60, top: 35, bottom: 38, right: 27 }}
        xScale={{ type: "time" }}
        yScale={{ type: "linear" }}
      >
        <AnimatedGrid
          columns={false}
          numTicks={4}
          lineStyle={{
            stroke: "#e1e1e1",
            strokeLinecap: "round",
            strokeWidth: 1
          }}
          strokeDasharray="0, 4"
        />
        <AnimatedAxis
          hideAxisLine
          hideTicks
          orientation="bottom"
          tickLabelProps={() => ({ dy: tickLabelOffset })}
          left={30}
          numTicks={4}
        />
        <AnimatedAxis
          hideAxisLine
          hideTicks
          orientation="left"
          numTicks={4}
          tickLabelProps={() => ({ dx: -10 })}
        />
      
        <AnimatedLineSeries
          stroke="#008561"
          dataKey="primary_line1"
          data={data1}
          {...accessors}
        />
       
        {data2 &&
          <AnimatedLineSeries
            stroke="rgba(93, 173, 226, 0.8)"
            dataKey="primary_line2"
            data={data2}
            {...accessors}
          />
        }
        <Tooltip
          className={classes.tooltip}
          snapTooltipToDatumX
          snapTooltipToDatumY
          showSeriesGlyphs
          glyphStyle={{
            fill: '#008561',
            strokeWidth: 0,
            cx: 4,
            cy: 4
          }}
          renderTooltip={({ tooltipData }) => {
            return (
              <TooltipContainer>
                {Object.entries(tooltipData.datumByKey).map((lineDataArray) => {
                  const [key, value] = lineDataArray;

                  return (
                    <div className="row" key={key}>
                      <div className="date">
                        {format(accessors.xAccessor(value.datum), "MMM d")}
                      </div>
                      <div className="value">
                        <ColoredSquare color={value.datum.color} />
                        {accessors.yAccessor(value.datum)}
                      </div>
                    </div>
                  );
                })}
              </TooltipContainer>
            );
          }}
        />
      </XYChart>
    </ChartContainer>
  )
};

export default LineChart