import React, {useEffect, useState} from 'react';
import {arWidgetDeleteCategory, arWidgetGetCategories} from '../../../Api/Utils/Utils';
import ArTable from '../../../Organizms/ArTable/ArTable';
import {ArBreadcrumbs} from '../../../Organizms/Breadcrumbs/ArBreadcrumbs';
import {langManager} from '../../../../utils/languageManager/manager';
// import { currentUser } from '../../../../Helpers/helpers';
import { categoryBreadCrumbs, initialBreadCrumbs } from '../../../../Helpers/helpers';


export default function Categories(props) {
  const setParentId = (parentId) => localStorage.setItem('category', parentId);
  const currentPageSize = sessionStorage.getItem('pageSize');
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  
  
  useEffect(() => {
    document.title = "Категории"
  }, [])


  //Переключение языка
  const [ $, setCategories] = useState(langManager.$.components.categories)
  langManager.subscribe(($) => {
    setCategories($.components.categories)
    document.title = $.components.categories.title
  })
  //******************
  
  return (
    <>
      <ArBreadcrumbs breadCrumbs={[initialBreadCrumbs, categoryBreadCrumbs]}/>
      {currentUser && currentUser.roleName === 'admin'
       ?
      <ArTable
      basePath="/category"
      addBtnText={$.addCategory}
      // parentId={props.options.parentId}
      setParentId={setParentId}
      targetList={{
        get: arWidgetGetCategories,
        delete: arWidgetDeleteCategory,
      }}
      pageSize={currentPageSize}
      tableHeadings={[
        { name: $.organizationName , align: 'left', width: '200px' },
        { name: $.models , align: 'left', width: '200px' },
        { name: $.published , align: 'left', width: '200px' },
        { name: $.unpublished , align: 'left', width: '200px' },
        { name: $.creationDate , align: 'left', width: '200px' },
        { name: $.dateOfEdit , align: 'left', width: '200px' },
        { name: $.organization , align: 'left', width: '200px' },
        { name: $.actions, align: 'center', width: '200px' },
      ]}
      rowCells={[
        { type: 'text', align: 'left', contentKey: 'name', onClick: 'link' },
        { type: 'text', align: 'left', contentKey: 'modelsCount' },
        { type: 'text', align: 'left', contentKey: 'publishedModelsCount' },
        { type: 'text', align: 'left', contentKey: 'notPublishedModelsCount' },
        { type: 'date', align: 'left', contentKey: 'createdAt', color: '#8CC63F' },
        { type: 'date', align: 'left', contentKey: 'updatedAt', color: '#FF931E' },
        { type: 'text', align: 'left', contentKey: 'organization' },
        { type: 'actions', align: 'center' },
      ]}
      filters={[
        {
          name: 'name',
          type: 'input',
          initialValue: '',
          placeholder: $.organizationName,
        },
        {
          name: 'organizationId',
          type: 'autocomplete',
          initialValue: '',
          placeholder: $.organization ,
        },
      ]}/>
    :
      <ArTable
        basePath="/category"
        addBtnText={$.addCategory}
        // parentId={props.options.parentId}
        setParentId={setParentId}
        targetList={{
          get: arWidgetGetCategories,
          delete: arWidgetDeleteCategory,
        }}
        pageSize={currentPageSize}
        tableHeadings={[
          { name: $.organizationName , align: 'left', width: '200px' },
          { name: $.models , align: 'left', width: '200px' },
          { name: $.published , align: 'left', width: '200px' },
          { name: $.unpublished , align: 'left', width: '200px' },
          { name: $.creationDate , align: 'left', width: '200px' },
          { name: $.dateOfEdit , align: 'left', width: '200px' },
          { name: $.actions, align: 'center', width: '200px' },
        ]}
        rowCells={[
          { type: 'text', align: 'left', contentKey: 'name', onClick: 'link' },
          { type: 'text', align: 'left', contentKey: 'modelsCount' },
          { type: 'text', align: 'left', contentKey: 'publishedModelsCount', color: '#8CC63F' },
          { type: 'text', align: 'left', contentKey: 'notPublishedModelsCount', color: '#FF931E' },
          { type: 'date', align: 'left', contentKey: 'createdAt' },
          { type: 'date', align: 'left', contentKey: 'updatedAt' },
          { type: 'actions', align: 'center' },
        ]}
        filters={[
          {
            name: 'name',
            type: 'input',
            initialValue: '',
            placeholder: $.organizationName,
          },
        ]}/>}  
    </>
  );
}
